import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import ObjectItems from "../objectoverview/objectInfo/ObjectItems";
import { Protected } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import Details from "../objectdetails/Details";
import PageNotFound from "../../pages/PageNotFound";
import Settings from "../settings/Settings";
import Support from "../support/Support";
import Config from "../config/Config";

const Content = () => {
  const { isLoggedIn }: { isLoggedIn: boolean } = useAppSelector(
    (state) => state.user
  );

  // Removes the date filter config & sort filter config from local storage
  localStorage.removeItem("dateFilterConfig");
  localStorage.removeItem("filterDateFutureConfig");
  localStorage.removeItem("filterHeaders");

  return (
    <div className="w-[100%]">
      <Routes>
        <Route path={"/*"} element={<PageNotFound />}></Route>
        <Route
          path={"/dashboard"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Dashboard />
            </Protected>
          }
        ></Route>
         <Route
          path={"/config"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Navigate to={"article"}  />
            </Protected>
          }
        ></Route>
        <Route
          path={"/config/:id"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Config />
            </Protected>
          }
        ></Route>

        <Route
          path={"/settings"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Settings />
            </Protected>
          }
        ></Route>
        <Route
          path={"/support"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Support />
            </Protected>
          }
        ></Route>
        {/* Objects Overview route */}
        <Route
          path={"/objects"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Navigate to={"article"} />
            </Protected>
          }
        ></Route>
        <Route
          path={"objects/:id"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <ObjectItems />
            </Protected>
          }
        ></Route>
        {/* Tracking Overview route */}
        <Route
          path={"/tracking"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Navigate to={"product"} />
            </Protected>
          }
        ></Route>
        <Route
          path={"/tracking/:id"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <ObjectItems />
            </Protected>
          }
        ></Route>
        <Route
          path={"/analysis"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Navigate to={"machine"} />
            </Protected>
          }
        ></Route>
        {/* Analysis Overview route */}
        <Route
          path={"/analysis/:id"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <ObjectItems />
            </Protected>
          }
        ></Route>
        {/* Object Details route */}
        <Route
          path={`/:id/:id`}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Details />
            </Protected>
          }
        >
          <Route
            path={`:id/*`}
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <Details />
              </Protected>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default Content;
