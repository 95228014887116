import React from "react";

const FormHeaderComponent = ({
  title,
  details,
  resize
}: {
  title: string;
  details?: string;
  resize?: boolean;
}) => {
  return (
    <div>
      <div className="logo-container w-[60px] mx-auto">
        <img src="/assets/Yobibytes_logo.svg" alt="Yobibytes_logo" className=""/>
      </div>
      <div className={`login-text-container ${resize && 'w-[80%]'} w-[90%] mx-auto`}>
        <h2 className="text-[30px] font-[600] text-center mt-[33px] text-[#3A6A78]">
          {title}
        </h2>
        <h3 className="text-[16px] font-[400] text-center mt-[12px] text-[#667085]">
           {details}
        </h3>
      </div>
    </div>
  );
};

export default FormHeaderComponent;
