import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  lineGraphSelector,
  setGlobalConfiguration,
} from "../../redux/lineGraph/lineGraphSlice";
import CustomButton from "../generics/CustomButton";
import DateTimePickerSelector from "../generics/DateTimePickerSelector";
import { substractAnHourFromDate } from "../generics/utilities/lineGraphUtils";
import {
  formatDateForRequest,
  timeOperation,
} from "../generics/utilities/utils";
import {
  getDateTimeFromAndToFromContainedProducts,
  getDateTimeFromAndToFromProductionOrder,
} from "./apiCalls/AnalysisApiCalls";
import { Resolution } from "./components/Dimension";
import TimeSeriesSelector from "./components/TimeSeriesSelector";
import GraphContainer from "./GraphContainer";
import GraphContainer1 from "./GraphContainer1";

// Returns the date time to request configuration time series
const requestConfigurationTimeSeries = (type?: string) => {
  if (type && type === "dateTimeFrom")
    return formatDateForRequest(
      substractAnHourFromDate(new Date(), 1).toISOString()
    );

  return formatDateForRequest(new Date().toISOString());
};

const SpecificMachineView = () => {
  const location: any = useLocation();
  const pathUrlHasAnalysis = location.pathname.includes("analysis");
  const dispatch = useAppDispatch();
  const lastElementRef = useRef<null | HTMLDivElement>(null);
  const objectType = location?.state?.ObjectType;
  const timeStampCreation = location?.state?.TimeStampCreation;
  const { globalConfiguration } = useAppSelector(lineGraphSelector);
  const timer = useRef<any>(null);

  // Holds the date base configuration that the LineGraphFilter component uses
  const [dateConfiguration, setDateConfiguration] = useState({
    dateTimeFrom:
      objectType === "Product"
        ? formatDateForRequest(
            timeOperation(
              "30 m",
              "substract",
              formatDateForRequest(new Date(timeStampCreation).toISOString())
            )
          )
        : requestConfigurationTimeSeries("dateTimeFrom"),
    dateTimeTo:
      objectType === "Product"
        ? formatDateForRequest(
            timeOperation(
              "30 m",
              "add",
              formatDateForRequest(new Date(timeStampCreation).toISOString())
            )
          )
        : requestConfigurationTimeSeries(),
    resolution: 20,
  });
  const [startDateTimeSeries, setStartDateTimeSeries] = useState<{
    startTime: string;
    endTime: string;
  }>({
    startTime: "",
    endTime: "",
  });

  // Updates the global configuration state.
  useEffect(() => {
    dispatch(
      setGlobalConfiguration({
        dateTimeFrom: dateConfiguration.dateTimeFrom,
        dateTimeTo: dateConfiguration.dateTimeTo,
        resolution: dateConfiguration.resolution,
      })
    );

    setStartDateTimeSeries({
      startTime: dateConfiguration.dateTimeFrom,
      endTime: dateConfiguration.dateTimeTo,
    });
  }, [
    dateConfiguration?.dateTimeFrom,
    dateConfiguration?.dateTimeTo,
    dateConfiguration?.resolution,

    dispatch,
  ]);

  // Gets the contained Products of Package
  const getContainedProducts = useCallback(() => {
    getDateTimeFromAndToFromContainedProducts(location, timer);
  }, [location]);

  // Gets the relationships ValidFrom and ValidTo from ProductionOrder
  const getProductionOrderDateTime = useCallback(() => {
    getDateTimeFromAndToFromProductionOrder(location, timer);
  }, [location]);

  useEffect(() => {
    if (location?.state?.ObjectType === "Package") {
      getContainedProducts();
    } else if (location?.state?.ObjectType === "ProductionOrder") {
      getProductionOrderDateTime();
    }
  }, [
    getContainedProducts,
    getProductionOrderDateTime,
    location?.state?.ObjectType,
  ]);

  useEffect(() => {
    setDateConfiguration((prev: any) => {
      return {
        ...prev,
        dateTimeFrom: globalConfiguration.dateTimeFrom,
        dateTimeTo: globalConfiguration.dateTimeTo,
      };
    });
  }, [globalConfiguration.dateTimeFrom, globalConfiguration.dateTimeTo]);

  // Holds the chart components
  const [graphCharts, setGraphCharts] = useState([
    <GraphContainer1 lastElementRef={null} key={0} />,
  ]);

  /* Functions starts here  */
  const addLineChart = useCallback(() => {
    setGraphCharts([
      ...graphCharts,
      <GraphContainer1
        lastElementRef={lastElementRef}
        key={graphCharts.length}
      />,
    ]);
  }, [graphCharts]);

  // Sets the start date
  const getStartDate = useCallback((startDateVal: string) => {
    let dateTimeFrom = formatDateForRequest(startDateVal);

    if (dateTimeFrom.includes("AM")) {
      dateTimeFrom = dateTimeFrom.replace("AM", "");
    }
    if (dateTimeFrom.includes("PM")) {
      dateTimeFrom = dateTimeFrom.replace("PM", "");
    }

    setDateConfiguration((prev: any) => {
      return {
        ...prev,
        dateTimeFrom: dateTimeFrom,
      };
    });
  }, []);

  // Sets the end date
  const getEndDate = useCallback((endDateVal: string) => {
    let dateTimeTo = formatDateForRequest(endDateVal);
    if (dateTimeTo.includes("AM")) {
      dateTimeTo = dateTimeTo.replace("AM", "");
    }
    if (dateTimeTo.includes("PM")) {
      dateTimeTo = dateTimeTo.replace("PM", "");
    }
    setDateConfiguration((prev: any) => {
      return { ...prev, dateTimeTo: dateTimeTo };
    });
  }, []);

  // Updates the config when Resolution value changes
  const getResolution = (resolutionValue: number) => {
    setDateConfiguration((prev) => {
      return { ...prev, resolution: resolutionValue };
    });
  };

  // Updates the configData with the parameter objects
  const setConfigData = (parameter: string) => {
    setDateConfiguration((prev: any) => {
      return {
        ...prev,
        dateTimeFrom: parameter,
        dateTimeTo: formatDateForRequest(new Date().toISOString()),
      };
    });
    setStartDateTimeSeries(() => ({
      startTime: parameter,
      endTime: formatDateForRequest(new Date().toISOString()),
    }));
  };

  // Renders the Graph and It's Button
  const displayGraph = () => {
    return (
      <>
        <>
          <div className="charts-container w-full space-y-8 px-10">
            {graphCharts}
          </div>
          <div className="flex items-center justify-center py-2 mt-4">
            <CustomButton
              text={"Add Chart"}
              action={addLineChart}
            ></CustomButton>
          </div>
        </>
      </>
    );
  };

  /* Functions ends here  */
  return (
    <div className="w-[98%] mt-[35px] pt-6 rounded-[8px] h-full   border-[#EAECF0] bg-[#fff] shadow-[0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)]">
      {pathUrlHasAnalysis && (
        <div className="flex justify-between">
          <div className="ml-10 mb-6   flex gap-4">
            {/*<div className="w-[100%]">
              <SelectField
                placeholder="Object Parameters"
                options={getObjectParameters()}
                handleObjectRelationshipSelect={handleSelectChange}
              />
      </div>*/}
            <DateTimePickerSelector
              isLineGraph={true}
              getStartDate={getStartDate}
              isDate={"START_DATE"}
              startDateTimeSeries={startDateTimeSeries.startTime}
            />
            <DateTimePickerSelector
              isLineGraph={true}
              isDate={"END_DATE"}
              minValue={dateConfiguration.dateTimeFrom}
              getEndDate={getEndDate}
              startDateTimeSeries={startDateTimeSeries.endTime}
            />
            <TimeSeriesSelector
              setConfig={setConfigData}
            />
          </div>
          <div className="mr-10">
            <Resolution getResolution={getResolution} />
          </div>
        </div>
      )}

      <div className="charts-container w-full space-y-8 px-10">
        {pathUrlHasAnalysis ? displayGraph() : <GraphContainer />}
      </div>

      <div ref={lastElementRef}></div>
    </div>
  );
};

export default SpecificMachineView;
