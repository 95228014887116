import { FC, ReactNode } from "react"
import { setIsOpenRightSidebar } from "../../../redux/graph/graphSlice"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"


type Props = {
  childComponent?: ReactNode
  pushDownValue: string
  sidebarWidth?: number
}

const RightSidebar: FC<Props> = ({
  childComponent,
}) => {
  const dispatch = useAppDispatch()
  // STORE STATE
  const { isOpenRightSidebar } = useAppSelector(
    (state: any) => state?.graphReducer
  )

  return (
    <div
      className={` ${
        isOpenRightSidebar
          ? "w-[340px] h-[100%] max-h-screen "
          : "w-[0rem] hidden"
      } bg-white border  h-screen  absolute top-0 bottom-0 right-0 overflow-y-scroll right-sidebar`}
    >
      <div className="w-full h-5 bg-[#ffffff] top-0 right-0 sticky z-20"></div>
      <div className="absolute -left-[45px] top-[14px]">
        <img
          src="/assets/leftSidebar/rightMenu.svg"
          // the style fixed here is overriding the parent overflow effect, that hides this image
          className={`border rounded-md rounded-r-none p-3 fixed ${
            !isOpenRightSidebar && "ml-2"
          } cursor-pointer mr-5`}
          onClick={() => dispatch(setIsOpenRightSidebar(!isOpenRightSidebar))}
          alt=""
        />
      </div>
      {/* this shows the details of graph */}
      {childComponent}
    </div>
  )
}

export default RightSidebar
