import _ from "lodash";
import moment from "moment";

const randomColor = require("randomcolor"); // import the script

//format the graph options groups

export const formatGroups = (data: any) => {
  return data.reduce(function (acc: any, cv: any) {
    return {
      ...acc,
      [cv.group]: {
        color: {
          background: randomColor({
            luminosity: "light",
            format: "hsla", // e.g. 'hsla(27, 88.99%, 81.83%, 0.6450211517512798)'
          }),
          border: randomColor({
            luminosity: "light",
            format: "hsla", // e.g. 'hsla(27, 88.99%, 81.83%, 0.6450211517512798)'
          }),
        },
      },
    };
  }, {});
};

export const truncate = (string: string, len: number) => {
  const dots = string?.length > len ? "..." : "";
  return string?.substring(0, len) + dots;
};

// finder: takes an array, value, and key and returns the found item
export const finder = (array: any, key: any, value: any) => {
  return array?.find((obj: any) => obj?.[key] === value);
};

// return values of all checkedboxes
export const getValuesOfAllCheckedInputs = (inputsArray: any): any[] => {
  let newSelectedRelatedMaps = [];
  for (const element of inputsArray) {
    if (element.checked === true) {
      newSelectedRelatedMaps.push(element.value);
    }
  }
  return newSelectedRelatedMaps;
};

// get the values of a property
export const pluck = (arryOfobjs: any, key: string) =>
  arryOfobjs?.map((obj: any) => obj[key]);

// uncheck all checkboxes
export const uncheckAllCheckedInputs = (inputsArray: any) => {
  for (const element of inputsArray) {
    element.checked = false;
  }
};

// check all checkboxes
export const checkAllCheckedInputs = (inputsArray: any) => {
  for (const element of inputsArray) {
    element.checked = true;
  }
};

export const formatDateTime = (value: string, key: string = "") => {
  if(key === "Label"){
    console.log("key.match(/_/g)?.length")
    console.log(key)
    console.log(value.match(/_/g))
    if(value.match(/_/g)?.length === 2){
      var firstIndex = value.indexOf("_");
      var secondIndex = value.indexOf("_", firstIndex + 1);
      console.log("value.substring(firstIndex + 1, secondIndex);")
      console.log(value.substring(firstIndex + 1, secondIndex))
      return value.substring(firstIndex + 1, secondIndex);
    }
    return value
  } else {
    const isFalsePositive = !Number.isNaN(+value);

    if (!isFalsePositive && moment(value).isValid()) {
      let d = new Date(value);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      let year = d.getFullYear();
      let hours = "" + d.getHours();
      let minutes = "" + d.getMinutes();
      let seconds = "" + d.getSeconds();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;

      if (minutes.length < 2) minutes = "0" + minutes;
      if (seconds.length < 2) seconds = "0" + seconds;
      return (
        [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")
      );
    } else {
      return value;
    }
  }
};

// split string buy uppercase

export const formatString = (value: any) => {
  if (value === undefined) return;
  if (value.length === 1) {
    return value;
  } else {
    const _formatArr = [...value.split(/(?=[A-Z])/).join(" ")]
      .join("")
      .split(" ");
    return [..._formatArr]
      .map((_value, idx, arr) => {
        if (_value.length === 1 && arr[idx + 1]?.length === 1) {
          return _value + arr[idx + 1];
        }
        return _value;
      })
      .filter((val) => val.length > 1)
      .join(" ");
  }
};

export const split_snake_case = (value: any) => {
  if (value === undefined) return;
  return value.split("_").join(" ");
};

export const formatGraphRelationshipForRequest = (
  objects: any,
  objectType: string
) => {
  let result = [];
  for (const key in objects?.Config[`${objectType}`]?.["Relationships"]) {
    result?.push({
      RelatedObjectName: key,
      OnlyActive: true,
    });
  }
  result = _.uniqBy(result, "RelatedObjectName");

  return result;
};

// return the relations types
export const getSelectedRelationshipType = (
  objects: any,
  parentObj: string,
  childObjType: string
) => {
  return objects?.Config[`${parentObj}`]?.["Relationships"][childObjType]?.[
    "Type"
  ];
};

// return the selected object relationships
export const getSelectedObjectRelationships = (
  objects: any,
  parentObj: string
) => {
  const relatedObjects = objects?.Config[`${parentObj}`]?.["Relationships"];
  let relationships: any[] = [];
  for (const key in relatedObjects) {
    relationships.push(relatedObjects[key]);
  }
  return relationships;
};

// ==================Generate A Contrasting Text=======================

interface RGB {
  b: number;
  g: number;
  r: number;
}

function rgbToYIQ({ r, g, b }: RGB): number {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

function hexToRgb(hex: string): RGB | undefined {
  if (!hex || hex === undefined || hex === "") {
    return undefined;
  }

  const result: RegExpExecArray | null =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
}

export function contrast(
  colorHex: string | undefined,
  threshold: number = 128
): string {
  if (colorHex === undefined) {
    return "#000";
  }

  const rgb: RGB | undefined = hexToRgb(colorHex);

  if (rgb === undefined) {
    return "#000";
  }

  return rgbToYIQ(rgb) >= threshold ? "#000" : "#fff";
}

//remove null from an array
export const removeNull = (array: any[]) => array.filter((x) => x !== null);

//comparing the items in arrays and objects
export const depCompare = (oldDependencies: any[], newDependencies: any[]) => {
  return (
    oldDependencies.length === newDependencies.length &&
    oldDependencies.every((dep, i) => dep === newDependencies[i])
  );
};

export const getValue = (obj: any, path: any) =>
  path
    .replace(/\[([^[\]]*)]/g, ".$1.")
    .split(".")
    .filter((prop: any) => prop !== "")
    .reduce(
      (prev: any, next: any) =>
        prev instanceof Object ? prev[next] : undefined,
      obj
    );

export const removeDuplicates = (baseNode: any, child: any) => {
  let duplicateResult = [];
  for (let keys in baseNode) {
    if (!child.includes(baseNode[keys])) {
      duplicateResult.push(baseNode[keys]);
    }
  }
  return duplicateResult;
};

// Filters through the canvas and removes the deselected values
export const removeDeselectedObjectRelationshipFromCanvas = (
  storeGraph: Array<any>,
  deselectedNodes: Array<any>
) => {
  return storeGraph.filter((item: any) => {
    return !deselectedNodes.some((val: any, index: number) => {
      return item.id === val.id && item.diveDepth === val.diveDepth;
    });
  });
};

export const removeDuplicatesFromNextRelatedObject = (
  storeGraph: any,
  nextFormattedData: any
) => {
  return nextFormattedData.filter((item: any) => {
    return !storeGraph.every((val: any, index: number) => {
      return item.id.toString() === val.id.toString();
    });
  });
};

// Updates the dive depth of the currently fetched object
export const updateCurrentNodeWithDepth = (
  currentNodes: any,
  diveDepth: number
) => {
  return currentNodes.map((node: any) => {
    node.diveDepth = diveDepth + 1;
    return node;
  });
};

//capitalize only the first letter of the string.
export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
