import { UsersState } from "../../types/usersTypes";


export const initialState: UsersState = {
  users: [],
  loading: true,
  error: null,
  totalNumberOfUsers: 0,
  totalQuantityUsersCurrent: 0,
  page: 0,
}
