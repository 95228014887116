export const productsBackwardTrackingPathMap = [
    {
        RelatedObjectTypes: [
            "Product"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct"
            }
        ]
    }
]