import React from 'react'

const PageNotFound = () => {
  return (
    <div className='pageNotFound flex items-center justify-center border-2 h-[100vh]'>
        <h3 className="text-[30px] font-semibold">Sorry, page not found</h3>
    </div>
  )
}

export default PageNotFound