import { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getObjects, objectSelector } from "../../../redux/objects/objectSlice";
import { getObjectType } from "../../generics/utilities/utils";
import { updateConfigProps } from "../../generics/utils/api/objects/configUpdateApiCalls";

interface Props {
  value: string;
  label: string;
  activeObject: string;
  setSuccessMessage: (value: string) => void;
}

const GeneralInformation: FC<Props> = ({ value, label, activeObject, setSuccessMessage }) => {
  const dispatch = useAppDispatch();
  const { objects } = useAppSelector(objectSelector);
  const [newValue, setNewValue] = useState(value);
  const [duplicateNotification, setDuplicateNotification] = useState("");
  // Get object Type
  let currentObjectString = getObjectType(objects, activeObject);

  const [isLoading, setIsLoading] = useState(false);

  const autoSaveChanges = useCallback(
    debounce((valueProp) => {
      if (label === "Name") {
        if (value === valueProp) {
          setDuplicateNotification("ObjectType already exist!");
          setTimeout(() => {
            setDuplicateNotification("");
          }, 1000);
          return;
        }
        setIsLoading(true);
        updateConfigProps(currentObjectString, label, valueProp).then((res) => {
          setIsLoading(false);
          if (res?.status === 200) {
            setSuccessMessage(`${label} was saved successfully!`);
            dispatch(getObjects());
            setTimeout(() => {
              setSuccessMessage("");
            }, 10000);
          }
        });
      } else {
        setIsLoading(true);
        updateConfigProps(currentObjectString, label, valueProp).then(
          (response) => {
            setIsLoading(false);
            if (response?.status === 200) {
              setSuccessMessage(`${label} was saved successfully!`);
              dispatch(getObjects());
              setTimeout(() => {
                setSuccessMessage("");
              }, 10000);
            }
          }
        );
      }
    }, 3000),
    []
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewValue(e?.target?.value);
      autoSaveChanges(e?.target?.value);
    },
    [autoSaveChanges]
  );

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const setIconColor = (val: string) => {
    return val ? "red" : "#667085";
  };

  return (
    <div className="my-1">
      <p className="">{label}</p>
      <div className="my-2 flex justify-start align-middle items-center">
        <form className="w-[21%] ">
          <div className={`my-2 flex justify-start align-middle items-center`}>
            <input
              className={`px-3 py-2 w-full outline-none border-primary-borderColor ${
                duplicateNotification ? "border-[red]" : ""
              } text-primary-textNormal placeholder-primary-textMuted text-xs rounded-l-md`}
              type="text"
              name={label}
              onChange={handleInputChange}
              id={label}
              placeholder="Enter new value"
              value={`${newValue}`}
            />
            <button
              type="submit"
              className={`px-3 py-2 border-[1px] border-primary-borderColor ${
                duplicateNotification ? "border-[red]" : ""
              } rounded-r-md`}
            >
              {isLoading ? (
                <ClipLoader size={14} loading={isLoading} color="#667085" />
              ) : (
                <FiEdit2
                  data-type={"editUser"}
                  color={setIconColor(duplicateNotification)}
                  size={16}
                  style={{ cursor: "pointer" }}
                />
              )}
            </button>
          </div>
          {duplicateNotification && (
            <span className="text-[red]">{duplicateNotification}</span>
          )}
        </form>
      </div>
    </div>
  );
};

export default GeneralInformation;