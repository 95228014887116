import React from "react";

const Support = () => {
  return (
    <div className="flex items-center justify-center h-[100vh]">
      <div className="">
        <p className="text-[30px] font-[600] text-primary-purpleLight">Support Coming Soon!</p>
        <img className="mt-10" src="/assets/illustration.svg" alt="illustration" />
      </div>
    </div>
  );
};

export default Support;
