import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SxProps } from "@mui/material";
import {
  activeObjectSelector,
  setSelectedTableRelationship,
} from "../../../redux/activeObject/activeObject";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  availableParameters: {
    loading: boolean;
    error: boolean;
    parameters: Array<string>;
  };
  setValuesToParent: (values: any) => void;
  defaultParameter?: Array<string>;
  multiple?: boolean;
  placeHolder?: string;
};
const MultiSelectField: React.FC<Props> = ({
  availableParameters,
  setValuesToParent,
  defaultParameter,
  multiple,
  placeHolder = "Parameters",
}) => {
  const [newValue, setNewValue]: any = useState(
    defaultParameter ? defaultParameter?.[0] : null
  );

  const { activeObjectType } = useAppSelector(activeObjectSelector);
  const dispatch = useAppDispatch();

  const style: SxProps = {
    "& label.Mui-focused": {
      color: "gray",
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px !important",
      backgroundColor: "#fff !important",
      maxWidth: "400px !important",
      maxHeight: "40px !important",
      overflow: "hidden !important",
      "& .Mui-focused fieldset": {
        border: "solid 1px #3A6A78",
        borderRadius: "8px !important",
      },

      "& .css-1q60rmi-MuiAutocomplete-endAdornment:before": {
        content: `${multiple && newValue?.length > 1 ? '"..."' : '""'}`,
        position: "relative",
        top: "3px !important",
        left: "10px",
      },
      "& input[type='text']": {
        position: "relative",
        bottom: `${
          multiple && newValue?.length > 0 ? "9px !important" : "0px !important"
        }`,
      },
      "& .css-kxqv5u-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused": {
        border: "solid 1px red !important",
      },
    },
  };

  useEffect(() => {
    if (multiple) {
      // Resets the Component
      setNewValue([]);
      dispatch(setSelectedTableRelationship([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObjectType]);

  const setSelectedData = (value: Array<string> | string) => {
    setValuesToParent(value);
    setNewValue(value);
  };

  return (
    <div className="Multi-SelectField-container">
      <Autocomplete
        fullWidth
        multiple={multiple ? true : false}
        id="checkboxes-tags-demo"
        ListboxProps={{
          className: "myCustomList",
        }}
        loading={availableParameters.loading}
        options={availableParameters.parameters ?? []}
        value={newValue || null}
        closeText={""}
        sx={{
          "& #checkboxes-tags-demo": {
            whiteSpace: "normal",
          },
          // '.MuiOutlinedInput-notchedOutline': { border: 0 }
        }}
        disableCloseOnSelect
        onChange={(e: any, value: any) => setSelectedData(value)}
        getOptionLabel={(option) => {
          if(Object.keys(option).length === 0){
            return "";
          }
          return option;
        }}
        isOptionEqualToValue={(option, value) => {return option === value}}
        renderOption={(props, option, { selected }) => {
          //  Check if parameters has value else returns an empty array
          if (availableParameters.parameters.length > 0) {
            return (
              <li {...props}>
                <div>
                  <Checkbox
                    className="border-2 border-[tomato]"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </div>
              </li>
            );
          }
        }}
        // style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            className="Multi-select"
            {...params}
            sx={style}
            InputLabelProps={{ shrink: true }}
            size="small"
            placeholder={multiple && newValue?.length > 1 ? "" : placeHolder}
          />
        )}
      />
    </div>
  );
};

export default MultiSelectField;
