import { TableBody } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { resetFilterObjects } from "../../redux/graph/filterSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchObject,
  objectDetailsSelector,
} from "../../redux/objects/objectDetailsSlice";

import { headerTypes } from "../../types/products";
import AFTPalette from "../generics/aftpalette";
import GeneralCompHeader from "../generics/utilities/GeneralCompHeader";
import { machineColumnHeaders } from "../generics/utilities/utils";
import { formatDateTime } from "../generics/utils/utils";
import ViewContainer from "../generics/ViewContainer";
import { ErrorPage } from "../graph/error/ErrorPage";
import CustomTable from "../table/CustomTable";
import TableHeader from "../table/TableHeader";
import TableRow from "../table/TableRow";
import AnalysisRelatedObjects from "./AnalysisRelatedObjects";
import Rollups from "../objectdetails/Rollups";

const AnalysisOverview = ({
  state: propState,
  setValue,
}: {
  state: any;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const generalHeader = machineColumnHeaders;
  let locationState: any = useLocation()

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { error, objectInfo, loading } = useAppSelector(objectDetailsSelector);

  useEffect(() => {
    // Resets the filtered objects to prevent rendering of the cached data when
    // the user switches between the tab
    dispatch(resetFilterObjects());
  }, [dispatch]);

  useEffect(() => {
    if (propState !== null) {
      dispatch(
        fetchObject({
          externalId: propState?.["ExternalID"],
          objectType: propState?.["ObjectType"],
        })
      );
      localStorage.setItem("state", JSON.stringify(propState));
    } else {
      navigate(-1);
    }
  }, [dispatch, navigate, propState]);

  const showGeneralInformation = () => {
    if (error) return <ErrorPage error={error} />;
    if (typeof objectInfo?.[0]?.["Name"] === "undefined") {
      return (
        <div className="flex items-center justify-center h-[200px]">
          <ClipLoader
            size={30}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      );
    } else {
      return (
        <div className="generalInfo-table relatedObjects-table-bgColor overflow-x-auto mt-[32px] w-[98%] md:w-[100%] lg:w-full border-[1px] rounded-[8px]">
          <CustomTable elementKey="general-Information">
            <TableHeader
              pageType={headerTypes.objectDetailsOverview}
              showSortIcon={false}
              tableHeader={generalHeader.concat(["Last Updated Date"])}
            />
            <TableBody>
              <>
                <TableRow key={"generalInformation-row"}>
                  <>
                    <th></th>
                    <td className="relative table-cell-Style">
                      {objectInfo?.[0]?.["Name"]}
                    </td>
                    <td className="relative table-cell-Style">
                      {objectInfo?.[0]?.["ExternalID"]}
                    </td>
                    <td className="relative table-cell-Style">
                      {formatDateTime(
                        objectInfo?.[0]?.["TimeStampDBInsertion"]
                      )}
                    </td>
                    <td className="relative table-cell-Style">
                      {formatDateTime(
                        objectInfo?.[0]?.["TimeStampDBLastUpdate"]
                      )}
                    </td>
                  </>
                </TableRow>
              </>
            </TableBody>
          </CustomTable>
        </div>
      );
    }
  };

  //   Checks and returns a boolean value value that determines whether the attributes table should be shown
  const showAttributesFlag = (objectInfoDetails: any) => {
    let tableData: Array<boolean> | boolean =
      objectInfoDetails?.[0] &&
      Object?.entries(objectInfoDetails?.[0])?.map(([key, objectValue]) => {
        if (typeof objectValue === "object") {
          return false;
        }
        if (
          key !== "RelatedObjects" &&
          key !== "TimeStampDBInsertion" &&
          key !== "TimeStampDBLastUpdate" &&
          key !== "ID" &&
          key !== "ObjectType" &&
          key !== "ExternalID" &&
          key !== "Name"
        ) {
          return true;
        }
        return false;
      });

    return typeof tableData === "boolean"
      ? tableData
      : tableData?.includes(true);
  };

  const showAttributesTable = (objectInfoData: any) => {
    if (loading) {
      return (
        <div className="flex items-center justify-center">
          <ClipLoader
            size={35}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      );
    }

    if (error) return <ErrorPage error={error} />;

    // Checks if loading is false and renders the att
    if (!loading && showAttributesFlag(objectInfoData)) {
      return (
        <>
          <div className="tracking-details">
            <div className="attributesTable min-h-[90px] max-h-[270px]  overflow-y-scroll">
              <table className="w-full  " key="attributes">
                <TableHeader
                  showSortIcon={false}
                  pageType={headerTypes.objectDetailsOverview}
                  tableHeader={["Name", "Value"]}
                />
                <tbody className="">
                  {typeof objectInfoData?.[0] !== "undefined" &&
                    Object.keys(objectInfoData?.[0])?.length > 0 &&
                    Object?.entries(objectInfoData?.[0])?.map(
                      ([key, objectValue]) => {
                        if (typeof objectValue === "object") {
                          return null;
                        }
                        if (
                          key !== "RelatedObjects" &&
                          key !== "TimeStampDBInsertion" &&
                          key !== "TimeStampDBLastUpdate" &&
                          key !== "ID" &&
                          key !== "ObjectType" &&
                          key !== "ExternalID" &&
                          key !== "Name"
                        ) {
                          const ItemsKey = key;
                          return (
                            <tr
                              className="border-t-[0.1px] general-text-color"
                              key={ItemsKey}
                            >
                              <th></th>
                              <td className="table-cell-Style">{ItemsKey}</td>
                              <td className="table-cell-Style">
                                {objectValue as any}
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      }
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {!loading &&
            typeof objectInfoData?.[0] !== "undefined" &&
            objectInfoData && (
              <div>
                <h2 className="text-center text-[20px] pb-[34px]">
                  No Attributes
                </h2>
              </div>
            )}
        </>
      );
    }
  };

  return (
    <div className="h-[88vh] overflow-y-scroll overviewContainer">
      <ViewContainer>
        <div className="Details-view-wrapper lg:pb-10">
          <div className="generalInformationContainer">
            <GeneralCompHeader />
            <>{showGeneralInformation()}</>
          </div>
          {/* Other Attributes */}

          <div className="relatedObject mb-20  mt-[32px]  ">
            <h5 className="text-[18px] font-[600] ml-2 mb-[24px]  ">
              Attributes
            </h5>
            {/* Shows the attributes table */}
            {showAttributesTable(objectInfo)}
          </div>

          <AnalysisRelatedObjects state={locationState?.state} />
          {<Rollups />}
        </div>
      </ViewContainer>
    </div>
  );
};

export default AnalysisOverview;
