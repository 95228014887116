import React from "react";

const ErrorMessageComp = ({
  errorMessage,
  deleteObjectType = false,
}: {
  errorMessage: string;
  deleteObjectType?: boolean;
}) => {
  return (
    <div
      className={`error-container border-[1px] border-[#FDA29B] flex ${
        deleteObjectType ? "p-[16px]" : "p-[16px] mt-[32px] "
      } rounded-[8px] bg-[#FFFBFA]`}
    >
      <div>
        <img src="/assets/error_icon.svg" alt="" />
      </div>
      <p className="ml-[13px] text-[14px] text-[#B42318]">{errorMessage}</p>
    </div>
  );
};

export default ErrorMessageComp;
