import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Content from "../components/content/Content"
import MobileNav from "../components/sideBar/MobileNav"
import Sidebar from "../components/sideBar/Sidebar"
import TopBar from "../components/topBar/TopBar"
import { useAppSelector } from "../redux/hooks"

const Home = () => {
  // STORE STATE
  const { isOpenCanvas } = useAppSelector((state: any) => state.graphReducer)
  const navigate = useNavigate()
  let path: any = window.location.pathname.split("/")
  path = path[path.length - 1]
  useEffect(() => {
    (async () => {
      if (path === "home") {
        setTimeout(() => {
          navigate("dashboard", {
            replace: true,
          })
        }, 500)
      }
    })()
  }, [path, navigate])

  return (
    <div className="relative">
      <MobileNav />
      <div className="page-Item-Container flex">
        <div
          className={`${
            isOpenCanvas && "md:hidden lg:hidden"
          }"absolute sticky top-0 bottom-0 left-0 z-10 h-[100vh] border-r-[1px] border-[#EAECF0]"`}
        >
          <Sidebar />
        </div>
        <div className="relative w-full md:w-[100%] lg:left-0 lg:w-full h-[100%]">
          <div className="relative">
            <TopBar />
          </div>
          <div className="relative md:border-l-[1px]  -top-12 page-Item-Container z-20 h-[90vh] rounded-t-[32px]">
            <Content />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
