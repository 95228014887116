import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";

const AUTH_TOKEN_KEY: string = "authToken";
export function isTokenActive(token: any) {
  if (!token) return false;
  const expirationDate = getTokenExpirationDate(token);
  const now = Math.floor(Date.now() / 1000);
  return expirationDate > now;
}

export function getTokenExpirationDate(encodedToken: any) {
  const token: any = jwt_decode(encodedToken);
  if (!token?.exp) {
    return null;
  }
  return token.exp;
}

export function setAuthToken(token: string) {
  return Cookies.set(AUTH_TOKEN_KEY, JSON.stringify(token));
}

export const getAuthToken = () => {
  const token = Cookies.get(AUTH_TOKEN_KEY) || null;
  return token ? JSON.parse(token) : null;
};

export function clearAuthToken() {
  axios.defaults.headers.common.Authorization = "";
  return Cookies.remove(AUTH_TOKEN_KEY);
}

export function decodeToken(token: string) {
  return jwt_decode(token) || null;
}
