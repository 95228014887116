import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../services/api";

export interface LineGraphState {
  records: any;
  globalConfiguration: {
    dateTimeFrom: string;
    dateTimeTo: string;
    resolution: number;
  };
  chartAnalysisRequestState: {
    loading: boolean;
    success: boolean;
    error: string;
  };
  loading: boolean;
  error: string;
  currentExecutedAtMachineRelationships: Array<any>;
}

const initialState: LineGraphState = {
  records: [],
  chartAnalysisRequestState: {
    loading: false,
    success: false,
    error: "",
  },
  loading: false,
  error: "",
  globalConfiguration: {
    dateTimeFrom: "",
    dateTimeTo: "",
    resolution: 0,
  },
  currentExecutedAtMachineRelationships: [],
};

export const getLineGraphRecords = createAsyncThunk(
  "getLineGraphRecords",
  async (payload: any, { rejectWithValue }) => {
    const graphParams = {
      ObjectType: payload?.ObjectType,
      DateTimeFrom: payload?.DateTimeFrom,
      DateTimeTo: payload?.DateTimeTo,
      Dimensions: JSON.stringify({
        Machine: "bleb",
      }),
      GroupDimensions: JSON.stringify(payload?.GroupDimensions),
      MeasureNames: JSON.stringify(payload?.MeasureNames),
      Resolution: payload?.Resolution,
    };

    if(graphParams.MeasureNames !== "[\"\"]" &&  graphParams.MeasureNames !== "[]"){

      try {
        const res = await Api({
          url: "/object/timeseries/record",
          method: "GET",
          params: graphParams,
        });

        if (
          res.status === 200 &&
          res.data &&
          Object?.keys(res.data.Records)?.length > 0
        ) {
          return res.data.Records;
        } else {
          return rejectWithValue("No data available to plot graph");
        }
      } catch (err) {
        return rejectWithValue(
          "Failure fetching Data. Please check your configurations"
        );
      }
    }
    else
    {
      return rejectWithValue(
        "Please choose a parameter"
      );
    }
  }
);

const lineGraphSlice = createSlice({
  name: "lineGraph",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setGlobalConfiguration: (state, { payload }: PayloadAction<any>) => {
      state.globalConfiguration = payload;
    },
    resetGlobalConfiguration: (state) => {
      state.globalConfiguration = {
        dateTimeFrom: "",
        dateTimeTo: "",
        resolution: 20,
      };
    },
    setChartAnalysisRequestState: (
      state,
      {
        payload,
      }: PayloadAction<{
        loading: boolean;
        success: boolean;
        error: string;
      }>
    ) => {
      state.chartAnalysisRequestState.loading = payload.loading;
      state.chartAnalysisRequestState.error = payload.error;
      state.chartAnalysisRequestState.success = payload.success;
    },

    setCurrentExecutedAtMachineRelationships: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.currentExecutedAtMachineRelationships = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLineGraphRecords.pending, (state) => {
        state.records = [];
        state.error = "";
        state.loading = true;
      })
      .addCase(getLineGraphRecords.fulfilled, (state, { payload }) => {
        state.records = payload;
        state.loading = false;
      })
      .addCase(getLineGraphRecords.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      });
  },
});
export const {
  setErrors,
  setGlobalConfiguration,
  resetGlobalConfiguration,
  setChartAnalysisRequestState,
  setCurrentExecutedAtMachineRelationships
} = lineGraphSlice.actions;

export default lineGraphSlice.reducer;

export const lineGraphSelector = (state: {
  lineGraphRecords: LineGraphState;
}) => state.lineGraphRecords;
