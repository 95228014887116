export const packagesForwardTrackingPathMaps = [
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ],
        BeginRelatedObjectMap: {
            ObjectType: "Product",
            RelatedObjectName: "BuiltInProduct"
        }
    },
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ],
        BeginRelatedObjectMap: {
            ObjectType: "Product",
            RelatedObjectName: "Package"
        }
    }
]