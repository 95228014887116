import moment from "moment";
import {
  analysisObjectTypes,
  objectDetailsView,
  trackingObjectTypes,
} from "../../../public/featuresConfig";
import { getActiveObject } from "../../../redux/activeObject/activeObject";
import Api, { store } from "../../../services/api";
import { RelationshipType } from "../../../types/products";
import {
  capitalizeFirstLetter,
  formatGraphRelationshipForRequest,
} from "../utils/utils";

export const machineColumnHeaders = ["Name", "ExternalID", "Creation Date"];
export const relatedObjectHeaders = ["Relationship", "Object Name"];

// Checks for single relationships values and returns the columns
export const checkSingleAndReturnRelationship = (
  objects: any,
  objectType: string
) => {
  const columnHeadersRelatedObjects: Array<string> = [];
  objectType = getObjectType(objects, objectType);
  if (objects) {
    objects?.Config &&
      objectType &&
      Object.keys(objects?.Config)?.length > 0 &&
      Object.entries(
        objects?.Config?.[`${objectType}`]?.["Relationships"]
      )?.forEach(([parentKey, parentValue]) => {
        Object?.entries(parentValue as Object).forEach(([key, value]) => {
          if (key === "Single" && value) {
            columnHeadersRelatedObjects.push(parentKey);
          }
        });
      });
  }
  return columnHeadersRelatedObjects;
};

// Returns false when delete old property value is true and true when it is false.
// This condition is used in rendering the Only Active switch icon.
export const checkAndReturnDeleteOldValue = (
  objects: any,
  objectType: string,
  relationshipName: string
) => {
  if (objects) {
    const deleteOldStatus =
      objects?.Config?.[`${objectType}`]?.["Relationships"]?.[relationshipName]
        ?.DeleteOld;
    if (deleteOldStatus) {
      return false;
    }
  }
  return true;
};

// Check and returns true for objects that has Rollup
export const checkObjectHasRollups = (objects: any, objectType: string) => {
  let hasRollupValue: Array<any> = [];

  if (objectType) {
    objects.Config &&
      Object.keys(objects.Config).length > 0 &&
      Object.entries(
        objects?.Config[`${objectType}`]?.["Relationships"]
      )?.forEach(([parentKey, parentValue]) => {
        Object?.entries(parentValue as Object).forEach(([key, value]) => {
          if (key === "RollUpValues" && value) {
            hasRollupValue.push({
              RelatedObjectName: parentKey,
            });
          }
        });
      });
  }

  return hasRollupValue;
};

export const getObjectType = (objects: any, objectType: string) => {
  const objectsKeyMaps = [];
  for (const key in objects?.Config) {
    objectsKeyMaps.push(key);
  }

  let currentObjectType = objectsKeyMaps.filter(
    (name) => name?.toLowerCase() === objectType.toLowerCase()
  );

  return currentObjectType[0];
};

export const formatDate = (isoStrDate: string) => {
  const date1 = new Date(isoStrDate);
  const month =
    date1.getMonth().toString().length === 1
      ? String(date1.getMonth() + 1).padStart(2, `0`)
      : date1.getMonth() + 1;

  return (
    date1.getFullYear() +
    "-" +
    month +
    "-" +
    date1.getDate() +
    " " +
    date1.toLocaleString().split(",")[1]
  );
};

export const formatDateForRequest = (isoStrDate: string) => {
  const date1 = new Date(isoStrDate);
  const month =
    date1.getMonth().toString().length === 1
      ? String(date1.getMonth() + 1).padStart(2, `0`)
      : date1.getMonth() + 1;
  const day =
    Number(date1.getDate()) >= 10
      ? date1.getDate()
      : `0${date1.getDate()}`;

  const hours =
    Number(date1.getHours()) >= 10 ? date1.getHours() : "0" + date1.getHours();
  const mins =
    Number(date1.getMinutes()) >= 10
      ? date1.getMinutes()
      : "0" + date1.getMinutes();
  const secs =
    Number(date1.getSeconds()) >= 10
      ? date1.getSeconds()
      : "0" + date1.getSeconds();

  const time = `${hours}:${mins}:${secs}`;

  return date1.getFullYear() + "-" + month + "-" + day + " " + time;
};

export const showIcon = (eyeIconIsOpen: boolean) => {
  return eyeIconIsOpen ? "Open_eye_icon.svg" : "Close_eye_icon.svg";
};

export const formRelatedObjects = (objects: any, currentObject: string) => {
  return formRelationshipForRequest(objects, currentObject);
};

// This function returns the available relationships that a provided object type has
export const formRelationshipForRequest = (
  objects: any,
  objectType: string
) => {
  let result = [];
  if (objectType) {
    for (const key in objects?.Config?.[`${objectType}`]?.["Relationships"]) {
      result?.push({
        RelatedObjectName: key,
        OnlyActive: true,
      });
    }
  }
  return result;
};

// This function returns the available relationships that a provided object type has
export const getRelatedObjectKeys = (relatedObjects: any) => {
  let result = [];
  if (relatedObjects) {
    for (const key in relatedObjects) {
      result?.push(key);
    }
  }
  return result;
};

export const getRequestRelationship = (
  relatedObjects: Array<any>,
  relationshipHeaders: Array<string>
) => {
  const requestRelationships: Array<any> = [];
  relatedObjects?.forEach((item) => {
    relationshipHeaders.forEach((header) => {
      if (header === item.RelatedObjectName) {
        requestRelationships.push(item);
      }
    });
  });

  return requestRelationships;
};

export const getMinutesBetweenDates = (startDate: any, endDate: any) => {
  let diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
};

// Groups the nodes and returns the grouped values
export const groupFilteredData = (nodes: Array<any>) => {
  let dataGroups = new Map();

  nodes?.slice(1).forEach((currentValue) => {
    if (dataGroups.has(currentValue.Object.TimeStampDBInsertion)) {
      dataGroups
        .get(currentValue.Object.TimeStampDBInsertion)
        .push({ ...currentValue });
    } else {
      dataGroups.set(currentValue.Object.TimeStampDBInsertion, [
        { ...currentValue },
      ]);
    }
  });
  return dataGroups;
};

export const getAllObjectTypes = (objectConfig: any) => {
  return (
    objectConfig &&
    Object.keys(objectConfig.Config)?.map((value: any) => {
      return value;
    })
  );
};

export const getRelatedObjectNames = (objects: any, objectType: string) => {
  const relationships = formatGraphRelationshipForRequest(objects, objectType);
  return relationships?.map((item: RelationshipType) => {
    return item?.RelatedObjectName;
  });
};

// Logs the duration of the requests
export const logRequestDuration = (time: number) => {
  /*console.log(
    "Request took : ",
    `${Math.round((performance.now() - time) / 1000)} seconds`
  );*/
};

//   Ex2_Product_ExternalID_60
export const getTrackingObjects = async (requestParams: any, path: string) => {
  try {
    const response: any = await Api({
      url: `${path}`,
      method: "GET",
      params: requestParams,
    });
    return response.data.Result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const dispatchGetActiveObject = (objectType?: string) => {
  const objects = store.getState()?.objects.objects;
  let objectTypePath = store.getState()?.activeObject.activeObjectType;
  const pathSplit = objectTypePath.split("/");
  objectTypePath = getObjectType(objects, pathSplit[pathSplit.length - 1]);
  const relatedObjects = formRelatedObjects(objects, objectTypePath);
  const relatedObjectsConfig = getRequestRelationship(
    relatedObjects,
    store.getState()?.activeObject?.selectedRelatedObjectsColumns
  );
  const objectTypeValue = objectType
    ? getObjectType(objects, capitalizeFirstLetter(objectType))
    : objectTypePath;

  store.dispatch(
    getActiveObject({
      objectType: objectTypeValue,
      relatedObject: relatedObjectsConfig,
      limit: 10,
    })
  );
};

// Constructs the sub navigation items that the application uses.
export const createSubNavigation = (value: any, objects: any) => {
  if (!objects?.Config) return;
  if (
    value.title === "Config" ||
    value.title === "Objects" ||
    value.title === "Tracking"
  ) {
    return Object.keys(objects?.Config).map((key) => {
      return {
        title: key,
        path: `/home/${value.title?.toLowerCase()}/${key.toLowerCase()}`,
      };
    });
  } else {
    return Object.keys(objects?.Config).map((key) => {
      return {
        title: key,
        path: `/home/${key.toLowerCase()}`,
      };
    });
  }
};

export const sliceLegendGroupName = (groupName: { group: string }) => {
  if (groupName?.group?.length > 19)
    return groupName?.group.slice(0, 16) + "...";

  return groupName?.group;
};

// Utility function that calculates time differences
export const timeOperation = (
  time: string,
  operationType: string,
  dateString: string
): any => {
  const [timeNumber, measure] = time.split(" ");
  let duration: any = {};
  switch (measure) {
    case "m":
      duration = { number: timeNumber, measure: "minutes" };
      break;
    case "h":
      duration = { number: timeNumber, measure: "hours" };
      break;
    case "d":
      duration = { number: timeNumber, measure: "days" };
      break;
    default:
      duration = {};
  }
  if (duration && Object.keys(duration).length > 0) {
    if (operationType === "add") {
      return moment(new Date(dateString))
        .add(duration.number, duration.measure)
        .toDate();
    }

    if (operationType === "substract") {
      return moment(new Date(dateString))
        .subtract(duration.number, duration.measure)
        .toDate();
    }
  }

  return null;
};

export const getRelationshipNameObjectType = (
  objects: any,
  {
    parentRelatedObjectType,
    objectRelationshipName,
  }: { parentRelatedObjectType: string; objectRelationshipName: string }
) => {
  return objects?.Config?.[`${parentRelatedObjectType}`].Relationships?.[
    `${objectRelationshipName}`
  ]?.Type;
};

// Utility function that displays that returns the title label of object types.
export const displayTitleValueLabel = (
  titleValue: string | null,
  objects: any
) => {
  let labelValue = "";
  if (objects?.Config && Object.keys(objects?.Config)?.length > 0) {
    Object.entries(objects?.Config).forEach(
      ([key, val]: [key: string, val: any]) => {
        if (key?.toUpperCase() === titleValue?.toUpperCase()) {
          labelValue = val?.Labels?.Name?.English;
        }
      }
    );
  }
  return labelValue ? labelValue : titleValue;
};

// Utility function that displays that returns the title label of Related object names.
export const displayRelationshipNameValueLabel = (
  parentObjectType: string,
  title: string,
  objects: any
) => {
  const relationship =
    objects?.Config?.[parentObjectType as keyof typeof objects.Config]
      ?.Relationships?.[`${title}`];
  return relationship?.Labels.Name.English;
};

/* Returns the current view Array index position.
   The view array is passed as an argument by the function caller.
*/
export const getCurrentViewIndex = <
  T extends Array<string>,
  V extends Array<{
    name: string;
    routeName: string;
    value: number;
  }>
>(
  locationPath: T,
  options: V
) => {
  const path = locationPath;

  for (let val of options) {
    if (path.includes(val.routeName)) {
      return options.findIndex(
        (view: { name: string; routeName: string; value: number }) => {
          return path.includes(view.routeName);
        }
      );
    }
  }

  return 1;
};

/* A function that checks and returns options that are available for each view */
export const getAvailableViews = (
  options: Array<{
    name: string;
    routeName: string;
    value: number;
  }>,
  objectType: string,
  view = objectDetailsView[2]
) => {
  /* Checks if the Tracking view  is available for the objectType when the user is on the Analysis details.
   Otherwise removes the TrackingView from  options */
  if (view === objectDetailsView[1].toLowerCase()) {
    if (trackingObjectTypes.includes(objectType)) {
      return options;
    } else {
      return options.filter(
        (option) => option.routeName !== objectDetailsView[0].toLowerCase()
      );
    }
  } else if (view === objectDetailsView[0].toLowerCase()) {
    /* Checks if the Analysis view  is available for the objectType when the user is on the Tracking Object details.
   Otherwise removes the AnalysisView from  options */
    if (analysisObjectTypes.includes(objectType)) {
      return options;
    } else {
      return options.filter(
        (option) => option.routeName !== objectDetailsView[1].toLowerCase()
      );
    }
  } else {
    /* Checks for the views available and returns them */
    let val: Array<{
      name: string;
      routeName: string;
      value: number;
    }> = [...options];
    if (!trackingObjectTypes.includes(objectType)) {
      val = options.filter((option) => option.routeName !== "tracking");
    }
    if (!analysisObjectTypes.includes(objectType)) {
      val = val.filter((option) => option.routeName !== "analysis");
    }

    return val;
  }
};
