import { NodeDetails } from "./nodeDetails/NodeDetails";
import { EdgeDetails } from "./edgeDetails/EdgeDetails";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { useAppSelector } from "../../../redux/hooks";
import MoreNodesNotification from "../notifications/MoreNodesNotification";
import useLocalStorage from "../../../customHooks/useLocalStorage";
import Relationships from "./relationships/Relationships";
import { displayTitleValueLabel } from "../../generics/utilities/utils";
import { objectSelector } from "../../../redux/objects/objectSlice";
import { memo } from "react";

function GraphDetails() {
  // STORE STATE
  const { isFilterable, isOpenNotification } = useAppSelector(
    (state: any) => state.graphReducer
  );

  const { objects } = useAppSelector(objectSelector);
  const { storedValue: selectedNode } = useLocalStorage("selectedNode");
  const { storedValue: targetNode } = useLocalStorage("targetNode");
  const { storedValue: isSelectedEdge } = useLocalStorage("isSelectedEdge");

  return (
    <div className="mt-1 w-full">
      {isFilterable && isOpenNotification && <MoreNodesNotification />}
      <Accordion allowMultipleExpanded allowZeroExpanded className="">
        {/*isFilterable && (
          <AccordionItem className="!border-b !border-[#F2F4F7]">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__button !bg-white !pt-0 text-[12px] text-primary-textNormal font-bold">
                Filters
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="!border-t !border-[#F2F4F7] p-3 text-[12px]">
              <GraphFilter />
            </AccordionItemPanel>
          </AccordionItem>
        )*/}
        {selectedNode && (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton
                className={`accordion__button !bg-white ${
                  isFilterable ? "" : "!pt-0"
                } text-[12px] text-primary-textNormal font-bold`}
              >
                {selectedNode.ObjectType
                  ? displayTitleValueLabel(selectedNode.ObjectType, objects)
                  : displayTitleValueLabel(
                      selectedNode.Object?.ObjectType,
                      objects
                    )}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="p-3 text-[12px]">
              <NodeDetails
                selectedNode={selectedNode}
                heightDeterminer="graph"
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {!isSelectedEdge && selectedNode && (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__button !bg-white text-[12px] text-primary-textNormal font-bold">
                Expand Node
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="w-full text-[12px]">
              <Relationships />
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {isSelectedEdge && (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__button !bg-white text-[12px] text-primary-textNormal font-bold">
                Edge
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="p-3 text-[12px]">
              <EdgeDetails />
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {isSelectedEdge && Object.keys(targetNode)?.length > 0 && (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__button !bg-white text-primary-textNormal text-[12px] font-bold">
                {targetNode.ObjectType
                  ? displayTitleValueLabel(targetNode.ObjectType, objects)
                  : displayTitleValueLabel(
                      targetNode.Object?.ObjectType,
                      objects
                    )}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="p-3 text-[12px]">
              <NodeDetails selectedNode={targetNode} heightDeterminer="graph" />
            </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
    </div>
  );
}

export default memo(GraphDetails)