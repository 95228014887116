import { useAppSelector } from "../../../../redux/hooks";
import { RollupData } from "./RollupData";
import { useEffect, useState } from "react";
import { filterObject } from "../../../generics/utils/groupParameters";
import useMemoCompare from "../../../../customHooks/useMemoCompare";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ParameterRenderer from "./parameters/ParameterRenderer";
import { Link } from "react-router-dom";

type Props = {
  heightDeterminer: string;
  selectedNode: any;
};

export function NodeDetails({ heightDeterminer, selectedNode }: Props) {
  // STORE STATE
  const { rollUps, parentNodeId } = useAppSelector(
    (state: any) => state.graphReducer
  );

  // Use the previous obj value if the "id" property hasn't changed
  const objFinal = useMemoCompare(selectedNode, (prev: any, next: any): any => {
    return prev && prev.id === next.id;
  });

  // LOCAL STATE
  const [isParentNode, setIsParentNode] = useState(false);
  const [determinHeight, setDeterminHeight] = useState(heightDeterminer);
  const [groupedParameters, setGroupedParameters] = useState<any>({});
  const [currentObject, setCurrentObject] = useState<any>({});

  //this effect is used to ensure that only the relationships for a parent node are shown
  useEffect(() => {
    setDeterminHeight(heightDeterminer);
    if (parentNodeId === objFinal?.ExternalID) {
      setIsParentNode(true);
    } else {
      setIsParentNode(false);
    }

    if(selectedNode.hasOwnProperty("Object")){
      setCurrentObject(objFinal.Object)
    }
    else{
      setCurrentObject(objFinal)
    }

    // set parameter groups
    if (objFinal?.hasOwnProperty("Object")) {
      const groupedParams = filterObject(objFinal.Object);
      setGroupedParameters(groupedParams);
    } else {
      const groupedParams = filterObject(objFinal);
      setGroupedParameters(groupedParams);
    }
  }, [parentNodeId, objFinal, heightDeterminer, selectedNode]);

  const getPath = () => {
    const path = "../home/objects/" + currentObject?.ObjectType + "/" + currentObject?.Name
    return path
  }

  return (
    <div
      className={`node-details flex justify-start ${
        determinHeight && determinHeight === "graph"
          ? "max-h-[60vh]"
          : "h-[95vh] max-h-[95vh]"
      } flex-col overflow-x-hidden overflow-y-scroll`}
    >
      <div className="flex  space-x-4 justify-end">
        <Link
          to={getPath()}
          state={currentObject}
          className="!capitalize bg-white rounded text-primary-black py-[0.4rem] px-3 border-secondary border-solid border-2 "
        >
          Details
        </Link>
      </div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="!border-none"
      >
        {Array.from(groupedParameters).map(([key, value]: any): any => {
          return (
            Object.keys(value)?.length > 0 && (
              <AccordionItem key={key} className="!border-none">
                <AccordionItemHeading>
                  <AccordionItemButton className="accordion__button !py-2 !bg-white text-[12px] font-bold !border-t">
                    {key}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="!border-none px-3 text-[12px]">
                  <ParameterRenderer parameters={value} />
                </AccordionItemPanel>
              </AccordionItem>
            )
          );
        })}
        {/* rollups section */}
        {/* if there are rollups show them for only the parent node */}
        {isParentNode &&
          rollUps &&
          rollUps !== "undefined" &&
          rollUps !== "null" &&
          Object.keys(rollUps)?.length > 0 && (
            <AccordionItem className="!border-none">
              <AccordionItemHeading>
                <AccordionItemButton className="accordion__button !py-2 !bg-white text-[12px] font-bold !border-t">
                  RollUps
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="!border-none px-3 text-[12px]">
                <RollupData />
              </AccordionItemPanel>
            </AccordionItem>
          )}
      </Accordion>
    </div>
  );
}
