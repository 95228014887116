export const productionOrderBackwardTrackingPathMap = [
  {
    RelatedObjectTypes: ["ProductionOrder"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProductInPackage",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Product",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "ProductionOrderTarget",
      },
    ],
  },
];
