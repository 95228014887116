
const AFTPalette = {
  primary: {
    purpleDark: "#3A6A78",
    purpleLight: "#3A6A78",
  },

  secondary: {
    grayLight: "#F5F5FF",
    orangeDark: "#F36F56",
    orangeLight: "#FFC444",
  },

  accent: {
    grayDark: "#3A6A78",
    grayLight: "#344054",
    grayXSLight: "#F2F4F7",
    grayMuted: "#667085",
    grayMuted300: "#D0D5DD"
  },
}

export default AFTPalette
