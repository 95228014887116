import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import FormHeaderComponent from "../components/generics/FormHeaderComponent";
import { showIcon } from "../components/generics/utilities/utils";

interface FormFIelds {
  password: boolean;
  confirmPassword: boolean;
}
const NewPassword = () => {
  const [loginError, setLoginError] = useState<FormFIelds>({
    password: false,
    confirmPassword: false,
  });
  const [showError, setShowError] = useState(false);
  const [isEyeIconOpen, setIsEyeIconOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const newPassword = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);

  const validateForm = () => {
    if (newPassword.current !== null && confirmPassword.current !== null) {
      if (newPassword.current.value === "") {
        setShowError(true);
        setLoginError((prev: FormFIelds) => {
          return {
            ...prev,
            password: true,
          };
        });
        setErrorMessage("Passwords field cannot be empty");
        return false;
      }
      if (confirmPassword.current.value === "") {
        setShowError(true);
        setLoginError((prev: FormFIelds) => {
          return {
            ...prev,
            confirmPassword: true,
          };
        });
        setErrorMessage("ConfirmPassword field cannot be empty");
        return false;
      }

      //   Check if the two fields are thesame
      if (
        !comparePassword(
          newPassword.current.value,
          confirmPassword.current.value
        )
      )
        return false;
    }
    return true;
  };

  const comparePassword = (password: string, validatePassword: string) => {
    if (password.toString() === validatePassword.toString()) return true;
    return false;
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form cannot be submitted");
    }
  };

  return (
    <div className="login w-full h-[100vh] bg-[#fff] flex items-center justify-center">
      <div className="login-container w-[85%] h-[550px] md:w-[60%] md:h-[700px] lg:w-[30%] lg:h-[550px] ">
        <FormHeaderComponent
          title={"Set new password"}
          resize={true}
          details={
            "Your new password must be different to the previously used passwords."
          }
        />
        {showError && (
          <div className="error-container border-[1px] border-[#FDA29B] flex p-[16px] mt-[32px] h-[52px] rounded-[8px] bg-[#FFFBFA] ">
            <div>
              <img src="/assets/error_icon.svg" alt="" />
            </div>
            <p className="ml-[13px] text-[14px] text-[#B42318]">
              {errorMessage}
            </p>
          </div>
        )}
        <form
          onSubmit={(e) => submitForm(e)}
          className="login-form mt-[32px] text-[#667085]"
        >
          <div className="password-container mt-[20px]">
            <div className="password-container mt-[20px]">
              <label className="block text-[#344054] text-[14px] font-normal mb-[4px]">
                Password
              </label>
              <div className="password-inputFieldContainer">
                <div
                  className={`password flex items-center  border-[1px] ${
                    loginError.password
                      ? "border-[#FDA29B] "
                      : "border-[#D0D5DD] "
                  } shadow-sm rounded-[8px]`}
                  onClick={() => {
                    setLoginError((prev: FormFIelds) => {
                      return {
                        ...prev,
                        password: false,
                      };
                    });
                    setShowError(false);
                  }}
                >
                  <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                    placeholder="Enter your Password"
                    type={`${isEyeIconOpen ? "text" : "password"}`}
                    ref={newPassword}
                  />
                  {loginError.password ? (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src="/assets/error_icon.svg"
                      alt="Password Icon"
                    />
                  ) : (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src={`/assets/${showIcon(isEyeIconOpen)}`}
                      alt="Password Icon"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="password-container mt-[20px]">
              <label className="block text-[#344054] text-[14px] font-normal mb-[4px]">
                Confirm Password
              </label>
              <div className="password-inputFieldContainer">
                <div
                  className={`password flex items-center  border-[1px] ${
                    loginError.confirmPassword
                      ? "border-[#FDA29B] "
                      : "border-[#D0D5DD] "
                  } shadow-sm rounded-[8px]`}
                  onClick={() => {
                    setLoginError((prev: FormFIelds) => {
                      return {
                        ...prev,
                        confirmPassword: false,
                      };
                    });
                    setShowError(false);
                  }}
                >
                  <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                    placeholder="Enter your Password"
                    type={`${isEyeIconOpen ? "text" : "password"}`}
                    ref={confirmPassword}
                  />
                  {loginError.confirmPassword ? (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src="/assets/error_icon.svg"
                      alt="Password Icon"
                    />
                  ) : (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src={`/assets/${showIcon(isEyeIconOpen)}`}
                      alt="Password Icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full text-[16px] mt-[24px]">
            <button className=" bg-[#3A6A78] w-[100%] h-[44px] rounded-[8px] text-[#fff]">
              Reset password
            </button>
          </div>
          <div className="register-now text-[14px] mt-[32px] flex items-center justify-center">
            <Link to="/login">
              <label className="cursor-pointer text-[#667085] text-[normal] flex items-center ">
                <img src="/assets/left_icon.svg" alt="" />

                <span className="text-[#667085] ml-[12px]">
                  {" "}
                  Back to log in
                </span>
              </label>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
