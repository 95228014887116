export const filterObject = (object: any) => {
  const includeMap = {
    Name: "Name",
    ExternalID: "External ID",
    TimeStampDBInsertion: "DB Insert Date",
    TimeStampDBLastUpdate: "DB Last Update Date",
  }
  const excludeKeys = [
    "ObjectType",
    "ID",
    "QuantityObjectsCurrent",
    "RelatedObjects",
    "RollUps",
    "group",
    "id",
    "label",
    "object_externalID",
    "uniqByExternalIDAndType",
    "relatedObjectName",
    "diveDepth"
  ]
  const mapOfParameters = new Map<any, any>()
  let general: any = {}
  let attributes: any = {}
  if (object) {
    for (const key in object) {
      if (Object.keys(includeMap).includes(key)) {
        const uiKey = includeMap[key as keyof typeof includeMap]
        general[uiKey] = object[key]
      }
      if (!Object.keys(includeMap).includes(key) && !excludeKeys.includes(key)) {
        if (typeof object[key] === 'boolean') {
          attributes[key] = object[key].toString();
        } else {
          attributes[key] = object[key]
        }
      }
    }
  }

  mapOfParameters.set("General Information", general)
  mapOfParameters.set("Attributes", attributes)

  return mapOfParameters
}

export const splitAndCapitalize = (param: string) => {
  return param.charAt(0).toUpperCase() + param.slice(1)
}

export function splitAndGroupParams(obj: any) {
  const out: any = []
  Object.entries(obj).forEach((eda) => {
    if (eda[0].includes("_")) {
      const _item = eda[0].split("_")
      const entry = out.find((data: any) => data?.id === _item[0])
      if (entry) {
        entry["data"].push({ key: _item[1], value: eda[1] })
      } else {
        out.push({ id: _item[0], data: [{ key: _item[1], value: eda[1] }] })
      }
    }
  })
  return out
}

