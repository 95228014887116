import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { resetFilterObjects } from "../../redux/graph/filterSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  objectDetailsSelector,
  fetchObject,
  fetchRelatedObjects,
} from "../../redux/objects/objectDetailsSlice";
import { objectSelector } from "../../redux/objects/objectSlice";
import { headerTypes } from "../../types/products";
import AFTPalette from "../generics/aftpalette";
import BreadCrumbs from "../generics/BreadCrumbs";
import PageTitle from "../generics/Pagetitle";
import {
  checkAndReturnDeleteOldValue,
  displayRelationshipNameValueLabel,
  displayTitleValueLabel,
  getRelationshipNameObjectType,
  machineColumnHeaders,
} from "../generics/utilities/utils";
import { formatDateTime } from "../generics/utils/utils";
import ViewContainer from "../generics/ViewContainer";
import { ErrorPage } from "../graph/error/ErrorPage";
import ObjectDetails from "../table/ObjectTable/relatedObjects/ObjectDetails";
import TableHeader from "../table/TableHeader";
import DetailsPagination from "./DetailsPagination";
import OnlyActiveSwitch from "./detailsRelatedObjectSwitch/OnlyActiveSwitch";
import PredefinedViews from "./detailsRelatedObjectSwitch/PredefinedViews";

import Rollups from "./Rollups";

const ObjectDetailsOverview = ({ state: propState }: { state: any }) => {
  const generalHeader = machineColumnHeaders;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { objects } = useAppSelector(objectSelector);
  const { error, objectInfo, relatedObjName, loading, nextRelatedObjects} = useAppSelector(
    objectDetailsSelector
  );

  const [relatedObjects, setRelatedObjects] = useState(
    objectInfo?.[0]?.RelatedObjects
  );
  const currentLocation =
    typeof propState?.["ObjectType"] !== "undefined"
      ? propState?.["ObjectType"]
      : window?.location?.pathname.split("/")[3];

  let breadCrumbs = window?.location?.pathname.split("/");
  breadCrumbs = breadCrumbs.slice(1, breadCrumbs.length);
  const [onlyActiveSettings, setOnlyActiveSettings] = useState({
    onlyActiveFlag: true,
    currentObjectType: "",
  });
  const [nextRelationshipTotal, setNextRelationshipTotal] = useState();

  useEffect(() => {
    setRelatedObjects(objectInfo?.[0]?.RelatedObjects);
  }, [objectInfo]);

  useEffect(() => {
    if(relatedObjName !== undefined && relatedObjName !== "" && Object.keys(nextRelatedObjects).length > 0){
      setRelatedObjects({
        ...relatedObjects,
        [relatedObjName]: nextRelatedObjects
      });
    }
  }, [nextRelatedObjects, relatedObjName, relatedObjects]);

  useEffect(() => {
    // Resets the filtered objects to prevent rendering of the cached data when
    // the user switches between the tab
    dispatch(resetFilterObjects());
  }, [dispatch]);

  useEffect(() => {
    if (propState !== null) {
      dispatch(
        fetchObject({
          externalId: propState?.["ExternalID"],
          objectType: propState?.["ObjectType"],
        })
      );
      localStorage.setItem("state", JSON.stringify(propState));
    } else {
      navigate(-1);
    }

    localStorage.removeItem("allrelatedObjects");

  }, [dispatch, navigate, propState]);

  const showGeneralInformation = () => {
    if (error) return <ErrorPage error={error} />;

    if (typeof objectInfo?.[0]?.["Name"] === "undefined") {
      return (
        <div className="flex items-center justify-center">
          <ClipLoader
            size={22}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      );
    } else {
      return (
        <div className="generalInfo-table relatedObjects-table-bgColor overflow-x-auto mt-[32px] w-[98%] md:w-[100%] lg:w-full border-[1px] rounded-[8px]">
          <table className="w-full" key="general-Information">
            <TableHeader
              pageType={headerTypes.objectDetailsOverview}
              showSortIcon={false}
              tableHeader={generalHeader}
            />
            <tbody className="w-full  ">
              <>
                <tr className="border-t-[0.1px] general-text-color text-[12px]">
                  <th></th>
                  <td className="relative table-cell-Style">
                    {objectInfo?.[0]?.["Name"]}
                  </td>
                  <td className="relative table-cell-Style">
                    {objectInfo?.[0]?.["ExternalID"]}
                  </td>
                  <td className="relative table-cell-Style">
                    {formatDateTime(objectInfo?.[0]?.["TimeStampDBInsertion"])}
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
      );
    }
  };

  // USED TO SHOW ONLY ACTIVE OBJECTS
  const getOnlyActiveCheckValue = useCallback(
    (onlyActiveValue: boolean, activeObjectType: string) => {
      const reqParams = {
        skip: 0,
        relationshipName: activeObjectType,
        externalId: propState?.["ExternalID"],
        objectType: propState?.["ObjectType"],
        onlyActive: onlyActiveValue,
      };

      // Dispatches an action to fetch the relatedObjects based on the onlyActive value.
      dispatch(fetchRelatedObjects(reqParams));

      // Updates the state that holds onlyActive configuration
      setOnlyActiveSettings({
        onlyActiveFlag: onlyActiveValue,
        currentObjectType: activeObjectType,
      });
    },
    [dispatch, propState]
  );

  const updateNextRelationshipTotal = (value: any) => {
    setNextRelationshipTotal(value);
  };

  const showRelatedObjects = useCallback(() => {
    if (relatedObjects && Object.keys(relatedObjects)?.length > 0) {
      return (
        <>
          {/* Shows the related objects if any exists */}
          {relatedObjects &&
            Object.keys(relatedObjects)?.length > 0 &&
            Object.keys(relatedObjects)?.map(
              (currentObject: string, index: number) => {
                // Gets the current RelationshipName related object type
                const relationshipName = getRelationshipNameObjectType(
                  objects,
                  {
                    parentRelatedObjectType: objectInfo?.[0]?.ObjectType,
                    objectRelationshipName: currentObject,
                  }
                );

                return (
                  <div
                    key={currentObject.toString()}
                    className="relatedObject mb-[24px]  mt-2 "
                  >
                    <div className="relatedObjectTable overflow-x-auto mt-[24px] mb-14 border-[1px] relatedObjects-table-bgColor  rounded-[8px]">
                      <table className="w-full " key="related-objects">
                        <caption className="py-[10px] text-[16px] general-text-color font-[600] text-start pl-[24px]  border-b-[0.5px]">
                          <div className="flex justify-between items-center">
                            <label>
                              {currentObject !== relationshipName ? (
                                <span>
                                  {displayRelationshipNameValueLabel(
                                    objectInfo?.[0]?.ObjectType,
                                    currentObject,
                                    objects
                                  )}{" "}
                                  <span className=" text-[#4d4d4f] ">{` (${displayTitleValueLabel(
                                    relationshipName,
                                    objects
                                  )})`}</span>
                                </span>
                              ) : (
                                displayRelationshipNameValueLabel(
                                  objectInfo?.[0]?.ObjectType,
                                  currentObject,
                                  objects
                                )
                              )}
                            </label>
                            {checkAndReturnDeleteOldValue(
                              objects,
                              propState?.["ObjectType"],
                              currentObject
                            ) && (
                              <label className="pr-8 space-x-4 text-[#4d4d4f]">
                                <OnlyActiveSwitch
                                  activeObjectName={currentObject}
                                  getOnlyActiveCheckValue={
                                    getOnlyActiveCheckValue
                                  }
                                />
                              </label>
                            )}
                          </div>
                        </caption>
                        {relatedObjects[currentObject]?.["Elements"].length >
                        0 ? (
                          <>
                            <TableHeader
                              onlyActiveSettings={onlyActiveSettings}
                              showSortIcon={true}
                              relationshipName={currentObject}
                              pageType={headerTypes.objectDetailsOverview}
                              tableHeader={generalHeader}
                            />
                            <ObjectDetails
                              breadCrumbs={breadCrumbs}
                              onlyActiveSettings={onlyActiveSettings}
                              relationshipData={{
                                relatedObjName: relatedObjName,
                                relationshipName: currentObject,
                              }}
                              updateNextRelationshipTotal={
                                updateNextRelationshipTotal
                              }
                              relatedObjects={
                                relatedObjects[currentObject]?.["Elements"]
                              }
                            />
                          </>
                        ) : (
                          <div className="text-center py-4">{`No ${displayRelationshipNameValueLabel(
                            propState?.["ObjectType"],
                            currentObject,
                            objects
                          )} available`}</div>
                        )}
                      </table>
                      {relatedObjects[currentObject]?.["QuantityObjectsTotal"] >
                        10 && (
                        <DetailsPagination
                          quantityObjectInfo={{
                            QuantityObjectsTotal:
                              relatedObjects[currentObject]?.[
                                "QuantityObjectsTotal"
                              ],
                            currentObjectType: currentObject,
                          }}
                          onlyActiveSettings={onlyActiveSettings}
                          relationshipData={{
                            relatedObjName: relatedObjName,
                            relationshipName: currentObject,
                            nextRelationshipTotal: nextRelationshipTotal,
                          }}
                          pageCount={Math.round(
                            relatedObjects[currentObject]?.[
                              "QuantityObjectsTotal"
                            ] / 10
                          )}
                          paginationRequestParams={{
                            relationshipName,
                            externalId: propState?.["ExternalID"],
                            objectType: propState?.["ObjectType"],
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            )}
        </>
      );
    } else {
      return (
        <>
          <div>
            <h2 className="text-center mt-10 text-[18px] pb-[34px]">
              No related Objects
            </h2>
          </div>
        </>
      );
    }
  }, [
    breadCrumbs,
    generalHeader,
    getOnlyActiveCheckValue,
    nextRelationshipTotal,
    objectInfo,
    objects,
    onlyActiveSettings,
    propState,
    relatedObjName,
    relatedObjects,
  ]);

  const showAttributesFlag = (objectInfoDetails: any) => {
    let tableData: Array<boolean> | boolean =
      objectInfoDetails?.[0] &&
      Object?.entries(objectInfoDetails?.[0])?.map(([key, objectValue]) => {
        if (typeof objectValue === "object") {
          return false;
        }
        if (
          key !== "RelatedObjects" &&
          key !== "TimeStampDBInsertion" &&
          key !== "TimeStampDBLastUpdate" &&
          key !== "ID" &&
          key !== "ObjectType" &&
          key !== "ExternalID" &&
          key !== "Name"
        ) {
          return true;
        }
        return false;
      });

    return typeof tableData === "boolean"
      ? tableData
      : tableData?.includes(true);
  };

  const showAttributesTable = (objectInfoData: any) => {
    if (error) return <ErrorPage error={error} />;

    if (!loading && showAttributesFlag(objectInfoData)) {
      return (
        <>
          <div className="rounded-[8px] relatedObjects-table-bgColor py-[10px] border-[1px] w-[98%] md:w-[98%] lg:w-full">
            <div className="attributesTable min-h-[90px] max-h-[270px]  overflow-y-scroll">
              <table className="w-full  " key="attributes">
                <TableHeader
                  showSortIcon={false}
                  pageType={headerTypes.objectDetailsOverview}
                  tableHeader={["Name", "Value"]}
                />
                <tbody className="">
                  {typeof objectInfoData?.[0] !== "undefined" &&
                    Object.keys(objectInfoData?.[0])?.length > 0 &&
                    Object?.entries(objectInfoData?.[0])?.map(
                      ([key, objectValue]) => {
                        if (typeof objectValue === "object") {
                          return null;
                        }
                        if (
                          key !== "RelatedObjects" &&
                          key !== "TimeStampDBInsertion" &&
                          key !== "TimeStampDBLastUpdate" &&
                          key !== "ID" &&
                          key !== "ObjectType" &&
                          key !== "ExternalID" &&
                          key !== "Name"
                        ) {
                          const ItemsKey = key;
                          return (
                            <tr
                              className="border-t-[0.1px] general-text-color"
                              key={ItemsKey}
                            >
                              <th></th>
                              <td className="table-cell-Style">{ItemsKey}</td>
                              <td className="table-cell-Style">
                                {`${objectValue as string}`}
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      }
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {!loading &&
            typeof objectInfoData?.[0] !== "undefined" &&
            objectInfoData && (
              <div>
                <h2 className="text-center text-[20px] pb-[34px]">
                  No Attributes
                </h2>
              </div>
            )}
        </>
      );
    }
  };

  const renderRelatedObjectsTable = () => {
    if (loading)
      return (
        <div className="flex items-center justify-center mt-20">
          <ClipLoader
            size={40}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      );

    return <div className="mb-[34px]">{showRelatedObjects()}</div>;
  };

  // graph request parameters
  const parameters = {
    type: propState?.ObjectType,
    ExternalID: propState?.ExternalID,
    limit: 10,
  };
  const routData = JSON.stringify({
    parameters,
    graphDispatcher: "fetchInitialGraph",
  });
  return (
    <div className="h-[88vh] overflow-y-scroll overviewContainer">
      <ViewContainer>
        <div className="mt-[10px] flex  justify-between items-center">
          <div className="w-full flex justify-between align-middle">
            <PageTitle
              title={`${displayTitleValueLabel(
                currentLocation,
                objects
              )} Details`}
            />

            <div className="graph-navigation-container flex  space-x-4">
              <PredefinedViews />
              <Link 
                to="/graph"
                state={routData}
                className="!capitalize button-yobi rounded-md text-white py-[0.4rem] px-3 border-secondary border-solid border-2"
              >
                View Graph
              </Link>
            </div>
          </div>
        </div>
        <div className="Details-view-wrapper">
          <div className="mt-[30px]">
            <BreadCrumbs />
          </div>
          <div className="generalInformationContainer">
            <div>
              <h4 className="text-[18px] font-[600] mt-[20px]">
                General Information
              </h4>
            </div>
            <>{showGeneralInformation()}</>
          </div>
          {/* Other Attributes */}
          {!loading && (
            <div className="relatedObject mb-20  mt-[32px]  ">
              <h5 className="text-[18px] font-[600] ml-2 mb-[24px]  ">
                Attributes
              </h5>
              {/* Shows the attributes table */}
              {showAttributesTable(objectInfo)}
            </div>
          )}
          <div className="relatedObjectsContainer mt-[60px]">
            <h4 className="text-[18px] font-[600]">Related Objects</h4>
            {renderRelatedObjectsTable()}
          </div>
          {<Rollups />}
        </div>
      </ViewContainer>
    </div>
  );
};

export default ObjectDetailsOverview;
