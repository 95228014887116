import { cloneDeep } from "lodash";
import {
  setGraphCrumbs,
  setIsFilterable,
  setLegendFlag,
  setRelationships,
  setSelectedRelatedMapsInState,
} from "../../redux/graph/graphSlice";
import { ErrorPage } from "./error/ErrorPage";
import GraphComponent from "./canvas/GraphComponent";
import RightSidebar from "./rightSidebar/RightSidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import GraphDetails from "./graphDetails/GraphDetails";
import { ErrorBoundary } from "react-error-boundary";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import {
  fetchEitherForwardOrBackwardTracks,
  fetchInitialGraph,
  fetchObjectTracks,
} from "../../redux/graph/actions";
import useMemoCompare from "../../customHooks/useMemoCompare";
import { setCurrentExecutedAtMachineRelationships } from "../../redux/lineGraph/lineGraphSlice";
import { setLoading } from "../../redux/login/loginSlice";
import { getObjects } from "../../redux/objects/objectSlice";
import { getSelectedObjectRelationships, pluck } from "../generics/utils/utils";
import { fetchSelectedConfigAndObjectData } from "../generics/utils/api/objects/objectsApiCalls";

const WrapGraph = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { parameters, graphDispatcher } = JSON.parse(location?.state) ?? {};
  localStorage.setItem("trackingDive", parameters.tracking);
  // Use the previous obj value if the "id" property hasn't changed
  const memoizedParameters = useMemoCompare(parameters, (prev, next) => {
    return prev && prev.id === next.id;
  });
  // STORE STATE
  const { error } = useAppSelector((state) => state?.graphReducer);
  const objects = useAppSelector((state) => state?.objects.objects);
  const storeGraph = useAppSelector((state) =>
    cloneDeep(state?.graphReducer.storeGraph)
  );

  // reset cached data
  const resetConfigurations = useCallback(() => {
    // Sets the currentExecutedAtMachineRelationships for the line indicating the timeseries.
    dispatch(setCurrentExecutedAtMachineRelationships([]));

    // clear the localstorage
    localStorage.removeItem("isSelectedEdge");
    localStorage.removeItem("isShowButton");
    localStorage.removeItem("selectedNode");
    localStorage.removeItem("selectedEdgeId");
    localStorage.removeItem("selectedEdgeRelations");
    localStorage.removeItem("targetNode");
    // resetting some states
    dispatch(setSelectedRelatedMapsInState([]));
    dispatch(setRelationships([]));
    dispatch(setGraphCrumbs([]));
    dispatch(setIsFilterable(false));
    dispatch(setLoading(true));
  }, [dispatch]);

  // dispatch actions to get the graph data
  const getGraphData = useCallback(() => {
    if (graphDispatcher === "fetchInitialGraph") {
      dispatch(fetchInitialGraph(memoizedParameters));
    }
    if (graphDispatcher === "fetchObjectTracks") {
      dispatch(fetchObjectTracks(memoizedParameters));
    }
    if (graphDispatcher === "fetchEitherForwardOrBackwardTracks") {
      dispatch(fetchEitherForwardOrBackwardTracks(memoizedParameters));
    }
  }, [dispatch, graphDispatcher, memoizedParameters]);

  // handle graph back button
  const handleGraphBackButton = useCallback(() => {
    // Resets configurations and prepares object for the next display
    resetConfigurations();
    // go back
    navigate(-1);
  }, [navigate, resetConfigurations]);

  // fetch graph data when component renders
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) getGraphData();

    return () => (isCancelled = true);
  }, [getGraphData]);

  // Fetches the available categories of objects for display
  useEffect(() => {
    dispatch(getObjects());
  }, [dispatch]);

  // GRAPH EVENTS
  const events = {
    select: async function (event) {
      let { nodes } = event;
      if (nodes[0] !== undefined) {
        const targetNode = storeGraph?.nodes.find(
          (node) => node?.id === nodes[0]
        );
        const nodeRlationships = getSelectedObjectRelationships(
          objects,
          targetNode.group
        );
        localStorage.setItem("isSelectedEdge", false);
        localStorage.setItem("isShowButton", false);
        localStorage.setItem("selectedNode", JSON.stringify(targetNode));
        localStorage.setItem(
          "nodeRlationships",
          JSON.stringify(nodeRlationships)
        );
        localStorage.setItem("selectedRelatedMapsInState", JSON.stringify([]));
        localStorage.setItem("isFetchingSelectedNode", true);
        fetchSelectedConfigAndObjectData(
          targetNode?.group,
          targetNode?.ExternalID,
          pluck(nodeRlationships, "Name")
        ).then((res) => {
          let { objectRes } = res;
          let newRelatedObj = [];
          for (let object in objectRes?.[0].RelatedObjects) {
            let value = objectRes?.[0].RelatedObjects[object];
            value.objectName = object;
            newRelatedObj.push(value);
          }
          localStorage.setItem(
            "allrelatedObjects",
            JSON.stringify(newRelatedObj)
          );
          localStorage.setItem("isFetchingSelectedNode", false);
        });
      }
    },
    selectEdge: async function ({ edges }) {
      localStorage.setItem("isSelectedEdge", true);
      if (edges[0] !== undefined) {
        // get the selected edge
        const selectedEdge = storeGraph.edges.find((edge) => {
          return edge.id === edges[0];
        });

        localStorage.setItem("selectedEdgeId", selectedEdge?.to);
        //  get the target node
        const targetObject = storeGraph.nodes.find(
          (node) => node.id === selectedEdge?.to
        );

        //  get the source node
        const sourceObject = storeGraph.nodes.find(
          (node) => node.id === selectedEdge?.from
        );

        // set the source object and edge details
        if (sourceObject.hasOwnProperty("Elements")) {
          localStorage.setItem(
            "selectedNode",
            JSON.stringify(sourceObject?.Object)
          );
          localStorage.setItem(
            "selectedEdgeRelations",
            JSON.stringify(sourceObject?.Relationships[0])
          );
        } else {
          localStorage.setItem("selectedNode", JSON.stringify(sourceObject));
        }

        // set the target object and edge details
        if (targetObject.hasOwnProperty("Elements")) {
          localStorage.setItem(
            "targetNode",
            JSON.stringify(targetObject?.Object)
          );
          localStorage.setItem(
            "selectedEdgeRelations",
            JSON.stringify(targetObject?.Relationships[0])
          );
        } else {
          localStorage.setItem("targetNode", JSON.stringify(targetObject));
        }
        return null;
      }
    },
    click: (event) => {
      dispatch(setLegendFlag(true));
    },
  };
  // this component is passed here to reuse the RightSidebar component
  const graphDetails = <GraphDetails />;
  return (
    <div className=" bg-[#ffffff] ml-0 flex flex-col overflow-hidden">
      <div className="graph ">
        <div className="flex w-full relative">
          <ErrorBoundary FallbackComponent={ErrorPage}>
            {/* back button on the graph page */}
            <button
              className="absolute top-4 border py-2 px-5 ml-4 rounded-md"
              onClick={handleGraphBackButton}
            >
              <MdKeyboardBackspace color="#3A6A78" />
            </button>
            <GraphComponent error={error} events={events} />
            <RightSidebar childComponent={graphDetails} pushDownValue="graph" />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
export default WrapGraph;
