export const articlesBackwardTrackingPathMaps = [
    {
        RelatedObjectTypes: [
            "Article"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProductInPackage"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Article"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Article"
            }
        ]
    }
]