import { FC, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { setActiveObjectType } from "../../../redux/activeObject/activeObject";
import { useAppDispatch } from "../../../redux/hooks";
import { setObjectTypes } from "../../../redux/objects/objectSlice";
import Api from "../../../services/api";
import Loader from "../../generics/Loader";
import ErrorMessageComp from "../ErrorMessageComp";

interface Props {
  closeModal: () => void;
  setSuccessMessage: (value: string) => void;
  activeObject: string;
}

const DeleteObjectType: FC<Props> = ({
  closeModal,
  activeObject,
  setSuccessMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    onSucesss: false,
    onError: false,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteObjectType = async () => {
    // Prepares the state for the request
    setActionStatus({
      onSucesss: false,
      onError: false,
    });

    setLoading(true);

    // Makes the request
    try {
      const deleteResult = await Api({
        url: "config/objecttype",
        method: "delete",
        data: {
          ObjectType: activeObject,
        },
      });

      const firstConfigObjectType = Object.keys(
        deleteResult.data.Config
      )?.[0].toLowerCase();

      // Updates the action states
      setLoading(false);
      setActionStatus((prev) => ({ ...prev, onSucesss: true }));
      setSuccessMessage(`${activeObject} was deleted successfully!`);
      closeModal();

      setTimeout(() => {
        setSuccessMessage("");
        // Programmatically navigates to the first config object type
        navigate(`/home/config/${firstConfigObjectType}`, {
          replace: true,
        });
      }, 3000);

      // Updates the object Types state with the response result
      dispatch(setObjectTypes(deleteResult.data));
      // Sets the active Object type with it's value
      dispatch(setActiveObjectType("/home/config/article"));
    } catch (error) {
      setLoading(false);
      // Updates the action state
      setActionStatus((prev) => ({ ...prev, onError: true }));
      console.log(error);
    }
  };
  return (
    <div className="space-y-5">
      <div className="space-y-4">
        <div className="w-full flex justify-between items-center">
          <h6 className="text-[18px] text-accent-grayDark font-[600]">
            Delete
          </h6>
          {!loading && (
            <VscClose
              onClick={closeModal}
              style={{ cursor: "pointer", color: "#667085" }}
              size={24}
            />
          )}
        </div>
      </div>
      {/* ERROR MESSAGE */}
      {actionStatus.onError && (
        <ErrorMessageComp
          deleteObjectType={true}
          errorMessage="Sorry, we are unable to delete Object Type"
        />
      )}
      <div className="action-message-container space-y-2">
        <h5 className="text-[14px] text-accent-grayDark ">
          Are you sure you want to delete this Object{" "}
          <span className="font-bold">{activeObject}</span>?
        </h5>
        <h5 className="text-[14px] text-accent-grayDark">
          This action cannot be undone.
        </h5>
      </div>
      <div className="space-x-3  flex justify-between mt-2">
        <button
          className="disabled:bg-[#ffffff] disabled:cursor-not-allowed border-[1px] border-[#cccccc]    flex-1 rounded-[8px] py-2 px-5"
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="bg-[#de2323] disabled:bg-[#e46b6b] disabled:cursor-not-allowed  justify-center items-center space-x-5 text-[#fff] flex-1 rounded-[8px] py-2 px-5"
          onClick={deleteObjectType}
        >
          Delete
        </button>
      </div>
      <div className="text-center">
        <Loader show={loading} />
      </div>
    </div>
  );
};

export default DeleteObjectType;
