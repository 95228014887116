import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers";
import { FiCalendar } from "react-icons/fi";
import { SxProps } from "@mui/material";
import { substractAnHourFromDate } from "./utilities/lineGraphUtils";

type Props = {
  disabled?: boolean;
  setValueToParent?: (date: string) => string;
  setRange1ValueToParent?: (date: string) => string;
  setRange2ValueToParent?: (date: string) => string;
  getStartDate?: (date: string) => void;
  getEndDate?: (date: string) => void;
  isRange?: boolean;
  isRange2?: boolean;
  starDateRange?: any;
  isLineGraph?: boolean;
  minValue?: string;
  isDate?: string;
  startDateTimeSeries?: any;
  endDateTimeSeries?: any;
};
const DateTimePickerSelector: React.FC<Props> = ({
  disabled,
  setValueToParent,
  setRange1ValueToParent,
  setRange2ValueToParent,
  getEndDate,
  getStartDate,
  minValue,
  isRange,
  isRange2,
  starDateRange,
  isLineGraph = false,
  isDate,
  startDateTimeSeries,
}) => {
  const [dateValue, setDateValue]: any = useState<Date | null>(() => {
    if (startDateTimeSeries) return new Date(startDateTimeSeries);
    return null;
  });

  const style: SxProps = {
    "& label.Mui-focused": {
      color: "gray",
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "solid 1px #3A6A78",
      },
    },
  };
  useEffect(() => {
    setDateValue(startDateTimeSeries);
  }, [startDateTimeSeries]);

  useEffect(() => {
    const setDefaultDateTime = async () => {
      if (isDate === "START_DATE") {
        await setDateValue(substractAnHourFromDate(new Date(), 1));
        typeof setValueToParent !== "undefined" && setValueToParent(dateValue);
      }
      if (isDate === "END_DATE") {
        await setDateValue(new Date());
        typeof setValueToParent !== "undefined" && setValueToParent(dateValue);
      }
    };

    setDefaultDateTime();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (date: string) => {
    setDateValue(date);
    if (isRange) {
      if (isRange2) {
        typeof setRange2ValueToParent !== "undefined" &&
          setRange2ValueToParent(date);
      } else {
        typeof setRange1ValueToParent !== "undefined" &&
          setRange1ValueToParent(date);
      }
    } else {
      typeof setValueToParent !== "undefined" && setValueToParent(date);
    }

    // Graph Line filtering
    if (isLineGraph) {
      if (isDate === "START_DATE") {
        typeof getStartDate !== "undefined" && getStartDate(date);
      }
      if (isDate === "END_DATE") {
        typeof getEndDate !== "undefined" && getEndDate(date);
      }
    }
  };

  const minDate = () => {
    if (isRange2) {
      return starDateRange;
    } else if (isDate === "END_DATE") {
      return new Date(minValue as string);
    } else {
      return undefined;
    }
  };

  return (
    <div className="date-container w-full">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDateTimePicker
          components={{ OpenPickerIcon: FiCalendar }}
          disabled={disabled}
          renderInput={(params) => {
            return (
              <TextField
                className="w-full"
                sx={style}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                InputLabelProps={{ shrink: false }}
                size="small"
                hiddenLabel
                {...params}
              />
            );
          }}
          label={dateValue === null && "Date"}
          ampm={true}
          inputFormat={"yy-MM-dd HH:mm:ss a "}
          views={["year", "day", "hours", "minutes", "seconds"]}
          minDate={minDate()}
          maxDate={new Date()}
          value={dateValue || null}
          onChange={(newDate: any) => {
            handleDateChange(newDate);
          }}
        ></MuiDateTimePicker>
      </LocalizationProvider>
    </div>
  );
};

export default DateTimePickerSelector;
