import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { useAppSelector } from "../../../../redux/hooks"
import { RollupsLevelOneProps } from "./parameters/rollups/RollupsLevelOneProps"

export function RollupData() {
  // STORE STATE
  const { rollUps } = useAppSelector((state: any) => state.graphReducer)

  return (
    <div className="node-details flex justify-start max-h-[60vh] flex-col overflow-x-hidden overflow-y-scroll levelsScrollHidden">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="!border-none"
      >
        {Object.keys(rollUps)?.length > 0 &&
          Object.keys(rollUps)?.map((key) => {
            return (
              <AccordionItem key={key} className="!border-none">
                <AccordionItemHeading>
                  <AccordionItemButton className="accordion__button !py-0 !bg-white text-[12px] font-bold">
                    {key}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="!border-none p-1 text-[12px]">
                  <RollupsLevelOneProps levelOneProps={rollUps[key]} />
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
      </Accordion>
    </div>
  )
}
