import DateSelectField from "../../generics/DateSelectField";
import PageTitle from "../../generics/Pagetitle";
import TextField from "../../generics/TextField";
import ViewContainer from "../../generics/ViewContainer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setIsOpenCanvas,
} from "../../../redux/graph/graphSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { objectSelector } from "../../../redux/objects/objectSlice";
import { useLocation } from "react-router-dom";
import {
  checkSingleAndReturnRelationship,
  getObjectType,
} from "../../generics/utilities/utils";
import {
  activeObjectSelector,
  setSelectedTableRelationship,
} from "../../../redux/activeObject/activeObject";
import ReactSelectField from "../../generics/selectFields/ReactSelectField";
import OverviewContainer from "../../table/OverviewContainer";

const ObjectItems = () => {
  const dispatch = useAppDispatch();
  let currentPath: string | string[] =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  // the next line removes the filter info and filter columns from the local storage so as to get an unsorted list of objects.
  // This is so as the this removal has to be done at a top level in other for the filter pagination to work properly.
  //  This item is been set in the TableSortButton component incase of future updates to the configuration.
  localStorage.removeItem("filterInfo");
  localStorage.removeItem("orderColumn");
  localStorage.removeItem("filterConfig");

  // Shows the navbar when the user uses the back button to go back
  // to previous page
  useEffect(() => {
    localStorage.removeItem("allFilterConfig");
    //dispatch(setIsOpenNavdebar(false));
    dispatch(setIsOpenCanvas(false));
  }, [dispatch]);

  const { objects } = useAppSelector(objectSelector);
  const { activeObjectType } = useAppSelector(activeObjectSelector);
  const [columnRelationships, setColumnRelationships] = useState<Array<string>>(
    []
  );
  const [activeSearchInputName, setActiveSearchInputName] = useState("");

  // Gets the object type
  const location = useLocation();
  const locationLength = location.pathname.split("/").length;
  const objectPath = location.pathname.split("/")[locationLength - 1];
  const currentObject = getObjectType(objects, objectPath);

  const columnHeadersRelatedObjects: Array<string> = useMemo(() => {
    return checkSingleAndReturnRelationship(objects, `${currentObject}`);
  }, [currentObject, objects]);

  useEffect(() => {
    setColumnRelationships(columnHeadersRelatedObjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObjectType, columnHeadersRelatedObjects]);

  // Returns relationships of the table header
  const setValuesToParent = useCallback(
    (value: Array<string>) => {
      // sets the selected column relationship
      dispatch(setSelectedTableRelationship(value));
    },
    [dispatch]
  );

  const resetInActiveSearchField = useCallback(
    (activeInputTypeName: string) => {
      setActiveSearchInputName(activeInputTypeName);
    },
    []
  );

  return (
    <div className="page-Item-Container pl-[12px] md:pl-[32px] relative bottom-[0px] rounded-t-[32px] ">
      <ViewContainer>
        <PageTitle title={currentPath} />
        <div className="All-Products-Container w-[100%] lg:mt-[30px]">
          <div className="lg:flex  lg:items-center lg:justify-between">
            <div
              className="filter-container
          md:grid md:grid-cols-2 md:gap-y-2 md:gap-x-4 mt-[24px] lg:mt-0 pr-4 md:pr-0 space-y-4 md:text-[16px] md:space-y-0 
          lg:grid-cols-4 lg:gap-x-2 w-[100%] lg:w-[62%]"
            >
              <TextField
                placeholder="Name"
                name={"Name"}
                activeSearchInputName={activeSearchInputName}
                resetPreviousInputField={resetInActiveSearchField}
              />
              <TextField
                placeholder="ExternalID"
                name={"ExternalID"}
                activeSearchInputName={activeSearchInputName}
                resetPreviousInputField={resetInActiveSearchField}
              />
              <DateSelectField type={"START_DATE"} />
              <DateSelectField type={"END_DATE"} />
            </div>
            <div className="mt-3 pr-4 md:pr-0 md:mt-[10px] lg:mt-0 lg:w-[30%] ">
              <ReactSelectField
                options={columnRelationships?.map((optionValue: string) => {
                  return {
                    value: optionValue,
                    label: optionValue,
                    isDisabled: false,
                  } as any;
                })}
                setValuesToParent={setValuesToParent}
                multiple={true}
              />
            </div>
          </div>

          <OverviewContainer />
        </div>
      </ViewContainer>
    </div>
  );
};

export default ObjectItems;
