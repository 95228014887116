const TopBar = () => {
  const path = window.location.pathname.split("/");

  const pathLength = path?.length;
  const hasDashboard = path.includes("dashboard");
  const hasSupport = path.includes("support");
  const hasSettings = path.includes("settings");
  const hasObjects = path.includes("objects");
  const hasProductTracking = path.includes("tracking");
  const hasProductAnalysis = path.includes("analysis");

  // Holds the top bar element
  const topBar = (
    <div
      className={`w-[100%] h-[60px]  bg-[#3A6A78] 
       flex justify-end items-center  md:border-l-2`}
    ></div>
  );


  // Conditionally displays the top bar
  const showTopBar = () => {
    if (pathLength <= 3 && !hasSupport && pathLength <= 3 && !hasSettings)
      return topBar;
    if (path.length <= 3 && hasDashboard) return topBar;
    if (path.length <= 4 && hasProductTracking) return topBar;
    if (path.length <= 4 && hasObjects) return topBar;
    if (path.length <= 4 && hasProductAnalysis) return topBar;

    return null;
  };

  return showTopBar();
};

export default TopBar;
