import { useFormik } from "formik"
import React, { FC } from "react"
import { VscClose } from "react-icons/vsc"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { deleteUser } from "../../../../redux/roles/actions"
import { fetchGetAllUsers } from '../../../../redux/users/actions'

interface Props {
  closeModal: () => void
  username: string
}

const DeleteUser: FC<Props> = ({ closeModal, username }) => {

  const { page } = useAppSelector((state: any) => state.users);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      name: username
    },
    onSubmit: (values) => {
      const deleteDetails = {
        username: username,
      };

      //
      dispatch(
        deleteUser(deleteDetails)
      );
      setTimeout(() => {
        closeModal();
        dispatch(fetchGetAllUsers(page));
      }, 1000);
    }
  })


  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <h6 className="text-lg text-accent-grayDark font-bold">Delete User</h6>
        <VscClose
          onClick={closeModal}
          style={{ cursor: "pointer", color: "#667085" }}
          size={24}
        />
      </div>
      <p className="text-accent-grayMuted text-[14px] my-2">
        Are you sure you want to delete{" "}
        <span className="font-bold">{username}</span> This action cannot be
        undone.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-x-3 w-full flex justify-between align-middle mt-7">
          <button
            onClick={closeModal}
            className="text-[#000] flex-1 border rounded-[8px] py-2 px-5"
          >
            Cancel
          </button>
          <button type="submit" className="bg-primary-error flex-1 justify-center items-center space-x-2 text-[#fff] rounded-[8px] py-2 px-5 font-normal">
            Delete
          </button>
      </div>
      </form>
    </div>
  )
}

export default DeleteUser
