import Switch from "@mui/material/Switch";
import { Dispatch, SetStateAction, useState } from "react";
import { alpha, styled } from "@mui/material/styles";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const SwitchComp = ({
  activeObjectType,
  getOnlyActiveCheckValue,
  setLoading,
}: {
  activeObjectType: string;
  getOnlyActiveCheckValue?: (value: boolean, activeObjectType: string) => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const [checked, setChecked] = useState(true);

  const backgroundColor = "#3A6A78";
  const color = "#3A6A78";

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: color as string,
      "&:hover": {
        backgroundColor: alpha(
          backgroundColor as string,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: backgroundColor as string,
    },
  }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (getOnlyActiveCheckValue) {
      getOnlyActiveCheckValue(event.target.checked, activeObjectType);
    }
    setChecked(event.target.checked);
    setLoading(true);
  };

  return (
    <GreenSwitch
      checked={checked}
      onChange={handleChange}
      {...label}
      size={"small"}
    />
  );
};

export default SwitchComp;
