export const articlesForwardTrackingPathMaps = [
    {
        RelatedObjectTypes: [
            "Article"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Article"
            }
        ]
    }
]