import Api from "../../../../../services/api";
import {productsBackwardTrackingPathMap} from "../../treeConfigs/backwardTracking/products/products"
import {productionOrderBackwardTrackingPathMap} from "../../treeConfigs/backwardTracking/productionOrder/productionOrder"
import {articlesBackwardTrackingPathMaps} from "../../treeConfigs/backwardTracking/articles/articles"
import {machineBackwardTrackingPathMap} from "../../treeConfigs/backwardTracking/machine/machine"
import {packagesBackwardTrackingPathMaps} from "../../treeConfigs/backwardTracking/packages/packages"
import {completeBackwardTrackingPathMap} from "../../treeConfigs/backwardTracking/completeBackward/completeBackward"
import { errorHandler, objectsUrl } from "./objectsApiCalls";

// stringify the pathmaps
export const getStringifiedPathMapBackwards = (buttonType: string) => { 
    let stringifiedBackwardObjectPathMapConfig;
    const buttonTypeEdited = buttonType.replaceAll(" ", "").toLowerCase()
    switch (buttonTypeEdited) {
      case "product":
        
        stringifiedBackwardObjectPathMapConfig = JSON.stringify(
            productsBackwardTrackingPathMap
        );
        break;
      case "productionorder":
        
        stringifiedBackwardObjectPathMapConfig = JSON.stringify(
            productionOrderBackwardTrackingPathMap
        );
        break;
      case "article":
        
        stringifiedBackwardObjectPathMapConfig = JSON.stringify(
          articlesBackwardTrackingPathMaps
        );
        break;
      case "machine":
       
        stringifiedBackwardObjectPathMapConfig = JSON.stringify(
            machineBackwardTrackingPathMap
        );
        break;
      case "package":
        
        stringifiedBackwardObjectPathMapConfig = JSON.stringify(
            packagesBackwardTrackingPathMaps
        );
        break;
      case "allrelatedobjects":
        
        stringifiedBackwardObjectPathMapConfig = JSON.stringify(
          completeBackwardTrackingPathMap
        );
        break;
      default:
        
        break;
    }
  
    return stringifiedBackwardObjectPathMapConfig
   }
  
  // fetch objects base on configs
  export const getBackwardTracks = async (
    objectType: string,
    ExternalID: string,
    buttonType: string
  ) => {
    
  
    const stringifiedBackwardObjectPathMapConfig = getStringifiedPathMapBackwards(buttonType)
   
    try {
      let time = performance.now();
      
      const requestParamsBackward = {
        ObjectType: objectType,
        ObjectKeyValueMap: {
          ExternalID: [ExternalID],
        },
        PathMaps: stringifiedBackwardObjectPathMapConfig,
        "IgnoreDirection": true
      };
      
      // request for backward tracking
      const resdataBackward = await Api({
        url: objectsUrl,
        method: "get",
        params: requestParamsBackward,
      });
      
      return resdataBackward.data.Objects;
    } catch (err: any) {
      return errorHandler(err);
    }
  };