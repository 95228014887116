import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchRelatedObjects,
  objectDetailsSelector,
  resetDetailsPageNumber,
} from "../../redux/objects/objectDetailsSlice";
import { objectSelector } from "../../redux/objects/objectSlice";
import SkeletonLoader from "../generics/SkeletonLoader";
import { displayRelationshipNameValueLabel } from "../generics/utilities/utils";

type Props = {
  quantityObjectInfo: any;
  paginationRequestParams: any;
  pageCount: number;
  relationshipData: {
    relatedObjName: string;
    relationshipName: string;
    nextRelationshipTotal: number | undefined;
  };
  onlyActiveSettings?: {
    onlyActiveFlag: boolean;
    currentObjectType: string;
  };
};

const DetailsPagination: React.FC<Props> = ({
  quantityObjectInfo,
  pageCount,
  paginationRequestParams,
  relationshipData,
  onlyActiveSettings,
}) => {
  const { paginationDetailsPageNumber, nextRelatedObjects } = useAppSelector(
    objectDetailsSelector
  );
  const { relatedObjectsLoading } = useAppSelector(objectDetailsSelector);
  const { objects } = useAppSelector(objectSelector);
  const [detailsPageNumber, setDetailsPageNumber] = useState(0);
  const [buttonIsClicked, setButtonIsClicked] = useState(false);
  const [totalObjectCount, setTotalObjectCount] = useState(
    quantityObjectInfo?.QuantityObjectsTotal
  );
  const [paginationPageCount, setPaginationPageCount] = useState(pageCount);
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setDetailsPageNumber(0);
    setTimeout(() => {
      dispatch(resetDetailsPageNumber(false));
    }, 400);
  }, [paginationDetailsPageNumber, dispatch]);

  useEffect(() => {
    if (!relatedObjectsLoading) {
      setLoading(false);
    }
  }, [relatedObjectsLoading]);

  useEffect(() => {
    if (
      nextRelatedObjects?.Elements?.length > 0 &&
      relationshipData.relatedObjName === relationshipData.relationshipName
    ) {
      setTotalObjectCount(relationshipData.nextRelationshipTotal);
      // Updates the pagination page count state
      if (relationshipData?.nextRelationshipTotal !== undefined) {
        setPaginationPageCount(relationshipData.nextRelationshipTotal / 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextRelatedObjects?.Elements, relationshipData.nextRelationshipTotal]);

  // Resets the details pagenumber to 0 when a the onlyActive value is changed.
  useEffect(() => {
    setDetailsPageNumber(0);
  }, [onlyActiveSettings?.onlyActiveFlag]);

  // check whether to add s to objecttype
  const ss = quantityObjectInfo?.QuantityObjectsTotal > 1 ? "s" : "";

  const changePage = useCallback(
    ({ selected }: any) => {
      const skip = selected * 10;
      setDetailsPageNumber(() => selected);
      const reqParams = {
        skip: skip,
        relationshipName: paginationRequestParams.relationshipName,
        externalId: paginationRequestParams.externalId,
        objectType: paginationRequestParams?.objectType,
        onlyActive: onlyActiveSettings?.onlyActiveFlag,
      };

      if (buttonIsClicked) {
        dispatch(fetchRelatedObjects(reqParams));
        setLoading(true);
      }
    },
    [
      buttonIsClicked,
      dispatch,
      onlyActiveSettings?.onlyActiveFlag,
      paginationRequestParams.externalId,
      paginationRequestParams?.objectType,
      paginationRequestParams.relationshipName,
    ]
  );

  return (
    <div className="relative w-full h-fit overflow-x-scroll overflow-y-hidden pt-[12px] md:static  md:overflow-hidden">
      <div className="pagination-container relative w-[full] h-[40px] left-[15px] top-[12px] pr-[10px] md:pr-[0px] pb-[40px] md:pb-[0px] md:static md:bottom-[16px] md:mr-[24px] md:h-[46px] md:top-[26px]  ">
        <div className="flex justify-between align-middle mt-4">
          <div className="flex text-primary-textNormal space-x-[5px] ml-6">
            <div className="text-primary-textNormal text-sm">
              {totalObjectCount}
            </div>
            <div className="text-primary-textNormal text-sm">
              {displayRelationshipNameValueLabel(
                location.state?.ObjectType,
                quantityObjectInfo.currentObjectType,
                objects
              ) + ss}
            </div>
          </div>

          <div className="w-[20%]">
            {relatedObjectsLoading && loading && (
              <div className="mx-auto w-fit">
                <SkeletonLoader />
              </div>
            )}
          </div>
          <div onMouseOver={() => setButtonIsClicked(true)}>
            <ReactPaginate
              previousLabel={
                <span>
                  <img
                    className="inline"
                    src="/assets/PreviousIcon.svg"
                    alt="next icon label"
                  />
                </span>
              }
              nextLabel={
                <span>
                  <img
                    className="inline"
                    src="/assets/NextIcon.svg"
                    alt="next icon label"
                  />
                </span>
              }
              pageCount={Math.ceil(paginationPageCount)}
              onPageChange={changePage}
              containerClassName={`pagination`}
              previousLinkClassName={`previousButton`}
              nextLinkClassName={`nextButton`}
              disabledClassName={`pagination-disabled`}
              activeClassName={`activePagination`}
              activeLinkClassName={`activePage`}
              forcePage={detailsPageNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPagination;
