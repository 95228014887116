import { Skeleton } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import { objectDetailsSelector } from "../../redux/objects/objectDetailsSlice";

const SkeletonLoader = () => {
  const { error } = useAppSelector(objectDetailsSelector);
  return (
    <>
      {error !== undefined ? (
        <div className="inline space-x-2">
          <Skeleton
            className="!inline-block"
            variant="circular"
            width={8}
            height={8}
          />
          <Skeleton
            className="!inline-block"
            variant="circular"
            width={8}
            height={8}
          />
          <Skeleton
            className="!inline-block"
            variant="circular"
            width={8}
            height={8}
          />
        </div>
      ) : null}
    </>
  );
};

export default SkeletonLoader;
