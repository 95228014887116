import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import ActionButton from "../ActionButton";
import Labels from "./Labels";
import { LabelProps } from "./propsTypes";


const AccordionLabelSection = ({ properties }: { properties: LabelProps }) => {
  const {
    setAccordionIndex,
    setAccordionToggler,
    accordionIndex,
    accordionToggler,
    index,
    key,
    value,
  } = properties;
  return (
    <AccordionItem
      className="!border-t-0"
      onClick={() => {
        setAccordionIndex(index);
        setAccordionToggler(!accordionToggler);
      }}
    >
      <AccordionItemHeading className="hover:bg-accent-tableBg rounded-md">
        <AccordionItemButton className="!bg-[transparent] text-[12px] font-bold">
          <div className="w-full flex  justify-start items-center space-x-5 p-2">
            {accordionIndex === index && accordionToggler ? (
              <AiOutlineMinusCircle color="#7F56D9" className="w-6 h-6" />
            ) : (
              <AiOutlinePlusCircle color="#7F56D9" className="w-6 h-6" />
            )}
            <div className="w-full flex justify-between items-center">
              <p className="text-lg font-[500] text-primary-textNormal">
                {key}
              </p>
              <ActionButton
                eventHander={() => alert("Labels")}
                title="Add"
                type="add"
              />
            </div>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="p-3 text-[12px]">
        <div className="w-[60%]">
          <Labels label={value} />
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default AccordionLabelSection;
