import React, { useCallback, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ObjectDetailsOverview from "./ObjectDetailsOverview";
import { useLocation } from "react-router-dom";
import {
  setGraphCrumbs,
  setIsFilterable,
  setIsOpenCanvas,
  setIsOpenNavdebar,
  setIsShowAllCheckboxRelations,
  setLoading,
  setRelationships,
  setSelectedRelatedMapsInState,
  setTransferTableData,
} from "../../redux/graph/graphSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchInitialGraph,
  fetchObjectTracks,
} from "../../redux/graph/actions";
import SpecificMachineView from "../specificMachineView/SpecificMachineView";
import { activeObjectSelector } from "../../redux/activeObject/activeObject";
import BackwardsTracking from "../tracking/backwardTracking/BackwardsTracking";
import ForwardsTracking from "../tracking/forwardTracking/ForwardsTracking";
import TrackingOverview from "../tracking/trackingOverview/TrackingOverview";
import { resetTrackingPath } from "../../redux/tracking/trackingPathSlice";
import AnalysisOverview from "../analysis/AnalysisOverview";
import { setCurrentExecutedAtMachineRelationships } from "../../redux/lineGraph/lineGraphSlice";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#3A6A78 !important",
    },
  },
  typography: {
    fontFamily: `"Open Sans"`,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="pl-[32px]">{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Details() {
  const dispatch = useAppDispatch();
  let locationState: any = useLocation();
  let locationData = locationState?.state;
  const [value, setValue] = React.useState(0);
  const [tabViews, setTabViews] = React.useState<string[]>(["Overview"]);
  const { activeObjectType } = useAppSelector(activeObjectSelector);
  const showTrackingTabs = locationState.pathname.includes("tracking");
  const pathHasAnalysis = locationState.pathname.includes("analysis");

  // Resets the sortIcon to the default one
  localStorage.removeItem("filterHeaders");

  // Set state data in the store for graph
  useEffect(() => {
    dispatch(setTransferTableData(locationState?.state));
    dispatch(setIsOpenNavdebar(true));

    // Sets the tab for the tracking section of the application
    if (showTrackingTabs) {
      setTabViews(["Overview", "Backwards Tracking", "Forwards Tracking"]);
    } else if (pathHasAnalysis) {
      //Sets the tab for the analysis section of the application
      setTabViews(["Overview", "Time Series"]);
    } else {
      setTabViews(["Overview"]);
    }
  }, [dispatch, locationState?.state, pathHasAnalysis, showTrackingTabs]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  const handleOverviewChange = useCallback(() => {
    //reset the following states
    dispatch(setIsOpenCanvas(false));
    dispatch(setSelectedRelatedMapsInState([]));
    dispatch(setRelationships([]));
    dispatch(setGraphCrumbs([]));
    dispatch(setIsFilterable(false));
    dispatch(setIsShowAllCheckboxRelations(false));
  }, [dispatch]);

  const handleProductChange = useCallback(() => {
    // Closes the side bar so as to view the line graph in it's full Glory
    dispatch(setIsOpenCanvas(true));

    //reset the setSelectedRelatedMapsInState
    dispatch(setSelectedRelatedMapsInState([]));
  }, [dispatch]);

  const handleForwardsBackwardsTracking = useCallback(() => {
    dispatch(setIsOpenCanvas(false));
    dispatch(resetTrackingPath([]));
    dispatch(setLoading(false));

    //reset the setSelectedRelatedMapsInState
    dispatch(setSelectedRelatedMapsInState([]));
  }, [dispatch]);

  const resetConfigurations = useCallback(() => {
    // Sets the currentExecutedAtMachineRelationships for the line indicating the timeseries.
    dispatch(setCurrentExecutedAtMachineRelationships([]));

    // clear the localstorage
    localStorage.removeItem("isSelectedEdge");
    localStorage.removeItem("isShowButton");
    localStorage.removeItem("selectedNode");
    localStorage.removeItem("selectedEdgeId");
    localStorage.removeItem("selectedEdgeRelations");
    localStorage.removeItem("targetNode");
    // resetting some states
    dispatch(setSelectedRelatedMapsInState([]));
    dispatch(setRelationships([]));
    dispatch(setGraphCrumbs([]));
    dispatch(setIsFilterable(false));
    dispatch(setLoading(true));
  }, [dispatch]);
  // Handles the rendering of the graph
  const handleGraphChange = useCallback(() => {
    // Resets configurations and prepares object for the next display
    resetConfigurations();

    if (showTrackingTabs) {
      const parameters = {
        type: locationData?.["ObjectType"],
        ExternalID: locationData?.["ExternalID"],
        buttonType: "All Related Objects",
      };
      dispatch(fetchObjectTracks(parameters));
    } else {
      if (locationData.ObjectType !== null) {
        const parameters = {
          type: locationData.ObjectType,
          limit: 10,
          ExternalID: locationData.ExternalID,
        };
        dispatch(fetchInitialGraph(parameters));
      }
    }

    dispatch(setIsOpenCanvas(true));
  }, [dispatch, locationData, resetConfigurations, showTrackingTabs]);

  const checkAndReturnTabIndex = (
    tabView: Array<string>,
    currentTabString: string
  ) => {
    return tabViews.findIndex((val: string) => val === currentTabString);
  };

  const showOverviewComponent = () => {
    if (showTrackingTabs)
      return (
        <TrackingOverview setValue={setValue} state={locationState?.state} />
      );

    if (pathHasAnalysis)
      return (
        <AnalysisOverview setValue={setValue} state={locationState?.state} />
      );
    return <ObjectDetailsOverview state={locationState?.state} />;
  };

  return (
    <div className="relative mt-[32px] top-12">
      <Box sx={{ width: "100%" }}>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "white",
              marginTop: "-33px",
              paddingTop: "20px",
            }}
          >
            <Tabs
              className="ml-[32px] !h-[40px] bg-white"
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* overview tab */}
              <Tab
                className="w-[240px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                label={tabViews[0]}
                onClick={handleOverviewChange}
                {...a11yProps(0)}
              />

              {/* Shows the backward tracking tab */}
              {showTrackingTabs && (
                <Tab
                  className="!w-[240px] !capitalize !border-2 !boder-[tomato] text-[14px] font-[400] text-accent-grayMuted"
                  label={tabViews[1]}
                  onClick={
                    showTrackingTabs
                      ? handleForwardsBackwardsTracking
                      : handleGraphChange
                  }
                  {...a11yProps(0)}
                />
              )}

              {/*Shows the forward tracking tab */}
              {showTrackingTabs && (
                <Tab
                  className="w-[240px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                  label={tabViews[2]}
                  onClick={
                    showTrackingTabs
                      ? handleForwardsBackwardsTracking
                      : handleGraphChange
                  }
                  {...a11yProps(0)}
                />
              )}
              {/* Renders the machine specific view tab*/}
              {pathHasAnalysis && (
                <Tab
                  className="w-[240px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                  label="Time Series"
                  onClick={handleProductChange}
                  {...a11yProps(0)}
                />
              )}
            </Tabs>
          </Box>

          <TabPanel
            value={value}
            index={checkAndReturnTabIndex(tabViews, "Overview")}
          >
            {showOverviewComponent()}
          </TabPanel>
          {showTrackingTabs && (
            <TabPanel
              value={value}
              index={checkAndReturnTabIndex(tabViews, "Backwards Tracking")}
            >
              <BackwardsTracking setValue={setValue} />
            </TabPanel>
          )}
          {showTrackingTabs && (
            <TabPanel
              value={value}
              index={checkAndReturnTabIndex(tabViews, "Forwards Tracking")}
            >
              <ForwardsTracking setValue={setValue} />
            </TabPanel>
          )}
          {(activeObjectType.toLowerCase().includes("machine") ||
            pathHasAnalysis) && (
            <TabPanel
              value={value}
              index={
                pathHasAnalysis
                  ? checkAndReturnTabIndex(tabViews, "Time Series")
                  : checkAndReturnTabIndex(tabViews, "MachineView")
              }
            >
              <SpecificMachineView />
            </TabPanel>
          )}
        </ThemeProvider>
      </Box>
    </div>
  );
}
