import {
  setChartAnalysisRequestState,
  setCurrentExecutedAtMachineRelationships,
  setGlobalConfiguration,
} from "../../../redux/lineGraph/lineGraphSlice";
import Api, { store } from "../../../services/api";
import { formatDateForRequest, timeOperation } from "../../generics/utilities/utils";

// Gets the contained Products of Package used in MachineSpecificView Component.
export async function getDateTimeFromAndToFromContainedProducts(
  location: any,
  timer: any
) {
  const configParams = {
    ObjectType: location?.state?.ObjectType,
    ObjectKeyValueMap: {
      ExternalID: location?.state?.ExternalID,
    },
    ReturnRollUps: true
  };

  try {
    // Updates the loading state of the chart analysis request state
    timer.current = Date.now();
    const triggerTime = timer.current;
    store.dispatch(
      setChartAnalysisRequestState({
        loading: true,
        error: "",
        success: false,
      })
    );

    const res = await Api({
      url: "object",
      method: "GET",
      params: configParams,
    });

    let 
      startTimeTo: string = "",
      startTimeFrom: string = "";

    if (timer.current === triggerTime) {
      // Gets the first elements TimestampCreation
      startTimeFrom =
        res.data.Objects[0]?.RollUps?.Product?.Object.TimeStampDBInsertion.Min;
      //var dateStart = new Date(startTimeFrom);
      //dateStart = new Date(dateStart.getTime() + dateStart.getTimezoneOffset() * 60000);
      //startTimeFrom = dateStart.toISOString()

      // Gets the last elements TimestampCreation Date
      startTimeTo =
      res.data.Objects[0]?.RollUps?.Product?.Object.TimeStampDBInsertion.Max;
      //startTimeTo = new Date(new Date(startTimeTo).getTime() + new Date(startTimeTo).getTimezoneOffset() * 60000).toISOString()

      // dispatch new date series to global date configuration
      if (startTimeFrom && startTimeTo) {
        store.dispatch(
          setGlobalConfiguration({
            ...store.getState()?.globalConfiguration,
            dateTimeFrom: formatDateForRequest(startTimeFrom),
            dateTimeTo: formatDateForRequest(startTimeTo),
          })
        );
      }

      store.dispatch(
        setChartAnalysisRequestState({
          ...store.getState()?.chartAnalysisRequestState,
          success: true,
        })
      );
    }
    if(startTimeFrom === ""){
      setChartAnalysisRequestState({
        loading: false,
        error: "No Time Series Availabel",
        success: false,
      })
    }
  } catch (error) {
    console.log(error);
    store.dispatch(
      setChartAnalysisRequestState({
        loading: false,
        error: "Failure fetching Records",
        success: false,
      })
    );
  }
}


 // Sort Relationship based on validFrom Date
 const sortAvailableRelationships = (relationshipValues: any) => {
    return relationshipValues.length > 1
      ? relationshipValues.sort((a: any, b: any) => {
          return (
            new Date(a.validFrom).valueOf() - new Date(b.validFrom).valueOf()
          );
        })
      : relationshipValues;
  };


export async function getDateTimeFromAndToFromProductionOrder(
  location: any,
  timer: any
) {
  const requestParameters = {
    ObjectType: location?.state.ObjectType,
    ObjectKeyValueMap: {
      ExternalID: location.state.ExternalID,
    },
    RelatedMaps: JSON.stringify([
      {
        RelatedObjectName: "CurrentlyExecutedAtMachine",
        OrderBy: "ValidFrom",
        OrderDirection: "ASC",
      },
    ]),
  };

  try {
    // Updates the loading state of the chart analysis request state
    timer.current = Date.now();
    const triggerTime = timer.current;

    store.dispatch(
      setChartAnalysisRequestState({
        loading: true,
        error: "",
        success: false,
      })
    );

    const response = await Api({
      url: "object",
      method: "GET",
      params: requestParameters,
    });

    let responseStartTimeTo: string = "",
      responseStartTimeFrom: string = "";

    if (timer.current === triggerTime) {
      // If the relationship doesn't include CurrentlyExecutedAtMachine or CurrentlyExecutedAtMachine has no machine in it
      if (
        !response.data.Objects[0]?.RelatedObjects?.CurrentlyExecutedAtMachine ||
        response.data.Objects[0]?.RelatedObjects?.CurrentlyExecutedAtMachine
          .Elements.length === 0
      ) {
        responseStartTimeFrom = timeOperation(
          "60 m",
          "substract",
          formatDateForRequest(new Date().toISOString())
        );

        responseStartTimeTo = new Date().toISOString();
      } else {
        const relationships: any =
          response.data.Objects[0]?.RelatedObjects.CurrentlyExecutedAtMachine
            .Elements[0].Relationships;

        // Sort Relationship based on validFrom Date
        const sortedRelationships = sortAvailableRelationships(relationships);
        // If the last relationship of sortedRelationships has a validTO date we set the valueFrom and validTo.
        // Otherwise we set the valueFrom of the first relationship and set the validTo to the current Date time.
        if (sortedRelationships[sortedRelationships?.length - 1]?.ValidTo) {
          responseStartTimeFrom = sortedRelationships[0]?.ValidFrom;
          responseStartTimeTo =
            sortedRelationships[sortedRelationships?.length - 1].ValidTo;
        } else {
          responseStartTimeFrom = sortedRelationships[0]?.ValidFrom;
          responseStartTimeTo = new Date().toISOString();
        }

        // Sets the currentExecutedAtMachineRelationships for the line indicating the timeseries.
        store.dispatch(
          setCurrentExecutedAtMachineRelationships(
            sortedRelationships.map((relationshipItems: any) => {
              return {
                ...relationshipItems,
                ValidFrom: formatDateForRequest(relationshipItems.ValidFrom),
                ValidTo: formatDateForRequest(relationshipItems.ValidTo),
              };
            })
          )
        );
      }
      // dispatch new date series to global date configuration
      if (responseStartTimeFrom && responseStartTimeTo) {
        store.dispatch(
          setGlobalConfiguration({
            ...store.getState()?.globalConfiguration,
            dateTimeFrom: formatDateForRequest(responseStartTimeFrom),
            dateTimeTo: formatDateForRequest(responseStartTimeTo),
          })
        );
      }

      store.dispatch(
        setChartAnalysisRequestState({
          ...store.getState()?.chartAnalysisRequestState,
          success: true,
        })
      );
    }
  } catch (error) {
    console.log(error);
    store.dispatch(
      setChartAnalysisRequestState({
        loading: false,
        error: "Failure fetching Records",
        success: false,
      })
    );
  }
}
