import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { HiOutlineCode } from "react-icons/hi";
import { AiOutlineLineChart } from "react-icons/ai";
import { BsDiagram2 } from "react-icons/bs";
import { RiHome6Line } from "react-icons/ri";
import { TbStack2, TbSettings } from "react-icons/tb";
import AFTPalette from "../generics/aftpalette";
import { SidebarItem } from "./DataStructure";

export const SidebarPrimaryData: SidebarItem[] = [
  {
    title: "Home",
    path: "/home/dashboard",
    pathString: "dashboard",
    icon: <RiHome6Line />,
    hasSubNav: false,
  },
  {
    title: "Config",
    path: "/home/config",
    pathString: "Config",
    icon: <HiOutlineCode />,
    iconOpened: (
      <BiChevronUp
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    iconClosed: (
      <BiChevronDown
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    hasSubNav: true,
  },
  {
    title: "Objects",
    path: "/home/objects",
    pathString: "Objects",
    icon: <TbStack2 />,
    iconOpened: (
      <BiChevronUp
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    iconClosed: (
      <BiChevronDown
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    hasSubNav: true,
  },
  {
    title: "Tracking",
    path: "/home/tracking",
    pathString: "tracking",
    icon: <BsDiagram2 />,
    iconOpened: (
      <BiChevronUp
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    iconClosed: (
      <BiChevronDown
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    hasSubNav: true,
  },
  {
    title: "Analysis",
    path: "/home/analysis",
    pathString: "analysis",
    icon: <AiOutlineLineChart />,
    iconOpened: (
      <BiChevronUp
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    iconClosed: (
      <BiChevronDown
        className={`text-[${AFTPalette.accent.grayMuted}] text-2xl`}
      />
    ),
    hasSubNav: true,
  },
];
export const SidebarSecondaryData: SidebarItem[] = [
  // {
  //   title: "Support",
  //   path: "/home/support",
  //   icon: <HiOutlineSupport />,
  // },
  {
    title: "Settings",
    pathString: "settings",
    path: "/home/settings",
    icon: <TbSettings />,
  },
];
