import React, { FC } from "react";
import { sliceLegendGroupName } from "../../../generics/utilities/utils";

type Props = {
  relationship: any;
  index: number;
  lastElementIndex: number;
};
const GraphLegendList: FC<Props> = ({
  relationship,
  index,
  lastElementIndex,
}) => {


  return (
    <li
      key={relationship}
      className={`flex flex-row items-center  ${
        lastElementIndex === index ? "mb-[5px]" : "mb-[20px]"
      } `}
    >
      <i className={`${relationship.group} !w-[8px] !h-[8px] p-0 inline`}></i>
      <p className="inline p-0 ml-2 text-accent-grayDark hover:bg-[white] text-[16px]">
        {sliceLegendGroupName(relationship)}
      </p>
    </li>
  );
};

export default GraphLegendList;
