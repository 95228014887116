import React from "react";

const SortIconDown = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4737 6.73684L6.73684 2L2 6.73684"
        stroke="black"
        strokeWidth="1.57895"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.7368L6.73684 16.4737L11.4737 11.7368"
        stroke="#3A6A78"
        strokeWidth="1.57895"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SortIconDown;
