import  { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../services/api";
import DateTimePickerSelector from "../../generics/DateTimePickerSelector";
import DateTimePicker from "react-datetime-picker";
import { FiCalendar } from "react-icons/fi";
import MultiSelectField from "../../generics/selectFields/MultiSelectField";
import { Resolution } from "./Dimension";
import { formatDateForRequest } from "../../generics/utilities/utils";
import { useAppDispatch } from "../../../redux/hooks";
import { getLineGraphRecords } from "../../../redux/lineGraph/lineGraphSlice";
import { substractAnHourFromDate } from "../../generics/utilities/lineGraphUtils";
import ReactSelectField from "../../generics/selectFields/ReactSelectField";
import { OptionType } from "../../../types/products";
import TimeSeriesSelector from "./TimeSeriesSelector";

const LineGraphFilter = ({
  data,
  setSelectedItems,
  setGroupNestValue,
}: {
  data: Array<string>;
  setSelectedItems: (items: Array<string>) => void;
  setGroupNestValue: (value: boolean) => void;
}) => {
  const location: any = useLocation();
  const [value, onChange] = useState(new Date());
  const [shoWOptionalPicker, setShowShowOptional] = useState(false);
  const [config, setConfig] = useState({
    ObjectType: "Product",
    DateTimeFrom: formatDateForRequest(
      substractAnHourFromDate(new Date(), 1).toISOString()
    ),
    DateTimeTo: formatDateForRequest(new Date().toISOString()),
    Dimensions: {
      Machine: [location?.state?.ExternalID],
    },
    GroupDimensions: [""],
    MeasureNames: ["ST03 Erg"],
    Resolution: 20,
  });

  const [options, setOptions] = useState<Array<OptionType>>();
  const [dropDownItems, setDropdownItems] = useState<Array<string>>([]);
  const [parametersConfig, setParametersConfig] = useState<{
    loading: boolean;
    parameters: Array<string>;
    error: boolean;
  }>({
    loading: false,
    parameters: [],
    error: false,
  });
  const [renderDefaultValue, setRenderDefaultValue] = useState(false);
  const [startDateTimeSeries, setStartDateTimeSeries] = useState<{
    startTime: string;
    endTime: string;
  }>({
    startTime: "",
    endTime: "",
  });

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const params = {
      ObjectType: "Product",
      ObjectFilterList: JSON.stringify([
        {
          Key: "TimeStampDBInsertion",
          Comparator: "ge",
          Value: "2022-09-29 08:00:00",
        },
        {
          Key: "TimeStampDBInsertion",
          Comparator: "le",
          Value: new Date(),
        },
      ]),
      RelationshipFilterMaps: JSON.stringify([
        {
          RelatedObjectName: location?.state?.ObjectType,
          ObjectKeyValueMap: {
            ExternalID: location?.state?.ExternalID,
          },
        },
      ]),
      Limit: 1,
    };

    // Replace this fetch with the attributes from the visited Machine
    // Fetches the needed parameter values for the Multi-select dropdown
    const getSpecificProductFromMachine = async () => {
      try {
        // SETS THE PARAMETERS LOADING TO TRUE
        setParametersConfig({
          ...parametersConfig,
          loading: true,
          error: false,
        });

        const res = await Api({
          url: "/object",
          method: "GET",
          params: params,
        });

        if (res.status === 200 && res?.data?.Objects?.length > 0) {
          const object = res?.data?.Objects[0];
          let paramValues: Array<string> = [];

          Object.entries(object).forEach(
            ([key, val]: [key: string, val: any]) => {
              if (typeof val === "number") {
                paramValues.push(key);
              }
            }
          );
          // SETS THE PARAMETERS OF THE OBJECT TYPES AND LOADING STATE
          setParametersConfig({
            ...parametersConfig,
            parameters: paramValues,
            loading: false,
          });
        }
      } catch (err) {
        console.log(err);
        setParametersConfig({
          error: true,
          parameters: [],
          loading: false,
        });
      }
    };

    getSpecificProductFromMachine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Constructs options for the drop down based on parameters values when selected dropdown values is Empty
  useEffect(() => {
    if (dropDownItems.length > 0 && renderDefaultValue) {
      const optionValues =
        data.length > 0
          ? ["Group Nest"]
              .concat(data.map((d: any) => d.name))
              .map((optionValue: string) => {
                return {
                  value: optionValue,
                  label: optionValue,
                  isDisabled: optionValue === "Group Nest" ? true : false,
                };
              })
          : [];
      setOptions(optionValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // FETCH GRAPH DATA
  useEffect(() => {
    dispatch(
      getLineGraphRecords({
        ObjectType: config?.ObjectType,
        DateTimeFrom: config?.DateTimeFrom,
        DateTimeTo: config?.DateTimeTo,
        Dimensions: {
          Machine: config?.Dimensions.Machine,
        },
        GroupDimensions: config.GroupDimensions,
        MeasureNames: config?.MeasureNames,
        Resolution: config?.Resolution,
      })
    );
  }, [
    config?.DateTimeFrom,
    config?.DateTimeTo,
    config?.Dimensions.Machine,
    config.GroupDimensions,
    config?.MeasureNames,
    config?.ObjectType,
    config?.Resolution,
    dispatch,
  ]);

  const setMultiSelectFieldValueToParent = (values: any) => {
    setConfig((prev) => {
      return { ...prev, MeasureNames: [values] };
    });
  };
  const getStartDate = (startDateVal: string) => {
    let dateTimeFrom = formatDateForRequest(startDateVal);

    if (dateTimeFrom.includes("AM")) {
      dateTimeFrom = dateTimeFrom.replace("AM", "");
    }
    if (dateTimeFrom.includes("PM")) {
      dateTimeFrom = dateTimeFrom.replace("PM", "");
    }

    setConfig((prev: any) => {
      return {
        ...prev,
        DateTimeFrom: dateTimeFrom,
      };
    });
  };
  const getEndDate = (endDateVal: string) => {
    let dateTimeTo = formatDateForRequest(endDateVal);
    if (dateTimeTo.includes("AM")) {
      dateTimeTo = dateTimeTo.replace("AM", "");
    }
    if (dateTimeTo.includes("PM")) {
      dateTimeTo = dateTimeTo.replace("PM", "");
    }
    setConfig((prev: any) => {
      return { ...prev, DateTimeTo: dateTimeTo };
    });
  };

  // Updates the config when Group Nest is selected
  const getNest = (nextValue: boolean) => {
    const nest = nextValue ? "Nest" : "";
    setConfig((prev) => {
      return { ...prev, GroupDimensions: [nest] };
    });
  };
  // Updates the config when Resolution value changes
  const getResolution = (resolutionValue: number) => {
    setConfig((prev) => {
      return { ...prev, Resolution: resolutionValue };
    });
  };

  // Updates the Line Graph based on the items that the user has selected.
  const setLineGroupValues = (
    items: Array<string>,
    isDefaultValue?: boolean
  ) => {
    const nest = "Group Nest";

    // Rend
    isDefaultValue !== undefined && setRenderDefaultValue(isDefaultValue);
    if (items.includes(nest)) {
      getNest(true);
      setGroupNestValue(true);
      setDropdownItems(items);
      createOptionValues(items as any);
      setSelectedItems(items);
    } else {
      getNest(false);
      setGroupNestValue(false);
      setDropdownItems(items);
      createOptionValues(items as any);
      setSelectedItems(items);
    }
  };

  // Checks and returns options for usage for createOptionValues function
  const checkAndReturnOptions = (
    selectOptions: Array<string>,
    newOptions: Array<OptionType> | undefined
  ): Array<OptionType> => {
    let optionsResult: Array<OptionType> | undefined = [];
    selectOptions.forEach((val: string) => {
      if (val === "Group Nest") {
        // Handles the activation of the  Group nest option when the parameter group is  deselected
        newOptions = options?.map((option: OptionType) => {
          if (option.value !== "Group Nest") {
            option.isDisabled = true;
          }
          return option;
        });
        optionsResult = newOptions;
      } else {
        // Handles the deactivation of the  Group nest option when the parameter group is selected
        newOptions = options?.map((option: OptionType) => {
          if (option.value === "Group Nest" && !renderDefaultValue) {
            option.isDisabled = true;
          } else {
            option.isDisabled = false;
          }
          return option;
        });
        optionsResult = newOptions;
      }
    });

    return optionsResult;
  };

  // Creates options for the select dropdown
  const createOptionValues = (optionVals: Array<string>) => {
    let newOptions: Array<OptionType> | undefined;
    if (optionVals.length > 0) {
      setOptions(checkAndReturnOptions(optionVals, newOptions));
    } else {
      // Enables all options in the drop down
      newOptions = options?.map((option: OptionType) => {
        option.isDisabled = false;
        return option;
      });
      setOptions(newOptions);
    }
  };

  const setConfigData = (parameter: string) => {
    setConfig((prev: any) => {
      return {
        ...prev,
        DateTimeTo: new Date(),
        DateTimeFrom: parameter,
      };
    });
    setStartDateTimeSeries(() => ({
      startTime: parameter,
      endTime: new Date().toISOString(),
    }));
  };

  return (
    <div className="mx-[32px] space-y-4">
      <div className=" flex  items-center justify-between">
        <div className="parameters-container flex gap-4 lg:pr-4">
          <div className="w-[200px]">
            <MultiSelectField
              availableParameters={parametersConfig}
              defaultParameter={config.MeasureNames}
              setValuesToParent={setMultiSelectFieldValueToParent}
            />
          </div>
          <div className="flex gap-4 items-center justify-between">
            <div className="max-w-[80%]  flex gap-4">
              <DateTimePickerSelector
                isLineGraph={true}
                getStartDate={getStartDate}
                isDate={"START_DATE"}
                startDateTimeSeries={startDateTimeSeries.startTime}
              />
              <TimeSeriesSelector
                setConfig={setConfigData}
              />
              <DateTimePickerSelector
                isLineGraph={true}
                isDate={"END_DATE"}
                minValue={config.DateTimeFrom}
                getEndDate={getEndDate}
                startDateTimeSeries={startDateTimeSeries.endTime}
              />
            </div>

            <div className="w-[300px] pr-2">
              <ReactSelectField
                options={options as any}
                setValuesToParent={setLineGroupValues}
                placeholder={"Select Groups"}
                defaultValue={{
                  value: "A",
                  label: "A",
                  isDisabled: false,
                }}
              />
            </div>
          </div>
        </div>

        <div className="">
          <Resolution getResolution={getResolution} />
        </div>
      </div>
      <div>
        <div className="flex items-center mb-2 ">
          <p>
            Show Optional DatePicker{" "}
            <input
              className=" rounded-full ml-2"
              type="checkbox"
              value={"Nest"}
              checked={shoWOptionalPicker}
              onClick={() => setShowShowOptional(!shoWOptionalPicker)}
            />
          </p>
        </div>

        {shoWOptionalPicker && (
          <DateTimePicker
            calendarIcon={<FiCalendar className="text-[20px]" />}
            className={"react-datetime-picker"}
            maxDate={new Date()}
            format={"yyyy-MM-dd h:mm:ss a"}
            clearIcon={false as any}
            onChange={onChange}
            value={value}
            disableClock={true}
          />
        )}
      </div>
    </div>
  );
};

export default LineGraphFilter;
