import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBackwardTracks } from "../../components/generics/utils/api/objects/backwardTrackingCalls";
import { getObjectTracks } from "../../components/generics/utils/api/objects/forwardAndBackwardTrackingCalls";
import { getForwardTracks } from "../../components/generics/utils/api/objects/forwardTrackingCalls";
import {
  fetchConfigAndObjectData,
  fetchNodeFilterData,
  fetchSelectedConfigAndObjectData,
} from "../../components/generics/utils/api/objects/objectsApiCalls";
import { InitailRequestParams } from "../../types/graphDataTypes";
import { setRelationships, setRollUps } from "./graphSlice";

// async actions: initial request
export const fetchInitialGraph = createAsyncThunk<any, any, any>(
  "graph/fetchInitialGraph",
  async (
    { type, limit, ExternalID }: InitailRequestParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    // resetting rollups before every init req
    dispatch(setRollUps({}));
    try {
      const objects = await fetchConfigAndObjectData(type, ExternalID, limit);
      return fulfillWithValue(objects);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// async actions: next request
export const fetchRelationsOfGraph = createAsyncThunk<any, any, any>(
  "graph/fetchRelationsOfGraph",
  async (
    { type, ExternalID, limit, behavior }: InitailRequestParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    // resetting rollups before every req
    dispatch(setRollUps({}));
    try {
      const resData = await fetchConfigAndObjectData(type, ExternalID, limit);

      return fulfillWithValue({ ...resData, behavior });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// async actions: next request
export const fetchSelectedRelationsOfGraph = createAsyncThunk<any, any, any>(
  "graph/fetchSelectedRelationsOfGraph",
  async (
    { type, ExternalID, limit, behavior, relatedObjects }: InitailRequestParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    // resetting rollups before every req
    dispatch(setRollUps({}));
    try {
      const resData = await fetchSelectedConfigAndObjectData(
        type,
        ExternalID,
        relatedObjects,
        limit,
      );
      return fulfillWithValue({ ...resData, behavior });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// async actions: next request
export const fetchObjectAndRelationsFilters = createAsyncThunk<any, any, any>(
  "graph/fetchObjectAndRelationsFilters",
  async (
    configParams: any,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    // resetting rollups before every req
    dispatch(setRollUps({}));
    try {
      const resData = await fetchNodeFilterData(configParams);

      return fulfillWithValue(resData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// async actions: fetch Forward and BackwardTracks
export const fetchObjectTracks = createAsyncThunk<any, any, any>(
  "graph/fetchObjectTracks",
  async (
    {
      type,
      ExternalID,
      buttonType,
      behavior,
      relatedObjects,
    }: InitailRequestParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const limit = 10;
      let resData;

      if (behavior === "dive") {
        const data = await fetchSelectedConfigAndObjectData(
          type,
          ExternalID,
          relatedObjects,
          limit,
        );
        // add behavior to data
        resData = { ...data, behavior };
      } else {
        dispatch(setRollUps({}));
        resData = await getObjectTracks(type, ExternalID, buttonType);
      }

      if (resData === "Network Error") {
        return rejectWithValue(
          "Network Error: Please Check Internet Connection!"
        );
      }
      if (resData?.resdataFB?.length === 0) {
        return rejectWithValue("No Tracks Available for this Object!");
      }
      return fulfillWithValue(resData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// async actions: fetchEitherForwardOrBackwardTracks
export const fetchEitherForwardOrBackwardTracks = createAsyncThunk<
  any,
  any,
  any
>(
  "graph/fetchEitherForwardOrBackwardTracks",
  async (
    {
      tracking,
      type,
      ExternalID,
      buttonType,
      behavior,
      relatedObjects,
    }: InitailRequestParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const limit = 10;
      // resetting rollups before every req
      dispatch(setRollUps({}));

      let resData;
      // forward tracking
      if (tracking === "forward") {
        if (behavior === "dive") {
          const data = await fetchSelectedConfigAndObjectData(
            type,
            ExternalID,
            relatedObjects,
            limit,
          );
          // add behavior to data
          resData = { ...data, behavior };
        } else {
          resData = await getForwardTracks(type, ExternalID, buttonType);
        }
      }

      // backward tracking
      if (tracking === "backward") {
        if (behavior === "dive") {
          const data = await fetchSelectedConfigAndObjectData(
            type,
            ExternalID,
            relatedObjects,
            limit,
          );
          // add behavior to data
          resData = { ...data, behavior };
        } else {
          resData = await getBackwardTracks(type, ExternalID, buttonType);
        }
      }
      
      if (resData === "Network Error") {
        return rejectWithValue(
          "Network Error: Please Check Internet Connection!"
          );
        }
        
        if (resData?.length === 0) {
          return rejectWithValue("No Tracks Available for this Object!");
        }
      return fulfillWithValue(resData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// this is the async action graph crumb request
export const fetchGraphCrumbData = createAsyncThunk<any, any, any>(
  "graph/fetchGraphCrumbData",
  async (
    { type, ExternalID, limit }: InitailRequestParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    // resetting rollups before every req
    dispatch(setRollUps({}));
    dispatch(setRelationships([]));
    try {
      const crumbObject = await fetchConfigAndObjectData(
        type,
        ExternalID,
        limit
      );
      return fulfillWithValue({ crumbObject, ExternalID });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
