import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/generics/LoadingSpinner";
import { showIcon } from "../components/generics/utilities/utils";
import { useAppDispatch } from "../redux/hooks";
import { setCurrentUserEmail, setLogin } from "../redux/login/loginSlice";
import Api from "../services/api";
import FormHeaderComponent from "../components/generics/FormHeaderComponent";
import { setAuthToken } from "../services/auth";

const SignIn = () => {
  const userName = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const [loginError, setLoginError] = useState(false);
  const [isEyeIconOpen, setIsEyeIconOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUserDeniedAccess, setIsUserDeniedAccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const sendSignInRequest = async (data: {
    UserName: string;
    Password: string;
  }) => {
    setLoading(true);

    try {
      const response = await Api.post("/token ", data);
      setLoading(false);
      const token = response.data.Token;
      setAuthToken(token);
      // Sends the user to the Dashboard
      if (response.status === 200) {
        navigate("/home/dashboard");
        dispatch(setLogin(true));
        dispatch(setCurrentUserEmail(data.UserName));
      } else {
        setLoginError(true);
        setErrorMessage("Sorry, we can't log you in at the moment. Try again.");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (
        error.response?.data &&
        error.response?.data?.hasOwnProperty("Error")
      ) {
        setLoginError(true);
        setErrorMessage(
          "The username or password you entered is incorrect. Try again."
        );
      } else {
        setIsUserDeniedAccess(true);
        setErrorMessage(
          "Sorry, we are unable to log you in at the moment. Contact your administrator if this issue persists."
        );
        setLoginError(true);
      }
    }
  };
  const signIn = async (e: any) => {
    e.preventDefault();

    if (userName.current !== null && password.current !== null) {
      if (userName?.current.value !== "" && password.current.value !== "") {
        // Submits the data to the backend
        const data = {
          UserName: userName?.current.value,
          Password: password.current.value,
        };

        // MAKES THE REQUEST TO SIGNIN THE USER
        sendSignInRequest(data);
        return null;
      }
    }
    setIsUserDeniedAccess(false);
    setLoginError(true);
    setErrorMessage("The username or password cannot be empty.");
  };

  return (
    <div className="bg-[#fff] relative">
      <div className="login w-full h-[100vh] bg-[#fff] flex items-center justify-center">
        <div className="login-container w-[85%] h-[550px] md:w-[60%] md:h-[700px] lg:w-[30%] lg:h-[600px] ">
          <FormHeaderComponent
            title={"Log in to your account"}
            details={"Welcome back! Please enter your details."}
          />
          {loginError && (
            <div className="error-container border-[1px] border-[#FDA29B] flex p-[16px] mt-[32px] h-[72px] rounded-[8px] bg-[#FFFBFA] ">
              <div>
                <img src="/assets/error_icon.svg" alt="" />
              </div>
              <p className="ml-[13px] text-[14px] text-[#B42318]">
                {errorMessage}
              </p>
            </div>
          )}
          <form className="login-form mt-[32px]" onSubmit={(e) => signIn(e)}>
            <div className="email-container ">
              <label className="block text-[#344054]  text-[14px] font-normal mb-[4px]">
                Username
              </label>
              <div
                className={`email-container flex items-center border-[1px] ${
                  loginError && !isUserDeniedAccess
                    ? "border-[#FDA29B] "
                    : "border-[#D0D5DD] "
                } shadow-sm rounded-[8px] `}
                onClick={() => setLoginError(false)}
              >
                <input
                  className={`email-inputField text-[16px] text-accent-grayMuted  w-full ml-[0.2px] h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none`}
                  placeholder="Enter your username"
                  type="text"
                  ref={userName}
                />
                {loginError && !isUserDeniedAccess && (
                  <img
                    className="p-4 cursor-pointer"
                    src="/assets/error_icon.svg"
                    alt="Password Icon"
                  />
                )}
              </div>
            </div>
            <div className="password-container mt-[20px]">
              <label className="block text-[#344054] text-[14px] font-normal mb-[4px]">
                Password
              </label>
              <div className="password-inputFieldContainer">
                <div
                  className={`password flex items-center  border-[1px] ${
                    loginError && !isUserDeniedAccess
                      ? "border-[#FDA29B] "
                      : "border-[#D0D5DD] "
                  } shadow-sm rounded-[8px]`}
                  onClick={() => setLoginError(false)}
                >
                  <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                    placeholder="Enter your Password"
                    type={`${isEyeIconOpen ? "text" : "password"}`}
                    ref={password}
                  />
                  {loginError && !isUserDeniedAccess ? (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src="/assets/error_icon.svg"
                      alt="Password Icon"
                    />
                  ) : (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src={`/assets/${showIcon(isEyeIconOpen)}`}
                      alt="Password Icon"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="remember-forgotPassword mt-[26px] flex items-center justify-between">
              <div className="remember-me flex items-center ">
                <input
                  className="remember-me-inputField cursor-pointer w-[16px] h-[16px] border-[1px] border-[#D0D5DD] shadow-sm rounded-[2px]"
                  type="checkbox"
                />
                <label className="ml-[8px]  text-[14px] font-normal text-[#344054]">
                  Remember me
                </label>
              </div>
              <div className="remember-me flex items-center">
                <Link to="/forgotpassword">
                  <label className=" text-[14px]  font-normal text-[#3A6A78] cursor-pointer">
                    Forgot password
                  </label>
                </Link>
              </div>
            </div>
            <div className="w-full mt-[24px]">
              <button className=" bg-[#3A6A78] text-[16px] w-[100%] h-[44px] rounded-[8px] text-[#fff]">
                {loading ? <LoadingSpinner loading={loading} /> : "Log in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
