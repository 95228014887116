export const completeBackwardTrackingPathMap = [
  {
    RelatedObjectTypes: ["Article"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProductInPackage",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Product",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "Article",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Article",
      },
    ],
  },
  {
    RelatedObjectTypes: ["ProductionOrder"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProductInPackage",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Product",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "ProductionOrderTarget",
      },
    ],
  },
  {
    RelatedObjectTypes: ["Machine"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProductInPackage",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Product",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "ProductionOrderTarget",
      },
      {
        ObjectType: "ProductionOrder",
        RelatedObjectName: "CurrentlyExecutedAtMachine",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "Machine",
      },
    ],
  },
  {
    RelatedObjectTypes: ["Product"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
    ],
  },
  {
    RelatedObjectTypes: ["Package"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProductInPackage",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Product",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "Package",
      },
    ],
    BeginRelatedObjectMap: {
      ObjectType: "Product",
      RelatedObjectName: "BuiltOutOfProduct",
    },
  },
  {
    RelatedObjectTypes: ["Package"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltOutOfProductInPackage",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "Product",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "Package",
      },
    ],
    BeginRelatedObjectMap: {
      ObjectType: "Product",
      RelatedObjectName: "BuiltOutOfProductInPackage",
    },
  },
  {
    RelatedObjectTypes: ["Package"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "Package",
      },
    ],
  },
];
