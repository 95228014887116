import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as React from "react";

import {
  breadCrumbSelector,
  removeExcessPreviousState,
  removePreviousState,
} from "../../redux/breadCrumb/breadCrumbSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Api from "../../services/api";
import BreadCrumbArrow from "./BreadCrumbArrow";
import useBackClickListener from "../../customHooks/useBackClickListener";
import { isEqual } from "lodash";
import { objectDetailsSelector } from "../../redux/objects/objectDetailsSlice";
import SkeletonLoader from "./SkeletonLoader";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { displayTitleValueLabel } from "./utilities/utils";
import { objectSelector } from "../../redux/objects/objectSlice";

const BreadCrumbTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100%",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    cursor: "pointer",
    isolation: "isolate",
  },
}));

const BreadCrumbs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let breadCrumbs = window?.location?.pathname.split("/");
  breadCrumbs = breadCrumbs.slice(1, breadCrumbs.length);
  const { previousState } = useAppSelector(breadCrumbSelector);
  const { objects } = useAppSelector(objectSelector);
  const { objectInfo } = useAppSelector(objectDetailsSelector);

  const [previousObjectState, setPreviousObjectState] = useState(previousState);
  const [showBackButton, setShowBackButton] = useState(false);
  const [firstTwoLinks, setFirstTwoLinks]: Array<any> = useState();

  const handleBackButtonClick = (location: any) => {
    const backObjectState = location?.state;
    if (checkIfObjectsAreEqual(backObjectState))
      dispatch(removePreviousState());
  };

  const checkIfObjectsAreEqual = (obj: any) => {
    return isEqual(obj, previousObjectState[previousObjectState.length - 1]);
  };

  // Custom hook to handle back event clicks
  useBackClickListener(({ location }: { location: any }) => {
    handleBackButtonClick(location);
  });

  useEffect(() => {
    // Shows only 6 previous objects. If an previousState is greater than 4 then 4 object will be sliced.
    if (previousState && previousState?.length > 4) {
      // Selects the last 6 elements from previousState value
      const objectLinksToShow = previousState?.slice(1).slice(-4);
      // Selects the first 2 elements from the previousState value
      setFirstTwoLinks(previousState?.slice(0, 2));

      setPreviousObjectState(objectLinksToShow);
      setShowBackButton(true);
    } else {
      // When the length of previousState is lesser than 2 the next line sets "setFirstTwoLinks" to previous state
      if (previousState?.length <= 2) {
        setFirstTwoLinks(previousState);
      }
      if (previousState?.length === 0) {
        setShowBackButton(false);
      }
      setPreviousObjectState(previousState);
    }
  }, [previousState]);

  // Displays the first two previous visited Objects
  const showFirstTwoObjects = () => {
    if (showBackButton && firstTwoLinks?.length >= 1) {
      return firstTwoLinks?.map((objectID2: any, index: number) => {
        const value =
          objectID2?.["ObjectType"] +
          " " +
          checkValuesLength(objectID2?.["ExternalID"]);
        const keyProps = value + index;
        return (
          <BreadCrumbTooltip
            className="z-[-1]"
            key={keyProps}
            title={
              displayTitleValueLabel(objectID2?.["ObjectType"], objects) +
              " " +
              objectID2?.["ExternalID"]
            }
          >
            <span
              data-attr1={objectID2?.["ExternalID"]}
              data-attr2={objectID2?.["ObjectType"]}
              className="previous-object  mr-[3px]  cursor-pointer text-[#3A6A78]"
              onClick={(e) => {
                goToPreviousObject(e);
              }}
            >
              {value} <BreadCrumbArrow />
            </span>
          </BreadCrumbTooltip>
        );
      });
    } else {
      return null;
    }
  };

  const checkValuesLength = (item: string) => {
    if (previousState?.length <= 2) return item;
    return item?.length > 14 ? item?.slice(0, 14) + "..." : item;
  };

  const goToPreviousObject = (e: any) => {
    const objectType = e?.target?.dataset.attr2;
    const objectExternalId = e?.target?.dataset.attr1;

    (async () => {
      const previousObjectInfo = await Api({
        url: "object",
        method: "GET",
        params: {
          ObjectType: `${objectType}`,
          ObjectKeyValueMap: {
            ExternalID: `${objectExternalId}`,
          },
        },
      })
        .then((response) => {
          return response?.data?.Objects[0];
        })
        .catch((err) => {
          return err;
        });

      // sets the current state position that the user wants to go to
      dispatch(removeExcessPreviousState(previousObjectInfo));

      navigate(`${objectExternalId}`, {
        state: previousObjectInfo,
      });
    })();
  };

  const displayPreviousObject = () => {
    if (previousState.length === 0) {
      setShowBackButton(false);
    }
    dispatch(removePreviousState());
  };

  // Shows the active breadcrumb object type and name
  const showCurrentActiveObjectNameAndType = (objectInfoDetails: any) => {
    if (previousState?.length <= 2) {
      return (
        displayTitleValueLabel(
          objectInfoDetails?.[0]?.["ObjectType"],
          objects
        ) +
        " " +
        objectInfoDetails?.[0]?.Name
      );
    }

    return objectInfoDetails?.[0]?.Name?.length > 12
      ? displayTitleValueLabel(
          objectInfoDetails?.[0]?.["ObjectType"],
          objects
        ) +
          " " +
          objectInfoDetails?.[0]?.Name?.slice(0, 12) +
          "..."
      : displayTitleValueLabel(
          objectInfoDetails?.[0]?.["ObjectType"],
          objects
        ) +
          " " +
          objectInfoDetails?.[0]?.Name;
  };

  const showCurrentActiveObject = () => {
    if (typeof objectInfo?.[0]?.["ObjectType"] === "undefined") {
      return <SkeletonLoader />;
    } else {
      return (
        <BreadCrumbTooltip
          title={
            displayTitleValueLabel(objectInfo?.[0]?.["ObjectType"], objects) +
            " " +
            objectInfo?.[0]?.Name
          }
        >
          <span className="current-object cursor-default mr-[3px] text-primary-purpleDark">
            {showCurrentActiveObjectNameAndType(objectInfo)}
          </span>
        </BreadCrumbTooltip>
      );
    }
  };

  return (
    <div className="breadCrumb flex">
      <div className=" text-[14px] space-x-[18px] ">
        <span className="mr-[3px] general-text-color font-[400] ">
          {displayTitleValueLabel(
            breadCrumbs[1]?.slice(0, 1).toUpperCase() +
              breadCrumbs[1].slice(1, breadCrumbs[1].length),
            objects
          )}{" "}
          <BreadCrumbArrow />
        </span>
        {showBackButton && showFirstTwoObjects()}
        {showBackButton && previousObjectState?.length > 2 && (
          <>
            <span
              onClick={displayPreviousObject}
              className="preventSelect ml-[10px] cursor-pointer hover:text-primary-purpleDark hover:scale-50"
            >
              {" "}
              {"..."} <BreadCrumbArrow />
            </span>
          </>
        )}
        {/* Previous button link is clicked */}
        {previousObjectState?.length > 0 &&
          previousObjectState?.map((objectID: any, index: number) => {
            const value =
              displayTitleValueLabel(objectID?.["ObjectType"], objects) +
              " " +
              checkValuesLength(objectID?.["ExternalID"]);

            const mapKey = value + index;

            // Prevents the rendering of the already displayed 2 object externalID
            if (showBackButton) {
              if (index === 0 || index === 1) {
                return null;
              }
            }
            return (
              <BreadCrumbTooltip
                key={mapKey}
                title={
                  displayTitleValueLabel(objectID?.["ObjectType"], objects) + " " + objectID?.["ExternalID"]
                }
              >
                <span
                  data-attr1={objectID?.["ExternalID"]}
                  data-attr2={objectID?.["ObjectType"]}
                  className="link-class previous-object  mr-[3px] cursor-pointer general-text-color"
                  onClick={(e) => {
                    goToPreviousObject(e);
                  }}
                >
                  {value} <BreadCrumbArrow />
                </span>
              </BreadCrumbTooltip>
            );
          })}

        {showCurrentActiveObject()}
      </div>
    </div>
  );
};

export default BreadCrumbs;
