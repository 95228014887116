import { Formik, Form, Field } from "formik"
import React, { FC, useState } from "react"
import { VscClose } from "react-icons/vsc"

interface Props {
  closeModal: () => void
}

const ResendInvites: FC<Props> = ({ closeModal }) => {
  const emails = [
    "justice.ajah@amalitech.com",
    "sunain.malik@amalitech.org",
    "jedidiah.eyiah-king@amalitech.com",
    "caleb.ackom@amalitech.org",
  ]

  // local state user
  const [errorMessage, setErrorMessage] = useState("")

  // const formik = useFormik({
  const initialValues = {
    emails: [],
  }
  const onSubmit = (values: any, onSubmitProps: any) => {
    // setIsSubmitting to false
    onSubmitProps.setSubmitting(false)
  }
  const validate = (values: any) => {
    let errors: any = {}
    if (values.emails.length === 0) {
      setErrorMessage("Please select at least one email!")
      errors.name = "Please select at least one email!"
    }

    return errors
  }
  // })

  return (
    <div>
      <div className="w-full flex justify-between items-center mb-3">
        <h6 className="text-lg text-accent-grayDark font-bold">
          Resend Invites
        </h6>
        <VscClose
          onClick={closeModal}
          style={{ cursor: "pointer", color: "#667085" }}
          size={24}
        />
      </div>
      {errorMessage && (
        <div className="error-container border-[1px] border-[#FDA29B] flex p-[16px] mt-[32px] rounded-[8px] bg-[#FFFBFA] ">
          <div>
            <img src="/assets/error_icon.svg" alt="" />
          </div>
          <p className="ml-[13px] text-[14px] text-[#B42318]">{errorMessage}</p>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        // validateOnMount
      >
        {(formik: any) => {
          return (
            <Form>
              {emails &&
                emails.map((email) => {
                  return (
                    <div
                      key={email}
                      className="flex justify-start align-middle items-center mt-3"
                    >
                      <Field
                        type="checkbox"
                        name="emails"
                        id={email}
                        value={email}
                        className="text-[#3A6A78] focus:ring-0 focus:ring-offset-0 focus:shadow-none rounded-sm shadow"
                      />
                      <label
                        htmlFor={email}
                        className="text-[#344054] text-[14px] ml-2"
                      >
                        {email}
                      </label>
                    </div>
                  )
                })}
              <div className="space-x-3 mt-5 w-full flex justify-between align-middle">
                <button
                  onClick={closeModal}
                  className="text-[#000] flex-1 border rounded-[8px] py-2 px-5"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="bg-primary-purpleDark flex-1 justify-center items-center space-x-2 text-[#fff] rounded-[8px] py-2 px-5 font-normal"
                >
                  Resend
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ResendInvites
