import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../services/api";

export interface LoginState {
  isLoggedIn: boolean;
  loading: boolean;
  userEmail: string;
  info: any;
  error: string;
}

const initialState: LoginState = {
  isLoggedIn: false,
  loading: false,
  userEmail: "",
  info: {},
  error: "",
};

export const getCurrentUser = createAsyncThunk(
  "getCurrentUser",
  async (payload: string, { rejectWithValue }) => {
    return Api({
      url: "user",
      method: "GET",
      params: {
        UserName: payload,
        ReturnRoles: true
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue("Failure getting User");
      });
  }
);

const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLogin: (state, { payload }: PayloadAction<any>) => {
      state.isLoggedIn = payload;
    },

    setCurrentUserEmail: (state, { payload }: PayloadAction<string>) => {
      state.userEmail = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.info = payload.Users[0];
    });
  },
});

export const { setLoading, setErrors, setLogin, setCurrentUserEmail } =
  loginSlice.actions;

export default loginSlice.reducer;

export const loginSelector = (state: { user: LoginState }) => state.user;
