import React, { useRef, useState } from "react";
import { showIcon } from "./utilities/utils";

type Props = {
  title: string;
};

const Password: React.FC<Props> = ({ title }) => {
  const [loginError, setLoginError] = useState(false);
  const [isEyeIconOpen, setIsEyeIconOpen] = useState(false);
  const password = useRef<HTMLInputElement>(null);


  return (
    <div className=" pb-[20px] mt-[32px] flex justify-between ">
      <label className="block text-accent-grayLight">{title}</label>
      <div className="flex-wrap">
        <div
          className={`password flex items-center  border-[1px] ${
            loginError ? "border-[#FDA29B] " : "border-[#D0D5DD] "
          } shadow-sm rounded-[8px] w-[512px]`}
          onClick={() => setLoginError(false)}
        >
          <input
            className="email-inputField w-full text-[16px] text-accent-grayMuted  h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
            type={`${isEyeIconOpen ? "text" : "password"}`}
            required={true}
            minLength={8}
            ref={password}
          />

          {title !== "Current Password" &&
            (loginError ? (
              <img
                className="px-4 cursor-pointer"
                onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                src="/assets/error_icon.svg"
                alt="Password Icon"
              />
            ) : (
              <img
                className="px-4 cursor-pointer"
                onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                src={`/assets/${showIcon(isEyeIconOpen)}`}
                alt="Password Icon"
              />
            ))}
        </div>
        {title === "New Password" && (
          <label className="text-[14px] mt-[6px] w-[100%] text-accent-grayMuted">
            Your new password must be more than 8 characters
          </label>
        )}
      </div>
    </div>
  );
};

export default Password;
