import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ParameterRenderer from "../ParameterRenderer";

interface Props {
  levelTwoProps: any;
}

export function RollupsLevelTwoProps({ levelTwoProps }: Props) {
  return (
    <div className="node-details flex justify-start max-h-[60vh] flex-col overflow-x-hidden overflow-y-scroll levelsScrollHidden">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="!border-none"
      >
        {Object.keys(levelTwoProps)?.length > 0 &&
          Object.keys(levelTwoProps)?.map((key) => {
            if (key === "ID") return null;
            return (
              <AccordionItem key={key} className="!border-none">
                <AccordionItemHeading>
                  <AccordionItemButton className="accordion__button !py-1 !bg-white text-[11px] font-bold">
                    {key}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="!border-none p-0 text-[12px]">
                  <ParameterRenderer parameters={levelTwoProps[key]} />
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
}
