import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  activeObjectSelector,
  getSearchedObjectByName,
  getSearchedSuggestions,
  setFilterSuggestions,
} from "../../redux/activeObject/activeObject";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import { objectSelector } from "../../redux/objects/objectSlice";
import {
  checkSingleAndReturnRelationship,
  formRelatedObjects,
  getRequestRelationship,
  dispatchGetActiveObject,
} from "./utilities/utils";

const TextField = ({
  placeholder,
  name,
  resetPreviousInputField,
  activeSearchInputName,
}: {
  placeholder?: any;
  name?: string;
  resetPreviousInputField?: (inputTypeName: string) => void;
  activeSearchInputName?: string;
}) => {
  const value = useRef<HTMLInputElement>(null);
  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");
  const { activeObjectType, filterSuggestions } =
    useAppSelector(activeObjectSelector);
  const { objects } = useAppSelector(objectSelector);
  const [fieldIsActive, setFieldIsActive] = useState(false);
  const [isShowSuggestions, setIsShowSuggestions] = useState(true);

  let limit = 10;
  const componentRef: any = useRef();

  useEffect(() => {
    function handleClick(e: any) {
      if (
        componentRef &&
        componentRef.current?.className !== e.target.className
      ) {
        setIsShowSuggestions(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    if (inputValue !== "") {
      clearInputField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObjectType]);

  // Clears the input when the user switches between using the name and externalId
  //useEffect(() => {
  //  if (name !== activeSearchInputName) clearInputField();
  //}, [activeSearchInputName, name]);

  // Sets the InputValue state with the search value
  const setSearchItem = useCallback(
    (e: any, suggestedID?: string) => {
      let searchedObject = e?.target?.value ?? suggestedID;

      // Activates the suggestion display
      setIsShowSuggestions(true);
      if (value.current !== null) {
        // Indicates that the search input is active
        setFieldIsActive(true);

        // Updates the state with the search text
        setInputValue(searchedObject);

        // Prepares the configuration for the request
        const currentObjectType = JSON.parse(
          localStorage.getItem("activeProduct") as string
        );
        const singleTrueRelationships = checkSingleAndReturnRelationship(
          objects,
          `${currentObjectType}`
        );

        // Formats the relationships returned from checkSingleAndReturnRelationship
        const relatedObjects = formRelatedObjects(objects, currentObjectType);

        // RETURNS ONLY THE NEEDED RELATED OBJECTS FOR THE CONFIG
        const relatedObjectsConfig = getRequestRelationship(
          relatedObjects,
          singleTrueRelationships
        );

        const key = placeholder;

        // Preparation of the request configuration ends here

        // Dispatches an action to fetch the searched object && suggestions
        const allFilterConfig = JSON.parse(
          (localStorage.getItem("allFilterConfig") as any) || null
        );
        setFilterToLocalStorage(allFilterConfig, key, searchedObject);
        //if (searchedObject !== "") {
          dispatch(
            getSearchedSuggestions({
              objectType: currentObjectType,
              searchProperty: name,
              searchTerm: searchedObject,
            })
          );
          dispatch(
            getSearchedObjectByName({
              objectType: currentObjectType,
              value: searchedObject,
              key,
              relatedObject: relatedObjectsConfig,
              limit: limit,
            })
          );
        //}
      }
    },
    [dispatch, limit, name, objects, placeholder]
  );

  const setFilterToLocalStorage = (config: any, filterKey: string, value: string) => {
    localStorage.setItem(
      "allFilterConfig",
      JSON.stringify(
        config
          ? {
              ...config,
              [filterKey]: {
                value: value,
                comparator: "co",
              },
            }
          : {
            [filterKey]: {
                value: value,
                comparator: "co",
              },
            }
      )
    );
  };

  // persist debounce value between renders and this function is also responsible for calling the searchTerm function.
  const debouncedSearchItem = useMemo(() => {
    return debounce(setSearchItem, 300);
  }, [setSearchItem]);

  useEffect(() => {
    if (!firstRender) {
      if (inputValue === "") {
        // Updates the pagination active page and the this resets the pagination active to start from the first element.
        dispatch(setFilterSuggestions([]));
        localStorage.removeItem("filterConfig");

        // Dispatches an action to show the default data when the textfield is empty
        if (fieldIsActive) {
          dispatchGetActiveObject();
        }
      }
    }
    // Disables fieldIsActive and firstRender

    setFirstRender(false);

    // clean up any side effects from debounce when our component gets unmounted
    return () => {
      debouncedSearchItem.cancel();
    };
  }, [
    debouncedSearchItem,
    dispatch,
    fieldIsActive,
    firstRender,
    inputValue,
    limit,
    name,
    objects,
    placeholder,
  ]);

  // handling filter suggestions
  const handleInputChange = debounce((item) => {
    if (value.current !== null) {
      value.current.value = item;
      setInputValue(item);
      // Calls the search term function that makes the request to get the Object
      setSearchItem(null, item);
    }
    setIsShowSuggestions(false);
  }, 300);

  const clearInputField = () => {
    if (value.current !== null) {
      setInputValue("");
      setFieldIsActive(false);
      value.current.value = "";
    }
  };

  return (
    <div className="relative">
      <div className="flex items-center border-[1px] w-[100%] hover:!border-[1px] hover:!border-primary-purpleLight   h-[40px] rounded-[8px] border-[#bebec4] bg-[#ffff]">
        <input
          className={` w-full text-accent-grayMuted h-[28px] border-none py-[12px] px-[8px] ml-2 bg-[#ffff] focus:outline-none`}
          placeholder={placeholder}
          onClick={() => {
            name && resetPreviousInputField && resetPreviousInputField(name);
          }}
          ref={value}
          name={name}
          onChange={debouncedSearchItem}
          autoComplete="off"
        />
      </div>
      {inputValue && filterSuggestions?.length > 0 && isShowSuggestions && (
        <div
          ref={componentRef}
          className="filterSuggestionDropdown absolute top-12 bg-white border w-full rounded-md p-4 z-[9999] max-h-[400px] overflow-hidden overflow-y-scroll"
        >
          <ul>
            {filterSuggestions
              ?.slice(0, 10)
              .filter((item) => {
                const sTerm: any = inputValue.toLowerCase();
                const eIterm = item.toLowerCase();
                return sTerm && eIterm;
              })
              .map((itm) => (
                <li
                  className="py-1 cursor-pointer break-all"
                  key={itm}
                  onClick={() => {
                    handleInputChange(itm);
                  }}
                >
                  {itm}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TextField;
