import React, { FC } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

interface Props {
  dimensions: any;
}

const TimeSeriesDimensions: FC<Props> = ({ dimensions }) => {
  return (
    <div className="relative w-[60%] p-5 shadow-md sm:rounded-lg">
      <ol className="list-none">
        {dimensions && dimensions.length > 0 ? (
          dimensions?.map((dimension: any, index: number) => {
            return (
              <li key={index + new Date().getMilliseconds()} className="flex justify-between items-center align-middle my-2">
                <span>{dimension}</span>
                <span className="flex justify-end items-center space-x-5">
                  <RiDeleteBinLine
                    data-type={"delete"}
                    color="#667085"
                    size={18}
                    style={{ cursor: "pointer" }}
                  />
                  <FiEdit2
                    data-type={"editUser"}
                    color="#667085"
                    size={18}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </li>
            );
          })
        ) : (
          <div className="text-center py-3">
            Time Series Dimensions are not available
          </div>
        )}
      </ol>
    </div>
  );
};

export default TimeSeriesDimensions;
