export const machineBackwardTrackingPathMap = [
    {
        RelatedObjectTypes: [
            "Machine"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProductInPackage"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "ProductionOrderTarget"
            },
            {
                ObjectType: "ProductionOrder",
                RelatedObjectName: "CurrentlyExecutedAtMachine"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Machine"
            }
        ]
    }
]