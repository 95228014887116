import Password from "../generics/Password"



const ChangePassword = () => {

  return (
    <div className="change-password-container w-full">
    <div className="border-b-[1px]">
      <div className="w-[80%] pb-[20px] mt-[32px]">
        <Password title="Current Password"/>
      </div>
    </div>
    <div className="border-b-[1px]">
      <div className="w-[80%] pb-[20px] mt-[32px]">
        <Password title="New Password"/>
      </div>
    </div>
    <div className="border-b-[1px]">
      <div className="w-[80%] pb-[20px] mt-[32px]">
        <Password title="Confirm Password"/>
      </div>
    </div>
 
    <div className="button-group text-[14px] flex justify-end mt-[20px]">
      <div className="space-x-3">
        <button className="text-[#000] border-[1px] w-[63px] h-[40px] rounded-[8px]">
          Cancel
        </button>
        <button className="bg-primary-purpleDark text-[#fff] w-[63px] h-[40px] rounded-[8px] font-normal">
          Save
        </button>
      </div>
    </div>
  </div>
  )
}

export default ChangePassword