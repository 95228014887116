export const objectDetailsView = ["TRACKING", "ANALYSIS", "DETAILS"]
export const trackingObjectTypes = ["Product"];


export const analysisObjectTypes = [
  "Machine",
  "Package",
  "Product",
  "ProductionOrder",
];




