import { useEffect } from "react"
import { setIsOpenNotification } from "../../../redux/graph/graphSlice"
import { useAppDispatch } from "../../../redux/hooks"


const MoreNodesNotification = () => {
  const dispatch = useAppDispatch()
  // reset notification value
  useEffect(() => {
    setTimeout(() => {
      dispatch(setIsOpenNotification(false))
    }, 15000)

  }, [dispatch])
  return (
    <div className="bg-[#F5F5FF] flex justify-between items-start px-5 py-4 mx-2 my-2 rounded-lg text-left text-[#3A6A78] h-20">
      <span className="text-[12px] pr-8">
        More relationships found Kindly select filter
      </span>
      <span className="text-[12px]">X</span>
    </div>
  )
}

export default MoreNodesNotification