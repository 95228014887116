/* eslint-disable react-hooks/exhaustive-deps */
/** GridLine.js */
import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

const GridLine = ({
  type,
  scale,
  ticks,
  size,
  transform,
  disableAnimation,
  className,
  ...props
}: {
  type: any;
  scale: any;
  ticks: any;
  size: any;
  transform: any;
  className?: string;
  disableAnimation: any;
}) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    const axisGenerator = type === "vertical" ? d3.axisBottom : d3.axisLeft;
    const axis = axisGenerator(scale).ticks(ticks).tickSize(-size);

    const gridGroup: any = d3.select(ref.current);
    if (disableAnimation) {
      gridGroup.call(axis);
    } else {
      gridGroup
        .transition()
        .duration(750)
        .ease(d3.easeLinear)
        .call(axis);
    }
    gridGroup.select(".domain").remove();
    gridGroup.selectAll("text").remove();
    gridGroup.selectAll("line").attr("stroke", "#F2F4F7");
  }, [scale, ticks, size, disableAnimation]);

  return <g className={className} ref={ref} transform={transform} {...props} />;
};

GridLine.propTypes = {
  scale: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  ticks: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  transform: PropTypes.string,
  size: PropTypes.number,
  disableAnimation: PropTypes.bool,
};

GridLine.defaultProps = {
  ticks: 0,
  transform: "",
  size: 0,
  disableAnimation: false,
};

export default GridLine;
