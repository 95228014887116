import axios, { AxiosRequestConfig } from "axios";
import { apiUrl } from "../public/config";
import { getAuthToken, isTokenActive, setAuthToken } from "./auth";
import { setLogin } from "./../redux/login/loginSlice";

export let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};


const Api = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Request interceptor
Api.interceptors.request.use(
  function (config: AxiosRequestConfig<any>) {
    const token = getAuthToken();
    if (isTokenActive(token)) {
      // Adds header to the request object if not there already
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
      return config;
    }

    store.dispatch(setLogin(false));
    return config;
  },

  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Request response interceptor
Api.interceptors.response.use((response) => {
  // Updates token with the value of refresh token
  setAuthToken(response.data?.RefreshedToken);
  return response;
});

export default Api;
