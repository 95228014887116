import { useEffect, useLayoutEffect, useRef } from "react";
import { useAppDispatch } from "../redux/hooks";

interface TrackingPropertyTypes {
  info: {
    externalId: string;
    objectType: string;
  };
  trackingAction: (parameters: any) => any;
}

const useCallbackRef = (callback: any) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  return callbackRef;
};

export const useFetchTracking = ({
  info,
  trackingAction,
}: TrackingPropertyTypes) => {
  const dispatch = useAppDispatch();

  
  const savedTrackingAction = useCallbackRef(trackingAction);

  useEffect(() => {
    const fetchProductTracking = () => {
      dispatch(
        savedTrackingAction.current({
          type: "Article",
          externalId: info.externalId,
          objectType: info.objectType,
        })
      );
      dispatch(
        savedTrackingAction.current({
          type: "Machine",
          externalId: info.externalId,
          objectType: info.objectType,
        })
      );
      dispatch(
        savedTrackingAction.current({
          type: "Package",
          externalId: info.externalId,
          objectType: info.objectType,
        })
      );
      dispatch(
        savedTrackingAction.current({
          type: "Product",
          externalId: info.externalId,
          objectType: info.objectType,
        })
      );
      dispatch(
        savedTrackingAction.current({
          type: "ProductionOrder",
          externalId: info.externalId,
          objectType: info.objectType,
        })
      );
    };

    fetchProductTracking();
  }, [dispatch, info.externalId, info.objectType, savedTrackingAction]);
};

export default useFetchTracking;
