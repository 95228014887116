import  { MouseEvent, useCallback, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { HiOutlinePlus } from "react-icons/hi";
import { Box, Modal, Stack, Typography } from "@mui/material";
import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import AddUser from "./AddUser";
import ResendInvites from "./ResendInvites";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import { ErrorPage } from "../../../graph/error/ErrorPage";
import UserRows from "./UserRows";
import { ClipLoader } from "react-spinners";
import AFTPalette from "../../../generics/aftpalette";
import { setPage } from "../../../../redux/users/usersSlice";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { fetchGetAllUsers } from "../../../../redux/users/actions";
import { ErrorBoundary } from "react-error-boundary";
import { fetchGetAllRoles } from "../../../../redux/roles/actions";
import { style } from "./boxStyles";


interface Column {
  id: "Name" | "UserName" | "Email" | "RolesString" | "RegisteredActive" | "actions";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Name", label: "Name", minWidth: 100 },
  { id: "Email", label: "Email Address", minWidth: 100 },
  { id: "UserName", label: "Username", minWidth: 100 },
  { id: "RolesString", label: "Roles", minWidth: 100 },
  { id: "RegisteredActive", label: "Registered / Active", minWidth: 100 },
  { id: "actions", label: "", minWidth: 100 },
];

export default function CustomTable() {
  const dispatch = useAppDispatch();
  // STORE STATE
  const { users, error, totalNumberOfUsers, loading, page } = useAppSelector(
    (state: any) => state.users
  );

  // sort users in descending order by date of insertion
  const sortedUsers = [...users].sort((a: any, b: any): any => {
    let valOne: any = new Date(a.TimeStampDBInsertion);
    let valtwo: any = new Date(b.TimeStampDBInsertion);
    return valtwo - valOne;
  });

  const [rowsPerPage] = useState(7);
  const [open, setOpen] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isResendInvites, setIsResendInvites] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  const [currentRole, setCurrentRole] = useState("");

  // check whether to add s to objecttype
  const ss = users?.length > 1 ? "s" : "";

  // get the screen width
const { width } = useWindowDimensions()

const handleOpen = useCallback(
  (e: MouseEvent) => {
    const target = e.currentTarget as any;
    const action = target.dataset.type;
    const username = target.dataset.username;
    const role = target.dataset.role;
    switch (action) {
      case "addUser":
        dispatch(fetchGetAllRoles());
        setOpen(true);
        setIsAddUser(true);
        setIsResendInvites(false);
        setIsEditUser(false);
        setIsDeleteUser(false);
        break;
      case "resend":
        setOpen(true);
        setIsAddUser(false);
        setIsResendInvites(true);
        setIsEditUser(false);
        setIsDeleteUser(false);
        break;
      case "editUser":
        dispatch(fetchGetAllRoles());
        setOpen(true);
        setIsAddUser(false);
        setIsResendInvites(false);
        setIsEditUser(true);
        setIsDeleteUser(false);
        setCurrentUsername(username)
        setCurrentRole(role)
        break;
      case "delete":
        setOpen(true);
        setIsAddUser(false);
        setIsResendInvites(false);
        setIsEditUser(false);
        setIsDeleteUser(true);
        setCurrentUsername(username)
        break;
      default:
        break;
    }
    setOpen(true);
  },
  [dispatch]
);
const handleClose = useCallback(() => setOpen(false), []);

const handleChangePage = useCallback(
  ({ selected }: any) => {
    const skip = selected * 7;
    setPage(skip);
    dispatch(fetchGetAllUsers(skip));
  },
  [dispatch]
);

// Avoid a layout jump when reaching the last page with empty rows.
const emptyRows = page > 0 ? rowsPerPage - users.length : 0;

const pagesCount = useMemo(() => {
  return Math.round(totalNumberOfUsers / 7);
}, [totalNumberOfUsers]);

  const renderUserRowsOrError =
    users.length > 0 ? (
      <UserRows
        users={sortedUsers}
        columns={columns}
        modalHandler={handleOpen as any}
      />
    ) : (
      <div className="h-full mt-16 absolute left-0 right-0">
        <ErrorPage error="No matching user found!" />
      </div>
    );

  // render the user rows
  const renderUserRows = loading ? (
    <div className="absolute top-[50%] left-[50%]">
      <ClipLoader
        size={40}
        loading={loading}
        color={AFTPalette.primary.purpleLight}
      />
    </div>
  ) : (
    renderUserRowsOrError
  );
  



  if (!users) return <ErrorPage error={error} />;
  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden", borderRadius: "8px", mb: 1 }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: "rgba(52, 64, 84, 0.7)" } }}
      >
        <Box sx={style}>
          <div className="">
            <ErrorBoundary FallbackComponent={ErrorPage}>
              {isAddUser && <AddUser closeModal={handleClose} />}
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={ErrorPage}>
              {isResendInvites && <ResendInvites closeModal={handleClose} />}
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={ErrorPage}>
              {isEditUser && <EditUser closeModal={handleClose} currentUsername={currentUsername} currentRole={currentRole}/>}
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={ErrorPage}>
              {isDeleteUser && <DeleteUser closeModal={handleClose} username={currentUsername}/>}
            </ErrorBoundary>
          </div>
        </Box>
      </Modal>
      <TableContainer sx={{ overflow: "hidden" }}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={4} sx={{ py: "1.4rem" }}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#3A6A78",
                    }}
                    variant="h6"
                    component="div"
                  >
                    Team members
                  </Typography>
                  <span className="bg-secondary-grayLight text-primary-purpleDark text-[12px] rounded-full py-[2px] px-3">
                    {`${totalNumberOfUsers}  User${ss}`}
                  </span>
                </Stack>
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    mt: "0.5rem",
                    fontSize: "14px",
                    color: "#667085",
                  }}
                  variant="body1"
                  component="div"
                >
                  Manage your team members and their account permissions here.
                </Typography>
              </TableCell>
              <TableCell align="right" colSpan={2}>
                <div className="space-x-3 w-full flex justify-end align-middle">
                  <button
                    data-type={"resend"}
                    onClick={handleOpen}
                    className="text-[#000] border rounded-[8px] py-2 px-5"
                  >
                    Resend Invites
                  </button>
                  <button
                    data-type={"addUser"}
                    onClick={handleOpen}
                    className="bg-primary-purpleDark flex justify-center items-center space-x-2 text-[#fff] rounded-[8px] py-2 px-5 font-normal"
                  >
                    <HiOutlinePlus size={20} />
                    <span>Add User</span>
                  </button>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns &&
                columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody
            className="h-[57vh] overflow-hidden"
            sx={{ position: "relative" }}
          >
           {error ? (
              <div className="h-full mt-5 absolute left-0 right-0">
                <ErrorPage error={error} />
              </div>
            ) : (
              renderUserRows
            )}
            {/* render empty rows on show of users */}
            {(totalNumberOfUsers > rowsPerPage || emptyRows > 0) && (
              <TableRow style={{ height: width > 1650 ? 83 * emptyRows : 63 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalNumberOfUsers > rowsPerPage && !error && (
        <div className="relative w-full h-fit overflow-x-scroll overflow-y-hidden pt-[12px] md:static  md:overflow-hidden">
          <div className="pagination-container relative w-[full] h-[40px] left-[15px] top-[12px] pr-[10px] md:pr-[0px] pb-[40px] md:pb-[0px] md:static md:bottom-[16px] md:mr-[24px] md:h-[46px] md:top-[26px]  ">
            <ReactPaginate
              previousLabel={
                <span>
                  <img
                    className="inline"
                    src="/assets/PreviousIcon.svg"
                    alt="next icon label"
                  />
                </span>
              }
              nextLabel={
                <span>
                  <img
                    className="inline"
                    src="/assets/NextIcon.svg"
                    alt="next icon label"
                  />
                </span>
              }
              pageCount={pagesCount}
              onPageChange={handleChangePage}
              containerClassName={`pagination`}
              previousLinkClassName={`previousButton`}
              nextLinkClassName={`nextButton`}
              disabledClassName={`pagination-disabled`}
              activeClassName={`activePagination`}
              activeLinkClassName={`activePage`}
              initialPage={0}
            />
          </div>
        </div>
      )}
    </Paper>
  );
}