import { useCallback, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchRelatedObjects,
  objectDetailsSelector,
} from "../../redux/objects/objectDetailsSlice";
import { objectSelector } from "../../redux/objects/objectSlice";
import { headerTypes } from "../../types/products";
import AFTPalette from "../generics/aftpalette";
import {
  checkAndReturnDeleteOldValue,
  displayRelationshipNameValueLabel,
  displayTitleValueLabel,
  getRelationshipNameObjectType,
  machineColumnHeaders
} from "../generics/utilities/utils";
import DetailsPagination from "../objectdetails/DetailsPagination";
import OnlyActiveSwitch from "../objectdetails/detailsRelatedObjectSwitch/OnlyActiveSwitch";
import ObjectDetails from "../table/ObjectTable/relatedObjects/ObjectDetails";
import TableHeader from "../table/TableHeader";


const AnalysisRelatedObjects = ({ state: propState }: { state: any }) => {
  const { objectInfo, relatedObjName, loading } = useAppSelector(
    objectDetailsSelector
  );

  let breadCrumbs = window?.location?.pathname.split("/");
  breadCrumbs = breadCrumbs.slice(1, breadCrumbs.length);
  const generalHeader = machineColumnHeaders;
  const { objects } = useAppSelector(objectSelector);
  const [relatedObjects, setRelatedObject] = useState(
    objectInfo?.[0]?.RelatedObjects
  );
  const [onlyActiveSettings, setOnlyActiveSettings] = useState({
    onlyActiveFlag: true,
    currentObjectType: "",
  });
  const dispatch = useAppDispatch();
  const [nextRelationshipTotal, setNextRelationshipTotal] = useState(0);

  useEffect(() => {
    setRelatedObject(objectInfo?.[0]?.RelatedObjects);
  }, [objectInfo, objects]);

  const updateNextRelationshipTotal = (value: any) => {
    setNextRelationshipTotal(value);
  };

  // USED TO SHOW ONLY ACTIVE OBJECTS
  const getOnlyActiveCheckValue = useCallback(
    (onlyActiveValue: boolean, activeObjectType: string) => {
      const reqParams = {
        skip: 0,
        relationshipName: activeObjectType,
        externalId: propState?.["ExternalID"],
        objectType: propState?.["ObjectType"],
        onlyActive: onlyActiveValue,
      };

      // Dispatches an action to fetch the relatedObjects based on the onlyActive value.
      dispatch(fetchRelatedObjects(reqParams));

      // Updates the state that holds onlyActive configuration
      setOnlyActiveSettings({
        onlyActiveFlag: onlyActiveValue,
        currentObjectType: activeObjectType,
      });
    },
    [dispatch, propState]
  );

  const showRelatedObjects = () => {
    if (relatedObjects && Object.keys(relatedObjects)?.length > 0) {
      return (
        <>
          {/* Shows the related objects if any exists */}
          {relatedObjects &&
            Object.keys(relatedObjects)?.length > 0 &&
            Object.keys(relatedObjects)?.map(
              (currentObject: string, index: number) => {
                // Gets the current RelationshipName related object type
                const relationshipName = getRelationshipNameObjectType(
                  objects,
                  {
                    parentRelatedObjectType: objectInfo?.[0]?.ObjectType,
                    objectRelationshipName: currentObject,
                  }
                );
                return (
                  <div
                    key={currentObject.toString()}
                    className="relatedObject mb-[24px]  mt-2 "
                  >
                    <div className="relatedObjectTable overflow-x-auto mt-[24px] mb-14 border-[1px] relatedObjects-table-bgColor  rounded-[8px]">
                      <table className="w-full " key="related-objects">
                        <caption className="py-[10px] text-[16px] general-text-color font-[600] text-start pl-[24px]  border-b-[0.5px]">
                          <div className="flex justify-between items-center">
                            <label>
                              {currentObject !== relationshipName ? (
                                <span>
                                  {displayRelationshipNameValueLabel(
                                    objectInfo?.[0]?.ObjectType,
                                    currentObject,
                                    objects
                                  )}{" "}
                                  <span className=" text-[#4d4d4f] ">{` (${displayTitleValueLabel(
                                    relationshipName,
                                    objects
                                  )})`}</span>
                                </span>
                              ) : (
                                displayRelationshipNameValueLabel(
                                  objectInfo?.[0]?.ObjectType,
                                  currentObject,
                                  objects
                                )
                              )}
                            </label>
                            {checkAndReturnDeleteOldValue(
                              objects,
                              propState?.["ObjectType"],
                              currentObject
                            ) && (
                              <label className="pr-8 space-x-4 text-[#4d4d4f]">
                                <OnlyActiveSwitch
                                  activeObjectName={currentObject}
                                  getOnlyActiveCheckValue={
                                    getOnlyActiveCheckValue
                                  }
                                />
                              </label>
                            )}
                          </div>
                        </caption>
                        {relatedObjects[currentObject]?.["Elements"].length >
                        0 ? (
                          <>
                            <TableHeader
                              onlyActiveSettings={onlyActiveSettings}
                              showSortIcon={true}
                              relationshipName={currentObject}
                              pageType={headerTypes.objectDetailsOverview}
                              tableHeader={generalHeader}
                            />
                            <ObjectDetails
                              breadCrumbs={breadCrumbs}
                              onlyActiveSettings={onlyActiveSettings}
                              relationshipData={{
                                relatedObjName: relatedObjName,
                                relationshipName: currentObject,
                              }}
                              updateNextRelationshipTotal={
                                updateNextRelationshipTotal
                              }
                              relatedObjects={
                                relatedObjects[currentObject]?.["Elements"]
                              }
                            />
                          </>
                        ) : (
                          <tbody>
                          <tr>
                            <td>
                            
                          <div className="text-center py-4">{`No ${displayRelationshipNameValueLabel(
                            propState?.["ObjectType"],
                            currentObject,
                            objects
                          )} available`}</div>
                          </td>
                          </tr>
                          </tbody>
                        )}
                      </table>
                      {relatedObjects[currentObject]?.["QuantityObjectsTotal"] >
                        10 && (
                        <DetailsPagination
                          quantityObjectInfo={{
                            QuantityObjectsTotal:
                              relatedObjects[currentObject]?.[
                                "QuantityObjectsTotal"
                              ],
                            currentObjectType: currentObject,
                          }}
                          onlyActiveSettings={onlyActiveSettings}
                          relationshipData={{
                            relatedObjName: relatedObjName,
                            relationshipName: currentObject,
                            nextRelationshipTotal: nextRelationshipTotal,
                          }}
                          pageCount={Math.round(
                            relatedObjects[currentObject]?.[
                              "QuantityObjectsTotal"
                            ] / 10
                          )}
                          paginationRequestParams={{
                            relationshipName,
                            externalId: propState?.["ExternalID"],
                            objectType: propState?.["ObjectType"],
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            )}
        </>
      );
    } else {
      return (
        <>
          <div>
            <h2 className="text-center mt-10 text-[18px] pb-[34px]">
              No related Objects
            </h2>
          </div>
        </>
      );
    }
  };

  const renderRelatedObjectsTable = () => {
    if (loading)
      return (
        <div className="flex items-center justify-center mt-20">
          <ClipLoader
            size={40}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      );

    return <div className="mb-[34px]">{showRelatedObjects()}</div>;
  };
  return (
    <div className="relatedObjectsContainer mt-[60px]">
      <h4 className="text-[18px] font-[600]">Related Objects</h4>
      {renderRelatedObjectsTable()}
    </div>
  );
};

export default AnalysisRelatedObjects;
