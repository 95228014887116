import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SidebarItem } from "../../components/sideBar/DataStructure";

export interface SideBarState {
  menuItems: Array<SidebarItem>;
  resetOpenedSideBarNav: boolean;
}

const initialState: SideBarState = {
  menuItems: [],
  resetOpenedSideBarNav: false,
};

const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    setGlobalMenuItems: (
      state,
      { payload }: PayloadAction<Array<SidebarItem>>
    ) => {
      state.menuItems = payload;
    },
    resetGlobalMenuItems: (state, { payload }: PayloadAction<boolean>) => {
      state.resetOpenedSideBarNav = payload;
    },
  },
});

export const { setGlobalMenuItems, resetGlobalMenuItems } =
  sideBarSlice.actions;

export default sideBarSlice.reducer;

export const sideBarSelector = (state: { menuItems: SideBarState }) =>
  state.menuItems;
