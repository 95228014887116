export const productionOrderForwardTrackingPathMap = [
  {
    RelatedObjectTypes: ["ProductionOrder"],
    RelatedObjectMaps: [
      {
        ObjectType: "Product",
        RelatedObjectName: "BuiltInProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "Package",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "ContainingProductIsBuiltInProduct",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "ProductionOrderSource",
      },
      {
        ObjectType: "Package",
        RelatedObjectName: "ProductionOrderSource",
      },
      {
        ObjectType: "Product",
        RelatedObjectName: "ProductionOrderTarget",
      }
    ],
  },
];
