import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import PageNotFound from "./pages/PageNotFound";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import SignUp from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import TreeView from "./components/graph/treeView/TreeView";
import {
  getCurrentUser,
  loginSelector,
} from "./redux/login/loginSlice";
import NewPassword from "./pages/NewPassword";
import WrapGraph from "./components/graph/WrapGraph";

function App() {
  const { isLoggedIn, userEmail } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();


  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUser(userEmail));
    }
  }, [dispatch, isLoggedIn, userEmail]);

  return (
    <div className="App w-[100%]">
      <Routes>
        <Route path={"/register"} element={<SignUp />}></Route>
        <Route path={"/newpassword"} element={<NewPassword />}></Route>
        <Route path={"/login"} element={<SignIn />}></Route>
        <Route path={"/forgotpassword"} element={<ForgotPassword />}></Route>
        <Route
          path={"/"}
          element={
            isLoggedIn ? <Navigate to="/home/dashboard" replace /> : <SignIn />
          }
        ></Route>

        <Route
          path={"/viewtree"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <TreeView />
            </Protected>
          }
        ></Route>
        <Route
          path={"/graph"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <WrapGraph />
            </Protected>
          }
        ></Route>
        <Route
          path={"/home/*"}
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Home />
            </Protected>
          }
        ></Route>
        <Route path={"*"} element={<PageNotFound />}></Route>
      </Routes>
    </div>
  );
}

export const Protected = ({
  isLoggedIn,
  children,
}: {
  isLoggedIn: boolean;
  children: React.ReactElement;
}) => {
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default App;
