import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchRollupValues,
  objectDetailsSelector,
} from "../../redux/objects/objectDetailsSlice";
import TableHeader from "../table/TableHeader";
import { objectSelector } from "../../redux/objects/objectSlice";
import { headerTypes } from "../../types/products";

type Props = {};
const Rollups: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { objectInfo, rollupValues, loading } = useAppSelector(
    objectDetailsSelector
  );
  const { objects } = useAppSelector(objectSelector);

  useEffect(() => {
    const getRollups = () => {
      const objectType = objectInfo?.[0]?.ObjectType;
      const externalId = objectInfo?.[0]?.ExternalID;

      if(objectType !== undefined && objectType !== "" && externalId !== undefined && externalId !== ""){
        dispatch(
          fetchRollupValues({
            objectType,
            externalId,
          })
        );
      }   
    };
    // Checks if the object type has Rollup
    getRollups();
  }, [dispatch, objectInfo, objects]);

  return (
    <>
      {!loading && typeof rollupValues?.[0]?.RollUps !== "undefined" && (
        <div className="relatedObjectsContainer mt-[60px] mb-20">
          <h4 className="text-[18px] font-[600]">RollUp</h4>
          <div className="generalInfo-table overflow-x-auto mt-[20px] w-[98%] md:w-[100%] lg:w-full border-[2px] rounded-[8px]">
            <>
              {rollupValues?.[0]?.RollUps &&
                Object.keys(rollupValues?.[0]?.RollUps)?.map(
                  (rollupType: any, index: number) => {
                    return (
                      <div
                        className="Rollup-container"
                        key={rollupValues?.[0]?.RollUps[`${rollupType}`]?.Name}
                      >
                        <div className=" w-full py-[10px] text-[16px] text-[#3A6A78] font-[600] text-start pl-[24px]  border-b-[0.5px]">
                          <label>
                            {rollupValues?.[0]?.RollUps[`${rollupType}`]?.Name}
                          </label>
                        </div>
                        {rollupValues?.[0]?.RollUps[`${rollupType}`] &&
                          Object.keys(
                            rollupValues?.[0]?.RollUps[`${rollupType}`]
                          ).map((keyName) => {
                            if (
                              keyName === "Object" ||
                              keyName === "Relationship"
                            )
                              return (
                                <div>
                                  <table className="w-full" key={keyName}>
                                    <caption className=" py-[10px] text-[14px] text-[#3A6A78] font-[600] text-start pl-[24px]  border-b-[0.5px]">
                                      <label>{keyName}</label>
                                    </caption>
                                    <TableHeader
                                      showSortIcon={false}
                                      pageType={
                                        headerTypes.objectDetailsOverview
                                      }
                                      tableHeader={[
                                        "Parameter",
                                        "Count",
                                        "Min",
                                        "Max",
                                        "Avg",
                                        "Stdev",
                                      ]}
                                    />
                                    <tbody className="w-full  ">
                                      {Object.entries(
                                        rollupValues?.[0]?.RollUps[
                                          `${rollupType}`
                                        ][keyName]
                                      ).map(([key, value]: [string, any]) => {
                                        // Returns null when the key is ID
                                        if (key === "ID") return null;

                                        // Else renders the rest of the items
                                        return (
                                          <tr className="border-t-[0.1px] text-[#667085] text-[12px] bg-[#fff]">
                                            <th></th>
                                            <td className="table-cell-Style">
                                              {key}
                                            </td>
                                            <td className="table-cell-Style">
                                              {value.Count}
                                            </td>
                                            <td className="table-cell-Style">
                                              {value.Min}
                                            </td>
                                            <td className="table-cell-Style">
                                              {value.Max}
                                            </td>
                                            <td className="table-cell-Style">
                                              {value.Avg}
                                            </td>
                                            <td className="table-cell-Style">
                                              {value.Stdev}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              );

                            return null;
                          })}
                      </div>
                    );
                  }
                )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default Rollups;
