import Api from "../../../../../services/api";
import {productsForwardTrackingPathMap} from "../../treeConfigs/forwardTracking/products/products"
import {productionOrderForwardTrackingPathMap} from "../../treeConfigs/forwardTracking/productionOrder/productionOrder"
import {articlesForwardTrackingPathMaps} from "../../treeConfigs/forwardTracking/articles/articles"
import {machineForwardTrackingPathMap} from "../../treeConfigs/forwardTracking/machine/machine"
import {packagesForwardTrackingPathMaps} from "../../treeConfigs/forwardTracking/packages/packages"
import {completeForwardTrackingPathMap} from "../../treeConfigs/forwardTracking/completeForward/completeForward"
import { errorHandler, objectsUrl } from "./objectsApiCalls";

// stringify the pathmaps
export const getStringifiedPathMapForwards = (buttonType: string) => { 
    let stringifiedForwardObjectPathMapConfig;
    const buttonTypeEdited = buttonType.replaceAll(" ", "").toLowerCase()
    switch (buttonTypeEdited) {
      case "product":
        
        stringifiedForwardObjectPathMapConfig = JSON.stringify(
            productsForwardTrackingPathMap
        );
        break;
      case "productionorder":
        stringifiedForwardObjectPathMapConfig = JSON.stringify(
            productionOrderForwardTrackingPathMap
        );
        break;
      case "article":
        
        stringifiedForwardObjectPathMapConfig = JSON.stringify(
          articlesForwardTrackingPathMaps
        );
        break;
      case "machine":
       
        stringifiedForwardObjectPathMapConfig = JSON.stringify(
            machineForwardTrackingPathMap
        );
        break;
      case "package":
        
        stringifiedForwardObjectPathMapConfig = JSON.stringify(
            packagesForwardTrackingPathMaps
        );
        break;
      case "allrelatedobjects":
        
        stringifiedForwardObjectPathMapConfig = JSON.stringify(
          completeForwardTrackingPathMap
        );
        break;
      default:
        
        break;
    }
  
    return stringifiedForwardObjectPathMapConfig
   }
  
  // fetch objects base on configs
  export const getForwardTracks = async (
    objectType: string,
    ExternalID: string,
    buttonType: string
  ) => {
    
  
    const stringifiedForwardObjectPathMapConfig = getStringifiedPathMapForwards(buttonType)
   
    try {
      let time = performance.now();
      
      const requestParamsBackward = {
        ObjectType: objectType,
        ObjectKeyValueMap: {
          ExternalID: [ExternalID],
        },
        PathMaps: stringifiedForwardObjectPathMapConfig,
        "IgnoreDirection": true,
        "ReverseDirection": true
      };
      
      // request for forward tracking
      const resdataForward = await Api({
        url: objectsUrl,
        method: "get",
        params: requestParamsBackward,
      });

      
      return resdataForward.data.Objects;
    } catch (err: any) {
      return errorHandler(err);
    }
  };