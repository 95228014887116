import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFilteredUsers, fetchGetAllUsers } from "./actions";
import { initialState } from "./initialState";

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    resetStore: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  //  FOR ASYNC STATE MUTATION
  extraReducers: (builder) => {
    // fetchGetAllUsers  is an initial render action
    builder.addCase(fetchGetAllUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchGetAllUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      for (const key in payload) {
        const element = payload[key];
        switch (key) {
          case "users":
            state.users = element;
            break;
          case "totalUserCount":
            state.totalNumberOfUsers = element;
            break;
          case "currentUserCount":
            state.totalQuantityUsersCurrent = element;
            break;
          default :
            break;
        }
      }
    });
    builder.addCase(fetchGetAllUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // fetchFilteredUsers  is an initial render action
    builder.addCase(fetchFilteredUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchFilteredUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      const {users, currentUserCount, totalUserCount } = payload      
      state.users = users;
      state.totalNumberOfUsers = totalUserCount;
      state.totalQuantityUsersCurrent = currentUserCount;
    });
    builder.addCase(fetchFilteredUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { setLoading, setError, setUsers, setPage } = usersSlice.actions;

export default usersSlice.reducer;
