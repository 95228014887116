import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import {
  formatDataObject,
  formatRelatedObjectsAndAddToCurrentGraph,
} from "../../components/generics/utils/graphFormaters";
import { formatPathMaps } from "../../components/generics/utils/treeTraverser";
import {
  finder,
  formatGroups,
  removeDuplicatesFromNextRelatedObject,
  updateCurrentNodeWithDepth,
} from "../../components/generics/utils/utils";
import { GraphState } from "../../types/graphDataTypes";
import {
  fetchEitherForwardOrBackwardTracks,
  fetchGraphCrumbData,
  fetchInitialGraph,
  fetchObjectAndRelationsFilters,
  fetchObjectTracks,
  fetchSelectedRelationsOfGraph,
} from "./actions";
import { initialState } from "./initialState";

const getAllRelatedObjects = <T extends {}>(relatedObjects: T) => {
  let result: Array<any> = [];
  for (let object in relatedObjects) {
    let value: { objectName: string } = relatedObjects[object] as any;
    value.objectName = object;
    result.push(value);
  }

  return result;
};

/* Function that adds the diveDepth property to the nodes available.
   This is used for the tracking of nodes.
*/
const setInitialDeptToNodes = (nodes: Array<any>, state: GraphState) => {
  return nodes.map((node: any, index: number) => {
    if (index === 0) {
      node.diveDepth = state.diveDepthCount;
    } else {
      node.diveDepth = state.diveDepthCount + 1;
    }

    return node;
  });
};

/* Sets the available nodes to be dropped to local storage.  
   This is used to remove the related Objects from the canvas 
   when the user deselects the checkbox.
*/
const checkLengthAndSetDropRelatedNodesToStorage = (
  values: Array<any>,
  NextFormattedDataNodes: Array<any>
) => {
  if (values.length === 0) {
    localStorage.setItem(
      "dropRelatedNodes",
      JSON.stringify(
        NextFormattedDataNodes.slice(1, NextFormattedDataNodes.length)
      )
    );
  } else {
    localStorage.setItem("dropRelatedNodes", JSON.stringify(values));
  }
};

// Handles actions for the dive behavior
const activateDiveBehavior = (
  state: GraphState,
  objectRes: any,
  selectedParentNode: any
) => {
  let NextFormattedData = formatRelatedObjectsAndAddToCurrentGraph(
    objectRes?.[0]
  );

  state.previousGraph = formatRelatedObjectsAndAddToCurrentGraph(
    objectRes?.[0]
  );

  // get the current storeGraph and add the next formatted payload
  const accumulatedGraph = {
    nodes: uniqBy(
      [
        ...state.storeGraph?.nodes,
        ...updateCurrentNodeWithDepth(
          [...NextFormattedData?.nodes],
          selectedParentNode.diveDepth
        ),
      ],
      "id"
    ),
    edges: uniqBy(
      [...state.storeGraph?.edges, ...NextFormattedData?.edges],
      "id"
    ),
  };

  /** My Implementation starts here  */
  let previousCanvasGraph = current(state.storeGraph?.nodes);

  let resultValues: Array<any> = removeDuplicatesFromNextRelatedObject(
    previousCanvasGraph,
    NextFormattedData?.nodes.slice(1, NextFormattedData?.nodes.length)
  );
  /* The next line of code sets the available nodes to be dropped to local storage.  
     This is used to remove the related Objects from the canvas 
     when the user deselects the checkbox.
  */
  checkLengthAndSetDropRelatedNodesToStorage(
    resultValues,
    NextFormattedData?.nodes
  );

  state.storeGraph = accumulatedGraph;
  state.isFilterable = true;
  state.relationships = accumulatedGraph?.nodes.slice(1);
  state.relatedObjects = accumulatedGraph;
};
// Handles actions for the non  dive behavior
const activateNonDiveBehavior = (
  state: GraphState,
  objectRes: any,
  selectedParentNode: any
) => {
  let formattedData = formatRelatedObjectsAndAddToCurrentGraph(objectRes?.[0]);
  if (formattedData.nodes.length > 10) {
    state.isFilterable = true;
    state.isOpenNotification = true;
  } else {
    state.isFilterable = false;
    state.isOpenNotification = false;
  }
  if (formattedData && formattedData?.nodes?.length > 0) {
    state.relationships = formattedData?.nodes.slice(1);
  }
  state.storeGraph = formattedData;
  state.previousGraph = formatDataObject(objectRes?.[0]);
  state.relatedObjects = formattedData;
  localStorage.setItem("selectedNode", JSON.stringify(formattedData?.nodes[0]));
  state.parentNodeId = objectRes?.[0].ExternalID;
  state.isShowAllCheckboxRelations = true;
  state.parentNode = objectRes?.[0].ObjectType;
  state.nodesGroupKeys = formatGroups(formattedData.nodes);
};

const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setStoreGraph: (state, { payload }: PayloadAction<any>) => {
      state.storeGraph = payload;
    },
    setTransferTableData: (state, { payload }: PayloadAction<any>) => {
      state.transferTableData = payload;
    },
    setGraphCrumbs: (state, action) => {
      state.graphCrumbs = action.payload;
    },
    setIsExpandedBreadcrumb: (state, action) => {
      state.isExpandedBreadcrumb = action.payload;
    },
    setIsShowButton: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowButton = payload;
    },
    setIsOpenRightSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenRightSidebar = payload;
    },
    setIsOpenNavdebar: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenNavbar = payload;
    },
    setIsOpenCanvas: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenCanvas = payload;
    },
    setIsOpenNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenNotification = payload;
    },
    setNotification: (state, { payload }: PayloadAction<string>) => {
      state.notification = payload;
    },
    setIsFilterable: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterable = payload;
    },
    setIsSelectedEdge: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectedEdge = payload;
    },
    setSelectedEdgeId: (state, { payload }: PayloadAction<string>) => {
      state.selectedEdgeId = payload;
    },
    setSelectedEdgeRelations: (state, { payload }: PayloadAction<any>) => {
      state.selectedEdgeRelations = payload;
    },
    setPreviousGraph: (state, { payload }: PayloadAction<any>) => {
      state.previousGraph = payload;
    },
    setRelatedObjects: (state, { payload }: PayloadAction<any>) => {
      state.relatedObjects = payload;
    },
    setRollUps: (state, { payload }: PayloadAction<any>) => {
      state.rollUps = payload;
    },
    setSelectedNode: (state, { payload }: PayloadAction<any>) => {
      state.selectedNode = payload;
    },
    setTargetNode: (state, { payload }: PayloadAction<any>) => {
      state.targetNode = payload;
    },
    setSelectedObjectType: (state, { payload }: PayloadAction<string>) => {
      state.selectedObjectType = payload;
    },
    setParentNodeId: (state, { payload }: PayloadAction<any>) => {
      state.parentNodeId = payload;
    },
    setParentNode: (state, { payload }: PayloadAction<any>) => {
      state.parentNode = payload;
    },
    setDisableCheckboxes: (state, { payload }: PayloadAction<boolean>) => {
      state.disableCheckboxes = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setRelationships: (state, { payload }: PayloadAction<any>) => {
      state.relationships = payload;
    },
    setAllRelatedObjects: (state, { payload }: PayloadAction<any>) => {
      state.relatedObjects = payload;
    },
    setIsShowAllCheckboxRelations: (state, { payload }: PayloadAction<any>) => {
      state.isShowAllCheckboxRelations = payload;
    },
    setSelectedRelatedMapsInState: (state, { payload }: PayloadAction<any>) => {
      state.selectedRelatedMapsInState = payload;
    },
    resetStore: (state) => {
      state.parentNodeId = "";
      state.isParentNode = false;
      state.loading = false;
      state.error = null;
      state.isShowButton = false;
      state.isSelectedEdge = false;
      state.isFilterable = false;
      state.isOpenRightSidebar = false;
      state.isOpenNavbar = true;
      state.isOpenNotification = false;
      state.isOpenCanvas = false;
      state.selectedEdgeId = "";
      state.graphCrumbs = [];
    },

    setIsShowRelationships: (state, { payload }: PayloadAction<any>) => {
      state.isShowRelationships = payload;
    },

    setLegendFlag: (state, { payload }: PayloadAction<any>) => {
      state.legendFlag = payload;
    },
    // this is set to true if there is no tracking data
    setIsTrackingError: (state, { payload }: PayloadAction<any>) => {
      state.isTrackingError = payload;
    },
  },
  //  FOR ASYNC STATE MUTATION
  extraReducers: (builder) => {
    // fetchInitialGraph  is an initial render action
    builder.addCase(fetchInitialGraph.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchInitialGraph.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;

      if (payload && Object.keys(payload).length > 0) {
        const { rollUpData, objectRes } = payload;
        const initialData = objectRes[0];

        // Sets the diveDepthCount to 0
        state.diveDepthCount = 0;
        initialData.diveDepth = state.diveDepthCount;
        // Formats the data object
        const formattedData = formatDataObject(initialData);
        state.storeGraph = formatDataObject(initialData);
        state.previousGraph = formattedData;
        localStorage.setItem(
          "selectedNode",
          JSON.stringify(formattedData?.nodes[0])
        );
        state.relatedObjects = formatRelatedObjectsAndAddToCurrentGraph(
          objectRes?.[0]
        );
        state.selectedObjectType = initialData?.ObjectType;
        state.parentNodeId = initialData?.ExternalID;
        state.parentNode = initialData?.ObjectType;
        state.nodesGroupKeys = formatGroups(formattedData.nodes);
        if (rollUpData.length > 0) {
          state.rollUps = rollUpData[0]?.RollUps ? rollUpData[0]?.RollUps : {};
        }
        const crumbs = {
          id: formattedData.nodes[0].object_externalID,
          identifier: formattedData.nodes[0].uniqByExternalIDAndType,
          ObjectType: formattedData.nodes[0].group,
          ObjectName: formattedData.nodes[0].Name,
        };
        state.graphCrumbs = Array.from(state.graphCrumbs).concat(crumbs);
      }
    });
    builder.addCase(fetchInitialGraph.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // fetchSelectedRelationsOfGraph is a button click action
    builder.addCase(fetchSelectedRelationsOfGraph.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchSelectedRelationsOfGraph.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        if (payload === "undefined") return;
        if (Object.keys(payload).length > 0) {
          const { rollUpData, objectRes, behavior } = payload;
          state.disableCheckboxes = false;
          // setting rollups
          if (rollUpData.length > 0) {
            state.rollUps = rollUpData[0]?.RollUps
              ? rollUpData[0]?.RollUps
              : {};
          }

          // Gets all avalable related objects attaching adding an objectName property to it.
          state.allRelatedObjects = getAllRelatedObjects(
            objectRes?.[0].RelatedObjects
          );

          // Get the parent node tp retrieve it's dive depth
          const selectedParentNode =
            JSON.parse(localStorage.getItem("selectedNode") as any) || null;

          if (behavior === "dive" && selectedParentNode) {
            // Handles the dive behavior
            activateDiveBehavior(state, objectRes, selectedParentNode);
          } else {
            activateNonDiveBehavior(state, objectRes, selectedParentNode);
          }
          localStorage.setItem("graphType", "single");
        } else {
          state.error = "Not data was returned for this object!";
        }
      }
    );
    builder.addCase(
      fetchSelectedRelationsOfGraph.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // fetch object and Relations filter data Of Graph is a button click action
    builder.addCase(fetchObjectAndRelationsFilters.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchObjectAndRelationsFilters.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        if (payload === "undefined") return;
        if (payload && Object.keys(payload).length > 0) {
          state.isOpenNotification = false;
          state.disableCheckboxes = false;
          const formattedData = formatRelatedObjectsAndAddToCurrentGraph(
            payload?.[0]
          );

          // show notification message
          if (formattedData.nodes.length > 10) {
            state.isFilterable = true;
            state.isOpenNotification = true;
          } else {
            state.isFilterable = false;
            state.isOpenNotification = false;
          }

          if (formattedData.nodes.length > 1) {
            state.storeGraph = formattedData;
            state.previousGraph = formatDataObject(payload?.[0]);
            state.relatedObjects = formattedData;
            localStorage.setItem(
              "selectedNode",
              JSON.stringify(formattedData?.nodes[0])
            );
            state.parentNodeId = payload?.[0].ExternalID;
            state.parentNode = payload?.[0].ObjectType;
          } else {
            state.isOpenNotification = true;
            state.notification = "No available filters for this object!";
          }
        } else {
          state.error = "No data was returned for this object!";
        }
      }
    );
    builder.addCase(
      fetchObjectAndRelationsFilters.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // fetch Tracks for forward and backward, is a button click action
    builder.addCase(fetchObjectTracks.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchObjectTracks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      const objectRes = payload?.objectRes || payload?.resdataFB;
      const behavior = payload?.behavior;

      if (payload === "undefined" || payload.length === 0) return;
      // Get the parent node tp retrieve it's dive depth
      const selectedParentNode =
        JSON.parse(localStorage.getItem("selectedNode") as any) || null;
      // currentCanvasGraph?.nodes?.length > 0

      if (behavior === "dive" && selectedParentNode) {
        let relatedObjects = [];
        for (let object in objectRes?.[0].RelatedObjects) {
          // let allRelatedObjects =
          let value = objectRes?.[0].RelatedObjects[object];
          value.objectName = object;
          relatedObjects.push(value);
        }
        state.allRelatedObjects = relatedObjects;

        let NextFormattedData = formatRelatedObjectsAndAddToCurrentGraph(
          objectRes?.[0]
        );
        state.previousGraph = formatRelatedObjectsAndAddToCurrentGraph(
          objectRes?.[0]
        );
        // get the current storeGraph and add the next formatted payload
        const accumulatedGraph = {
          nodes: uniqBy(
            [
              ...state.storeGraph?.nodes,
              ...updateCurrentNodeWithDepth(
                [...NextFormattedData?.nodes],
                selectedParentNode.diveDepth
              ),
            ],
            "id"
          ),
          edges: uniqBy(
            [...state.storeGraph?.edges, ...NextFormattedData?.edges],
            "id"
          ),
        };

        /** My Implementation starts here  */
        let previousCanvasGraph = current(state.storeGraph?.nodes);

        let resultValues: Array<any> = removeDuplicatesFromNextRelatedObject(
          previousCanvasGraph,
          NextFormattedData?.nodes.slice(1, NextFormattedData?.nodes.length)
        );

        checkLengthAndSetDropRelatedNodesToStorage(
          resultValues,
          NextFormattedData?.nodes
        );

        /** My Implementation Ends here  */

        state.storeGraph = accumulatedGraph;
        state.isFilterable = true;
        state.relationships = accumulatedGraph?.nodes.slice(1);
        state.relatedObjects = accumulatedGraph;
      } else {
        // Sets the diveDepthCount to 0
        state.diveDepthCount = 0;
        const resdataFB: any = formatPathMaps(payload?.resdataFB?.[0]);

        // Assign initial depth to the nodes.
        const nodesWithInitialDept = setInitialDeptToNodes(
          resdataFB.nodes,
          state
        );

        const trackingResdataFB = {
          nodes: uniqBy([...nodesWithInitialDept], "id"),
          edges: uniqBy([...resdataFB?.edges], "id"),
        };

        state.storeGraph = trackingResdataFB!;
        localStorage.setItem(
          "selectedNode",
          JSON.stringify(trackingResdataFB?.nodes[0])
        );
      }

      localStorage.setItem("graphType", "completeTracking");
    });
    builder.addCase(fetchObjectTracks.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // fetch Tracks for forward or backward, is a button click action
    builder.addCase(fetchEitherForwardOrBackwardTracks.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchEitherForwardOrBackwardTracks.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        const objectRes = payload?.objectRes || payload;
        const behavior = payload?.behavior;
        if (objectRes === "undefined" || objectRes?.length === 0) return;

        // Get the parent node tp retrieve it's dive depth
        const selectedParentNode =
          JSON.parse(localStorage.getItem("selectedNode") as any) || null;

        if (behavior === "dive" && selectedParentNode) {
          let relatedObjects = [];
          for (let object in objectRes?.[0].RelatedObjects) {
            // let allRelatedObjects =
            let value = objectRes?.[0].RelatedObjects[object];
            value.objectName = object;
            relatedObjects.push(value);
          }
          state.allRelatedObjects = relatedObjects;

          let NextFormattedData = formatRelatedObjectsAndAddToCurrentGraph(
            objectRes?.[0]
          );
          state.previousGraph = formatRelatedObjectsAndAddToCurrentGraph(
            objectRes?.[0]
          );

          // get the current storeGraph and add the next formatted payload
          const accumulatedGraph = {
            nodes: uniqBy(
              [
                ...state.storeGraph?.nodes,
                ...updateCurrentNodeWithDepth(
                  [...NextFormattedData?.nodes],
                  selectedParentNode.diveDepth
                ),
              ],
              "id"
            ),
            edges: uniqBy(
              [...state.storeGraph?.edges, ...NextFormattedData?.edges],
              "id"
            ),
          };

          /** My Implementation starts here  */
          let previousCanvasGraph = current(state.storeGraph?.nodes);

          let resultValues: Array<any> = removeDuplicatesFromNextRelatedObject(
            previousCanvasGraph,
            NextFormattedData?.nodes.slice(1, NextFormattedData?.nodes.length)
          );

          checkLengthAndSetDropRelatedNodesToStorage(
            resultValues,
            NextFormattedData?.nodes
          );

          /** My Implementation Ends here  */

          state.storeGraph = accumulatedGraph;
          state.isFilterable = true;
          state.relationships = accumulatedGraph?.nodes.slice(1);
          state.relatedObjects = accumulatedGraph;
        } else {
          // Sets the diveDepthCount to 0
          state.diveDepthCount = 0;
          const resdataFB: any = formatPathMaps(objectRes[0]);

          let tracking: any = formatPathMaps(objectRes[0]);
          // Returns nodes with diveDepth feature attached to it.
          tracking.nodes = setInitialDeptToNodes(resdataFB.nodes, state);
          state.storeGraph = tracking!;
          localStorage.setItem(
            "selectedNode",
            JSON.stringify(tracking?.nodes[0])
          );
        }

        localStorage.setItem("graphType", "forwardAndBackwardTracking");
      }
    );
    builder.addCase(
      fetchEitherForwardOrBackwardTracks.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // fetchGraphCrumbData is a button click action for crumb
    builder.addCase(fetchGraphCrumbData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchGraphCrumbData.fulfilled,
      (state, { payload: { crumbObject, ExternalID } }) => {
        state.loading = false;
        state.error = null;
        state.disableCheckboxes = false;
        const { rollUpData, objectRes } = crumbObject;
        if (rollUpData.length > 0) {
          state.rollUps = rollUpData[0]?.RollUps ? rollUpData[0]?.RollUps : {};
        }
        const initialData = finder(objectRes, "ExternalID", ExternalID);
        const formattedData =
          formatRelatedObjectsAndAddToCurrentGraph(initialData);
        state.isShowAllCheckboxRelations = true;
        if (formattedData.nodes.length > 10) {
          state.isFilterable = true;
          state.isOpenNotification = true;
        } else {
          state.isFilterable = false;
          state.isOpenNotification = false;
        }
        if (formattedData && formattedData?.nodes?.length > 0) {
          state.relationships = formattedData?.nodes.slice(1);
        }
        state.storeGraph = formattedData;
        localStorage.setItem(
          "selectedNode",
          JSON.stringify(formattedData?.nodes[0])
        );
        state.nodesGroupKeys = formatGroups(formattedData.nodes);
        state.previousGraph = formatDataObject(initialData);
        state.relatedObjects = formattedData;
        state.parentNodeId = initialData.ExternalID;
        state.parentNode = initialData.ObjectType;
      }
    );
    builder.addCase(fetchGraphCrumbData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  setStoreGraph,
  setPreviousGraph,
  setRelatedObjects,
  setSelectedNode,
  setTargetNode,
  setSelectedObjectType,
  setParentNodeId,
  setLoading,
  setError,
  setDisableCheckboxes,
  setIsShowButton,
  setIsSelectedEdge,
  setIsOpenRightSidebar,
  setSelectedEdgeId,
  setSelectedEdgeRelations,
  setGraphCrumbs,
  setIsExpandedBreadcrumb,
  resetStore,
  setIsOpenNotification,
  setNotification,
  setTransferTableData,
  setIsOpenNavdebar,
  setIsOpenCanvas,
  setIsFilterable,
  setRelationships,
  setSelectedRelatedMapsInState,
  setIsShowRelationships,
  setLegendFlag,
  setRollUps,
  setIsTrackingError,
  setIsShowAllCheckboxRelations,
  setAllRelatedObjects,
} = graphSlice.actions;

export default graphSlice.reducer;
