import { Skeleton } from '@mui/material';
import React from 'react'

const Loader = ({show}:{show: boolean}) => {
  
    return (<>
          {show && (
            <div className="inline space-x-2">
              <Skeleton
                className="!inline-block"
                variant="circular"
                width={8}
                height={8}
              />
              <Skeleton
                className="!inline-block"
                variant="circular"
                width={8}
                height={8}
              />
              <Skeleton
                className="!inline-block"
                variant="circular"
                width={8}
                height={8}
              />
            </div>
          )}
        </>
      );
  
}

export default Loader