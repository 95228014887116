import React, { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  fetchFilteredUsers,
  fetchGetAllUsers,
} from "../../../../redux/users/actions";
import { setPage } from "../../../../redux/users/usersSlice";
import { debounce } from "lodash";

const FilterUsers: FC = () => {
  const dispatch = useAppDispatch();

  // STORE STATE
  const { page } = useAppSelector((state: any) => state.users);

  const [searchTerm, setSearchTerm] = useState("");
  // update searchterm on input change
  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  // persist debounce value between renders
  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    if (searchTerm !== "") {
      dispatch(fetchFilteredUsers(searchTerm));
      dispatch(setPage(1));
    } else {
      dispatch(fetchGetAllUsers(page));
    }

    // clean up any side effects from debounce when our component gets unmounted
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults, dispatch, page, searchTerm]);

  return (
    <div>
      <form id="searchUers">
        <div className="w-full flex flex-col space-y-2 justify-center item-middle my-5">
          <input
            className="w-full text-[14px] text-primary-textNormal placeholder-primary-textMuted  h-[44px] rounded-[8px] border border-accent-grayMuted300 focus:!outline-none"
            type="text"
            placeholder="Enter Search Term"
            onChange={debouncedResults}
            minLength={3}
          />
        </div>
      </form>
    </div>
  );
};

export default FilterUsers;
