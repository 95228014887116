import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  formRelationshipForRequest,
  getObjectType,
} from "../../components/generics/utilities/utils";
import Api from "../../services/api";
export interface ObjectDetailsState {
  objectInfo: any;
  loading: boolean;
  relatedObjectsLoading: boolean;
  error: string;
  isRollupfetching: boolean;
  rollupValues: any;
  nextRelatedObjects: any;
  nextRelatedObjectsTotal: number;
  nextRelatedObjectsError: string;
  relatedObjName: string;
  paginationDetailsPageNumber: boolean;
}

const initialState: ObjectDetailsState = {
  objectInfo: [],
  loading: false,
  relatedObjectsLoading: false,
  error: "",
  isRollupfetching: false,
  rollupValues: [],
  nextRelatedObjects: {},
  nextRelatedObjectsTotal: 0,
  nextRelatedObjectsError: "",
  relatedObjName: "",
  paginationDetailsPageNumber: false,
};

// AppThunk sets the type definitions for the dispatch method
export const fetchObject = createAsyncThunk(
  "fetchObject",
  async (payload: any, { rejectWithValue }) => {
    let requestParams: any;
    // Get objects
    let objects = null;
    try {
      objects = await Api({
        url: "config",
        method: "GET",
      });
    } catch (error) {
      return rejectWithValue("Error Fetching next Object");
    }

    const objectInfo = getObjectType(objects.data, payload?.objectType);

    const relationships = formRelationshipForRequest(objects.data, objectInfo);
    const limit = 10;

    const relationshipsWithLimit = relationships?.map((obj: any) => {
      return {
        ...obj,
        Limit: limit,
      };
    });

    requestParams = JSON.stringify(relationshipsWithLimit);

    try {
      let timer: any = null;
      // Used in ensuring the right request is processed when the request is successful
      timer = Date.now();
      const triggerTime = timer;
      const response = await Api({
        url: "object",
        method: "GET",
        params: {
          ObjectType: payload?.objectType,
          Limit: 10,
          ObjectKeyValueMap: {
            ExternalID: payload.externalId,
          },
          RelatedMaps: requestParams,
        },
      });

      if (timer === triggerTime) {
        return response.data.Objects;
      }
    } catch (error) {
      return rejectWithValue("Error Fetching next Object");
    }
  }
);

// fetch the next related objects - pagination
export const fetchRelatedObjects = createAsyncThunk(
  "fetchRelatedObjects",
  async (
    { objectType, externalId, relationshipName, skip, onlyActive }: any,
    { rejectWithValue }
  ) => {
    let requestParams: any;

    // Gets the order format configuration from the local storage to be used in the subsequent requests.
    const orderType = JSON.parse(
      localStorage.getItem("filterDetailsInfo") as string
    );

    const relatedMaps: any = [
      {
        RelatedObjectName: relationshipName,
        Limit: 10,
        Skip: skip,
        OrderBy: orderType?.orderBy || "",
        OrderDirection: orderType?.order || "",
        OnlyActive: onlyActive,
      },
    ];

    requestParams = JSON.stringify(relatedMaps);
    let timer: any = null;
    timer = Date.now();
    const triggerTime = timer;

    return Api({
      url: "object",
      method: "GET",
      params: {
        ObjectType: objectType,
        Limit: 1,
        ObjectKeyValueMap: {
          ExternalID: externalId,
        },
        RelatedMaps: requestParams,
      },
    })
      .then((response) => {
        if (timer === triggerTime) {
          // checks if the response contains a related object else  rejectsWithValue
          if (response.data.Objects?.length === 0)
            return rejectWithValue({
              errorMessage: "Sorry, no data is available for this page",
              relatedObjectName: relationshipName,
            });

          // Returns the elements when the response has a related object
          return {
            nextData:
              response.data.Objects[0]?.RelatedObjects[relationshipName],
            relatedObjName: relationshipName,
          };
        }

        return null;
      })
      .catch((error) => {
        console.log(error);
        return rejectWithValue({
          errorMessage: "Sorry, something went wrong",
          relatedObjectName: relationshipName,
        });
      });
  }
);

// Sorts the related objects
export const sortRelatedObjects = createAsyncThunk(
  "sortRelatedObjects",
  async ({
    objectType,
    externalId,
    sortRelationshipName,
    onlyActive,
    limit,
  }: any) => {
    let requestParams: any;

    // Gets the order configuration from the local storage
    const orderType = JSON.parse(
      localStorage.getItem("filterDetailsInfo") as string
    );

    // Get objects
    const relatedMaps = [
      {
        RelatedObjectName: sortRelationshipName,
        Limit: 10,
        OrderBy: orderType?.orderBy || "",
        OrderDirection: orderType?.order || "",
        OnlyActive: onlyActive,
      },
    ];

    requestParams = JSON.stringify(relatedMaps);
    return Api({
      url: "object",
      method: "GET",
      params: {
        ObjectType: objectType,
        Limit: 10,
        ObjectKeyValueMap: {
          ExternalID: externalId,
        },
        RelatedMaps: requestParams,
      },
    })
      .then((response) => {
        return {
          nextData:
            response.data.Objects[0]?.RelatedObjects[sortRelationshipName],
          relatedObjName: sortRelationshipName,
        };
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

// RollupValues Thunk
export const fetchRollupValues = createAsyncThunk(
  "fetchRollupValues",
  async (payload: any) => {
    const { objectType, externalId } = payload;

    const params = {
      ObjectType: objectType,
      ObjectKeyValueMap: {
        ExternalID: externalId,
      },
      ReturnRollUps: true,
      Limit: 1,
    };

    try {
      return await Api({
        url: "object",
        method: "GET",
        params: params,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

const objectDetailsSlice = createSlice({
  name: "specificObject",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    //  Resets details page
    resetDetailsPageNumber: (state, { payload }: PayloadAction<boolean>) => {
      state.paginationDetailsPageNumber = payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchObject.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.objectInfo = action.payload;
        state.nextRelatedObjects = {};
        state.nextRelatedObjectsError = "";
      })
      .addCase(fetchObject.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.objectInfo = [];
        state.rollupValues = [];
      })
      .addCase(fetchObject.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
        state.objectInfo = [];
      })
      .addCase(fetchRelatedObjects.fulfilled, (state, action) => {
        state.relatedObjectsLoading = false;
        const { nextData, relatedObjName }: any = action?.payload;
        state.nextRelatedObjects = nextData;
        state.nextRelatedObjectsError = "";
        state.relatedObjName = relatedObjName;
        state.error = "";
      })

      // errorMessage: "Sorry, something went wrong",
      // relatedObjectName: relationshipName,
      .addCase(fetchRelatedObjects.pending, (state) => {
        state.nextRelatedObjects = {};
        state.relatedObjectsLoading = true;
        state.error = "";
        state.nextRelatedObjectsError = "";
    
      })
      .addCase(fetchRelatedObjects.rejected, (state, { payload }) => {
        const {
          errorMessage,
          relatedObjectName,
        }: { errorMessage: string; relatedObjectName: string } = payload as {
          errorMessage: string;
          relatedObjectName: string;
        };
        state.nextRelatedObjects = {};
        state.nextRelatedObjectsError = errorMessage;
        state.relatedObjectsLoading = false;
        state.relatedObjName = relatedObjectName;
      })
      .addCase(sortRelatedObjects.fulfilled, (state, action) => {
        const { nextData, relatedObjName }: any = action.payload;
        state.nextRelatedObjects = nextData;
        state.relatedObjName = relatedObjName;
        state.relatedObjectsLoading = false;
      })
      .addCase(sortRelatedObjects.pending, (state) => {
        state.nextRelatedObjects = {};
        state.relatedObjName = "";
        state.relatedObjectsLoading = true;
      })
      .addCase(sortRelatedObjects.rejected, (state) => {
        state.nextRelatedObjects = {};
        state.relatedObjName = "";
        state.relatedObjectsLoading = false;
      })
      .addCase(fetchRollupValues.fulfilled, (state, action) => {
        state.isRollupfetching = false;
        state.rollupValues = action.payload?.Objects;
      })
      .addCase(fetchRollupValues.pending, (state) => {
        state.isRollupfetching = true;
        state.rollupValues = [];
      })
      .addCase(fetchRollupValues.rejected, (state) => {
        state.isRollupfetching = false;
        state.rollupValues = [];
      });
  },
});

export const { setErrors, resetDetailsPageNumber } = objectDetailsSlice.actions;

export default objectDetailsSlice.reducer;

export const objectDetailsSelector = (state: {
  objectInfo: ObjectDetailsState;
}) => state.objectInfo;
