import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import FormHeaderComponent from "../components/generics/FormHeaderComponent";
import { validateEmail } from "../services/validation";

const ForgotPassword = () => {
  const [loginError, setLoginError] = useState(false);
  const email = useRef<HTMLInputElement>(null);

  const validateForm = () => {
    if (email.current !== null) {
      if (email.current.value === "") {
        setLoginError(true);
        return false;
      }

      const result = validateEmail(email.current.value);
      if (!result) {
        setLoginError(true);

        return false;
      }

      return true;
    }
  };
  const submitForm = (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form cannot be submitted");
    }
  };

  return (
    <div className="login w-full h-[100vh] bg-[#fff] flex items-center justify-center">
      <div className="login-container w-[85%] h-[550px] md:w-[60%] md:h-[700px] lg:w-[30%] lg:h-[550px] ">
        <FormHeaderComponent
          title={"Forgot password?"}
          details={"No worries, we'll send you reset instructions."}
        />
        {loginError && (
          <div className="error-container border-[1px] border-[#FDA29B] flex p-[16px] mt-[32px] h-[52px] rounded-[8px] bg-[#FFFBFA] ">
            <div>
              <img src="/assets/error_icon.svg" alt="" />
            </div>
            <p className="ml-[13px] text-[14px] text-[#B42318]">
              Please enter your email in a valid format.
            </p>
          </div>
        )}
        <form
          onSubmit={(e) => submitForm(e)}
          className="login-form mt-[32px] text-[#667085]"
        >
          <div className="email-container mt-[20px]">
            <label className="block text-[#344054] text-[14px] font-normal mb-[4px]">
              Email
            </label>
            <div
              className={`email-container flex items-center border-[1px] ${
                loginError ? "border-[#FDA29B] " : "border-[#D0D5DD] "
              } shadow-sm rounded-[8px] `}
              onClick={() => setLoginError(false)}
            >
              <input
                className={`email-inputField  text-[16px] w-full ml-[0.2px] h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none`}
                placeholder="Enter your email"
                type="text"
                minLength={8}
                ref={email}
              />
              {loginError && (
                <img
                  className="p-4 cursor-pointer"
                  src="/assets/error_icon.svg"
                  alt="Password Icon"
                />
              )}
            </div>
          </div>

          <div className="w-full text-[16px] mt-[24px]">
            <button className=" bg-[#3A6A78] w-[100%] h-[44px] rounded-[8px] text-[#fff]">
              Reset password
            </button>
          </div>
          <div className="register-now text-[14px] mt-[32px] flex items-center justify-center">
            <Link to="/login">
              <label className="cursor-pointer text-[#667085] text-[normal] flex items-center ">
                <img src="/assets/left_icon.svg" alt="" />

                <span className="text-[#667085] ml-[12px]">
                  {" "}
                  Back to log in
                </span>
              </label>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
