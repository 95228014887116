import React from "react"
import useLocalStorage from "../../../customHooks/useLocalStorage"
import { NodeDetails } from "../graphDetails/nodeDetails/NodeDetails"

const MemoiseNodeDetails = () => {
  //get the selectedNode from the local storage
  const { storedValue: selectedNode } = useLocalStorage("selectedNode")

  return (
    <div className="pl-4">
      <h5 className="text-[12px] font-bold pb-2">Node Details</h5>
      <NodeDetails selectedNode={selectedNode} heightDeterminer="treeView" />
    </div>
  )
}

export default MemoiseNodeDetails
