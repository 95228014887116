import Select, { components, StylesConfig } from "react-select";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  activeObjectSelector,
  setSelectedTableRelationship,
} from "../../../redux/activeObject/activeObject";
import { OptionType } from "../../../types/products";

// Used in the styling of the dropdown options
const dropDownValuesStyle = ({
  isDisabled,
  isFocused,
  isSelected,
}: {
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
}) => {
  if (isDisabled) return undefined;
  if (isSelected) return "#3A6A78";
  if (isFocused) return "#DEEBFF";

  return undefined;
};
const customStyles: StylesConfig = {
  multiValue: (base: any, state: any) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base: any, state: any) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base: any, state: any) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },

  control: (val) => {
    val.cursor = "pointer";
    return {
      ...val,
    };
  },
  singleValue: (singleValueStyles) => {
    singleValueStyles.cursor = "pointer";
    return {
      ...singleValueStyles,
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    styles.cursor = "pointer";
    return {
      ...styles,
      backgroundColor: dropDownValuesStyle({
        isDisabled,
        isFocused,
        isSelected,
      }),
    };
  },
  menuList: (menuListStyles) => {
    return {
      ...menuListStyles,
      cursor: "pointer",
    };
  },
};

const CustomOption = (props: any) => {
  return (
    <div ref={props.innerRef}>
      <components.Option {...props}>
        <div className="optionValue flex items-center justify-between !cursor-pointer">
          <label className="cursor-pointer">
            {props.label?.length > 20
              ? props.label.slice(0, 28) + "..."
              : props.label}
          </label>
          <input
            className="mr-4 px-2 rounded-sm cursor-pointer outline-none border-[#b6b0b0]"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
        </div>
      </components.Option>
    </div>
  );
};

const ValueContainer = (props: any) => {
  let values = props.getValue();
  let valueLabel = "";

  if (values.length > 0) {
    let selectedValue = props?.selectProps.getOptionLabel(values[0]);
    valueLabel +=
      selectedValue.length > 14
        ? selectedValue.slice(0, 14) + "..."
        : selectedValue;
  }
  if (values.length > 1) valueLabel += ` +(${values.length - 1} more)`;

  // Keep standard placeholder and input from react-select
  let childToRender = React.Children.toArray(props.children).filter(
    (child: any) => {
      return (
        ["Input", "DummyInput", "Placeholder"].indexOf(child?.type?.name) >= 0
      );
    }
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue ? valueLabel : "Please select a value"}
      {childToRender}
    </components.ValueContainer>
  );
};

type Props = {
  options: Array<string>;
  setValuesToParent: (values: any, isDefault?: boolean) => void;
  defaultParameter?: Array<string>;
  multiple?: boolean;
  placeholder?: string;
  defaultValue?: OptionType;
};

const ReactSelectField: React.FC<Props> = ({
  options,
  setValuesToParent,
  multiple,
  placeholder = "Select Multiple Relationship",
  defaultValue,
}) => {
  const [valuesList, setValuesList] = useState<Array<OptionType>>([]);
  const { activeObjectType } = useAppSelector(activeObjectSelector);
  const [defaultSelectedValueList, setDefaultValueList] = useState([
    defaultValue,
  ]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (multiple) {
      // Resets the Component
      setValuesList([]);
      dispatch(setSelectedTableRelationship([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObjectType]);

  const setSelectedData = (item: any, isDefault?: boolean) => {
    setValuesToParent(
      item.map((v: OptionType) => v.value),
      isDefault
    );
    setValuesList(item);
  };

  const setValue = () => {
    if (
      defaultSelectedValueList?.length > 0 &&
      typeof defaultSelectedValueList[0] !== "undefined" &&
      valuesList?.length === 0
    ) {
      setSelectedData(defaultSelectedValueList, true);
      return defaultValue;
    }

    if (valuesList.length === 0) return null;
    if (valuesList.length > 1) return valuesList;
  };

  return (
    <div className="">
      {" "}
      <Select
        styles={customStyles}
        className="react-multi-select !cursor-pointer"
        classNamePrefix="select"
        name="color"
        value={setValue()}
        defaultValue={defaultSelectedValueList ?? null}
        options={options}
        isMulti
        components={{
          ValueContainer,
          Option: CustomOption,
        }}
        hideSelectedOptions={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          border: "solid 1px #3A6A78",
          colors: {
            ...theme.colors,
            primary: "#3A6A78",
          },
        })}
        onChange={(values: any) => {
          // Sets the default value list to empty when the default value is unselected.
          if (values.length === 0) {
            setDefaultValueList(values);
          }
          setSelectedData(values, false);
        }}
        placeholder={placeholder}
        isClearable={true}
      />
    </div>
  );
};

export default ReactSelectField;
