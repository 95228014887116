import Api from "../../../../../services/api";
import { getStringifiedPathMapBackwards } from "./backwardTrackingCalls";
import { getStringifiedPathMapForwards } from "./forwardTrackingCalls";
import { errorHandler, objectsUrl } from "./objectsApiCalls";

// fetch objects base on configs
export const getObjectTracks = async (
  objectType: string,
  ExternalID: string,
  buttonType: string
) => {

  //let pathMaps = getStringifiedOverviewTrackingPathMap(buttonType);
  const pathMapsBackwards = getStringifiedPathMapBackwards(buttonType)
  const pathMapsForwards = getStringifiedPathMapForwards(buttonType)

  const merge = require('deepmerge')

  try {
    let time = performance.now();
    // requestParams for forward and Backward tracking
    const requestParamsFBBackwards = {
      ObjectType: objectType,
      ObjectKeyValueMap: {
        ExternalID: [ExternalID],
      },
      PathMaps: pathMapsBackwards,
      "IgnoreDirection": true
    };

    const resdataFBBackwards = await Api({
      url: objectsUrl,
      method: "get",
      params: requestParamsFBBackwards,
    });

    const requestParamsFBForwards = {
      ObjectType: objectType,
      ObjectKeyValueMap: {
        ExternalID: [ExternalID],
      },
      PathMaps: pathMapsForwards,
      "IgnoreDirection": true,
      "ReverseDirection": true
    };

    const resdataFBForwards = await Api({
      url: objectsUrl,
      method: "get",
      params: requestParamsFBForwards,
    });
    const resdataFB = merge(resdataFBForwards.data.Objects[0],resdataFBBackwards.data.Objects[0])

    return {
      resdataFB: [resdataFB],
    };
  } catch (err: any) {
    console.log(err)
    return errorHandler(err);
  }
};
