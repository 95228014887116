import { useAppSelector } from "../../../redux/hooks"
import { ErrorPage } from "../error/ErrorPage"
import RightSidebar from "../rightSidebar/RightSidebar"
import TreeGraph from "./TreeGraph"
import MemoiseNodeDetails from "./MemoiseNodeDetails"

const TreeGraphWrapper = () => {
  // STORE STATE
  const { error } = useAppSelector(
    (state: any) => state?.graphReducer
  )


  // this component is passed here to reuse the RightSidebar component
  const nodeDetails = <MemoiseNodeDetails />
  
  return (
    <div className="p-10 ml-0 flex flex-col overflow-hidden">
      <div className="graph">
        {error !== null ? (
          <div className="w-full h-[80vh] flex justify-center items-center align-middle">
            <ErrorPage error={error} />
          </div>
        ) : (
          <div className="flex w-full">
            <TreeGraph />
            <RightSidebar
              childComponent={nodeDetails}
              pushDownValue="treeGraph"
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default TreeGraphWrapper
