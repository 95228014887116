import { useRef } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setTrackingPath } from "../../redux/tracking/trackingPathSlice";

const TrackingTableCheckBox = ({ path }: { path: any }) => {
  const checkBoxRef = useRef<any>();
  const dispatch = useAppDispatch();

  return (
    <input
      className="cursor-pointer"
      ref={checkBoxRef}
      type="checkbox"
      value={"Nest"}
      name="check"
      onChange={(e) => {
        let checkboxes = document.getElementsByName("check");
        checkboxes?.forEach((item: any) => {
          item.checked = false;
        });
        e.currentTarget.checked = true;
        dispatch(
          setTrackingPath({
            path,
            activeObjectType: path.RelatedObject.ObjectType,
          })
        );
      }}
    />
  );
};

export default TrackingTableCheckBox;
