import Loader from "../loader/Loader";
import { cloneDeep } from "lodash";
import { hierarchicalOptions, freestyleOptions } from "../config/options";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setIsOpenRightSidebar,
  setIsShowRelationships,
} from "../../../redux/graph/graphSlice";
import { useEffect, useState } from "react";
import CustomSwitch from "./graphMenu/CustomSwitch";
import GraphLegend from "./graphMenu/GraphLegend";
import { ErrorPage } from "../error/ErrorPage";
import Canvas from "./Canvas";
import WarningNotification from "../notifications/WarningNotification";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";

const GraphComponent = ({ error, events }) => {
  const dispatch = useAppDispatch();
  // STORE STATE
  const { loading, isOpenRightSidebar, isOpenNotification, notification } =
    useAppSelector((state) => state.graphReducer);
  const storeGraph = useAppSelector((state) =>
    cloneDeep(state.graphReducer.storeGraph)
  );
  // LOCAL STATE
  const [graphLayout, setGraphLayout] = useState(true);

  useEffect(() => {
    dispatch(setIsShowRelationships(false));
  }, [dispatch]);

  // get the screen height
  const { height } = useWindowDimensions()
  //options for switching layout view
  const options = graphLayout ? hierarchicalOptions : freestyleOptions;

  var edges = (storeGraph.edges || [])
  for (let i=0;i<edges.length;i++){
    var edge = edges[i]
    if ((edge["label"].match(new RegExp("_", "g")) || []).length === 2){
      var firstUnderscore = edge["label"].indexOf("_")
      var newLabel = edge["label"].substring(firstUnderscore + 1)
      var secondUnderscore = newLabel.indexOf("_")
      newLabel = newLabel.substring(0, secondUnderscore)
      newLabel = newLabel.replace(/([a-z])([A-Z])/g, '$1 $2');
      edge["label"] = newLabel
    }
  }
  const graphContent = (
    <div className={`${height > 850 ? "h-[90.9vh]" : "h-[88vh]"} bg-primary-bgColor`}><Canvas storeGraph={storeGraph} options={options} events={events} /></div>
  )

  const canvas = (
    <>
      {error ? (
        <div className="w-full h-[80vh] flex justify-center items-center align-middle">
          <ErrorPage error={error} />
        </div>
      ) : (
        graphContent
      )}
    </>
  );
  return (
    <div
      className={`${isOpenRightSidebar ? "w-[85%]" : "w-[100%]"
        } "graph h-[100vh] relative"`}
    >
      <div className="bg-primary-bgColor">
        {/* border top to the breadcrumb on the graph page */}
        <div className="mt-14 pl-4 border-t pt-1">
        </div>
        <div className="">
          <div
            className={`${isOpenRightSidebar ? "right-[30rem]" : "right-20"
              } flex flex-row absolute top-[14px] justify-end align-middle`}
          >
            <div
              className={`legend-container  flex justify-start items-center mr-10  w-[60px]`}
            >
              <GraphLegend isOpenRightSidebar={isOpenRightSidebar} />
            </div>
            <CustomSwitch
              value={graphLayout}
              handleChange={(e) => setGraphLayout(e.target.checked)}
            />
          </div>
          <div className="flex absolute top-[15px] right-0 justify-end">
            <img
              src="/assets/leftSidebar/rightMenu.svg"
              className={`border rounded-md rounded-r-none p-3 cursor-pointer mr-0`}
              onClick={() =>
                dispatch(setIsOpenRightSidebar(!isOpenRightSidebar))
              }
              alt=""
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="relative">
          {notification && isOpenNotification && (
            <div className="absolute top-[0%] left-[50%]"><WarningNotification message={notification} /></div>
          )}
          {canvas}
        </div>
      )}
    </div>
  );
};

export default GraphComponent;
