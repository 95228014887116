import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

const useBackClickListener = (callback: any) => {
  const navigator: any = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }: { location: any; action: any }) => {
      if (action === "POP") {
        callback({ location, action });
      }
    };
    return navigator.listen(listener);
  }, [callback, navigator]);
};

export default useBackClickListener;
