import { createAsyncThunk } from "@reduxjs/toolkit"
import { doDeleteUser, editUser, editUserRole, getAllRoles, inviteUser } from "../../components/generics/utils/api/users/rolesApiCalls"

// async actions: next request
export const fetchGetAllRoles: any = createAsyncThunk<any, any, any>(
  "graph/fetchGetAllRoles",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resData = await getAllRoles()
      if (resData === "Network Error") {
        return rejectWithValue(
          "Network Error: Please Check Internet Connection!"
        )
      }

      if (resData === "Request failed with status code 400") {
        return rejectWithValue("Request failed with status code 400!")
      }

      if (resData.length === 0) {
        return rejectWithValue("No Roles Available!")
      }
      return fulfillWithValue(resData)
    } catch (error) {
      return rejectWithValue("This is the error: " + error)
    }
  }
)


export const sendInvitation = createAsyncThunk(
  "user/sendInvitation",
  async (payload: {
    email: string;
    roleName: string;
    registrationUrl: string;
  }, { rejectWithValue, fulfillWithValue }) => {

    try {
      const requestResponse = await inviteUser(payload);
      return fulfillWithValue(requestResponse);
    } catch (err) {
      return rejectWithValue("Sorry, we encountered a failure sending invite.");
    }

  }
)

export const editUserInfos = createAsyncThunk(
  "user/edit",
  async (payload: {
    originalUsername: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    stateUser: any;
  }, { rejectWithValue, fulfillWithValue }) => {

    try {
      const requestResponse = await editUser(payload);
      payload.stateUser.LastName = payload.lastName
      return fulfillWithValue(requestResponse);
    } catch (err) {
      return rejectWithValue("Sorry, we encountered a failure sending invite.");
    }
  }
)

export const editRole = createAsyncThunk(
  "user/editRole",
  async (payload: {
    username: string;
    oldRole: string;
    role: string;
  }, { rejectWithValue, fulfillWithValue }) => {

    try {
      const requestResponse = await editUserRole(payload);
      return fulfillWithValue(requestResponse);
    } catch (err) {
      return rejectWithValue("Sorry, we encountered a failure sending invite.");
    }
  }
)

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (payload: {
    username: string;
  }, { rejectWithValue, fulfillWithValue }) => {

    try {
      const requestResponse = await doDeleteUser(payload);
      return fulfillWithValue(requestResponse);
    } catch (err) {
      return rejectWithValue("Sorry, we encountered a failure sending invite.");
    }
  }
)
