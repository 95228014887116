import React from 'react'
import Graph from "react-graph-vis"
import { v4 as uuidv4 } from "uuid"
import { useAppSelector } from '../../../redux/hooks'
import Loader from '../loader/Loader'

const Canvas = ({ storeGraph, options, events }) => {
  // STORE STATE
  const { loading } = useAppSelector((state) => state.graphReducer)
  return (
    <>
      {loading ? (
        <div className="w-full h-[100vh] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <Graph
          key={uuidv4()}
          graph={storeGraph}
          options={options}
          events={events}
          getNetwork={(network) => {
            network?.on("click", function (params) {
              let nodeID = params["nodes"]["0"]
              if (nodeID) {
                let clickedNode = network?.body?.data?.nodes?.get(nodeID)
                // this get connected nodes is snippet for later use, but not not currently used
                network.getConnectedNodes(nodeID)
                network.getConnectedNodes(nodeID, 'to')
                network?.body?.data?.nodes?.update(clickedNode)
              }
            })
            //  if you want access to vis.js network api you can set the state in a parent component using this property
          }}
        />
      )}
    </>
  )
}

export default Canvas