import React from 'react'
import { ClipLoader } from 'react-spinners'
import AFTPalette from './aftpalette'


type Props={
    loading: boolean;
}
const LoadingSpinner:React.FC<Props> = ({loading}) => {
  return (
    <div className="flex items-center justify-center">
    <ClipLoader
      size={22}
      loading={loading}
      color={AFTPalette.primary.purpleLight}
    />
  </div>
  )
}

export default LoadingSpinner