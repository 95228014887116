import React, { useEffect, useState } from "react";
import MultilineChart from "./GraphViews/MultilineChart";
import LineGraphFilter from "./components/LineGraphFilter";
import { useAppSelector } from "../../redux/hooks";
import { lineGraphSelector } from "../../redux/lineGraph/lineGraphSlice";
import { ErrorPage } from "../graph/error/ErrorPage";
import Skeleton from "@mui/material/Skeleton";
import {
  checkObjectIsNest,
  formatLineGraph,
  formatNestLineGraph,
} from "../generics/utilities/lineGraphUtils";

const GraphContainer = () => {
  const [selectedItems, setSelectedItems]: any = React.useState([]);
  const [legendData, setLegendData]: Array<any> = React.useState([]);
  const { error, loading, records } = useAppSelector(lineGraphSelector);
  const [lineChartData, setLineChartData]: Array<any> = useState([]);
  const [isGroupNest, setIsGroupNest] = useState(false);

  useEffect(() => {
    let tempData: Array<any> = [];

    if (checkObjectIsNest(records)) {
      tempData = formatNestLineGraph(records);
    } else {
      tempData = formatLineGraph(records);
    }

    setLegendData(tempData);
    setLineChartData(tempData);
  }, [records]);

  const chartData: any = isGroupNest
    ? [...lineChartData]
    : [
        ...lineChartData?.filter((d: any) => {
          return selectedItems.includes(d.name as string);
        }),
      ];

  const setLineData = (data: Array<string>) => {
    setSelectedItems(data);
  };

  const setGroupNestValue = (val: boolean) => {
    setIsGroupNest(val);
  };

  return (
    <div className="border-[1px]  rounded-[8px] p-6">
      <LineGraphFilter
        data={legendData}
        setSelectedItems={setLineData}
        setGroupNestValue={setGroupNestValue}
      />
      {error !== "" ? (
        <div className="mt-10">
          <ErrorPage error={error} />
        </div>
      ) : (
        <>
          {loading && (
            <div className="mt-[35px]  rounded-[8px] h-full  border-[#EAECF0] bg-[#fff] shadow-[0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)]">
              <Skeleton className="!h-[60vh]" />
            </div>
          )}
        </>
      )}
      {error === "" && (
        <>
          {chartData && chartData?.length > 0 && (
            <MultilineChart data={chartData} />
          )}
        </>
      )}
      {legendData?.length > 0 && chartData?.length === 0 && (
        <ErrorPage error={"Please select group to view data "} />
      )}
    </div>
  );
};

export default GraphContainer;
