import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { hideAlert } from "../../../../redux/roles/rolesSlice";
import AlertComponent from "../../../generics/AlertComponent";
import CustomTable from "./CustomTable";
import FilterUsers from "./FilterUsers";

const Invites = () => {
  const dispatch = useAppDispatch();
  const { error, showAlert } = useAppSelector((state: any) => state.roles);

  // Shows alert Component when user is invited to join the system
  useEffect(() => {
    if (showAlert)
      setTimeout(() => {
        dispatch(hideAlert(false));
      }, 5000);
  }, [dispatch, showAlert]);

  const closeAlert = useCallback(() => {
    dispatch(hideAlert(false));
  }, [dispatch]);

  return (
    <div className="mt-2 relative">
      <div className="flex justify-end">
        <div className="flex-[0.3]">
          <FilterUsers />
        </div>
      </div>
      <div className="absolute -top-20 right-0">
        {showAlert && (
          <AlertComponent
            severity="success"
            message={error}
            closeDialog={closeAlert}
          />
        )}
      </div>
      <CustomTable />
    </div>
  );
};

export default Invites;
