import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../services/api";
import {
  getForwardsObjectConfigurations,
  getRequestPath,
} from "../../services/trackingConfigurations";
import { ForwardObjectTrackingState } from "../../types/ObjectTrackingTypes";

const initialState: ForwardObjectTrackingState = {
  forwardsTrackingObjects: {
    machines: [],
    articles: [],
    packages: [],
    products: [],
    productionOrders: [],
  },
  loading: false,
  error: "",
};

export const getForwardsTrackingObjects = createAsyncThunk(
  "getForwardsTrackingObjects",
  async (payload: any, { rejectWithValue }) => {
    const { type, externalId } = payload;

    const requestConfig: any = getForwardsObjectConfigurations(type);
    const path: any = getRequestPath(type);
    requestConfig?.ObjectKeyValueMap?.ExternalID.push(externalId);

    try {
      const response: any = await Api({
        url: `${path}`,
        method: "GET",
        params: requestConfig,
      });

      return { type, data: response.data.Result };
    } catch (error) {
      console.log(error);

      return rejectWithValue({ type, errorMessage: "Unable to fetch data" });
    }
  }
);

const forwardsTrackingObjectsSlice = createSlice({
  name: "forwardsTrackingObjects",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getForwardsTrackingObjects.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      switch (action.meta.arg.type) {
        case "Article":
          state.forwardsTrackingObjects.articles = [];
          break;
        case "Machine":
          state.forwardsTrackingObjects.machines = [];
          break;
        case "Package":
          state.forwardsTrackingObjects.packages = [];
          break;
        case "Product":
          state.forwardsTrackingObjects.products = [];
          break;
        case "ProductionOrder":
          state.forwardsTrackingObjects.productionOrders = [];
          break;
      }
    });
    builder.addCase(
      getForwardsTrackingObjects.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;

        switch (action.payload.type) {
          case "Article":
            state.forwardsTrackingObjects.articles = action.payload.data;
            break;
          case "Machine":
            state.forwardsTrackingObjects.machines = action.payload.data;
            break;
          case "Package":
            state.forwardsTrackingObjects.packages = action.payload.data;
            break;
          case "Product":
            state.forwardsTrackingObjects.products = action.payload.data;
            break;
          case "ProductionOrder":
            state.forwardsTrackingObjects.productionOrders = action.payload.data;
            break;
        }
      }
    );
    builder.addCase(
      getForwardsTrackingObjects.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload?.errorMessage;

        switch (action.payload?.type) {
          case "Article":
            state.forwardsTrackingObjects.articles = [];
            break;
          case "Machine":
            state.forwardsTrackingObjects.machines = [];
            break;
          case "Package":
            state.forwardsTrackingObjects.packages = [];
            break;
          case "Product":
            state.forwardsTrackingObjects.products = [];
            break;
          case "ProductionOrder":
            state.forwardsTrackingObjects.productionOrders = [];
            break;
        }
      }
    );
  },
});

export default forwardsTrackingObjectsSlice.reducer;

export const forwardsTrackingObjectSelector = (state: {
  forwardsTrackingObjects: ForwardObjectTrackingState;
}) => state.forwardsTrackingObjects;
