import { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { SidebarItem } from "./DataStructure";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Icon, Stack, styled } from "@mui/material";
import { setActiveObjectType } from "../../redux/activeObject/activeObject";



// PROP TYPES
type SubmenuItemProps = {
  item: SidebarItem;
  open: boolean;
  id: string;
  sidebarNavs?: Array<any>;
  setParentMenu?: (menuItems: any) => void;
};

// SUBMENU COMPONENT
const SubmenuItem: FC<SubmenuItemProps> = ({ item, open, id }) => {
  // STORE STATE
  const { isOpenNavbar } = useAppSelector((state: any) => state.graphReducer);
  const [itemsMenu, setItemsMenu]: any = useState();
  const [navIsOpen] = useState(false);

  const buttonRef = useRef(null);
  const buttonContainer = useRef(null);
  useEffect(() => {
    setItemsMenu(() => {
      return {
        ...item,
        subnav: item?.subnav,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.subnav]);

  const dispatch = useAppDispatch();

  // CUSTOM ICON
  const StyledIcon = styled(Icon)({
    fontSize: "1.5rem",
    marginRight: `${open ? "0.8rem" : "0rem"}`,
    marginLeft: `${open ? "0.8rem" : "0.4rem"}`,
  });

  // handles menu items with dropdowns
  const toggleSubnav = (e: any) => {
    Array.from(document.getElementsByClassName("items-menu")).forEach((el) => {
      el.classList.add("hideNav");
    });
    e.target.nextSibling?.classList.remove("hideNav");
  };

  return (
    <div id={id} ref={buttonContainer}>
      <NavLink
        ref={buttonRef}
        to={`${itemsMenu?.path}`}
        onClick={(e) => {
          toggleSubnav(e);
          dispatch(setActiveObjectType(itemsMenu.path));
        }}
        className={({ isActive }) =>
          isActive
            ? "text-primary-purpleDark bg-secondary-grayLight flex justify-between align-middle items-center text-[16px] py-1 pl-[4%] rounded-md "
            : "flex justify-between align-middle items-center text-accent-grayMuted text-[16px] py-1 pl-[4%] rounded-md"
        }
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyItems: "flex-start",
            alignItems: "center",
          }}
        >
          <StyledIcon>{itemsMenu?.icon}</StyledIcon>
          {open && itemsMenu?.title}
        </Stack>
        {open && (
          <i>{navIsOpen ? itemsMenu?.iconOpened : itemsMenu?.iconClosed}</i>
        )}
      </NavLink>
      <div className="items-menu hideNav">
        {itemsMenu?.subnav?.map((subitem: any, index: number) => {
          return (
            <div key={index}>
              {isOpenNavbar && (
                <p>
                  <NavLink
                    to={`${subitem?.path}`}
                    onClick={() => dispatch(setActiveObjectType(subitem?.path))}
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary-purpleDark bg-secondary-grayLight flex justify-between align-middle items-center text-[16px] py-1 pl-16 rounded-md navItems"
                        : "flex justify-between align-middle items-center text-accent-grayMuted text-[16px] py-1 pl-16 rounded-md "
                    }
                  >
                    {open && subitem?.title}
                  </NavLink>
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubmenuItem;
