import React, { FC, useCallback } from "react";
import { TableCell } from "@mui/material";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { StyledTableRow } from "./customStyledComponents";

type Props = {
  users: any[];
  columns: any;
  modalHandler: (value: string) => any;
};


const UserRows: FC<Props> = ({ users, columns, modalHandler }) => {
  const handleClick = useCallback((e: any) => {
    modalHandler(e);
  },[modalHandler]);

  return (
    <>
      {users &&
        users?.map((row: any) => {
          return (
            <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
              {columns &&
                columns?.map((column: any, index: any) => {
                  const value = row[column.id];
                  return column?.id !== "actions" ? (
                    <TableCell key={column.id} align={column.align}>
                      <span id={value}>{value}</span>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={index + new Date().getMilliseconds()}
                      align="right"
                    >
                      <span className="flex justify-end items-center space-x-5">
                        <RiDeleteBinLine
                          data-type={"delete"}
                          data-username={row["UserName"]}
                          onClick={handleClick}
                          color="#667085"
                          size={18}
                          style={{ cursor: "pointer" }}
                        />
                        <FiEdit2
                          onClick={handleClick}
                          data-type={"editUser"}
                          data-username={row["UserName"]}
                          data-role={row["RolesString"]}
                          color="#667085"
                          size={18}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </TableCell>
                  );
                })}
            </StyledTableRow>
          );
        })}
    </>
  );
};

export default UserRows;
