import React, { FC } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

interface Props {
  relationships: any;
}

const EitherOrRelationships: FC<Props> = ({ relationships }) => {
  return (
    <div className="relative w-[60%] p-5 shadow-md sm:rounded-lg">
      {relationships && relationships.length > 0 ? (
        relationships?.map((relation: any, index: number) => {
          return (
            <div key={index + new Date().getMilliseconds()} className="">
              <ol className="list-none">
                {relation?.length > 0 &&
                  relation?.map((item: string, indexValue: number) => {
                    return (
                      <li
                        key={indexValue + new Date().getMilliseconds()}
                        className="flex justify-between items-center align-middle my-2"
                      >
                        <span>{item}</span>
                        <span className="flex justify-end items-center space-x-5">
                          <RiDeleteBinLine
                            data-type={"delete"}
                            color="#667085"
                            size={18}
                            style={{ cursor: "pointer" }}
                          />
                          <FiEdit2
                            data-type={"editUser"}
                            color="#667085"
                            size={18}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </li>
                    );
                  })}
              </ol>
            </div>
          );
        })
      ) : (
        <div className="text-center py-3">Relationships are not available</div>
      )}
    </div>
  );
};

export default EitherOrRelationships;
