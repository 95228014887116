import * as React from "react";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAvailableViews,
  getCurrentViewIndex,
} from "../../generics/utilities/utils";
import { ListItemButton } from "@mui/material";
import { baseRoute } from "../../../public/routesConfig";
import { objectDetailsView } from "../../../public/featuresConfig";
import { resetGlobalMenuItems } from "../../../redux/sideBar/sidebarSlice";
import { useAppDispatch } from "../../../redux/hooks";

const options = [
  { name: "Tracking View", value: 0, routeName: "tracking" },
  { name: "Details View", value: 1, routeName: "overview" },
  { name: "Analysis View", value: 2, routeName: "analysis" },
];

interface ViewProps {
  name: string;
  routeName: string;
  value: number;
}

const PredefinedViews = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location: {
    state: any;
    pathname: string;
  } = useLocation();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = React.useState(
    getCurrentViewIndex(location.pathname.split("/"), options)
  );
  const dispatch = useAppDispatch();
  const optionsAvailable = React.useMemo(() => {
    // Check if objectType has tracking and shows the tracking option to switch to view. Otherwise
    // Remove that option from the options array.
    if (location.pathname.includes(objectDetailsView[1].toLowerCase())) {
      return getAvailableViews(
        options,
        location.state.ObjectType,
        objectDetailsView[1].toLowerCase()
      );
    }

    if (location.pathname.includes(objectDetailsView[0].toLowerCase())) {
      return getAvailableViews(
        options,
        location.state.ObjectType,
        objectDetailsView[1].toLowerCase()
      );
    }

    return getAvailableViews(options, location.state.ObjectType);
  }, [location]);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    option: ViewProps
  ) => {
    setSelectedIndex(option.value);
    setAnchorEl(null);
    dispatch(resetGlobalMenuItems(true));
    switch (option.routeName) {
      // Analysis
      case objectDetailsView[1].toLowerCase():
        return navigate(
          `${baseRoute}analysis/${location.state.ObjectType.toLowerCase()}/${
            location.state.Name
          }`,
          {
            state: location.state,
          }
        );
      // Tracking
      case objectDetailsView[0].toLowerCase():
        return navigate(
          `${baseRoute}tracking/${location.state.ObjectType.toLowerCase()}/${
            location.state.Name
          }`,
          {
            state: location.state,
          }
        );

      default:
        navigate(
          `${baseRoute}objects/${location.state.ObjectType.toLowerCase()}/${
            location.state.Name
          }`,
          {
            state: location.state,
          }
        );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="!m-0 !p-0">
      <List
        component="div"
        aria-label="Device settings"
        sx={{ padding: 0, margin: 0 }}
      >
        <ListItemButton
          className="!px-3 !py-[0.4rem] !rounded-sm  !text-[20px]"
          sx={{
            borderRadius: 2,
          }}
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <div className="flex items-center gap-x-2">
            <h4 className="text-[16px] font-medium">
              {options[selectedIndex].name}{" "}
            </h4>
            <div className="h-[26px]">
              <svg
                width="20"
                height="20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  open ? "!rotate-180 mt-[7px] -ml-[1px]" : "rotate-0 -mr-[8px]"
                }`}
              >
                <path
                  d="M2 11.7368L6.73684 16.4737L11.4737 11.7368"
                  stroke="#010101"
                  strokeWidth="1.57895"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {optionsAvailable.map((option) => (
          <MenuItem
            key={option.value}
            // disabled={index === 0}
            selected={option.value === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PredefinedViews;
