import Slider from "@mui/material/Slider";
import { useState } from "react";

export const Resolution = ({
  getResolution,
}: {
  getResolution: (value: number) => void;
}) => {
  const [value, setValue] = useState<number | string | Array<number | string>>(
    20
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(() => {
      getResolution(newValue as number);
      return newValue;
    });
  };

  return (
    <div className="flex items-center gap-4">
      <p>Values</p>
      <Slider
        className="!w-[150px]"
        value={typeof value === "number" ? value : 1}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        min={1}
      />

      <p>{value}</p>
      {/* <button>Show full</button> */}
    </div>
  );
};
