import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { RollupsLevelTwoProps } from "./RollupsLevelTwoProps";

interface Props {
  levelOneProps: any;
}

export function RollupsLevelOneProps({ levelOneProps }: Props) {
  return (
    <div className="node-details flex justify-start max-h-[60vh] flex-col overflow-x-hidden overflow-y-scroll levelsScrollHidden">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="!border-none"
      >
        <AccordionItem className="!border-none">
          <AccordionItemHeading>
            <AccordionItemButton className="accordion__button !py-1 !bg-white text-[12px] font-bold">
            Object
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="!border-none p-1 text-[12px]">
            <RollupsLevelTwoProps levelTwoProps={levelOneProps?.Object} />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="!border-none">
          <AccordionItemHeading>
            <AccordionItemButton className="accordion__button !py-1 !bg-white text-[12px] font-bold">
            Relationship
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="!border-none p-1 text-[12px]">
            <RollupsLevelTwoProps levelTwoProps={levelOneProps?.Relationship} />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
