import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";

// Sentry Integration
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { injectStore } from "./services/api";

Sentry.init({
  dsn: "https://3ba963bbb2394f168f7e796c00ade7be@o1182568.ingest.sentry.io/4503981390168064",
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay()
  ],

  // Capturing 25% of transactions for performance monitoring
  tracesSampleRate: 0.25,

  // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
  replaysSessionSampleRate: 0.25,

  // The sample rate for replays that are recorded when an error happens. 
  // This type of replay will record up to a minute of events prior to the error and continue recording until the session ends.
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

injectStore(store);

const persistor = persistStore(store)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
