import useLocalStorage from "../../../../customHooks/useLocalStorage"
import { formatDateTime, formatString } from "../../../generics/utils/utils"

export function EdgeDetails() {

  const { storedValue: selectedEdgeRelations } = useLocalStorage(
    "selectedEdgeRelations"
  )


  return (
    <>
      {Object.keys(selectedEdgeRelations)?.length > 0 && (
        <div className="edge-details flex justify-start overflow-y-scroll overflow-x-hidden flex-col">
          {Object.keys(selectedEdgeRelations)?.map((key) => {
            const excludeKeys = [
              "ID",
              "SourceObjectExternalID",
              "SourceObjectID",
              "SourceObjectType",
              "TargetObjectExternalID",
              "TargetObjectID",
              "TargetObjectType",
            ]
            if(excludeKeys.includes(key)){
              return ""
            }
            else {
              return (
                <div className="flex justify-start flex-row my-2 p-1" key={key}>
                  <div className="text-[#667085] text-[12px] w-[40%] break-words mr-2 pr-2">
                    {formatString(key)}
                  </div>
                  <div className="text-[#667085] w-[60%] text-[12px]">
                    {formatDateTime(selectedEdgeRelations[key], key)}
                  </div>
                </div>
              )
            }
          })}
        </div>
      )}
    </>
  )
}
