import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { objectSelector } from "../../redux/objects/objectSlice";
import {
  displayTitleValueLabel,
  getObjectType,
} from "../generics/utilities/utils";
import ViewContainer from "../generics/ViewContainer";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import GeneralInformation from "./Properties/GeneralInformation";
import AccordionLabelSection from "./Properties/AccordionLabelSection";
import AccordionRelationshipSection from "./Properties/AccordionRelationshipSection";
import AccordionIndexesSection from "./Properties/AccordionIndexesSection";
import AccordionTimeSeriesDimensions from "./Properties/AccordionTimeSeriesDimensions";
import AccordionEitherOrRelationships from "./Properties/AccordionEitherOrRelationships";
import { Box, Modal, Backdrop } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { style } from "../settings/invites/customTable/boxStyles";
import { ErrorPage } from "../graph/error/ErrorPage";
import AddObjectType from "./cruds/AddObjectType";
import DeleteObjectType from "./cruds/DeleteObjectType";
import { activeObjectSelector } from "../../redux/activeObject/activeObject";
import AlertComponent from "../generics/AlertComponent";

let generalInfo: any;
let objectProperties: any;
let arrayProperties: any;

const Config = () => {
  const path = useLocation().pathname?.split("/");
  let activeObject = decodeURI(path[path.length - 1]);
  activeObject =
    activeObject[0].toUpperCase() + activeObject.slice(1, activeObject.length);
  const { objects } = useAppSelector(objectSelector);
  const { activeObjectType: activeObjectConfigType } =
    useAppSelector(activeObjectSelector);
  const [configView, setConfigView] = useState<any>([]);
  const [accordionIndex, setAccordionIndex] = useState<number>(0);
  const [accordionToggler, setAccordionToggler] = useState<boolean>(false);
  const [relatedAccordionIndex, setRelatedAccordionIndex] = useState<number>(4);
  const [relatedAccordionToggler, setRelatedAccordionToggler] =
    useState<boolean>(true);
  const [eventType, setEventType] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [isAddObjectType, setIsAddObjectType] = useState(false);
  const [isDeleteObjectType, setIsDeleteObjectType] = useState(false);
  const [activeConfigType, setActiveConfigType] = useState(
    activeObjectConfigType
  );
  const [successMessage, setSuccessMessage] = useState("");
  // Get object Type
  let currentObjectString = getObjectType(objects, activeObject);
  // Returns relationships of the table header
  const newObjectsString = JSON.stringify(objects);

  useEffect(() => {
    const newPath = activeObjectConfigType.split("/");
    if (newPath?.length > 1) {
      let objectPath = newPath[newPath.length - 1];
      setActiveConfigType(objectPath);
    } else {
      setActiveConfigType(newPath.join(""));
    }
  }, [activeObjectConfigType]);

  const setConfigSectionData = (section: any, key: string) => {
    if (!Array.isArray(section)) {
      if (typeof section !== "object" || !section) {
        generalInfo = {
          ...generalInfo,
          [key]: section,
        };
      }
    }
    if (typeof section === "object") {
      objectProperties = {
        ...objectProperties,
        [key]: section,
      };
    }
    if (Array.isArray(section)) {
      arrayProperties = {
        ...arrayProperties,
        [key]: section,
      };
    }
  };

  const handleOpen = useCallback((e: MouseEvent) => {
    const target = e.currentTarget as any;
    const action = target.dataset.type;
    switch (action) {
      case "addObjectType":
        setOpen(true);
        setIsAddObjectType(true);
        break;
      case "deleteObjectType":
        setOpen(true);
        setIsDeleteObjectType(true);
        break;
    }
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setIsAddObjectType(false);
    setIsDeleteObjectType(false);
  }, []);

  useEffect(() => {
    let newObjects = JSON.parse(newObjectsString);
    const getRequestConfig = () => {
      if (newObjects?.Config) {
        for (const key in newObjects?.Config[currentObjectString]) {
          setConfigSectionData(
            newObjects?.Config[currentObjectString][key],
            key
          );
        }
        setConfigView(newObjects?.Config[currentObjectString]);
      }
    };
    getRequestConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject, newObjectsString, currentObjectString]);

  return (
    <div className="page-Item-Container h-[100vh] p-2 md:pl-[32px] relative bottom-[0px] rounded-t-[32px]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Backdrop
          open={open}
          style={{ backgroundColor: "rgba(52, 64, 84, 0.7)" }}
        >
          <Box sx={style}>
            <div className="">
              <ErrorBoundary FallbackComponent={ErrorPage}>
                {isAddObjectType && (
                  <AddObjectType
                    activeObject={activeObject}
                    closeModal={handleClose}
                    setSuccessMessage={setSuccessMessage}
                  />
                )}

                {isDeleteObjectType && (
                  <DeleteObjectType
                    activeObject={activeConfigType}
                    closeModal={handleClose}
                    setSuccessMessage={setSuccessMessage}
                  />
                )}
              </ErrorBoundary>
            </div>
          </Box>
        </Backdrop>
      </Modal>
      <ViewContainer>
        {/* SUCCESS MESSAGE */}
        <div className="w-[350px] absolute right-5 -top-6">
          {successMessage && (
            <AlertComponent
              closeDialog={handleClose}
              severity="success"
              message={successMessage}
            />
          )}
        </div>

        <div className="mt-[45px]">
          <div className="flex flex-col">
            <div className="config-show px-8 mt-10 pt-[37px] overflow-scroll text-[black] bg-[#fff] w-full h-[98vh] rounded-[8px] md:mt-4">
              <div className="">
                <div className="flex justify-between">
                  <div className="">
                    <h3 className="text-[30px] font-[400] text-primary-textNormal my-4 ">
                      Configuration Details
                    </h3>
                    <p className="text-[16px] font-[400] text-accent-grayMuted">
                      All are editable on click and will be saved automatically
                      after any changes
                    </p>
                  </div>
                  <div className="">
                    <button
                      data-type={"addObjectType"}
                      onClick={(e: any) => handleOpen(e)}
                      className={`bg-[#F5F5FF] text-[#3A6A78] px-3 py-2 rounded-md`}
                    >
                      Add ObjectType
                    </button>
                  </div>
                </div>
                <div className="h-[1px] w-full bg-accent-borderColor my-4"></div>
                <div className="config-header flex items-center justify-between">
                  <h3 className=" text-[18px] font-[700] text-[#3A6A78] mb-4  mt-8">
                    {displayTitleValueLabel(activeConfigType, objects)}
                  </h3>
                  <div
                    className="mr-4 p-3 transition ease-in-out delay-150 bg-secondary-grayLight rounded-[8px] hover:bg-[#e3e3e7] cursor-pointer"
                    data-type={"deleteObjectType"}
                    onClick={(e: any) => handleOpen(e)}
                  >
                    <img src="/assets/deleteIcon.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="">
                <>
                  <Accordion
                    allowMultipleExpanded
                    allowZeroExpanded
                    className=""
                  >
                    <AccordionItem
                      onClick={() => {
                        setAccordionIndex(0);
                        setAccordionToggler(!accordionToggler);
                      }}
                    >
                      <AccordionItemHeading className="hover:bg-accent-tableBg rounded-md">
                        <AccordionItemButton className="!bg-[transparent] text-[12px] font-bold">
                          <div className="w-full flex  justify-start items-center space-x-5 p-2">
                            {accordionIndex === 0 && accordionToggler ? (
                              <AiOutlineMinusCircle
                                color="#7F56D9"
                                className="w-6 h-6"
                              />
                            ) : (
                              <AiOutlinePlusCircle
                                color="#7F56D9"
                                className="w-6 h-6"
                              />
                            )}
                            <div className="w-full flex justify-between items-center">
                              <p className="text-lg font-[500] text-primary-textNormal">
                                General Information
                              </p>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="!border-b-0 p-3 text-[12px]">
                        {configView &&
                          generalInfo &&
                          Object.entries(generalInfo).map(
                            ([key, value]: any) => (
                              <GeneralInformation
                                key={key}
                                value={value}
                                label={key}
                                activeObject={activeObject}
                                setSuccessMessage={setSuccessMessage}
                              />
                            )
                          )}
                      </AccordionItemPanel>
                    </AccordionItem>
                    {configView &&
                      objectProperties &&
                      Object.entries(objectProperties).map(
                        ([key, value]: any, index: number) => {
                          if (key === "Labels") {
                            return (
                              <AccordionLabelSection
                                key={key}
                                properties={{
                                  setAccordionIndex,
                                  setAccordionToggler,
                                  accordionIndex,
                                  accordionToggler,
                                  index,
                                  key,
                                  value,
                                }}
                              />
                            );
                          }
                          if (key === "Relationships") {
                            return (
                              <AccordionRelationshipSection
                                key={key}
                                properties={{
                                  setAccordionIndex,
                                  setAccordionToggler,
                                  setRelatedAccordionToggler,
                                  setRelatedAccordionIndex,
                                  setEventType,
                                  relatedAccordionIndex,
                                  relatedAccordionToggler,
                                  accordionIndex,
                                  accordionToggler,
                                  index,
                                  key,
                                  value,
                                  eventType,
                                }}
                              />
                            );
                          }
                          if (key === "Indexes") {
                            return (
                              <AccordionIndexesSection
                                key={key}
                                properties={{
                                  setAccordionIndex,
                                  setAccordionToggler,
                                  accordionIndex,
                                  accordionToggler,
                                  index,
                                  key,
                                  value,
                                }}
                              />
                            );
                          }
                          if (key === "TimeSeriesDimensions") {
                            return (
                              <AccordionTimeSeriesDimensions
                                key={key}
                                properties={{
                                  setAccordionIndex,
                                  setAccordionToggler,
                                  accordionIndex,
                                  accordionToggler,
                                  index,
                                  key,
                                  value,
                                }}
                              />
                            );
                          }
                          if (key === "EitherOrRelationships") {
                            return (
                              <AccordionEitherOrRelationships
                                key={key}
                                properties={{
                                  setAccordionIndex,
                                  setAccordionToggler,
                                  accordionIndex,
                                  accordionToggler,
                                  index,
                                  key,
                                  value,
                                }}
                              />
                            );
                          }
                          return null;
                        }
                      )}
                  </Accordion>
                </>
              </div>
            </div>
          </div>
        </div>
      </ViewContainer>
    </div>
  );
};

export default Config;
