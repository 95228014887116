export const productsForwardTrackingPathMap = [
    {
        RelatedObjectTypes: [
            "Product"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            }
        ]
    }
]