import { configureStore, combineReducers } from "@reduxjs/toolkit";
import objectSlice from "./objects/objectSlice";
import objectDetailsSlice from "./objects/objectDetailsSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import loginSlice from "./login/loginSlice";
import graphSlice from "./graph/graphSlice";
import activeObject from "./activeObject/activeObject";
import breadCrumbSlice from "./breadCrumb/breadCrumbSlice";
import filterObjectsSlice from "./graph/filterSlice";
import localforage from "localforage";
import { getPersistConfig } from "redux-deep-persist";
import usersSlice from "./users/usersSlice";
import lineGraphSlice from "./lineGraph/lineGraphSlice";
import rolesSlice from "./roles/rolesSlice";
import backwardsTrackingSlice from "./tracking/backwardsTrackingSlice";
import forwardsTrackingSlice from "./tracking/forwardsTrackingSlice";
import trackingPathSlice from "./tracking/trackingPathSlice";
import sidebarSlice from "./sideBar/sidebarSlice";

const rootReducer = combineReducers({
  objects: objectSlice,
  objectInfo: objectDetailsSlice,
  user: loginSlice,
  users: usersSlice,
  roles: rolesSlice,
  graphReducer: graphSlice,
  activeObject: activeObject,
  filteredObject: filterObjectsSlice,
  previousState: breadCrumbSlice,
  lineGraphRecords: lineGraphSlice,
  forwardsTrackingObjects: forwardsTrackingSlice,
  backwardsTrackingObjects: backwardsTrackingSlice,
  trackingPath: trackingPathSlice,
  menuItems: sidebarSlice,
});

const persistConfig = getPersistConfig({
  key: "root",
  storage: localforage,
  blacklist: ["previousState", "filteredObjects", "graphReducer", "objects"],
  rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === "development",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
