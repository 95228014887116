import { useCallback } from "react";
import { Link } from "react-router-dom";
import { resetPreviousState } from "../../../redux/breadCrumb/breadCrumbSlice";
import { useAppDispatch } from "../../../redux/hooks";

const NextObjectLink = ({
  to,
  state,
  name,
}: {
  to: string;
  state: any;
  name: string;
}) => {
  const dispatch = useAppDispatch();

  const prepareStateForNextObject = useCallback(() => {
    dispatch(resetPreviousState());
    // Resets the order column icon for the next page
    localStorage.removeItem("orderColumn");
  }, [dispatch]);
  return (
    <Link to={`${to}`} state={state} onClick={prepareStateForNextObject}
    className="link-class"
    >
      {name}
    </Link>
  );
};

export default NextObjectLink;
