import React, { FC } from "react"
import Checkbox from "./fields/Checkbox"
import Datepicker from "./fields/Datepicker"
import Input from "./fields/Input"
import RadioButton from "./fields/RadioButton"
import Select from "./fields/Select"
import Textarea from "./fields/Textarea"


type Comparator = { key: string; value: string }

type Props = {
  control: string
  type?: string
  name: string
  formikObject: any
  groupTouched?: string
  selfTouched?: string
  objectType?: string
  searchProperty?: string
  options?: Comparator[]
  label?: string
  width?: any
}

const FormikControl: FC<Props> = ({
  control,
  options,
  width,
  groupTouched,
  selfTouched,formikObject, objectType, searchProperty,
  ...rest
}) => {
  switch (control) {
    case "input":
      return (
        <Input
          width={width}
          groupTouched={groupTouched}
          selfTouched={selfTouched}
          formikObject={formikObject}
          objectType={objectType}
          searchProperty={searchProperty}
          {...rest}
        />
      )
    case "textarea":
      return <Textarea width={width} formikObject={formikObject} {...rest} />
    case "select":
      return (
        <Select
          options={options}
          formikObject={formikObject}
          groupTouched={groupTouched}
          selfTouched={selfTouched}
          width={width}
          {...rest}
        />
      )
    case "radio":
      return (
        <RadioButton
          options={options}
          formikObject={formikObject}
          width={width}
          {...rest}
        />
      )
    case "checkbox":
      return (
        <Checkbox
          options={options}
          groupTouched={groupTouched}
          selfTouched={selfTouched}
          formikObject={formikObject}
          width={width}
          {...rest}
        />
      )
    case "date":
      return (
        <Datepicker
          width={width}
          selfTouched={selfTouched || ""}
          formikObject={formikObject}
          {...rest}
        />
      )
    default:
      return null
  }
}

export default FormikControl
