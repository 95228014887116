export const checkObjectIsNest = (data: any) => {
  let result = false;
  data &&
    Object.keys(data)?.length > 0 &&
    Object.keys(data)?.map((item: string, index: number) => {
      return data[item]?.map((value: any) => {
        if (value?.Dimensions?.hasOwnProperty("Nest")) {
          result = true;
        }
        return result;
      });
    });
  return result;
};


// Returns the line graph data when data doesn't have a group nest property
export const formatLineGraph = (records: any) => {
  let tempData: Array<any> = [];
  records &&
    Object.keys(records)?.length > 0 &&
    Object.keys(records)?.forEach((item) => {
      tempData.push({
        name: item,
        color: generateColor(),
        items: records[item].map((d: any) => {
          const { Value, Time, ...newValue } = d;
          return {
            ...newValue,
            date: new Date(d.Time),
            value: d.Value,
          };
        }),
      });
    });

  return tempData;
};

// Returns the graph Groups
export const formatNestLineGraph = (nestLineGraph: any) => {
  let tempData: Array<any> = [];
  let nestGroups: any = new Map();

  nestLineGraph &&
    Object.keys(nestLineGraph)?.length > 0 &&
    Object.keys(nestLineGraph)?.forEach((item: string, index: number) => {
      nestLineGraph?.[item].forEach((element: any) => {
        if (nestGroups.has(element?.Dimensions?.Nest)) {
          nestGroups.get(element?.Dimensions?.Nest).push({ ...element });
        } else {
          nestGroups.set(element?.Dimensions?.Nest, [{ ...element }]);
        }
      });
    });

  for (const [key, value] of nestGroups) {
    let item = value?.map((d: any) => {
      const { Value, Time, ...newValue } = d;
      return {
        ...newValue,
        date: new Date(d.Time),
        value: Math.round(d.Value),
      };
    });
    tempData.push({
      name: key,
      color: generateColor(),
      items: item.sort(),
    });
  }

  return tempData;
};
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i++) {
    const value = (hash >> (i * 11)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const generateColor = () => {
  //  Cryptographically Strong PseudoRandom Number Generator to generate a random color number
  const crypto = window.crypto;
  let array = new Uint32Array(1);
  crypto.getRandomValues(array);
  const num: string = array[0].toString();
  return stringToColor(num + "01");
};
// Groups Data based on Nest key property
export const groupMultipleParamsData = (data: any) => {
  let groupedKeysResult: any = {};

  for (let key in data) {
    let keyValue = key;
    groupedKeysResult[key] = [];
    for (let value of data[keyValue]) {
      const groupKey = value.Dimensions.Nest;
      if (!groupedKeysResult[key][groupKey]) {
        groupedKeysResult[key][groupKey] = [value];
      } else {
        groupedKeysResult[key][groupKey].push(value);
      }
    }
  }
  return groupedKeysResult;
};

export const substractAnHourFromDate = (date: Date, hours: number) => {
  date.setHours(date.getHours() - hours);

  return date;
};
