export const getBackwardsObjectConfigurations = (objectType: string) => {
  switch (objectType) {
    case "Article":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["Article"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProductInPackage",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "Product",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Article",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "Article",
          },
        ]),
      };

    case "Product":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["Product"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProduct",
          },
        ]),
      };

    case "Machine":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["Machine"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProductInPackage",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "Product",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "ProductionOrderTarget",
          },
          {
            ObjectType: "ProductionOrder",
            RelatedObjectName: "CurrentlyExecutedAtMachine",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Machine",
          },
        ]),
      };
    case "Package":
      return {
        ObjectType: "Product",
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        BulkMaps: JSON.stringify([
          {
            RelatedObjectTypes: ["Package"],
            RelatedObjectMaps: [
              {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct",
              },
              {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProductInPackage",
              },
              {
                ObjectType: "Package",
                RelatedObjectName: "Product",
              },
              {
                ObjectType: "Product",
                RelatedObjectName: "Package",
              },
            ],
            BeginRelatedObjectMap: {
              ObjectType: "Product",
              RelatedObjectName: "BuiltOutOfProduct",
            },
          },
          {
            RelatedObjectTypes: ["Package"],
            RelatedObjectMaps: [
              {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct",
              },
              {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProductInPackage",
              },
              {
                ObjectType: "Package",
                RelatedObjectName: "Product",
              },
              {
                ObjectType: "Product",
                RelatedObjectName: "Package",
              },
            ],
            BeginRelatedObjectMap: {
              ObjectType: "Product",
              RelatedObjectName: "BuiltOutOfProductInPackage",
            },
          },
          {
            RelatedObjectTypes: ["Package"],
            RelatedObjectMaps: [
              {
                ObjectType: "Product",
                RelatedObjectName: "Package",
              },
            ],
          },
        ]),
      };
    case "ProductionOrder":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["ProductionOrder"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProductInPackage",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "Product",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "ProductionOrderTarget",
          },
        ]),
      };
  }
};
export const getForwardsObjectConfigurations = (objectType: string) => {
  switch (objectType) {
    case "Article":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["Article"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltInProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Package",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "ContainingProductIsBuiltInProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Article",
          },
        ]),
      };

    case "Product":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["Product"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltInProduct",
          },
        ]),
      };

    case "Machine":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["Machine"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltInProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Package",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "ContainingProductIsBuiltInProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Machine",
          },
        ]),
      };
    case "Package":
      return {
        ObjectType: "Product",
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        BulkMaps: JSON.stringify([
          {
            RelatedObjectTypes: ["Package"],
            RelatedObjectMaps: [
              {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct",
              },
              {
                ObjectType: "Package",
                RelatedObjectName: "Product",
              },
              {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct",
              },
              {
                ObjectType: "Product",
                RelatedObjectName: "Package",
              },
            ],
            BeginRelatedObjectMap: {
              ObjectType: "Product",
              RelatedObjectName: "BuiltInProduct",
            },
          },
          {
            RelatedObjectTypes: ["Package"],
            RelatedObjectMaps: [
              {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct",
              },
              {
                ObjectType: "Package",
                RelatedObjectName: "Product",
              },
              {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct",
              },
              {
                ObjectType: "Product",
                RelatedObjectName: "Package",
              },
            ],
            BeginRelatedObjectMap: {
              ObjectType: "Product",
              RelatedObjectName: "Package",
            },
          },
        ]),
      };
    case "ProductionOrder":
      return {
        ObjectType: "Product",
        RelatedObjectTypes: JSON.stringify(["ProductionOrder"]),
        ObjectKeyValueMap: {
          ExternalID: [],
        },
        RelatedObjectMaps: JSON.stringify([
          {
            ObjectType: "Product",
            RelatedObjectName: "BuiltInProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "Package",
          },
          {
            ObjectType: "Package",
            RelatedObjectName: "ContainingProductIsBuiltInProduct",
          },
          {
            ObjectType: "Product",
            RelatedObjectName: "ProductionOrderSource",
          },
        ]),
      };
  }
};

export const getRequestPath = (objectType: string) => {
  if (objectType === "Package") return "object/path/bulk";

  return "object/path";
};
