import * as Yup from "yup";

export const initialValues = {
    name: "",
    timeStampField: "",
    timeSeriesObject: "",
    timeSeriesTimeStampField: "",
  }

export const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Please enter a name!"),
    timeStampField: Yup.string().trim(),
    timeSeriesObject: Yup.boolean(),
    timeSeriesTimeStampField: Yup.string().trim(),
});