import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logRequestDuration } from "../../components/generics/utilities/utils";
import {
  formatFilteredRelatedObjects,
  formatRelatedObjectsAndAddToCurrentGraph,
} from "../../components/generics/utils/graphFormaters";
import Api from "../../services/api";

export interface FilterObjectState {
  filteredObjects: any;
  filteredObjectRange: any;
  loading: boolean;
  suggestedObjects: any;

  error: string;
}

const initialState: FilterObjectState = {
  filteredObjects: [],
  filteredObjectRange: [],
  suggestedObjects: [],
  loading: false,
  error: "",
};
 
export const filterObjectByDate = createAsyncThunk(
  "filterObjectByDate",
  async (payload: any) => {
    const {
      objectType,
      parentObject,
      childObjectName,
      //key,
      //comparator,
      //value,
    } = payload;

    const params: any = {
      ObjectType: objectType,
      ObjectKeyValueMap: {
        ExternalID: parentObject,
      },
      RelatedMaps: JSON.stringify([
        {
          RelatedObjectName: childObjectName,
          //ObjectFilterList: [
          //  {
          //    Key: key,
          //    Comparator: comparator,
          //    Value: value,
          //  },
          //],
          OrderBy: "TimeStampDBInsertion",
          Limit: 50,
        },
      ]),
    };
    const allFilterConfig = JSON.parse(
      (localStorage.getItem("allFilterConfig") as any) || null
    );
    if (allFilterConfig && Object.keys(allFilterConfig).length > 0) {
      const objectFilterList: any[] = [];
      if("ExternalID" in allFilterConfig && allFilterConfig["ExternalID"]["value"] !== ""){
        objectFilterList.push({
          Key: "ExternalID",
          Comparator: allFilterConfig["ExternalID"]["comparator"],
          Value: allFilterConfig["ExternalID"]["value"]
        })
      }
      if("Name" in allFilterConfig && allFilterConfig["Name"]["value"] !== ""){
        objectFilterList.push({
          Key: "Name",
          Comparator: allFilterConfig["Name"]["comparator"],
          Value: allFilterConfig["Name"]["value"]
        })
      }
      if("START_DATE" in allFilterConfig && allFilterConfig["START_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["START_DATE"]["comparator"],
          Value: allFilterConfig["START_DATE"]["value"]
        })
      }
      if("END_DATE" in allFilterConfig && allFilterConfig["END_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["END_DATE"]["comparator"],
          Value: allFilterConfig["END_DATE"]["value"]
        })
      }
      params["ObjectFilterList"] = JSON.stringify(objectFilterList)
    }

    try {
      return await fetchObject(params);
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

const fetchObject = async (params: any) => {
  let time = performance.now();
  return Api({
    url: "sync/object",
    method: "GET",
    params: params,
  })
    .then((response) => {
      logRequestDuration(time);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const filterObjectByDateRange = createAsyncThunk(
  "filterObjectByDateRange",
  async (payload: any) => {
    const { objectType, parentObject, childObjectName} = //, key, value1, value2 } =
      payload;
    const params: any = {
      ObjectType: objectType,
      ObjectKeyValueMap: {
        ExternalID: parentObject,
      },
      RelatedMaps: JSON.stringify([
        {
          RelatedObjectName: childObjectName,
          //ObjectFilterList: [
          //  {
          //    Key: key,
          //    Comparator: "ge",
          //    Value: value1,
          //  },
          //  {
          //    Key: key,
          //    Comparator: "le",
          //    Value: value2,
          //  },
          //],
          OrderBy: "TimeStampDBInsertion",
        },
      ]),
    };
    const allFilterConfig = JSON.parse(
      (localStorage.getItem("allFilterConfig") as any) || null
    );
    if (allFilterConfig && Object.keys(allFilterConfig).length > 0) {
      const objectFilterList: any[] = [];
      if("ExternalID" in allFilterConfig && allFilterConfig["ExternalID"]["value"] !== ""){
        objectFilterList.push({
          Key: "ExternalID",
          Comparator: allFilterConfig["ExternalID"]["comparator"],
          Value: allFilterConfig["ExternalID"]["value"]
        })
      }
      if("Name" in allFilterConfig && allFilterConfig["Name"]["value"] !== ""){
        objectFilterList.push({
          Key: "Name",
          Comparator: allFilterConfig["Name"]["comparator"],
          Value: allFilterConfig["Name"]["value"]
        })
      }
      if("START_DATE" in allFilterConfig && allFilterConfig["START_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["START_DATE"]["comparator"],
          Value: allFilterConfig["START_DATE"]["value"]
        })
      }
      if("END_DATE" in allFilterConfig && allFilterConfig["END_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["END_DATE"]["comparator"],
          Value: allFilterConfig["END_DATE"]["value"]
        })
      }
      params["ObjectFilterList"] = JSON.stringify(objectFilterList)
    }
    try {
      return await fetchObject(params);
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const getObjectFilterRelationships = createAsyncThunk(
  "getObjectFilterRelationships",
  async (payload: any) => {
    const {
      parentObjectType,
      parentObjectExternalId,
      relatedMapsObjectName,
      filterObjectName,
      externalId,
    } = payload;

    // Request configuration
    const params = {
      ObjectType: parentObjectType,
      ObjectKeyValueMap: {
        ExternalID: parentObjectExternalId,
      },
      RelatedMaps: JSON.stringify([
        {
          RelatedObjectName: relatedMapsObjectName,
          Limit: 10,
          RelationshipFilterMaps: [
            {
              RelatedObjectName: filterObjectName,
              ObjectKeyValueMap: {
                ExternalID: externalId,
              },
            },
          ],
        },
      ]),
    };
    try {
      return await fetchObject(params);
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const suggestObjects = createAsyncThunk(
  "filterObject",
  async (payload: any) => {
    const { objectType } = payload; //, value } = payload;

    const params: any = {
      ObjectType: objectType,
      Limit: 10,
      //ObjectFilterList: JSON.stringify([
      //  {
      //    Key: "ExternalID",
      //    Comparator: "sw",
      //    Value: value,
      //  },
      //]),
    };
    const allFilterConfig = JSON.parse(
      (localStorage.getItem("allFilterConfig") as any) || null
    );
    if (allFilterConfig && Object.keys(allFilterConfig).length > 0) {
      const objectFilterList: any[] = [];
      if("ExternalID" in allFilterConfig && allFilterConfig["ExternalID"]["value"] !== ""){
        objectFilterList.push({
          Key: "ExternalID",
          Comparator: allFilterConfig["ExternalID"]["comparator"],
          Value: allFilterConfig["ExternalID"]["value"]
        })
      }
      if("Name" in allFilterConfig && allFilterConfig["Name"]["value"] !== ""){
        objectFilterList.push({
          Key: "Name",
          Comparator: allFilterConfig["Name"]["comparator"],
          Value: allFilterConfig["Name"]["value"]
        })
      }
      if("START_DATE" in allFilterConfig && allFilterConfig["START_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["START_DATE"]["comparator"],
          Value: allFilterConfig["START_DATE"]["value"]
        })
      }
      if("END_DATE" in allFilterConfig && allFilterConfig["END_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["END_DATE"]["comparator"],
          Value: allFilterConfig["END_DATE"]["value"]
        })
      }
      params["ObjectFilterList"] = JSON.stringify(objectFilterList)
    }
    let time = performance.now();
    try {
      return await Api({
        url: "sync/object",
        method: "GET",
        params: params,
      })
        .then((response) => {
          logRequestDuration(time);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

const filterObjectsSlice = createSlice({
  name: "filterObjects",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    resetFilterObjects: (state) => {
      state.filteredObjects = {};
    },
    clearErrorMessage: (state) => {
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder.addCase(suggestObjects.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(suggestObjects.fulfilled, (state, action) => {
      state.loading = false;
      state.suggestedObjects = action.payload?.Objects;
      state.error = "";
    });
    builder.addCase(suggestObjects.rejected, (state) => {
      state.loading = false;
      state.suggestedObjects = [];
      state.error = "Couldn't suggest objects";
    });
    builder.addCase(getObjectFilterRelationships.pending, (state, action) => {
      state.loading = true;
      state.filteredObjects = [];
      state.error = "";
    });
    builder.addCase(getObjectFilterRelationships.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      if (action.payload?.Objects.length !== 0) {
        state.filteredObjects = formatRelatedObjectsAndAddToCurrentGraph(
          action.payload?.Objects?.[0]
        );
      } else {
        alert("No data is returned for this filter");
      }
    });
    builder.addCase(getObjectFilterRelationships.rejected, (state, action) => {
      state.loading = false;
      state.error =
        "Sorry couldn't find object with the specified configuration options";
      state.filteredObjects = action.payload;
    });
    builder.addCase(filterObjectByDate.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });

    builder.addCase(
      filterObjectByDate.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        if (payload?.Objects[0]?.hasOwnProperty("RelatedObjects")) {
          state.filteredObjects = formatRelatedObjectsAndAddToCurrentGraph(
            payload?.Objects?.[0]
          );
        } else {
          state.error = "Sorry, no data available for the filtered object.";
        }
      }
    );
    builder.addCase(filterObjectByDate.rejected, (state, action) => {
      state.loading = false;
      state.error = "Object not found";
      state.filteredObjects = action.payload;
    });
    builder.addCase(filterObjectByDateRange.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.filteredObjects = [];
    });

    builder.addCase(filterObjectByDateRange.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.filteredObjectRange = formatFilteredRelatedObjects(
        action.payload?.Objects?.[0]
      );
    });
    builder.addCase(filterObjectByDateRange.rejected, (state, action) => {
      state.error =
        "Sorry couldn't find object with the specified configuration options";
      state.filteredObjects = action.payload;
      state.loading = false;
    });
  },
});

export const { setLoading, resetFilterObjects, clearErrorMessage } =
  filterObjectsSlice.actions;

export default filterObjectsSlice.reducer;

export const filterObjectSelector = (state: {
  filteredObject: FilterObjectState;
}) => state.filteredObject;
