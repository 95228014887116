import PageTitle from "../generics/Pagetitle";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ChangePassword from "./ChangePassword";
import Invites from "./invites/customTable/Invites";
import { fetchGetAllUsers } from "../../redux/users/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EditProfile from "./EditProfile";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#3A6A78 !important",
    },
  },
  typography: {
    fontFamily: `"Open Sans"`,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="pl-[32px]">{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const dispatch = useAppDispatch();
  // STORE STATE
  const { page } = useAppSelector((state: any) => state.users);
  const [value, setValue] = React.useState(0);

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <div className="settings-container pl-[32px] pr-[82px]">
      <div className="relative top-[78px]">
        <PageTitle title={"Settings"} />
        <div className="relative mt-[32px] ">
          <Box sx={{ width: "100%" }}>
            <ThemeProvider theme={theme}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className="ml-[32px]  !h-[36px]"
                  textColor="secondary"
                  style={{ height: "32px" }}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="!w-[170px] !h-[36px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                    label="Edit Profile"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="!w-[170px] !h-[36px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                    label="Change Password"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="!w-[170px] !h-[36px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                    label="Invites"
                    onClick={() => {
                      dispatch(fetchGetAllUsers(page));
                    }}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <EditProfile />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ChangePassword />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Invites />
              </TabPanel>
            </ThemeProvider>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Settings;
