import { GraphState } from "../../types/graphDataTypes";

export const initialState: GraphState = {
  storeGraph: {
    nodes: [],
    edges: [],
  },
  transferTableData: {},
  previousGraph: { nodes: [], edges: [] },
  selectedNode: {},
  relatedObjects: { nodes: [], edges: [] },
  nodesGroupKeys: {},
  targetNode: {},
  rollUps: {},
  selectedObjectType: "",
  loading: true,
  error: null,
  disableCheckboxes: true,
  parentNodeId: "",
  parentNode: "",
  isParentNode: false,
  isShowButton: false,
  isSelectedEdge: false,
  isFilterable: false,
  isOpenNotification: false,
  notification: "",
  isOpenRightSidebar: false,
  isOpenNavbar: true,
  selectedEdgeRelations: {},
  isOpenCanvas: false,
  selectedEdgeId: "",
  graphCrumbs: [],
  isExpandedBreadcrumb: false,
  relationships: [],
  allRelatedObjects: [],
  selectedRelatedMapsInState: [],
  isShowRelationships: false,
  isShowAllCheckboxRelations: false,
  legendFlag: true,
  isTrackingError: false,
  diveDepthCount: 0,
};
