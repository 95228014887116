import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../services/api";
import {
  getBackwardsObjectConfigurations,
  getRequestPath,
} from "../../services/trackingConfigurations";
import { BackwardObjectTrackingState } from "../../types/ObjectTrackingTypes";

const initialState: BackwardObjectTrackingState = {
  backwardsTrackingObjects: {
    machines: [],
    articles: [],
    packages: [],
    products: [],
    productionOrders: [],
  },
  loading: false,
  error: "",
};

export const getBackwardsTrackingObjects = createAsyncThunk(
  "getBackwardsTrackingObjects",
  async (payload: any, { rejectWithValue }) => {
    const { type, externalId } = payload;

    const requestConfig: any = getBackwardsObjectConfigurations(type);
    const path: any = getRequestPath(type);
    requestConfig?.ObjectKeyValueMap?.ExternalID.push(externalId);

    try {
      const response: any = await Api({
        url: `${path}`,
        method: "GET",
        params: requestConfig,
      });

      return { type, data: response.data.Result };
    } catch (error) {
      console.log(error);

      return rejectWithValue({ type, errorMessage: "Unable to fetch data" });
    }
  }
);

const backwardsTrackingObjectsSlice = createSlice({
  name: "forwardsTrackingObjects",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBackwardsTrackingObjects.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      switch (action.meta.arg.type) {
        case "Article":
          state.backwardsTrackingObjects.articles = [];
          break;
        case "Machine":
          state.backwardsTrackingObjects.machines = [];
          break;
        case "Package":
          state.backwardsTrackingObjects.packages = [];
          break;
        case "Product":
          state.backwardsTrackingObjects.products = [];
          break;
        case "ProductionOrder":
          state.backwardsTrackingObjects.productionOrders = [];
          break;
      }
    });
    builder.addCase(
      getBackwardsTrackingObjects.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;

        switch (action.payload.type) {
          case "Article":
            state.backwardsTrackingObjects.articles = action.payload.data;
            break;
          case "Machine":
            state.backwardsTrackingObjects.machines = action.payload.data;
            break;
          case "Package":
            state.backwardsTrackingObjects.packages = action.payload.data;
            break;
          case "Product":
            state.backwardsTrackingObjects.products = action.payload.data;
            break;
          case "ProductionOrder":
            state.backwardsTrackingObjects.productionOrders =
              action.payload.data;
            break;
        }
      }
    );
    builder.addCase(
      getBackwardsTrackingObjects.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload?.errorMessage;

        switch (action.payload?.type) {
          case "Article":
            state.backwardsTrackingObjects.articles = [];
            break;
          case "Machine":
            state.backwardsTrackingObjects.machines = [];
            break;
          case "Package":
            state.backwardsTrackingObjects.packages = [];
            break;
          case "Product":
            state.backwardsTrackingObjects.products = [];
            break;
          case "ProductionOrder":
            state.backwardsTrackingObjects.productionOrders = [];
            break;
        }
      }
    );
  },
});

export default backwardsTrackingObjectsSlice.reducer;

export const backwardsTrackingObjectSelector = (state: {
  backwardsTrackingObjects: BackwardObjectTrackingState;
}) => state.backwardsTrackingObjects;
