export const packagesBackwardTrackingPathMaps = [
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProductInPackage"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ],
        BeginRelatedObjectMap: {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProduct"
        }
    },
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltOutOfProductInPackage"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ],
        BeginRelatedObjectMap: {
            ObjectType: "Product",
            RelatedObjectName: "BuiltOutOfProductInPackage"
        }
    },
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ]
    }
]