import React, { useCallback, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { fetchEitherForwardOrBackwardTracks } from "../../redux/graph/actions";
import { useAppDispatch } from "../../redux/hooks";
import { headerTypes } from "../../types/products";
import CustomButton from "../generics/CustomButton";
import CustomTable from "../table/CustomTable";
import TableBody from "../table/TableBody";
import TableHeader from "../table/TableHeader";
import TableRow from "../table/TableRow";
import TrackingTableCheckBox from "./TrackingTableCheckBox";

interface TrackingDataTypes {
  tableDetails: {
    label: string;
    data: any;
    pathLength: number;
  };
  parentData: {
    externalId: string;
    objectType: string;
  };
  tracking: string;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}
const TrackingTable = ({
  tableDetails,
  parentData,
  tracking,
  setValue,
}: TrackingDataTypes) => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  const [pageNumber, setPageNumber] = useState(0);

  const objectsPerPage = 8;
  const objectsVisited = pageNumber * objectsPerPage;
  const pageCount = Math.round(tableDetails.pathLength / 8);

  const showGraph = useCallback(() => {
    const parameters = {
      tracking: tracking,
      type: parentData?.objectType,
      ExternalID: parentData?.externalId,
      buttonType: tableDetails.label,
    };
    dispatch(fetchEitherForwardOrBackwardTracks(parameters));
    const routData = JSON.stringify({parameters, graphDispatcher: "fetchEitherForwardOrBackwardTracks" })
    navigate("/graph", {state: routData})
  }, [dispatch, navigate, parentData?.externalId, parentData?.objectType, tableDetails.label, tracking]);

  const changePage = useCallback(({ selected }: { selected: number }) => {
    setPageNumber(selected);
  }, []);

  return (
    <div className="generalInfo-table first-of-type:mt-2 backwards-tracking-details relatedObjects-table-bgColor mt-10">
      <CustomTable>
        <caption className=" py-[10px] text-[16px] text-[#3A6A78] font-[600] text-start pl-[24px]  border-b-[0.5px]">
          <div className="flex items-center justify-between px-4">
            <label>{tableDetails?.label}</label>
            <CustomButton
              text="View in Graph"
              action={showGraph}
            ></CustomButton>
          </div>
        </caption>

        <TableHeader
          pageType={headerTypes.trackingDetailsOverview}
          showSortIcon={false}
          tableHeader={["Name", "PathLength"]}
        />
        <TableBody>
          {tableDetails?.data &&
            tableDetails?.data
              ?.slice(objectsVisited, objectsVisited + objectsPerPage)
              .map((path: any, index: number) => {
                return (
                  <TableRow key={path.RelatedObject.Name}>
                    <>
                      <th></th>
                      <td className="relative  table-cell-Style">
                        <div className="flex gap-2 items-center ">
                          <TrackingTableCheckBox path={path} />

                          <p className="text-[#667085] text-[16px]">
                            {path.RelatedObject.Name}
                          </p>
                        </div>
                      </td>
                      <td className="relative  table-cell-Style">
                        <p className="text-[#667085] text-[16px]">
                          {Math.ceil(path.Path?.length / 2)}
                        </p>
                      </td>
                    </>
                  </TableRow>
                );
              })}
        </TableBody>
      </CustomTable>
      {tableDetails?.pathLength > 10 && (
        <div className="border-t-2 border-[#f8f7f7] w-full block pt-4 pr-3">
          <ReactPaginate
            previousLabel={
              <span>
                <img
                  className="inline"
                  src="/assets/PreviousIcon.svg"
                  alt="next icon label"
                />
              </span>
            }
            nextLabel={
              <span>
                <img
                  className="inline"
                  src="/assets/NextIcon.svg"
                  alt="next icon label"
                />
              </span>
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={`pagination`}
            previousLinkClassName={`previousButton`}
            nextLinkClassName={`nextButton`}
            disabledClassName={`pagination-disabled`}
            activeClassName={`activePagination`}
            activeLinkClassName={`activePage`}
            forcePage={0}
          />
        </div>
      )}
    </div>
  );
};

export default TrackingTable;
