import { useLayoutEffect, useState } from "react";
import Api from "../../../services/api";
import MultiSelectField from "../../generics/selectFields/MultiSelectField";

const LineGraphFilter1 = ({
  measureNames, 
  setConfig,
  objectInfo
}: {
  measureNames: Array<string>;
  setConfig: (pre: any) => any;
  objectInfo: any;
}) => {
  const [parametersConfig, setParametersConfig] = useState<{
    loading: boolean;
    parameters: Array<string>;
    error: boolean;
  }>({
    loading: false,
    parameters: [],
    error: false,
  });

  // Fetches the needed parameter values for the Multi-select dropdown
  useLayoutEffect(() => {

    // Replace this fetch with the attributes from the visited Machine
    // Fetches the needed parameter values for the Multi-select dropdown
    const getSpecificProductFromMachine = async (objectType: string) => {

      // SETS THE PARAMETERS LOADING TO TRUE
      setParametersConfig({
        ...parametersConfig,
        loading: true,
        error: false,
      });  
      try {
        switch (objectType) {
          case "Machine":
            const paramsMachine = {
              ObjectType: "Machine",
              ObjectKeyValueMap: JSON.stringify({
                "ExternalID": objectInfo[0]?.ExternalID
              }),
              ReturnRollUps: true,
              Limit: 1,
            };
            const resMachine = await Api({
              url: "/object",
              method: "GET",
              params: paramsMachine,
            });
            if (resMachine.status === 200 && resMachine?.data?.Objects?.length > 0) {
              const object = resMachine?.data?.Objects[0];
              const rollup = object.RollUps?.ProductionOrderProduct.Object    
              let paramValues: Array<string> = [];
              Object.entries(rollup).forEach(
                ([key, val]: [key: string, val: any]) => {
                  const valMap = new Map(Object.entries(val));
                  if (valMap.has("Avg")) {
                    paramValues.push(key);
                  }
                }
              );
              // SETS THE PARAMETERS OF THE OBJECT TYPES AND LOADING STATE
              setParametersConfig({
                ...parametersConfig,
                parameters: paramValues,
                loading: false,
              });

              setConfig((prev: any) => {
                return { ...prev, MeasureNames: paramValues };
              });
              
            }
            break; 
          case "Product":
            // Gets the Machine ExternalID from the RelatedObjects
            let paramValues: Array<string> = [];
              Object.entries(objectInfo[0]).forEach(
                ([key, val]: [key: string, val: any]) => {
                  if(typeof val == "number"){
                    paramValues.push(key);
                  }
                }
              );
              // SETS THE PARAMETERS OF THE OBJECT TYPES AND LOADING STATE
              setParametersConfig({
                ...parametersConfig,
                parameters: paramValues,
                loading: false,
              });

              setConfig((prev: any) => {
                return { ...prev, MeasureNames: paramValues };
              });

            break;
          case "Package":
            const paramsPackage = {
              ObjectType: "Package",
              ObjectKeyValueMap: JSON.stringify({
                "ExternalID": objectInfo[0]?.ExternalID
              }),
              ReturnRollUps: true,
              Limit: 1,
            };
            const resPackage = await Api({
              url: "/object",
              method: "GET",
              params: paramsPackage,
            });
            if (resPackage.status === 200 && resPackage?.data?.Objects?.length > 0) {
              const object = resPackage?.data?.Objects[0];
              const rollup = object.RollUps?.Product.Object    
              let paramValues: Array<string> = [];
              Object.entries(rollup).forEach(
                ([key, val]: [key: string, val: any]) => {
                  const valMap = new Map(Object.entries(val));
                  if (valMap.has("Avg")) {
                    paramValues.push(key);
                  }
                }
              );
              // SETS THE PARAMETERS OF THE OBJECT TYPES AND LOADING STATE
              setParametersConfig({
                ...parametersConfig,
                parameters: paramValues,
                loading: false,
              });

              setConfig((prev: any) => {
                return { ...prev, MeasureNames: paramValues };
              });
              
            }
            break;
          case "ProductionOrder":
            const paramsProductionOrder = {
              ObjectType: "ProductionOrder",
              ObjectKeyValueMap: JSON.stringify({
                "ExternalID": objectInfo[0]?.ExternalID
              }),
              ReturnRollUps: true,
              Limit: 1,
            };
            const resProductionOrder = await Api({
              url: "/object",
              method: "GET",
              params: paramsProductionOrder,
            });
            if (resProductionOrder.status === 200 && resProductionOrder?.data?.Objects?.length > 0) {
              const object = resProductionOrder?.data?.Objects[0];
              const rollup = object.RollUps?.TargetPackageProduct.Object    
              let paramValues: Array<string> = [];
              Object.entries(rollup).forEach(
                ([key, val]: [key: string, val: any]) => {
                  const valMap = new Map(Object.entries(val));
                  if (valMap.has("Avg")) {
                    paramValues.push(key);
                  }
                }
              );
              // SETS THE PARAMETERS OF THE OBJECT TYPES AND LOADING STATE
              setParametersConfig({
                ...parametersConfig,
                parameters: paramValues,
                loading: false,
              });

              setConfig((prev: any) => {
                return { ...prev, MeasureNames: paramValues };
              });
              
            }
            break;
        }
      } catch (err) {
        console.log(err);
        setParametersConfig({
          error: true,
          parameters: [],
          loading: false,
        });
      }
    };

    getSpecificProductFromMachine(objectInfo?.[0]?.ObjectType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMultiSelectFieldValueToParent = (values: any) => {
    setConfig((prev: any) => {
      return { ...prev, SelectedMeasureNames: [values] };
    });
  };

  return (
    <div className="mx-[32px] space-y-4">
      <div className=" flex  items-center justify-between">
        <div className="parameters-container flex gap-4 lg:pr-4">
          <div className="w-[200px]">
            <MultiSelectField
              availableParameters={parametersConfig}
              setValuesToParent={setMultiSelectFieldValueToParent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineGraphFilter1;
