import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAllUsers, getFilteredUser } from "../../components/generics/utils/api/users/usersApiCalls"


// async actions: get all users request
export const fetchGetAllUsers: any = createAsyncThunk<any, any, any>(
  "graph/fetchGetAllUsers",
  async (skip, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resData = await getAllUsers(skip)
      if (resData === "Network Error") {
        return rejectWithValue(
          "Network Error: Please Check Internet Connection!"
        )
      }
   
      if (resData === "Request failed with status code 400") {
        return rejectWithValue("Request failed with status code 400!")
      }

      if (resData.length === 0) {
        return rejectWithValue("No Users Available!")
      }
      return fulfillWithValue(resData)
    } catch (error) {
      return rejectWithValue("This is the error: " + error)
    }
  }
)

// async actions: get filtered users
export const fetchFilteredUsers: any = createAsyncThunk<any, any, any>(
  "graph/fetchFilteredUsers",
  async (username, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resData = await getFilteredUser(username)
      if (resData === "Network Error") {
        return rejectWithValue(
          "Network Error: Please Check Internet Connection!"
        )
      }
   
      if (resData === "Request failed with status code 400") {
        return rejectWithValue("Request failed with status code 400!")
      }

      if (resData.length === 0) {
        return rejectWithValue("No Matching User Available!")
      }
      return fulfillWithValue(resData)
    } catch (error) {
      return rejectWithValue("This is the error: " + error)
    }
  }
)
