import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import _ from "lodash";
import { setLegendFlag } from "../../../../redux/graph/graphSlice";
import { useLocation } from "react-router-dom";
import GraphLegendList from "./GraphLegendList";
import { sliceLegendGroupName } from "../../../generics/utilities/utils";

type Props = {
  isOpenRightSidebar: boolean;
};

const GraphLegend: React.FC<Props> = ({ isOpenRightSidebar }) => {
  const { relatedObjects, legendFlag } = useAppSelector(
    (state: any) => state.graphReducer
  );
  const { isShowRelationships } = useAppSelector(
    (state: any) => state.graphReducer
  );
  const dispatch = useAppDispatch();
  const parentElement = relatedObjects?.nodes?.slice(0, 1)[0];
  const uniqRelationships = _.uniqBy(relatedObjects?.nodes, "group");
  const lastElementIndex = uniqRelationships?.length - 1;
  const location = useLocation();
  const hasTrackingPath = location.pathname.includes("tracking");

  const showLegend = () => {
    return (
      <div className="legend-Items-container w-full">
        {!legendFlag && (
          <div
            className=" -top-5 z-[0.5]  -right-20 absolute w-[100vw] h-[100vh] "
            onClick={() => dispatch(setLegendFlag(true))}
          ></div>
        )}
        <div className="relative w-full !z-50 ">
          {/*<p
            tabIndex={0}
            className={`cursor-pointer m-1 w-full ${!legendFlag ? "mt-2" : ""}`}
            onClick={() => {
              UpdateSetLegend();
              const elem = document.querySelector(".info-img");
              elem?.classList?.add("rotate");
            }}
          >
            <span className="text-accent-grayMuted text-sm">Info </span>
            <img
              className={`info-img inline ml-[14px] ${
                legendFlag ? "rotate-[0deg]" : "rotate-[180deg]"
              }`}
              src="/assets/legendToggleIcon.svg"
              alt="Legend Toggle Icon"
            />
          </p>
            */}
          <ul
            tabIndex={0}
            className={`dropdown-content ${
              legendFlag ? "hidden" : "visible"
            } relative menu p-2 mt-8 ${
              isOpenRightSidebar && "!left-[-110px]"
            } shadow bg-base-100 rounded-[8px] w-52  z-50`}
          >
            {!isShowRelationships ? (
              <li className="flex flex-row items-center  ">
                <i
                  className={`${parentElement?.group} !w-[8px] !h-[8px] p-0 inline`}
                ></i>
                <p className="inline p-0 ml-2 text-accent-grayDark hover:bg-[white] text-[16px]">
                  {sliceLegendGroupName(parentElement)}
                </p>
              </li>
            ) : (
              uniqRelationships &&
              uniqRelationships?.map((relationship: any, index: number) => {
                return (
                  <GraphLegendList
                    key={relationship.group}
                    relationship={relationship}
                    index={index}
                    lastElementIndex={lastElementIndex}
                  />
                );
              })
            )}
          </ul>
        </div>
      </div>
    );
  };

  return hasTrackingPath ? null : showLegend();
};

export default GraphLegend;
