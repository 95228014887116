import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import { setIsOpenNavdebar } from '../../redux/graph/graphSlice'

const MobileNav = () => {
      const dispatch = useDispatch()
      // STORE STATE
    const { isOpenNavbar } = useSelector((state: any) => state.graphReducer)
    

  return (
    <div className="w-[100%] h-[64px] bg-white flex justify-between items-center md:hidden">
      <Link to="/">
        <img
          src="/assets/Yobibytes_logo.svg"
          className={`cursor-pointer ml-10`}
          alt=""
          onClick={() => dispatch(setIsOpenNavdebar(false))}
          width="52"
        />
      </Link>
      <img
        src="/assets/leftSidebar/modile_burger.svg"
        className={`cursor-pointer mr-5`}
        onClick={() => dispatch(setIsOpenNavdebar(!isOpenNavbar))}
        alt=""
      />
    </div>
  )
}

export default MobileNav