import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { objectSelector } from "../../redux/objects/objectSlice";
import ObjectOverview from "./ObjectTable/ObjectOverview";

const OverviewContainer = () => {
  const { objects } = useAppSelector(objectSelector);

  const [currentActiveObject, setCurrentActiveObject] = useState("");
  const location = useLocation();

  // Stores the current active products value
  const path = location.pathname.split("/");
  const activeProduct = path[path.length-1]

  

  // Finds the objectType and sets the currentActiveObject state with the gotten value
  useEffect(() => {
    const findObjectType = (object: string) => {
      let listOfObjects = [];
      for (const key in objects?.Config) {
        listOfObjects.push(key);
      }
      let actualObjectType: any = listOfObjects?.filter(
        (obj: string) =>
          obj?.toString()?.toLowerCase() === object?.toString().toLowerCase()
      );

      return actualObjectType?.[0];
    };

    const objectTypePath = location.pathname.split("/");

    setCurrentActiveObject(
      findObjectType(objectTypePath[objectTypePath.length - 1])
    );

    localStorage.setItem(
      "activeProduct",
      JSON.stringify(findObjectType(activeProduct) || null)
    );
  }, [objects, activeProduct, location.pathname, currentActiveObject]);

  return (
    <div>
      {currentActiveObject ? (
        <ObjectOverview activeProduct={activeProduct} />
      ) : (
        <div className="h-[70vh] flex items-center justify-center">
          <h2>Please wait....</h2>
        </div>
      )}
    </div>
  );
};

export default OverviewContainer;
