import React from "react";
import PredefinedViews from "../../objectdetails/detailsRelatedObjectSwitch/PredefinedViews";

const GeneralCompHeader = () => {
  return (
    <div className="flex items-center justify-between">
      <h4 className="text-[18px] font-[600] mt-[20px]">General Information</h4>
      <div className="graph-navigation-container flex  space-x-4">
        <PredefinedViews />
      </div>
    </div>
  );
};

export default GeneralCompHeader;
