import { apiUrl } from "../../../../../public/config";
import Api from "../../../../../services/api";

// ==================================================
export const configUrl = apiUrl + "/config";
export const objectsUrl = apiUrl + "/object";

// error handling
export const errorHandler = (err: any) => {
  return err.message;
};

const formatRelationshipNameForRequest = (relationships: any) => {
  return relationships?.map((key: string) => {
    // Formats config relatonship name
    return {
      RelatedObjectName: key,
      Limit: 5,
    };
  });
};

// fetch configs and objects
export const fetchConfigAndObjectData = async (
  objectType: string,
  ExternalID: string,
  limit = 10
) => {
  try {
    //get the config
    const res = await Api.get(configUrl);

    //format the objects relatedMaps Config
    const configRelatedMaps =
      res.data.Config &&
      formatRelationshipNameForRequest(
        Object.keys(res.data.Config[objectType].Relationships)
      );

    //go get the object
    const objectRes = await fetchNodeData(
      objectType,
      ExternalID,
      configRelatedMaps,
      limit
    );
    //go get the rollUps objects
    const rollUpData = await fetchNewRollUpsData(objectType, ExternalID);
    return { rollUpData, objectRes };
  } catch (err) {
    errorHandler(err);
  }
};

// fetch configs and objects
export const fetchSelectedConfigAndObjectData = async (
  objectType: string,
  ExternalID: string,
  relatedObjects: any,
  limit = 10
) => {
  try {
    //format the objects relatedMaps Config
    const configRelatedMapsValue =
      formatRelationshipNameForRequest(relatedObjects);

    //go get the object
    const objectRes = await fetchNodeData(
      objectType,
      ExternalID,
      configRelatedMapsValue,
      limit
    );

    //go get the rollUps objects
    const rollUpData = await fetchNewRollUpsData(objectType, ExternalID);
    return { rollUpData, objectRes };
  } catch (err) {
    errorHandler(err);
  }
};

// fetch objects base on configs
export const fetchNodeData = async (
  objectType: string,
  ExternalID: string,
  objectMapConfig: any,
  limit = 10
) => {
  const stringifiedObjectMapConfig = JSON.stringify(objectMapConfig);
  try {
    let time = performance.now();
    const requestParamsForward = {
      ObjectType: objectType,
      Limit: limit,
      ObjectKeyValueMap: {
        ExternalID: ExternalID,
      },
      RelatedMaps: stringifiedObjectMapConfig,
      IgnoreDirection:true
    };
    const resdata = await Api({
      url: objectsUrl,
      method: "get",
      params: requestParamsForward,
    });

    return resdata.data.Objects;
  } catch (err: any) {
    return errorHandler(err);
  }
};

//fetch the data of the Rollups
export const fetchNewRollUpsData = async (
  objectType: string,
  ExternalID: string
) => {
  try {
    let time = performance.now();
    const newRollupsRequestParamsForward = {
      ObjectType: objectType,
      ObjectKeyValueMap: {
        ExternalID: ExternalID,
      },
      ReturnRollUps: true,
      Limit: 1,
    };
    const newRollupResData = await Api({
      url: objectsUrl,
      method: "get",
      params: newRollupsRequestParamsForward,
    });

    return newRollupResData?.data?.Objects;
  } catch (err: any) {
    return errorHandler(err);
  }
};

// fetch object and relationship filters
export const fetchNodeFilterData = async (configParams: any) => {
  let relatedMaps;
  if (configParams.isAddRelationshipFilter) {
    relatedMaps = [
      {
        RelatedObjectName: configParams.relatedObjectName1,
        ObjectFilterList: configParams.objectFilterList1,
        OnlyActive: configParams.onlyActive1,
        RelationshipFilterMaps: [
          {
            RelatedObjectName: configParams.relatedObjectName2,
            ObjectFilterList: configParams.objectFilterList2,
            OnlyActive: configParams.onlyActive2,
          },
        ],
        Limit: configParams.limit,
      },
    ];
  } else {
    relatedMaps = [
      {
        RelatedObjectName: configParams.relatedObjectName1,
        ObjectFilterList: configParams.objectFilterList1,
        OnlyActive: configParams.onlyActive1,
        Limit: configParams.limit,
      },
    ];
  }
  const stringifiedObjectMapConfig = JSON.stringify(relatedMaps);
  try {
    let time = performance.now();
    const requestParamsForward = {
      ObjectType: configParams.objectType,
      ObjectKeyValueMap: {
        ExternalID: configParams.externalID,
      },
      RelatedMaps: stringifiedObjectMapConfig,
    };
    const resdata = await Api({
      url: objectsUrl,
      method: "get",
      params: requestParamsForward,
    });

    return resdata.data.Objects;
  } catch (err: any) {
    return errorHandler(err);
  }
};
