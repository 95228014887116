import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import MultilineChart from "./GraphViews/MultilineChart";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  lineGraphSelector,
  setChartAnalysisRequestState,
} from "../../redux/lineGraph/lineGraphSlice";
import { ErrorPage } from "../graph/error/ErrorPage";
import Skeleton from "@mui/material/Skeleton";
import {
  checkObjectIsNest,
  formatLineGraph,
  formatNestLineGraph,
} from "../generics/utilities/lineGraphUtils";
import LineGraphFilter1 from "./components/LineGraphFilter1";
import Api from "../../services/api";
import { useLocation } from "react-router-dom";
import { objectDetailsSelector } from "../../redux/objects/objectDetailsSlice";

const GraphContainer1 = ({ lastElementRef }: { lastElementRef: any }) => {
  const { globalConfiguration, chartAnalysisRequestState } =
    useAppSelector(lineGraphSelector);
  const { objectInfo } = useAppSelector(objectDetailsSelector);

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [lineChartData, setLineChartData]: Array<any> = useState([]);
  const [machineId, setMachineId] = useState("");
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const timer = useRef<any>(null);

  const getObjectDimensionExternalId = useCallback(
    async (objectType: string) => {
      switch (objectType) {
        case "Machine":
          // Gets the ExternalID
          setMachineId(objectInfo[0]?.ExternalID);
          break;
        case "Product":
          // Gets the Machine ExternalID from the RelatedObjects
          setMachineId(
            objectInfo[0]?.RelatedObjects.Machine.Elements[0]["Object"]
              .ExternalID
          );

          break;
        case "Package":
          // Gets the first Machine ExternalID that relates to Package
          const configuration = {
            ObjectType: objectInfo?.[0]?.ObjectType,
            ObjectKeyValueMap: {
              ExternalID: objectInfo?.[0]?.ExternalID,
            },
            RelatedMaps: JSON.stringify([
              {
                RelatedObjectName: "ProductionOrderTarget",
                RelatedMaps: [
                  {
                    RelatedObjectName: "Machine",
                  },
                ],
              },
            ]),
          };

          try {
            const objectData = await Api({
              url: "object",
              method: "GET",
              params: configuration,
            });

            setMachineId(
              objectData.data.Objects[0]?.RelatedObjects[
                "ProductionOrderTarget"
              ].Elements[0].Object?.RelatedObjects["Machine"].Elements[0].Object
                .ExternalID
            );
            break;
          } catch (err) {
            console.log(err);
            break;
          }

        case "ProductionOrder":
          setMachineId(
            objectInfo[0]?.RelatedObjects["Machine"].Elements[0].ExternalID
          );
          break;
      }
    },
    [objectInfo]
  );

  // Holds the base configuration for the given object type.
  const [config, setConfig] = useState({
    ObjectType: "Product",
    DateTimeFrom: globalConfiguration.dateTimeFrom,
    DateTimeTo: globalConfiguration.dateTimeTo,
    Dimensions: {
      Machine: "blib",
    },
    GroupDimensions: [""],
    SelectedMeasureNames: [""],
    MeasureNames: [],
    Resolution: globalConfiguration.resolution,
  });

  useLayoutEffect(() => {
    // Updates the base configuration state
    setConfig((prev) => {
      return {
        ...prev,
        DateTimeFrom: globalConfiguration.dateTimeFrom,
        DateTimeTo: globalConfiguration.dateTimeTo,
        Resolution: globalConfiguration.resolution,
      };
    });
  }, [
    globalConfiguration.dateTimeFrom,
    globalConfiguration.dateTimeTo,
    globalConfiguration.resolution,
  ]);

  const formatDataForChart = useCallback(
    (data: any) => {
      let tempData: Array<any> = [];
      if (data) {
        // Check if the records has the Property nest and groups the values
        if (checkObjectIsNest(data)) {
          // Groups and formats the values for the line chart
          tempData = formatNestLineGraph(data);
        } else {
          // Formats the object for the  line chart
          tempData = formatLineGraph(data);
        }
        setLineChartData(tempData);

        // Scrolls to the bottom of the chart
        lastElementRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
    },
    [lastElementRef]
  );

  const processRequestResponse = useCallback(
    (res: any) => {
      if (
        res.status === 200 &&
        res.data &&
        Object?.keys(res.data.Records)?.length > 0
      ) {
        setLineChartData([]);
        formatDataForChart(res.data.Records);
        setLoading(false);
      } else {
        formatDataForChart({});
        setLineChartData([]);
        setError("No data available to plot graph");
        setLoading(false);
      }
      if (chartAnalysisRequestState.success === true) {
        dispatch(
          setChartAnalysisRequestState({
            loading: false,
            error: "",
            success: true,
          })
        );
      }
    },
    [chartAnalysisRequestState.success, dispatch, formatDataForChart]
  );

  // FETCHES THE LINE RECORDS FOR THE GRAPH
  useLayoutEffect(() => {
    let isCancelled = false;
    setLineChartData([]);

    const fetchLineChartRecords = async () => {
      let res: any = null;
      var dateTimeFrom = JSON.stringify(new Date(new Date(globalConfiguration.dateTimeFrom).toUTCString()).toISOString())
      var dateTimeTo = JSON.stringify(new Date(new Date(globalConfiguration.dateTimeTo).toUTCString()).toISOString())
      const graphParams = {
        ObjectType: config?.ObjectType,
        DateTimeFrom: dateTimeFrom,
        DateTimeTo: dateTimeTo,
        Dimensions: JSON.stringify({
          Machine: [machineId],
        }),
        GroupDimensions: JSON.stringify(config?.GroupDimensions),
        MeasureNames: JSON.stringify(config?.SelectedMeasureNames),
        Resolution: config?.Resolution,
      };
      if(config?.MeasureNames.length === 0 || config?.MeasureNames[0] === "" || config?.MeasureNames[0] === null){
        setError("No Time Series Available");
        setLoading(false);
      }
      else if(config?.SelectedMeasureNames.length > 0 && config?.SelectedMeasureNames[0] !== "" && config?.SelectedMeasureNames[0] !== null){
        setError("");
        setLoading(true);
        try {
          timer.current = Date.now();
          const triggerTime = timer.current;
          res = await Api({
            url: "/object/timeseries/record",
            method: "GET",
            params: graphParams,
          });
          Object.keys(res.data.Records)?.forEach((parameterName) => {
            for(let measure of res.data.Records[parameterName]){
              Object.keys(measure)?.forEach((key) => {
                if(key === "Time"){
                  if (!measure[key].endsWith("Z")){
                    measure[key] = measure[key] + "Z"
                  }
                  const convertedTime = new Date(new Date(measure[key]).toLocaleString("en-US")).toISOString();
                  measure[key] = convertedTime
                }
              })
            }
          })
          // Checks if the params date Configuration used is the right one and
          // continues with updating the state else it continues to the next line.
          if (timer.current === triggerTime) {
            processRequestResponse(res);
          }
        } catch (errMessage) {
          setError("Request failed");
          setLoading(false);

        }
      }
      else {
        setError("Please choose a parameter");
        setLoading(false);
      }
    };

    if (!isCancelled) {
      // Ensures that objectInfo is not empty and that machine dimensions has a value before making request to get timeseries.
      if (objectInfo[0] && config?.Dimensions?.Machine[0] !== null) {
        // SETS ERROR TO EMPTY BEFORE IT PROCEEDS WITH REQUESTS
        dispatch(
          setChartAnalysisRequestState({
            ...chartAnalysisRequestState,
            error: "",
          })
        );
        fetchLineChartRecords();
      } else {
        setLoading(true);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [
    chartAnalysisRequestState,
    config?.Dimensions?.Machine,
    config?.GroupDimensions,
    config?.MeasureNames,
    config?.SelectedMeasureNames,
    config?.ObjectType,
    config?.Resolution,
    dispatch,
    formatDataForChart,
    getObjectDimensionExternalId,
    globalConfiguration.dateTimeFrom,
    globalConfiguration.dateTimeTo,
    location?.state?.ObjectType,
    machineId,
    objectInfo,
    processRequestResponse,
  ]);
  

  // Calls the getObjectDimensionExternalId function to get the dimension external id
  useEffect(() => {
    if (loading && !machineId) {
      getObjectDimensionExternalId(objectInfo?.[0]?.ObjectType);
    }
  }, [getObjectDimensionExternalId, loading, machineId, objectInfo]);

  const showLineGraphData = () => {
    // Shows the loading screen
    if (loading || chartAnalysisRequestState.loading)
      return (
        <div className="mt-[35px]  rounded-[8px] h-full  border-[#EAECF0] bg-[#fff] shadow-[0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)]">
          <Skeleton className="!h-[60vh]" />
        </div>
      );

    // Shows the error page
    if ((error && !loading) || chartAnalysisRequestState.error)
      return (
        <div className="mt-10">
          <ErrorPage error={error || chartAnalysisRequestState.error} />
        </div>
      );

    // Renders the line chart when chart data is available
    if (!loading && lineChartData && lineChartData?.length > 0){
      return <MultilineChart data={lineChartData} />;
    }
  };

  return (
    <div className="border-[1px]  rounded-[8px] p-6">
      <LineGraphFilter1
        measureNames={config.MeasureNames}
        setConfig={setConfig}
        objectInfo={objectInfo}
      />

      <div className="graph-section-container mt-6">{showLineGraphData()}</div>
    </div>
  );
};

export default GraphContainer1;
