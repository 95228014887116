import Api from "../../../../../services/api";

export const configUrl =
  "https://api.digitaltwin.aftcloud.de/api/v1/config/objecttype";

// creating new objecttypes
export const createNewObjectType = async (objectDetails: any) => {
  return  Api({
    url: configUrl,
    method: "post",
    data: objectDetails,
  });
};

// updating config properties
export const updateConfigProps = async (
  objectType: string,
  label: string,
  newValue: string | boolean
) => {
  try {
   return await Api({
      url: configUrl,
      method: "patch",
      data: {
        ObjectType: objectType,
        Values: {
          [label]: newValue,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};
