import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { objectSelector } from "../../redux/objects/objectSlice";
import { displayTitleValueLabel } from "./utilities/utils";

type Props = {
  title?: string;
};
const PageTitle: React.FC<Props> = ({ title }) => {
  const { objects } = useAppSelector(objectSelector);

  let titleValue = title
    ? title?.split("")[0].toUpperCase() + title?.slice(1, title?.length)
    : null;


  return (
    <h3 className="text-[30px] font-normal antialiased text-accent-grayDark">
      {displayTitleValueLabel(titleValue, objects)}
    </h3>
  );
};

export default PageTitle;
