import React, { FC } from "react";
import Labels from "./Labels";

interface Props {
  relatedObject: any;
}

const Relationships: FC<Props> = ({ relatedObject }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left">
        <thead className="text-xs font-[400] text-accent-grayMuted capitalize bg-accent-tableBg">
          <tr>
            <th scope="col" className="px-6 py-3">
              Key
            </th>
            <th scope="col" className="px-6 py-3">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(relatedObject).map(
            ([subKey, subValue]: any, index: number) => {
              if (typeof subValue === "object" && subKey === "Labels") {
                return (
                  <tr>
                    <td className="px-6 py-4">{subKey}</td>
                    <td className="px-6 py-4">
                      <Labels label={subValue} />
                    </td>
                  </tr>
                );
              }
              if (typeof subValue === "object" || Array.isArray(subValue)) {
                return null;
              }
              return (
                <tr
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-accent-tableBg"
                  } hover:bg-accent-tableBg`}
                >
                  <td className="px-6 py-4">{subKey}</td>
                  <td className="px-6 py-4">{`${subValue}`}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Relationships;
