import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TrackingPathState {
  trackingPath: any;
}

const initialState: TrackingPathState = {
  trackingPath: {},
};

const trackingPathSlice = createSlice({
  name: "trackingPath",
  initialState,
  reducers: {
    setTrackingPath: (state, { payload }: PayloadAction<any>) => {
      state.trackingPath = payload as any;
    },
    resetTrackingPath: (state, { payload }: PayloadAction<any>) => {
      state.trackingPath = payload as any;
    },
  },
});

export const { setTrackingPath, resetTrackingPath } = trackingPathSlice.actions;

export default trackingPathSlice.reducer;

export const trackingPathSelector = (state: {
  trackingPath: TrackingPathState;
}) => state.trackingPath;
