import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Stack,
  styled,
  Icon,
} from "@mui/material";
import { useState, useEffect, useId, useCallback } from "react";
import AFTPalette from "../generics/aftpalette";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getObjects } from "../../redux/objects/objectSlice";
import { setIsOpenNavdebar } from "../../redux/graph/graphSlice";
import { SidebarPrimaryData, SidebarSecondaryData } from "./SidebarData";
import SubmenuItem from "./SubmenuItem";
import { loginSelector, setLogin } from "../../redux/login/loginSlice";
import { clearAuthToken } from "../../services/auth";
import { NavLink } from "react-router-dom";
import {
  activeObjectSelector,
  changeActivePage,
  setActiveObjectType,
  setClearInputFields,
} from "../../redux/activeObject/activeObject";
import {
  createSubNavigation,
  dispatchGetActiveObject,
} from "../generics/utilities/utils";
import {
  resetGlobalMenuItems,
  sideBarSelector,
} from "../../redux/sideBar/sidebarSlice";

const Sidebar = () => {
  // STORE STATE
  const { isOpenNavbar } = useAppSelector((state: any) => state.graphReducer);
  const { info } = useAppSelector(loginSelector);
  const { isClearInputFields } = useAppSelector(activeObjectSelector);
  const { resetOpenedSideBarNav } = useAppSelector(sideBarSelector);
  // LOCAL STATE
  var isGuest = false
  const roles = info?.Roles
  if(roles){
    for(let i=0; i<info?.Roles?.length;i++){
      if(info.Roles[i].Name === "Guest"){
        isGuest = true
      }
    }
  }
  if(isGuest){
    for(let i=0; i<SidebarPrimaryData.length;i++){
      if(SidebarPrimaryData[i].title === "Config"){
        SidebarPrimaryData.splice(i,1)
      }
    }
  }
  if(isGuest){
    for(let i=0; i<SidebarSecondaryData.length;i++){
      if(SidebarSecondaryData[i].title === "Settings"){
        SidebarSecondaryData.splice(i,1)
      }
    }
  }
  const [menuItems, setMenuItems] = useState<any>(SidebarPrimaryData);
  const [currentActiveNavigationTitle, setCurrentActiveNavigationTitle] =
    useState("");
  const ids = useId();
  const dispatch = useAppDispatch();
  const { objects }: { objects: any; loading: boolean } = useAppSelector(
    (state) => state.objects
  );
  // Fetches the available categories of objects for display
  useEffect(() => {
    dispatch(getObjects());
  }, [dispatch]);

  const UpadateShowMenuProperty = (menus: Array<any>) => {
    return menus.map((value: any, index: number) => {
      if (value.hasOwnProperty("showSubMenu")) {
        value.showSubMenu = false;
      }
      return value;
    });
  };

  useEffect(() => {
    // Handles the resetting of of the navigation and sets only the current navigation item to active
    if (resetOpenedSideBarNav) {
      const updatedVals = UpadateShowMenuProperty(menuItems);
      setMenuItems(updatedVals);
    }
  }, [menuItems, resetOpenedSideBarNav]);

  useEffect(() => {
    SidebarPrimaryData.forEach((value: any, index: number) => {
      let objectKeys = createSubNavigation(value, objects);
      SidebarPrimaryData[index].subnav = objectKeys;
      SidebarPrimaryData[index].showSubMenu = false;
    });
    setMenuItems(() => {
      return SidebarPrimaryData;
    });
  }, [objects]);

  const onMouseLeaveSidebar = () => {
    dispatch(setIsOpenNavdebar(false));
  };

  const onMouseEnterSidebar = () => {
    dispatch(setIsOpenNavdebar(true));
  };

  const logOut = useCallback(() => {
    localStorage.clear();
    dispatch(setLogin(false));
    clearAuthToken();
  }, [dispatch]);

  // Collapses and opens the sub navigation items
  const toggleSubNav = (activeNavigation: any) => {
    // Resets opened navigation
    dispatch(resetGlobalMenuItems(false));

    if (activeNavigation.title === currentActiveNavigationTitle) {
      // Toggles the navigation if the previous navigation is same with the active one
      const currentNavigationValue = {
        ...activeNavigation,
        showSubMenu: !activeNavigation.showSubMenu,
      };

      setMenuItems((prev: any) => {
        return prev.map((previousValue: any) => {
          if (previousValue.title === currentNavigationValue.title) {
            return currentNavigationValue;
          }
          return previousValue;
        });
      });
    } else {
      // Handles the resetting of of the navigation and sets only the current navigation item to active
      const newValues = UpadateShowMenuProperty(menuItems);
      setMenuItems(newValues);

      // SET THE ACTIVE NAVIGATION TO TRUE
      const newActiveNavigationValue = {
        ...activeNavigation,
        showSubMenu: !activeNavigation.showSubMenu,
      };
      setMenuItems((prev: any) => {
        return prev.map((previousValue: any) => {
          if (previousValue.title === newActiveNavigationValue.title) {
            return newActiveNavigationValue;
          }
          return previousValue;
        });
      });
    }
    // Updates the current navigation state which then becomes the previous navigation
    // when another another navigation is selected.
    setCurrentActiveNavigationTitle(activeNavigation.title);
  };

  const handleSubNavigationClick = useCallback(
    (e: any) => {
      localStorage.removeItem("allFilterConfig");

      const target = e.currentTarget;
      const path = target.dataset.path;

      dispatch(setActiveObjectType(path));
      dispatch(changeActivePage(true));

      // Dispatches an action to fetch the next object type details
      dispatchGetActiveObject();
      // clears the input field
      dispatch(setClearInputFields(!isClearInputFields));
      // Removes the filter items from the localstorage
      localStorage.removeItem("filterHeaders");
    },
    [dispatch, isClearInputFields]
  );

  // Returns style for the Parent Navigation active class links
  const checkActiveClass = useCallback((isActive: boolean, navItem: any) => {
    return isActive
      ? "text-primary-purpleDark bg-secondary-grayLight flex justify-between align-middle items-center text-[16px] py-1 pl-[4%] rounded-md "
      : "flex justify-between align-middle items-center text-accent-grayMuted text-[16px] py-1 pl-[4%] rounded-md";
  }, []);
  // Returns style for the Child Navigation  active class links
  const checkSubMenuActiveClass = useCallback(
    ({ isActive }: { isActive: boolean }) => {
      return isActive
        ? "text-primary-purpleDark bg-secondary-grayLight flex justify-between align-middle items-center text-[16px] py-1 pl-16 rounded-md navItems"
        : "flex justify-between align-middle items-center text-accent-grayMuted text-[16px] py-1 pl-16 rounded-md ";
    },
    []
  );

  // This function is responsible for displaying only the tracking sub Menu items
  const renderTrackingSubMenu = ({
    renderConfig,
    subitem,
  }: {
    subitem: any;
    renderConfig: {
      id: string;
      item: any;
      key: string;
      open: boolean;
    };
  }) => {
    // Returns only Product when rendering the tracking Sub menu Navigation
    if (subitem.title === "Product")
      return (
        <div key={subitem?.path}>
          {renderConfig?.open && (
            <p>
              <NavLink
                to={`${renderConfig.item?.path}`}
                onClick={handleSubNavigationClick}
                data-path={subitem?.path}
                className={checkSubMenuActiveClass}
              >
                {renderConfig?.open && subitem?.englishLabel}
              </NavLink>
            </p>
          )}
        </div>
      );
    return null;
  };
  // This function is responsible for displaying only the analysis sub Menu items
  const renderAnalysisSubMenu = ({
    renderConfig,
    subitem,
  }: {
    subitem: any;
    renderConfig: {
      id: string;
      item: any;
      key: string;
      open: boolean;
    };
  }) => {
    // Returns only Product when rendering the tracking Sub menu Navigation
    if (
      subitem.title === "Product" ||
      subitem.title === "Machine" ||
      subitem.title === "Package" ||
      subitem.title === "ProductionOrder"
    ) {
      let pathString: any = subitem.path;

      // Splits the sub navigation path
      pathString = pathString?.split("/");
      // Adds analysis to the path String for analysis starting from index 1 then shifting the rest of the elements in the array.
      pathString.splice(2, 0, "analysis");
      //  Joins the string back to construct a valid path string for the navigation to use
      pathString = pathString.join("/");

      return (
        <div key={subitem?.path}>
          {renderConfig?.open && (
            <p>
              <NavLink
                to={`${pathString}`}
                onClick={handleSubNavigationClick}
                data-path={subitem?.path}
                className={checkSubMenuActiveClass}
              >
                {renderConfig?.open && subitem?.englishLabel}
              </NavLink>
            </p>
          )}
        </div>
      );
    }
    return null;
  };
  const displaySubMenuItems = (renderConfig: {
    id: string;
    item: any;
    key: string;
    open: boolean;
  }) => {
    // Appends the  English labels to the subnavigation object types.
    const objectsWithConfigLabels = renderConfig?.item?.subnav?.map(
      (objectType: any) => {
        return {
          ...objectType,
          englishLabel:
            objects.Config[objectType?.title as keyof typeof objects.Config]
              ?.Labels?.Name.English,
        };
      }
    );

    return objectsWithConfigLabels?.map((subitem: any, index: number) => {
      if (renderConfig.item.title === "Tracking") {
        return renderTrackingSubMenu({ renderConfig, subitem });
      } else if (renderConfig.item.title === "Analysis") {
        return renderAnalysisSubMenu({ renderConfig, subitem });
      } else if (renderConfig.item.title === "Config") {
        return (
          <div key={subitem?.path}>
            {renderConfig?.open && (
              <p>
                <NavLink
                  to={`${subitem?.path}`}
                  onClick={handleSubNavigationClick}
                  data-path={subitem?.title}
                  className={checkSubMenuActiveClass}
                >
                  {renderConfig?.open &&
                    (subitem?.englishLabel
                      ? subitem?.englishLabel
                      : subitem.title)}
                </NavLink>
              </p>
            )}
          </div>
        );
      } else if (renderConfig.item.title === "Objects"){
        return (
          <div key={subitem?.path}>
            {renderConfig?.open && (
              <p>
                <NavLink
                  to={`${subitem?.path}`}
                  onClick={handleSubNavigationClick}
                  data-path={subitem?.path}
                  className={checkSubMenuActiveClass}
                >
                  {renderConfig?.open &&
                    (subitem?.englishLabel
                      ? subitem?.englishLabel
                      : subitem.title)}
                </NavLink>
              </p>
            )}
          </div>
        );
      }
      else {
        return (<></>)
      }
    });
  };

  const renderMenuNavItems = (renderConfig: {
    id: string;
    item: any;
    key: string;
    open: boolean;
  }) => {
    const StyledIcon = styled(Icon)({
      fontSize: "1.5rem",
      marginRight: `${isOpenNavbar ? "0.8rem" : "0rem"}`,
      marginLeft: `${isOpenNavbar ? "0.8rem" : "0.4rem"}`,
    });
    const defaultObject = renderConfig?.item?.subnav
      ? renderConfig?.item?.subnav?.[0]?.title
      : undefined;
    return (
      <div key={renderConfig?.id}>
        {/* Parent Menu Items starts here */}
        <div>
          <NavLink
            to={`${renderConfig.item?.path as string}`}
            onClick={(e) => {
              toggleSubNav(renderConfig.item);
              dispatch(
                setActiveObjectType(
                  `${renderConfig.item?.path}/${
                    defaultObject ? defaultObject : ""
                  }`
                )
              );
            }}
            className={({ isActive }: { isActive: boolean }): string => {
              return checkActiveClass(isActive, renderConfig);
            }}
          >
            <Stack
              direction="row"
              sx={{
                display: "flex",
                justifyItems: "flex-start",
                alignItems: "center",
              }}
            >
              <StyledIcon>{renderConfig.item?.icon}</StyledIcon>
              {renderConfig?.open && (renderConfig.item?.title as string)}
            </Stack>
            {renderConfig?.open && (
              <i>
                {renderConfig?.item.showSubMenu
                  ? renderConfig.item?.iconOpened
                  : renderConfig?.item?.iconClosed}
              </i>
            )}
          </NavLink>
        </div>

        {/* Parent Menu Item Ends here */}

        {/* Sub Menu Items starts here  */}
        {renderConfig?.item?.showSubMenu && (
          <div className="items-menu">{displaySubMenuItems(renderConfig)}</div>
        )}
        {/* Sub Menu Items ends here  */}
      </div>
    );
  };

  return (
    <div
      onMouseEnter={() => onMouseEnterSidebar()}
      onMouseLeave={() => onMouseLeaveSidebar()}
      className={` ${
        isOpenNavbar ? "w-[100vw] md:w-[312px]" : "hidden md:block md:w-[65px]"
      } bg-white h-screen   pt-8 relative bottom-0 top-0 duration-300 overflow-hidden`}
    >
      <div className={`hidden md:flex justify-between  p-3`}>
        <img
          src="/assets/Yobibytes_logo.svg"
          className={`cursor-pointer ml-10 ${!isOpenNavbar && "hidden"}`}
          alt=""
          width="52"
        />
        <img
          src="/assets/leftSidebar/handburger.svg"
          className={`${!isOpenNavbar && "ml-2"} cursor-pointer mr-5`}
          onClick={() => dispatch(setIsOpenNavdebar(!isOpenNavbar))}
          alt=""
        />
      </div>
      <div
        className={`${
          !isOpenNavbar && "md:mt-5 !overflow-hidden p-3"
        } h-[65%]  overflow-y-scroll -mt-8 md:mt-5 sidebar-scroll`}
      >
        {menuItems?.map((menu: any, index: number) => {
          return renderMenuNavItems({
            id: `item-${menu?.title}`,
            item: menu,
            key: index + ids,
            open: isOpenNavbar,
          });
        })}
      </div>
      <div className="w-full pr-[25px] p-3 absolute bottom-0  bg-white">
        {SidebarSecondaryData?.map((menu: any, index: number) => (
          <SubmenuItem
            id={`item-${menu.title}`}
            item={menu}
            key={menu.title + ids}
            open={isOpenNavbar}
          />
        ))}
        <Divider sx={{ my: 1 }} />
        {isOpenNavbar && (
          <List>
            <ListItem
              secondaryAction={
                <IconButton
                  className="!flex !justify-center"
                  edge="end"
                  aria-label="arrow left"
                  onClick={logOut}
                >
                  <img
                    src="/assets/leftSidebar/arrowleft.svg"
                    className={`cursor-pointer ml-2 mr-2 mt-[2px] mb-[2px] md:mr-0 `}
                    alt=""
                  />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#3A6A78" }}>
                  {info?.UserName?.[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color: AFTPalette.accent.grayLight,
                      fontSize: "14px",
                    }}
                  >
                    {info?.Name}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="h6"
                    sx={{
                      color: AFTPalette.accent.grayLight,
                      fontSize: "12px",
                    }}
                  >
                    {info?.Email}
                  </Typography>
                }
                className="!text-[40px] "
              />
            </ListItem>
          </List>
        )}
        {!isOpenNavbar && (
          <IconButton edge="start" aria-label="arrow left" onClick={logOut}>
            <img
              src="/assets/leftSidebar/arrowLeft.svg"
              className={`cursor-pointer ml-4 mb-3 md:mr-0  `}
              alt=""
            />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
