import React, { FC, useState } from "react";
import { VscClose } from "react-icons/vsc";
import {  useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Roles } from "../../../../types/rolesTypes";
import { sendInvitation } from "../../../../redux/roles/actions";
import { frontEndUrl } from "../../../../public/config";
import { validateEmail } from "../../../../services/validation";
import { fetchGetAllUsers } from "../../../../redux/users/actions";

interface Props {
  closeModal: () => void;
}

const AddUser: FC<Props> = ({ closeModal }) => {
  // STORE STATE
  const { roles } = useAppSelector((state: any) => state.roles);
  const { page } = useAppSelector((state: any) => state.users);

  // local state user
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    onSubmit: (values) => {
      if (!values.email) {
        setErrorMessage("Please provide an email!");
        return;
      } else {
        setErrorMessage("");
      }

      if (!validateEmail(values.email)) {
        setErrorMessage("Invalid email address!");
        return;
      } else {
        setErrorMessage("");
      }

      if (!values.role) {
        setErrorMessage("Please select a team!");
        return;
      } else {
        setErrorMessage("");
      }

      const inviteDetails = {
        email: values.email,
        roleName: values.role,
        registrationUrl: `${frontEndUrl}/register`,
      };

      //
      dispatch(
        sendInvitation(inviteDetails)
      );
      setErrorMessage("");
      setTimeout(() => {
        closeModal();
        dispatch(fetchGetAllUsers(page));
      }, 1000);
    }
  });

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <h6 className="text-lg text-accent-grayDark font-bold">
          Invite Members
        </h6>
        <VscClose
          onClick={closeModal}
          style={{ cursor: "pointer", color: "#667085" }}
          size={24}
        />
      </div>
      {/* ERROR MESSAGE */}
      {errorMessage && (
        <div className="error-container border-[1px] border-[#FDA29B] flex p-[16px] mt-[32px] rounded-[8px] bg-[#FFFBFA] ">
          <div>
            <img src="/assets/error_icon.svg" alt="" />
          </div>
          <p className="ml-[13px] text-[14px] text-[#B42318]">{errorMessage}</p>
        </div>
      )}
      <form onSubmit={formik.handleSubmit} id="sendInvite">
        <div className="w-full flex flex-col space-y-2 justify-center item-middle my-5">
          <input
            className="w-full text-[16px] text-accent-grayMuted  h-[44px] rounded-[8px] border border-accent-grayMuted300 focus:!outline-none"
            type="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            minLength={8}
          />
          <select
            className="w-full my-1 text-accent-grayMuted [&>:not(first-of-type)]:text-accent-grayLight border-accent-grayMuted300 rounded-lg outline-none"
            id="role"
            defaultValue={"DEFAULT"}
            {...formik.getFieldProps("role")}
          >
            <option value="DEFAULT" selected>
              Select Role
            </option>
            {roles &&
              roles?.map((role: Roles) => {
                return (
                  <option value={role.RoleName} key={role.RoleName}>
                    {role.RoleName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="space-x-3 w-full flex justify-between align-middle">
          <button
            onClick={closeModal}
            className="text-[#000] flex-1 border rounded-[8px] py-2 px-5"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-primary-purpleDark flex-1 justify-center items-center space-x-2 text-[#fff] rounded-[8px] py-2 px-5 font-normal"
          >
            Send Invites
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
