export const comparators = [
  { key: "Equals", value: "eq" },
  { key: "Not equals", value: "neq" },
  { key: "Less than", value: "lt" },
  { key: "Less or equal", value: "le" },
  { key: "Greater or equal", value: "ge" },
  { key: "Greater than", value: "gt" },
  { key: "Starts with", value: "sw" },
  { key: "Not starts with", value: "nsw" },
  { key: "Ends with", value: "ew" },
  { key: "Not ends with", value: "new" },
  { key: "Contains", value: "co" },
  { key: "Not contains", value: "nco" },
  { key: "Contained in", value: "ci" },
  { key: "Not contained in", value: "nci" },
]
export const configExternalID = [{ key: "ExternalID", value: "ExternalID" }]
export const configRelatedObject = [
  { key: "RelatedObjectName", value: "RelatedObject" },
]
export const configName = [{ key: "Name", value: "Name" }]
export const configDate = [
  { key: "Creation Date", value: "TimeStampDBInsertion" },
]
export const configOnlyActive = [
  { key: "Only Active Relationships", value: "isActive" },
]

export const timeSeries = [
  { key: "Custom", value: "Custom" },
  { key: "Past 1m", value: "1 m" },
  { key: "Past 5m", value: "5 m" },
  { key: "Past 15m", value: "15 m" },
  { key: "Past 1h", value: "1 h" },
  { key: "Past 3h", value: "3 h" },
  { key: "Past 6h", value: "6 h" },
  { key: "Past 12h", value: "12 h" },
  { key: "Past 24h", value: "24 h" },
  { key: "Past 2d", value: "2 d" },
  { key: "Past 7d", value: "7 d" },
  { key: "Past 30d", value: "30 d" },
]
