import moment from "moment";
import { FC} from "react";
import { formatDateForRequest } from "../../generics/utilities/utils";
import { timeSeries } from "../../generics/utils/relationshipsFilter";

type Props = {
  DateTimeTo?: string;
  setConfig?: (para: any) => any;
};

const TimeSeriesSelector: FC<Props> = ({ setConfig }) => {

  const handleDuration = (e: any) => {
    if(e.target.value !== "Custom"){
      const { number, measure } = handleTimeDestructure(e.target.value) as any;
      const timePast = moment(new Date()).subtract(number, measure).toDate();

      setConfig && setConfig(formatDateForRequest(timePast.toISOString()));
    }
  };

  const handleTimeDestructure = (time: string) => {
    const [numberOfMeasure, measure] = time.split(" ");
    switch (measure) {
      case "m":
        return { number: numberOfMeasure, measure: "minutes" };
      case "h":
        return { number: numberOfMeasure, measure: "hours" };
      case "d":
        return { number: numberOfMeasure, measure: "days" };
      default:
        return null;
    }
  };

  return (
    <div>
      <select
        name="series"
        id="series"
        defaultValue={"Custom"}
        onChange={handleDuration}
        className="text-accent-grayMuted [&>:not(first-of-type)]:text-accent-grayLight border-accent-grayMuted300 rounded-lg outline-none"
      >
        {timeSeries?.map(({ key, value }: { key: string; value: string }) => (
          <option
            value={value}
            key={key}
          >
            {key}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeSeriesSelector;
