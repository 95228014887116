import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setPreviousState } from "../../../../redux/breadCrumb/breadCrumbSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { objectDetailsSelector } from "../../../../redux/objects/objectDetailsSlice";
import { formatDateTime } from "../../../generics/utils/utils";

type Props = {
  relatedObjects: Array<any>;
  breadCrumbs: Array<string>;
  relationshipData: {
    relatedObjName: string;
    relationshipName: string;
  };
  updateNextRelationshipTotal?: any;
  onlyActiveSettings?: {
    onlyActiveFlag: boolean;
    currentObjectType: string;
  };
};

const ObjectDetails: React.FC<Props> = ({
  relatedObjects,
  relationshipData,
  onlyActiveSettings,
  updateNextRelationshipTotal,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { nextRelatedObjects, nextRelatedObjectsError } = useAppSelector(
    objectDetailsSelector
  );

  const [relatedObjectValues, setRelatedObjectsValue] =
    useState(relatedObjects);

  useEffect(() => {
    if (
      nextRelatedObjects?.Elements?.length > 0 &&
      relationshipData.relatedObjName === relationshipData.relationshipName
    ) {
      setRelatedObjectsValue(nextRelatedObjects?.Elements);
      updateNextRelationshipTotal(nextRelatedObjects?.QuantityObjectsTotal);
    } else if (
      nextRelatedObjects?.Elements === undefined &&
      relationshipData.relatedObjName === relationshipData.relationshipName &&
      nextRelatedObjectsError
    ) {
      setRelatedObjectsValue([]);
      updateNextRelationshipTotal(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextRelatedObjects?.Elements, nextRelatedObjectsError]);

  const showNextRelatedObject = (relatedObject: any) => {
    //   Get current item from the local storage and set it as previous Item.
    const previousItem = JSON.parse(localStorage.getItem("state") as any);

    // Set Item as previous
    dispatch(setPreviousState(previousItem));

    // Removes the breadCrump objectType from the storage
    navigate(`${relatedObject?.Object?.["Name"]}`, {
      state: relatedObject?.Object,
    });
  };

  return (
    <tbody>
      {relatedObjectValues.length > 0 ? (
        relatedObjectValues?.map((relatedObject, index: number) => {
          return (
            <tr
              key={relatedObject.Object?.["ExternalID"]}
              className="border-t-[0.1px]  general-text-color"
            >
              <th></th>
              <td
                onClick={() => {
                  showNextRelatedObject(relatedObject);
                }}
                className="link-class table-cell-Style"
              >
                {relatedObject.Object?.["Name"]}
              </td>
              <td className="table-cell-Style">
                {relatedObject.Object?.["ExternalID"]}
              </td>
              <td className="table-cell-Style">
                {typeof relatedObject?.Object?.["TimeStampCreation"] ===
                "undefined"
                  ? formatDateTime(
                      relatedObject?.Object?.["TimeStampDBInsertion"]
                    )
                  : formatDateTime(
                      relatedObject?.Object?.["TimeStampCreation"]
                    )}
              </td>
            </tr>
          );
        })
      ) : (
        <tr className="border-t-[0.1px]  general-text-color">
          <th></th>
          <td className=" pt-8"> </td>
          <td className=" pt-8 text-center"> {nextRelatedObjectsError}</td>
          <td className=" pt-8"></td>
        </tr>
      )}
    </tbody>
  );
};

export default ObjectDetails;
