import Alert, { AlertColor } from "@mui/material/Alert";

const AlertComponent = ({
  severity,
  message,
  closeDialog,
}: {
  severity: AlertColor;
  message: string;
  closeDialog: () => void;
}) => {
  return (
    <Alert
      className="flex  !border-2 !border-[#32D583] !rounded-[8px] !bg-[#F6FEF9]"
      variant="outlined"
      severity={severity}
    >
      <div className="flex  w-[280px] justify-between align-top ">
        <p className="break-words">{message}</p>
        <div className="">
          <img
            className="cursor-pointer"
            src="/assets/AlertCloseIcon.svg"
            alt="alert close icon"
            onClick={() => {
              closeDialog();
            }}
          />
        </div>
      </div>
    </Alert>
  );
};

export default AlertComponent;
