import React, { FC } from "react"
import { Field, ErrorMessage } from "formik"

type Props = {
  label?: string
  name: string
  formikObject: any
  width?: number
}

const Textarea: FC<Props> = ({ label, name, width, ...rest }) => {
  return (
    <div className="">
      {label && <label htmlFor={name}>{label}</label>}
      <Field
        id={name}
        name={name}
        as="textarea"
        {...rest}
        className={`w-[${width}]border-2 border-secondary-grayLighter text-xs rounded-md`}
      />
      <ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} />
    </div>
  )
}

export default Textarea
