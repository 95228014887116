import { FC, useEffect } from "react";
import { setIsOpenNotification, setNotification } from "../../../redux/graph/graphSlice";
import { useAppDispatch } from "../../../redux/hooks";

type Props = {
  message: string;
  enabler?: boolean;
};

const WarningNotification: FC<Props> = ({ message }) => {
  const dispatch = useAppDispatch();

  // reset notification value
  useEffect(() => {
    setTimeout(() => {
      dispatch(setIsOpenNotification(false));
      dispatch(setNotification(""));
    }, 15000);
  }, [dispatch]);


  return (
    <div className="z-[9999] border-[1px] border-[#FEC84B] flex justify-between items-center space-x-4 p-[16px] mt-[32px] rounded-[8px] bg-[#FFFCF5] ">
      <div>
        <img src="/assets/warning.svg" alt="" />
      </div>
      <p className="ml-[13px] text-[14px] text-[#B54708]">{message}</p>
      <div>
        <img src="/assets/close_notification.svg" alt="" />
      </div>
    </div>
  );
};

export default WarningNotification;
