import React, { FC } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

interface Props {
  indexes: any;
}

const Indexes: FC<Props> = ({ indexes }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left">
        <thead className="text-xs font-[400] text-accent-grayMuted capitalize bg-accent-tableBg">
          <tr>
            <th scope="col" className="px-6 py-3">
              Is Unique
            </th>
            <th scope="col" className="px-6 py-3">
              Property Name
            </th>
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {indexes && indexes.length > 0 ? indexes?.map(
            (prop: any, index: number) => {
              return (
                <tr
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-accent-tableBg"
                  } hover:bg-accent-tableBg`}
                >
                  <td className="px-6 py-4">{`${prop?.IsUnique}`}</td>
                  <td className="px-6 py-4">{prop?.PropertyName}</td>
                  <td
                    className="px-6 py-4"
                    key={index + new Date().getMilliseconds()}
                  >
                    <span className="flex justify-end items-center space-x-5">
                      <RiDeleteBinLine
                        data-type={"delete"}
                        color="#667085"
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                      <FiEdit2
                        data-type={"editUser"}
                        color="#667085"
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </td>
                </tr>
              );
            }
          ):  <tr
          className={`bg-white text-accent-grayMuted capitalize`}
        >
          <td colSpan={3}  className="text-center py-3">Indexes are not available</td>
        </tr>}
        </tbody>
      </table>
    </div>
  );
};

export default Indexes;
