import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loginSelector } from '../../redux/login/loginSlice';
import { apiUrl } from '../../public/config';
import { editUserInfos } from '../../redux/roles/actions';
import { useFormik } from 'formik';
import { hideAlert } from '../../redux/roles/rolesSlice';
import AlertComponent from '../generics/AlertComponent';

export const userUrl = apiUrl + "/user";

const EditProfile = () => {
  const { info } = useAppSelector(loginSelector);

  const [firstName, setFirstName] = useState(info.FirstName);
  const [lastName, setLastName] = useState(info.LastName);
  const [username, setUsername] = useState(info.UserName);
  const [email, setEmail] = useState(info.Email);
  const [originalUsername] = useState(info.UserName);

  const dispatch = useAppDispatch();
  const { error, showAlert } = useAppSelector((state: any) => state.roles);

  useEffect(() => {
    if (showAlert)
      setTimeout(() => {
        dispatch(hideAlert(false));
      }, 5000);
  }, [dispatch, showAlert]);

  const closeAlert = useCallback(() => {
    dispatch(hideAlert(false));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: "",
      role: "",
      stateUser: info
    },
    onSubmit: (values) => {
      //
      dispatch(
        editUserInfos({
            originalUsername: originalUsername,
            firstName: firstName,
            lastName: lastName,
            username: username,
            email: email,
            stateUser:info
        })
      );
    }
  });

  return (
    <div className="change-password-container w-full">
      <div className="absolute -top-20 right-0">
        {showAlert && (
          <AlertComponent
            severity="success"
            message={error}
            closeDialog={closeAlert}
          />
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="border-b-[1px]">
          <div className="w-[80%] pb-[20px] mt-[32px]">
            <div className="edit-profile-container">
              <div className=" pb-[20px] mt-[32px] flex justify-between ">
                <label className="block text-accent-grayLight">First Name</label>
                <div className="flex-wrap">
                  <div className="flex items-center  border-[1px] border-[#D0D5DD] shadow-sm rounded-[8px] w-[512px]">
                    <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted  h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-[1px]">
          <div className="w-[80%] pb-[20px] mt-[32px]">
            <div className="edit-profile-container">
              <div className=" pb-[20px] mt-[32px] flex justify-between ">
                <label className="block text-accent-grayLight">Last Name</label>
                <div className="flex-wrap">
                  <div className="flex items-center  border-[1px] border-[#D0D5DD] shadow-sm rounded-[8px] w-[512px]">
                    <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted  h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                      type="text"
                      id="firstName"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-[1px]">
          <div className="w-[80%] pb-[20px] mt-[32px]">
            <div className="edit-profile-container">
              <div className=" pb-[20px] mt-[32px] flex justify-between ">
                <label className="block text-accent-grayLight">Username</label>
                <div className="flex-wrap">
                  <div className="flex items-center  border-[1px] border-[#D0D5DD] shadow-sm rounded-[8px] w-[512px]">
                    <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted  h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                      type="text"
                      id="firstName"
                      value={username}
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-[1px]">
          <div className="w-[80%] pb-[20px] mt-[32px]">
            <div className="edit-profile-container">
              <div className=" pb-[20px] mt-[32px] flex justify-between ">
                <label className="block text-accent-grayLight">E-Mail</label>
                <div className="flex-wrap">
                  <div className="flex items-center  border-[1px] border-[#D0D5DD] shadow-sm rounded-[8px] w-[512px]">
                    <input
                    className="email-inputField w-full text-[16px] text-accent-grayMuted  h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                      type="text"
                      id="firstName"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-group text-[14px] flex justify-end mt-[20px]">
        <div className="space-x-3">
          <button className="bg-primary-purpleDark text-[#fff] w-[63px] h-[40px] rounded-[8px] font-normal" type="submit">
            Save
          </button>
        </div>
      </div>
      </form>  
    </div>     
  );
};

export default EditProfile;
