import React, { useEffect } from "react";
import { resetStore } from "../../redux/graph/graphSlice";
import { useAppDispatch } from "../../redux/hooks";

type Props = {};

const Dashboard: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetStore());
  }, [dispatch]);

  return (
    <div className="page-Item-Container h-[92vh] w-full relative bottom-[0px] rounded-t-[32px]">
      <div className="h-full w-[100%] flex flex-col justify-center items-center">
        <h3 className="text-[15px] md:text-[20px] font-[600] text-[#3A6A78] my-12 md:my-14">
          Welcome to the Yobibytes Bicycle Factory!
        </h3>
        <div>
          <img className="h-[200px]" src="/assets/Yobibytes_logo.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
