import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { MdKeyboardBackspace } from "react-icons/md"
import { Box, Tab, Tabs } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import TreeGraphWrapper from "./TreeGraphWrapper"
import { useAppDispatch } from "../../../redux/hooks"
import { fetchObjectTracks } from "../../../redux/graph/actions"

const theme = createTheme({
  palette: {
    secondary: {
      main: "#3A6A78 !important",
    },
  },
  typography: {
    fontFamily: `"Open Sans"`,
  },
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="pl-[32px]">{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const TreeView = () => {
  const dispatch: any = useAppDispatch()
  const location: any = useLocation()

  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // FETCHES INITIAL GRAPH
  useEffect(() => {
    // request parameters: dispatcher takes only one parameter
    const parameters = {
      type: location?.state?.objectType,
      ExternalID: location?.state?.externalID,
      buttonType: "Product"
    }
    dispatch(fetchObjectTracks(parameters))
  }, [dispatch, location?.state?.objectType, location?.state?.externalID])

  return (
    <div className="relative py-4 bg-[#ffffff] h-[100vh] overflow-hidden">
      <Box sx={{ width: "100%" }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <button
              className="border py-2 px-5 ml-4 rounded-md"
              onClick={() => navigate(-1)}
            >
              <MdKeyboardBackspace color="#3A6A78" />
            </button>
            <Tabs
              className="ml-[32px] !h-[40px]"
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="!w-[240px] !capitalize text-[14px] font-[400] text-accent-grayMuted"
                label={location?.state?.externalID}
                {...a11yProps(0)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TreeGraphWrapper />
          </TabPanel>
        </ThemeProvider>
      </Box>
    </div>
  )
}

export default TreeView
