export type Product = {
  "DMC Code": string;
  ExternalID: string;
  ID: string;
  Identifier: string;
  Name: string;
  Nest: string;
  ObjectType: string;
  "ST02 ESD IO/NIO": string;
  "ST02 Leitungstest IO/NIO": string;
  "ST03 Durchfluss IO/NIO": string;
  "ST03 Erg": number;
  "ST03 Staudruck IO/NIO": string;
  "ST03 Staudruck OGW": number;
  "ST03 Staudruck UGW": number;
  "ST04 Abbruch Hand": string;
  "ST04 Erg": number;
  "ST04 Grobleck IO/NIO": string;
  "ST04 Leckage IO/NIO": string;
  "ST04 Programm": number;
  "ST04 Pruefdruck IO/NIO": string;
  "ST05 Erg": number;
  "ST05 Programm": number;
  Teilenummer: number;
  TimeStampCreation: string;
  TimeStampDBInsertion: string;
  TimeStampDBLastUpdate: string;
  "Unique Code": string;
};

export interface RelationshipType {
  RelatedObjectName: string;
  OnlyActive: boolean;
}

export type TableHeaderViews = {
  objectOverview: string;
  objectDetailsOverview: string;
  trackingDetailsOverview: string;
};

export const headerTypes: TableHeaderViews = {
  objectOverview: "ObjectOverview",
  objectDetailsOverview: "DetailsOverview",
  trackingDetailsOverview: "TrackingOverview",
};

export interface OptionType {
  value: string;
  label: string;
  isDisabled: boolean;
}

export interface DateConfigType {
  startDate: {
    dateValue: string;
    comparatorType: string;
  };
  endDate: {
    dateValue: string;
    comparatorType: string;
  };
}
