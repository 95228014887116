import React, { FC } from 'react'
import { formatDateTime } from '../../../../generics/utils/utils'

type Props = {
    parameters: any
}

const ParameterRenderer: FC<Props> = ({parameters}) => {
  return (
    <div>
      {Object.keys(parameters)?.map((key) => {
        return (
          <div className="flex justify-start flex-row my-2" key={key}>
            <div className="text-[#667085] text-[12px] w-[40%] break-words mr-2 pr-2">
              {key}
            </div>
            <div className="text-[#667085] w-[60%] text-[12px]">
              <span className="break-all">
                {formatDateTime(parameters?.[key])}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ParameterRenderer