import React, { FC, useMemo } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { formatString } from "../../../../generics/utils/utils";


interface Props {
  allrelatedObjects: any;
  parentNode: any;
  objectConfig: any;
  handleAllCheckboxChange: (e: any, para: any) => void;
  handleSingleCheckboxChange: (e: any, para: any) => void;
}

const InitialRelationships: FC<Props> = ({
  allrelatedObjects,
  handleAllCheckboxChange,
  handleSingleCheckboxChange,
}) => {


  // calculate the totals of related nodes on the canvas
  const calculateTotalObject = useMemo(() => {
    let currentCount = 0;
    let quantityCount = 0;

    allrelatedObjects?.forEach((item: any) => {
      currentCount += item.QuantityObjectsCurrent;
      quantityCount += item.QuantityObjectsTotal;
    });
    return {
      quantityCurrentCount: currentCount,
      quantityTotalCount: quantityCount,
    };
  }, [allrelatedObjects]);

 
  //   GETS THE CURRENT OBJECTS TOTAL
  const getTotalObject = (objectName: string) => {
    let result = {
      quantityCurrentCount: 0,
      quantityTotalCount: 0,
    };
    allrelatedObjects.forEach((object: any) => {
      if (object.objectName === objectName) {
        result = {
          quantityCurrentCount: object.QuantityObjectsCurrent,
          quantityTotalCount: object.QuantityObjectsTotal,
        };
      }
    });

    return result;
  };

  return (
    <div className="flex w-full">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="!border-none"
      >
        {allrelatedObjects?.length > 0 && (
          <AccordionItem className="!border-none !w-[100%] !mr-0">
            <AccordionItemHeading>
              <AccordionItemButton className="!bg-white !p-0  flex justify-between items-center !py-2 text-[12px] font-bold">
                <div className={`bg-[#000000] h-2 w-2 mr-2 rounded-full`}></div>
                <div className="flex justify-between flex-1">
                  <span className="flex-[0.6]  text-[12px] text-primary-textNormal font-semibold">
                    Show All
                  </span>
                  <span className="flex-[0.3] text-[12px]  font-semibold text-primary-textNormal">
                    {calculateTotalObject &&
                      `${calculateTotalObject.quantityCurrentCount} / ${calculateTotalObject.quantityTotalCount}`}
                  </span>
                </div>
                <span
                  className="p-2 cursor-pointer"
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleAllCheckboxChange(e, "dive");
                  }}
                >
                  <input
                    type="checkbox"
                    id="allRelatedObjects"
                    className="allCheckbox cursor-pointer focus:ring-0 focus:ring-offset-0 focus:shadow-none text-[#3A6A78]  h-5 w-5 rounded-[100%] shadow"
                    name="selectAll"
                    onChange={(e) => handleAllCheckboxChange(e, "dive")}
                  />
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="!border-none pl-3 text-[12px]">
              {/* no component is rendered here */}
            </AccordionItemPanel>
          </AccordionItem>
        )}
        <div id="relatedObjects !w-[80%]">
          {allrelatedObjects &&
            allrelatedObjects?.length > 0 &&
            allrelatedObjects?.map((object: any) => {
              const objectTotal = getTotalObject(object?.objectName);
              return (
                <AccordionItem key={object?.objectName} className="!border-none ">
                  <AccordionItemHeading>
                    <AccordionItemButton className="!bg-white !p-0  flex justify-between items-center !py-2 text-[12px] font-bold!">
                      <div
                        className={`${object?.ObjectType} h-2 w-2 mr-2 rounded-full`}
                      ></div>
                      <div className="flex justify-between flex-1">
                        <span className="flex-[0.6] text-[12px]  font-semibold text-primary-textNormal">
                          {formatString(object?.objectName)}
                        </span>
                        <span className="flex-[0.3] text-[12px]  font-semibold text-primary-textNormal">
                          {objectTotal &&
                            `${objectTotal.quantityCurrentCount} / ${objectTotal.quantityTotalCount}`}
                        </span>
                      </div>
                      <span
                        className="p-2 cursor-pointer"
                        onClickCapture={(e) => {
                          e.stopPropagation();
                          handleSingleCheckboxChange(e, "dive");
                        }}
                      >
                        <input
                          type="checkbox"
                          className="relations cursor-pointer text-[#3A6A78] focus:ring-0 focus:ring-offset-0 focus:shadow-none h-5 w-5 rounded-[100%] shadow"
                          name={object.objectName}
                          value={[object.objectName]}
                          onChange={(e) =>
                            handleSingleCheckboxChange(e, "dive")
                          }
                        />
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="!border-none  px-3 text-[12px]">
                    {/* <Filters
                      parentNode={parentNode}
                      childNode={object?.Name}
                      objectConfig={objectConfig}
                    /> */}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </div>
      </Accordion>
    </div>
  );
};

export default InitialRelationships;
