// PathMaps Traverser

import _ from "lodash"
import { truncate } from "./utils"

// recursive function to traverse the object for all paths
const pathTraverser = (objt: any,  nodes: any = [], edges: any = []) => {
//the nodes[] and the edges[] are accumulators storing the result of each recursion    
    for (let key in objt?.RelatedObjects) {
      let value = objt?.RelatedObjects[key]
      for (const elementkey in value.Elements) {
        let element = value?.Elements[elementkey]
        for (const relationkey in element.Relationships) {
          let relation = element?.Relationships[relationkey]
          nodes.push({
            id: element?.Object?.ID,
            label:
              truncate(element?.Object?.ObjectType, 7) +
              "\n" +
              truncate(element?.Object?.Name, 8),
            group: element?.Object?.ObjectType,
            object_externalID: element?.Object?.ExternalID,
            uniqByExternalIDAndType:
              element?.Object?.ExternalID + element?.Object?.ObjectType,
            ...value,
            ...element,
            ...relation,
          })
          edges.push({
            id: relation?.ID,
            from: relation?.SourceObjectID,
            to: relation?.TargetObjectID,
            label: relation?.Label,
            font: { align: "middle" },
          })
          pathTraverser(element?.Object, nodes, edges)
        }
      }
    }

  return { nodes: _.uniqBy(nodes, "id"), edges }
}

//function the return the parent node and tree paths
export const formatPathMaps = (obj: any) => {
  const nodes: any[] = []
  const edges: any[] = []

  // if no data, return empty arrays
  if (!obj || obj.length === 0) return { nodes, edges }

    
// if we have some data format the parent object as the initial node
  nodes.push({
    id: obj.ID,
    label: truncate(obj.ObjectType, 7) + " \n " + truncate(obj.Name, 8),
    group: obj.ObjectType,
    object_externalID: obj.ExternalID,
    uniqByExternalIDAndType: obj.ExternalID + obj.ObjectType,
    ...obj,
  })

  edges.push({
    id: obj?.ID,
    from: obj?.ID,
    to: 2,
    label: obj?.ObjectType,
    font: { align: "middle" },
  })

  //check if obj has a property 'RelatedObjects' and traverse the down the path
    let resultData
  if (obj.hasOwnProperty("RelatedObjects")) {
     resultData = pathTraverser(obj)
     //add the initial node and the nodes from the recursion
    const newNodes = [...nodes, ...resultData.nodes]
    const newEdges = [...edges, ...resultData.edges]

    return { nodes: newNodes, edges: newEdges }
  } else {
    return { nodes: nodes, edges: edges }
  }
}
