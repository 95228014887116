import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { MultiLineDataPropTypes } from "../utils/propTypes";
import { formatPercent } from "../utils/commonUtils";

const Tooltip = ({
  xScale,
  yScale,
  width,
  height,
  data,
  margin,
  anchorEl,
  children,
  className,
  ...props
}: {
  xScale: any;
  yScale: any;
  width: any;
  height: any;
  data: any;
  margin: any;
  anchorEl: any;
  children?: any;
  className?: string;
}) => {
  const ref = React.useRef(null);
  const drawLine = React.useCallback(
    (x: any) => {
      d3.select(ref.current)
        .select(".tooltipLine")
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", -margin.top)
        .attr("y2", height);
    },
    [ref, height, margin]
  );

  const drawContent = React.useCallback(
    (x: any) => {
      const tooltipContent: any = d3
        .select(ref.current)
        .select(".tooltipContent");
      tooltipContent.attr("transform", (cur: any, i: number, nodes: any) => {
        const nodeWidth = nodes[i]?.getBoundingClientRect()?.width || 0;
        const translateX = nodeWidth + x > width ? x - nodeWidth - 12 : x + 8;
        return `translate(${translateX}, ${-margin.top})`;
      });
      tooltipContent
        .select(".contentTitle")
        .text(d3.timeFormat("%b %d, %Y")(xScale.invert(x)));
    },
    [xScale, margin, width]
  );

  const drawBackground = React.useCallback(() => {
    // reset background size to defaults
    const contentBackground = d3
      .select(ref.current)
      .select(".contentBackground");
    contentBackground.attr("width", 125).attr("height", 40);

    const tooltipContentElement: any = d3
      .select(ref.current)
      .select(".tooltipContent")
      .node();

    if (!tooltipContentElement) return;

    const contentSize = tooltipContentElement.getBoundingClientRect();
    contentBackground
      .attr("width", contentSize.width + 8)
      .attr("height", contentSize.height + 4);
  }, []);

  const onChangePosition = React.useCallback(
    (values: any, i: number, isVisible: boolean) => {
      let itemValue = values.value;

      d3.select(ref.current)
        .selectAll(".Value")
        .filter((td, tIndex) => {
          return tIndex === i;
        }).text(isVisible ? formatPercent(itemValue) : "");

      d3.select(ref.current)
        .selectAll(".relationshipValueAndTime")
        .filter((td, tIndex) => tIndex === i)
        .text(() => {
          if (values.Dimensions && !isVisible) {
            return "No data";
          } else {
            return ` Article: ${values.Dimensions.Article}, Machine: ${
              values.Dimensions.Machine
            },   ${new Date(values.date).toLocaleTimeString("en-US")} `;
          }
        });

      let nameWidth: number | string = 0,
        valueWidth: number | string = 0;

      // Gets the width of the elements which is used to calculate the gap of the elements to prevent overlapping.
      nameWidth = Math.round(
        d3.max(
          d3.select(ref.current).selectAll(".Name").nodes(),
          (node: any) => node.getBoundingClientRect().width
        )
      );

      valueWidth = Math.round(
        d3.max(
          d3.select(ref.current).selectAll(".Value").nodes(),
          (node: any) => node.getBoundingClientRect().width + 16
        )
      );

      d3.select(ref.current)
        .selectAll(".Value")
        .attr("transform", (datum, index, nodes: any) => {
          return `translate(${Number(nameWidth) +13},4)`;
        });

      d3.select(ref.current)
        .selectAll(".relationshipValueAndTime")
        .attr("transform", (datum, index, nodes: any) => {
          return `translate(${Number(valueWidth) + Number(nameWidth) + 28},4)`;
        });
    },
    []
  );

  const followPoints = React.useCallback(
    (e: any) => {
      const x = d3.pointers(e)[0][0];
      const xDate = xScale.invert(x);
      const bisectDate = d3.bisector((d: any) => d.date).left;
      let baseXPos = 0;

      // draw circles on line
      d3.select(ref.current)
        .selectAll(".tooltipLinePoint")
        .attr("transform", (cur, i) => {
          const index = bisectDate(data[i].items, xDate, 1);
          const d0: any = data[i]?.items[index - 1];
          const d1 = data[i]?.items[index];
          const d: any = xDate - d0?.date > d1?.date - xDate ? d1 : d0;
          if (d.date === undefined && d.value === undefined) {
            // move point out of container
            return "translate(-100,-100)";
          }
          const xPos = xScale(d.date);
          if (i === 0) {
            baseXPos = xPos;
          }

          let isVisible = true;
          if (xPos !== baseXPos) {
            isVisible = false;
          }
          const yPos = yScale(d.value);

          onChangePosition(d, i, isVisible);

          return isVisible
            ? `translate(${xPos}, ${yPos})`
            : "translate(-100,-100)";
        });

      drawLine(baseXPos);
      drawContent(baseXPos);
      drawBackground();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      anchorEl,
      drawLine,
      drawContent,
      drawBackground,
      xScale,
      yScale,
      data,
      onChangePosition,
    ]
  );

  React.useEffect(() => {
    // Shows the tooltip item
    d3.select(ref.current).attr("opacity", 1);

    // Attaches an event listener to the tooltip element
    d3.select(anchorEl)
      .on("mouseout.tooltip", () => {
        d3.select(ref.current).attr("opacity", 0);
      })
      .on("mouseover.tooltip", function (e) {
        d3.select(ref.current).attr("opacity", 1);
        followPoints(e);
      })
      .on("mousemove.tooltip", function (e) {
        d3.select(ref.current)
          .selectAll(".tooltipLinePoint")
          .attr("opacity", 1);
        followPoints(e);
      });
  }, [anchorEl, followPoints]);

  if (!data.length) return null;

  return (
    <g className={className} ref={ref} opacity={0} {...props}>
      <line className="tooltipLine" />
      <g className="tooltipContent">
        <rect className="contentBackground" rx={4} ry={4} opacity={0.8} />
        <text className="contentTitle" transform="translate(4,14)" />
        <g className="content" transform="translate(4,32)">
          {data.map((d: any, i: number) => {
            return (
              <>
                <g key={d.name} transform={`translate(6,${22 * i})`}>
                  <circle r={6} fill={d.color} />
                  <text className="Name" transform="translate(10,4)">
                    {`${d.name}  :`}
                  </text>
                  <text
                    className="Value stroke-[0.4] stroke-white"
                    opacity={0.8}
                    fontSize={10}
                  />
                  <text className="relationshipValueAndTime" />
                </g>
              </>
            );
          })}
        </g>
      </g>
      {data.map((d: any) => (
        <circle className="tooltipLinePoint" r={6} key={d.name} opacity={0} />
      ))}
    </g>
  );
};

Tooltip.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  data: MultiLineDataPropTypes,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
};

Tooltip.defaultProps = {
  width: 0,
  height: 0,
  margin: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  data: [],
  anchorEl: null,
};

export default Tooltip;
