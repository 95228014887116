import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../services/api";
import { getAuthToken } from "../../services/auth";

export interface ObjectState {
  objects: any;
  loading: boolean;
  error: string;
}

const initialState: ObjectState = {
  objects: [],
  loading: false,
  error: "",
};

export const getObjects = createAsyncThunk("products/getObjects", async () => {
  const token = getAuthToken();
  try {
    const res = await Api({
      url: "config",
      method: "GET",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error: any) {
    throw new Error();
  }
});

const objectSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setObjectTypes: (state, { payload }: PayloadAction<any>) => {
      state.objects = payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getObjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getObjects.fulfilled, (state, action) => {
        state.loading = false;
        state.objects = action.payload;
      })
      .addCase(getObjects.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setObjectTypes, setErrors } = objectSlice.actions;

export default objectSlice.reducer;

export const objectSelector = (state: { objects: ObjectState }) =>
  state.objects;
