const styleNode = (values, id, selected, hovering) => {
  if (hovering) {
    values.color = "#BFC8D6";
    values.borderColor = "red";
  }
  if (selected) {
    values.borderColor = "black";
    values.color = "#fcfcfc";
  }
};

export const hierarchicalOptions = {
  interaction: {
    hover: true,
    hoverConnectedEdges: true,
  },
  groups: {
    Article: {
      color: { background: "#F5B1A6", border: "#EE7D6B" },
    },
    Machine: {
      color: { background: "#A6D2AF", border: "#6BB47A" },
    },
    ProductionOrder: {
      color: { background: "#E4AFD7", border: "#D27ABC" },
    },
    CurrentlyExecutedProductionOrder: {
      color: { background: "#FEE8DB", border: "#FFD0B5" },
    },
    Product: {
      color: { background: "#A9B1FA", border: "#6F7DF6" },
    },
    MachineSpace: {
      color: { background: "#FADCA4", border: "#F7C568" },
    },
    MachineStorageSpace: {
      color: { background: "#C1ECFF", border: "#98DFFF" },
    },
    Package: {
      color: { background: "#ACDCEC", border: "#75C5E0" },
    },
    ResourceList: {
      color: { background: "#FCEDD1", border: "#FADCA4" },
    },
    ResourceListSource: {
      color: { background: "#E8EBEE", border: "#D0D5DD" },
    },
    ResourceListTarget: {
      color: { background: "#EEFEDA", border: "#E2FEBE" },
    },
    StorageSpace: {
      color: { background: "#CFD3FA", border: "#9299D3" },
    },
    Sensor: {
      color: { background: "#FAD9D3", border: "#F5B1A6" },
    },
    MachineGroup: {
      color: { background: "#FDC5E4", border: "#FF9FD3" },
    },
    SensorGroup: {
      color: { background: "#D7CAC7", border: "#927E7B" },
    },
    Reject: {
      color: { background: "#FDF6E8", border: "#FCEDD1" },
    },
  },
  nodes: {
    borderWidth: 2,
    borderWidthSelected: 1,
    color: {
      border: "#2B7CE9",
      background: "#97C2FC",
      highlight: {
        border: "#2B7CE9",
        background: "#D2E5FF",
      },
      hover: {
        border: "#2B7CE9",
        background: "#D2E5FF",
      },
    },
    shape: "circle",
    chosen: {
      node: styleNode,
    },
  },
  layout: {
    hierarchical: {
      enabled: true,
      parentCentralization: true,
      direction: "UD", // UD, DU, LR, RL
      sortMethod: "directed", // hubsize, directed
      // sortMethod: "directed", // hubsize, directed
      shakeTowards: "roots" // roots, leaves
    },
  },
  edges: {
    color: "tomato",
    label: "label",
  },
  height: "900px",
};

// freestyle options
export const freestyleOptions = {
  interaction: {
    hover: true,
    hoverConnectedEdges: true,
  },
  groups: {
    Article: {
      color: { background: "#F5B1A6", border: "#EE7D6B" },
    },
    Machine: {
      color: { background: "#A6D2AF", border: "#6BB47A" },
    },
    ProductionOrder: {
      color: { background: "#E4AFD7", border: "#D27ABC" },
    },
    CurrentlyExecutedProductionOrder: {
      color: { background: "#FEE8DB", border: "#FFD0B5" },
    },
    Product: {
      color: { background: "#A9B1FA", border: "#6F7DF6" },
    },
    MachineSpace: {
      color: { background: "#FADCA4", border: "#F7C568" },
    },
    MachineStorageSpace: {
      color: { background: "#C1ECFF", border: "#98DFFF" },
    },
    Package: {
      color: { background: "#ACDCEC", border: "#75C5E0" },
    },
    ResourceList: {
      color: { background: "#FCEDD1", border: "#FADCA4" },
    },
    ResourceListSource: {
      color: { background: "#E8EBEE", border: "#D0D5DD" },
    },
    ResourceListTarget: {
      color: { background: "#EEFEDA", border: "#E2FEBE" },
    },
    StorageSpace: {
      color: { background: "#CFD3FA", border: "#9299D3" },
    },
    Sensor: {
      color: { background: "#FAD9D3", border: "#F5B1A6" },
    },
    MachineGroup: {
      color: { background: "#FDC5E4", border: "#FF9FD3" },
    },
    SensorGroup: {
      color: { background: "#D7CAC7", border: "#927E7B" },
    },
    Reject: {
      color: { background: "#FDF6E8", border: "#FCEDD1" },
    },
  },
  nodes: {
    borderWidth: 2,
    shape: "circle",
    chosen: {
      node: styleNode,
    },
  },
  layout: {
    // hierarchical: false,
    hierarchical: {
      enabled: false,
      levelSeparation: 360,
      nodeSpacing: 100,
      treeSpacing: 900,
      blockShifting: false,
      edgeMinimization: false,
      parentCentralization: true,
      direction: "UD", // UD, DU, LR, RL
      sortMethod: "directed", // hubsize, directed
      shakeTowards: "roots", // roots, leaves
    },
  },
  edges: {
    color: "tomato",
    label: "label",
    physics: false,
  },
  height: "900px",
}

// Row Graph options
export const rowGraphOptions = {
  interaction: {
    hover: true,
    hoverConnectedEdges: true,
  },
  groups: {
    Article: {
      color: { background: "#F5B1A6", border: "#EE7D6B" },
    },
    Machine: {
      color: { background: "#A6D2AF", border: "#6BB47A" },
    },
    ProductionOrder: {
      color: { background: "#E4AFD7", border: "#D27ABC" },
    },
    CurrentlyExecutedProductionOrder: {
      color: { background: "#FEE8DB", border: "#FFD0B5" },
    },
    Product: {
      color: { background: "#A9B1FA", border: "#6F7DF6" },
    },
    MachineSpace: {
      color: { background: "#FADCA4", border: "#F7C568" },
    },
    MachineStorageSpace: {
      color: { background: "#C1ECFF", border: "#98DFFF" },
    },
    Package: {
      color: { background: "#ACDCEC", border: "#75C5E0" },
    },
    ResourceList: {
      color: { background: "#FCEDD1", border: "#FADCA4" },
    },
    ResourceListSource: {
      color: { background: "#E8EBEE", border: "#D0D5DD" },
    },
    ResourceListTarget: {
      color: { background: "#EEFEDA", border: "#E2FEBE" },
    },
    StorageSpace: {
      color: { background: "#CFD3FA", border: "#9299D3" },
    },
    Sensor: {
      color: { background: "#FAD9D3", border: "#F5B1A6" },
    },
    MachineGroup: {
      color: { background: "#FDC5E4", border: "#FF9FD3" },
    },
    SensorGroup: {
      color: { background: "#D7CAC7", border: "#927E7B" },
    },
    Reject: {
      color: { background: "#FDF6E8", border: "#FCEDD1" },
    },
  },
  nodes: {
    borderWidth: 2,
    shape: "circle",
    chosen: {
      node: (values, id, selected, hovering) => {
        if (hovering) {
          values.color = "#BFC8D6"
          values.borderColor = "red"
        }

        if (selected) {
          values.borderColor = "black"
          values.color = "#fcfcfc"
        }
      },
    },
  },
  layout: {
    // hierarchical: false,
    hierarchical: {
      enabled: true,
      levelSeparation: 360,
      nodeSpacing: 100,
      treeSpacing: 900,
      blockShifting: false,
      edgeMinimization: false,
      parentCentralization: true,
      direction: "LR", // UD, DU, LR, RL
      sortMethod: "directed", // hubsize, directed
      shakeTowards: "roots", // roots, leaves
    },
  },
  edges: {
    color: "tomato",
    label: "label",
    physics: false,
  },
  height: "200px",
}
