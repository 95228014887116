import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FiCalendar } from "react-icons/fi";
import {
  activeObjectSelector,
  changeActivePage,
  getActiveObject,
  getSearchedObjectByName,
} from "../../redux/activeObject/activeObject";
import { SxProps } from "@mui/system";
import {
  checkSingleAndReturnRelationship,
  formRelatedObjects,
  getRequestRelationship,
} from "./utilities/utils";
import { objectSelector } from "../../redux/objects/objectSlice";

type props = {
  type: string;
};
const DateSelectField: React.FC<props> = ({ type }) => {
  const [dateValue, setDateValue]: any = useState<Date | null>(null);
  const { activeObjectType } = useAppSelector(activeObjectSelector);
  const [fieldIsActive, setFieldIsActive] = useState(false);
  const { objects } = useAppSelector(objectSelector);
  const { isClearInputFields } = useAppSelector(activeObjectSelector);

  useEffect(() => {
    if (dateValue !== null) {
      clearDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObjectType, isClearInputFields]);

  const dispatch = useAppDispatch();
  const iconColor = "#344054  !important";
  const style = {
    width: "100%",
    "& label.Mui-focused": {
      color: "gray",
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "&.Mui-focused fieldset": {
        border: "solid 1px #3A6A78",
      },
      "&.MuiOutlinedInput-root MuiInputBase-root:hover": {
        border: "solid 1px #3A6A78",
      },
    },

    "& .MuiCalendarPicker-root": {
      color: "red",
    },

    svg: {
      color: "#c4c4c4",
    },
  };
  const popperSx: SxProps = {
    "& .MuiPaper-root": {
      border: "0px solid black",
      padding: 2,
      marginTop: 1,
      color: iconColor,
      backgroundColor: "rgba(255, 255, 255, 255)",
    },
    "& .MuiCalendarPicker-root": {
      // color: "red",
      // backgroundColor: "rgba(45, 85, 255, 0.4)",
    },
    "& .PrivatePickersSlideTransition-root": {},
    "& .MuiPickersDay-dayWithMargin": {
      color: "#344054",
      // backgroundColor: "rgba(50, 136, 153)",
    },
    "& .MuiPickersDay-dayWithMargin.Mui-selected": {
      color: "white",
      backgroundColor: iconColor,
    },

    "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" },
    ".root": {
      zIndex: 20,
    },
    "& .MuiClockPicker-root": {
      color: "rgba(255, 255, 255, 0.2)",

      "& .css-1t8wyba-MuiButtonBase-root-MuiIconButton-root": {
        backgroundColor: iconColor,
      },
      "& .css-fhpqww": {
        "& .css-eziifo": {
          // border: "solid 1px #3A6A78",
          "& .css-7lip4c": {
            backgroundColor: iconColor,
            "& .css-118whkv": {
              backgroundColor: "#344054",
              border: "16px solid #344054",
            },
            "& .css-2ujp1m": {
              backgroundColor: "#3A6A78",
              border: "16px solid #344054",
              color: "white",
            },
          },
          "& .css-a1rc6s": {
            backgroundColor: iconColor,
            "& .css-118whkv": {
              backgroundColor: "#344054",
              border: "16px solid #344054",
            },
            "& .css-2ujp1m": {
              backgroundColor: "#3A6A78",
              border: "16px solid #344054",
              color: "white",
            },
          },
        },
      },
    },
  };


  let limit = 10;

  useEffect(() => {
    // Validates that the date value has a valid value before making filtering objects by the value the user gives.
    const currentObjectType = JSON.parse(
      localStorage.getItem("activeProduct") as string
    );
    // Returns the single Relationships of the objectType
    const singleTrueRelationships = checkSingleAndReturnRelationship(
      objects,
      `${currentObjectType}`
    );
    // Formats the relationships returned from checkSingleAndReturnRelationship
    const relatedObjects = formRelatedObjects(objects, currentObjectType);
    // RETURNS ONLY THE NEEDED RELATED OBJECTS FOR THE CONFIG
    const relatedObjectsConfig = getRequestRelationship(
      relatedObjects,
      singleTrueRelationships
    );
    
    const allFilterConfig = JSON.parse(
      (localStorage.getItem("allFilterConfig") as any) || null
    );
    setFilterToLocalStorage(allFilterConfig, type, "", "");

    if (
      dateValue !== null &&
      Object.prototype.toString.call(dateValue) === "[object Date]"
    ) {
      if (!isNaN(dateValue.getTime())) {
        const date = new Date(dateValue).toISOString();
        const dateConfig = JSON.parse(
          (localStorage.getItem("dateFilterConfig") as any) || null
        );
        
        const key = "TimeStampDBInsertion";
        var comparator = "ge";
        if(type === "END_DATE"){
          comparator = "le"
        }
        const value = date;

        // Sets the date value to the local storage
        setDateConfigtoLocalStorage(dateConfig, value);

        setFilterToLocalStorage(allFilterConfig, type, value, comparator);

        dispatch(
          getSearchedObjectByName({
            objectType: currentObjectType,
            value,
            key,
            comparator,
            relatedObject: relatedObjectsConfig,
            limit: limit,
            isOverview: true,
          })
        );
        // Updates the pagination active page and the this resets the pagination active to start from the first element.
        dispatch(changeActivePage(true));
      }
    } else {
      // Removes the filter config from the local storage

      if (dateValue === null && fieldIsActive) {
        localStorage.removeItem("filterConfig");
        dispatch(
          getActiveObject({
            objectType: currentObjectType,
            relatedObject: relatedObjects,
            limit: limit,
          })
        );
      }
    }

    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, dispatch, fieldIsActive, limit, objects, type]);

  const clearDate = () => {
    setDateValue(null);
  };

  const setFilterToLocalStorage = (config: any, filterKey: string, value: string, comparator: string) => {
    localStorage.setItem(
      "allFilterConfig",
      JSON.stringify(
        config
          ? {
              ...config,
              [filterKey]: {
                value: value,
                comparator: comparator,
              },
            }
          : {
            [filterKey]: {
                value: value,
                comparator: comparator,
              },
            }
      )
    );
  };

  const setDateConfigtoLocalStorage = (dateConfig: any, value: string) => {
    if (type === "START_DATE") {
      localStorage.setItem(
        "dateFilterConfig",
        JSON.stringify(
          dateConfig
            ? {
                ...dateConfig,
                startDate: {
                  dateValue: value,
                  comparatorType: "gt",
                },
              }
            : {
                startDate: {
                  dateValue: value,
                  comparatorType: "gt",
                },
              }
        )
      );
    } else {
      localStorage.setItem(
        "dateFilterConfig",
        JSON.stringify(
          dateConfig
            ? {
                ...dateConfig,
                endDate: {
                  dateValue: value,
                  comparatorType: "le",
                },
              }
            : {
                endDate: {
                  dateValue: value,
                  comparatorType: "le",
                },
              }
        )
      );
    }
  };

  const showPlaceHolder = () => {
    if (type === "START_DATE") return "Start Date";
    if (type === "END_DATE") return "End Date";

    return "Select Date";
  };

  const onDateChange = useCallback((newDate: any) => {
    setFieldIsActive(true);
    if (newDate || newDate === null) {
      setDateValue(newDate);
    }
  }, []);

  const renderInputComponent = useCallback(
    (params: any) => {
      return (
        <TextField
          className=" !outline-none"
          sx={style}
          InputLabelProps={{ shrink: false }}
          size="small"
          hiddenLabel
          {...params}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="date-container">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          components={{ OpenPickerIcon: FiCalendar }}
          PopperProps={{
            sx: popperSx,
          }}
          label={dateValue === null && showPlaceHolder()}
          ampm={true}
          inputFormat={"yy-MM-dd HH:mm:ss a "}
          maxDate={new Date()}
          renderInput={renderInputComponent}
          value={dateValue || null}
          onChange={onDateChange}
        ></DateTimePicker>
      </LocalizationProvider>
    </div>
  );
};

export default DateSelectField;
