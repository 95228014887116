import { apiUrl } from "../../../../../public/config"
import Api from "../../../../../services/api"
import { errorHandler } from "../objects/objectsApiCalls"

export const allUsersUrl = apiUrl + "/user"

// fetch objects base on configs
export const getAllUsers = async (skip = 0) => {
  const resbody = {
    //Limit: 7,
    Skip: skip,
    ReturnRoles: true
  }
  try {
    const resdata = await Api({
      url: allUsersUrl,
      method: "get",
      params: resbody,
    })
    
    for (let i = 0; i < resdata.data.Users.length; i++) {
      const roleNames = []
      if("Roles" in resdata.data.Users[i]){
        for (let j = 0; j < resdata.data.Users[i].Roles?.length; j++) {
          roleNames.push(resdata.data.Users[i].Roles[j]["Name"])
        }
      }
      resdata.data.Users[i].RolesString = roleNames.join(', ');
      resdata.data.Users[i].RegisteredActive = resdata.data.Users[i].IsRegistered.toString() + " / " + resdata.data.Users[i].IsActive.toString()
    }

    return {users: resdata.data.Users, currentUserCount: resdata.data.QuantityUsersCurrent, totalUserCount: resdata.data.QuantityUsersTotal}
  } catch (err: any) {
    console.log(err)
    return errorHandler(err)
  }
}

// user filter
export const getFilteredUser = async (username: string) => {
  const resbody = {
    UserName: username
  }
  try {
    const resdata = await Api({
      url: allUsersUrl,
      method: "get",
      params: resbody,
    })

    return {users: resdata.data.Users, currentUserCount: resdata.data.QuantityUsersCurrent, totalUserCount: resdata.data.QuantityUsersTotal}
  } catch (err: any) {
    return errorHandler(err)
  }
}
