import Button from "@mui/material/Button";
import { useCallback, useMemo } from "react";

const CustomButton = (props: {
  text: string;
  action: (btnText: string) => void;
  width?: string;
}) => {
  const propsData = useMemo(() => {
    return props;
  }, [props]);

  const handleButtonClick = useCallback(() => {
    propsData.action(props.text);
  }, [props.text, propsData]);

  const renderButtonWidth = () => {
    if (props.width) return `lg:!max-w-[${props.width}]`;
    return `lg:!max-w-[170px]`;
  };

  return (
    <Button
      className={`!capitalize button-yobi !rounded-[8px] ${renderButtonWidth()}  lg:max-h-[40px]`}
      variant="contained"
      disableElevation
      onClick={handleButtonClick}
    >
      {props.text}
    </Button>
  );
};

export default CustomButton;
