import React, { FC } from 'react'

interface Props {
    title: string
    type: string
    eventHander: ()=>void
}

const ActionButton:FC<Props> = ({title, type, eventHander}) => {
  return (
    <button onClick={eventHander} className={`${type !== "delete" ? "bg-[#F5F5FF] text-[#3A6A78]" : "bg-[#FEF3F2] text-[#B42318]"} px-3 py-2 rounded-md`}>{title}</button>
  )
}

export default ActionButton