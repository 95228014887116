import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { objectDetailsSelector } from "../../../redux/objects/objectDetailsSlice";
import SkeletonLoader from "../../generics/SkeletonLoader";
import SwitchComp from "../../generics/SwitchComp";

const OnlyActiveSwitch = ({
  activeObjectName,
  getOnlyActiveCheckValue,
}: {
  activeObjectName: string;
  getOnlyActiveCheckValue: (
    onlyActiveValue: boolean,
    activeObjectType: string
  ) => void;
}) => {
  const { relatedObjectsLoading } = useAppSelector(objectDetailsSelector);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!relatedObjectsLoading) {
      setLoading(false);
    }
  }, [relatedObjectsLoading]);

  return (
    <div className="space-x-4">
      {relatedObjectsLoading && loading && <SkeletonLoader />}
      <label className="text-[14px]"> Only Active</label>
      <SwitchComp
        setLoading={setLoading}
        activeObjectType={activeObjectName}
        getOnlyActiveCheckValue={getOnlyActiveCheckValue}
      />
    </div>
  );
};

export default OnlyActiveSwitch;
