import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import ActionButton from "../ActionButton";
import { RelatedObjectsProps } from "./propsTypes";
import Relationships from "./Relationships";

const AccordionRelationshipSection = ({
  properties,
}: {
  properties: RelatedObjectsProps;
}) => {
  const {
    setAccordionIndex,
    setAccordionToggler,
    setRelatedAccordionToggler,
    setRelatedAccordionIndex,
    setEventType,
    relatedAccordionIndex,
    relatedAccordionToggler,
    accordionIndex,
    accordionToggler,
    index,
    key,
    value,
    eventType,
  } = properties;
  return (
    <AccordionItem
      onClick={() => {
        setAccordionIndex(index);
        setAccordionToggler(!accordionToggler);
      }}
      className="!border-0"
    >
      <AccordionItemHeading className="hover:bg-accent-tableBg rounded-md">
        <AccordionItemButton className="!bg-[transparent] text-[12px] font-bold">
          <div className="w-full flex  justify-start items-center space-x-5 p-2">
            {accordionIndex === index && accordionToggler ? (
              <AiOutlineMinusCircle color="#7F56D9" className="w-6 h-6" />
            ) : (
              <AiOutlinePlusCircle color="#7F56D9" className="w-6 h-6" />
            )}
            <div className="w-full flex justify-between items-center">
              <p className="text-lg font-[500] text-primary-textNormal">
                {key}
              </p>
              <ActionButton
                eventHander={() => alert("Relationships")}
                title="Add"
                type="add"
              />
            </div>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="p-3 text-[12px]">
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          className="!border-0"
        >
          {Object.keys(value).length > 0 ? Object.entries(value).map(
            ([keyValue, valueItem]: any, idx: number) => {
              let cIndex = idx + index;
              return (
                <AccordionItem
                  key={keyValue}
                  className={`!border-t-0`}
                  onClick={(e: any) => {
                    if (e.defaultPrevented) return; // Exits here if event has been handled
                    e.preventDefault();
                    setEventType(e.type);
                    setRelatedAccordionIndex(cIndex);
                    setRelatedAccordionToggler(!accordionToggler);
                  }}
                >
                  <AccordionItemHeading className="hover:bg-accent-tableBg rounded-md">
                    <AccordionItemButton className="!bg-[transparent] text-[12px] font-bold">
                      <div className="w-full flex  justify-start items-center space-x-5 p-2">
                        {relatedAccordionIndex === cIndex &&
                        !relatedAccordionToggler &&
                        eventType === "click" ? (
                          <AiOutlineMinusCircle
                            color="#7F56D9"
                            className="w-6 h-6"
                          />
                        ) : (
                          <AiOutlinePlusCircle
                            color="#7F56D9"
                            className="w-6 h-6"
                          />
                        )}
                        <div className="w-full flex justify-between items-center">
                          <p className="text-lg font-[500] text-primary-textNormal">
                            {keyValue}
                          </p>
                          <ActionButton
                            eventHander={() => alert("clicked " + keyValue)}
                            title="Delete"
                            type="delete"
                          />
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="p-3 text-[12px]">
                    <Relationships relatedObject={valueItem} />
                  </AccordionItemPanel>
                </AccordionItem>
              );
            }
          ) : <div className="text-center py-3">Relationships are not available</div>}
        </Accordion>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default AccordionRelationshipSection;
