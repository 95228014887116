
export function ErrorPage({ error }) {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <img
        className="w-[30%]"
        src="/assets/File_not_Found.svg"
        alt="server down"
      />
      <span className="bg-secondary-lighterPurple rounded-lg my-8 py-4 px-16 ">
        <p className="text-primary-purpleDark text-[14px] font-[400]">{error}</p>
      </span>
    </div>
  )
}
