import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../services/api";
import { formatDateForRequest } from "../../components/generics/utilities/utils";
import { DateConfigType } from "../../types/products";


let requestId = "";
export interface ActiveObjectState {
  activeObject: any;
  loading: boolean;
  error: string;
  name: string | undefined;
  activeNoTotal: number;
  changeActivePageStatus: boolean;
  activeObjectType: string;
  filterSuggestions: any[];
  loadingSuggestions: boolean;
  selectedRelatedObjectsColumns: Array<string>;
  isClearInputFields: boolean;
}

const initialState: ActiveObjectState = {
  name: "",
  activeObject: [],
  loading: false,
  error: "",
  activeNoTotal: 0,
  changeActivePageStatus: false,
  activeObjectType: "",
  filterSuggestions: [],
  loadingSuggestions: false,
  selectedRelatedObjectsColumns: [],
  isClearInputFields: false,
};

export const getActiveObject = createAsyncThunk(
  "getActiveObject",

  async (payload: any, { rejectWithValue }) => {
    const { objectType, relatedObject, skipValue, limit } = payload;
    const relatedObjects = JSON.stringify(relatedObject);

    // Gets the order configuration from the local storage
    const orderType = JSON.parse(localStorage.getItem("filterInfo") as string);

    // Gets the object filterConfig from the local storage.
    //const filterConfig = (localStorage.getItem("filterConfig") as string) || [];

    

    // Gets the searched dateFilter config
    //const dateConfig =
    //  (localStorage.getItem("filterDateFutureConfig") as string) || null;

    const params: any = {
      ObjectType: `${objectType}`,
      OrderBy: orderType?.orderBy || "",
      OrderDirection: orderType?.order || "",
      Limit: limit,
      Skip: skipValue || 0,
      RelatedMaps: relatedObjects,
    };

    const allFilterConfig = JSON.parse(
      (localStorage.getItem("allFilterConfig") as any) || null
    );
    if (allFilterConfig && Object.keys(allFilterConfig).length > 0) {
      const objectFilterList: any[] = [];
      if("ExternalID" in allFilterConfig && allFilterConfig["ExternalID"]["value"] !== ""){
        objectFilterList.push({
          Key: "ExternalID",
          Comparator: allFilterConfig["ExternalID"]["comparator"],
          Value: allFilterConfig["ExternalID"]["value"]
        })
      }
      if("Name" in allFilterConfig && allFilterConfig["Name"]["value"] !== ""){
        objectFilterList.push({
          Key: "Name",
          Comparator: allFilterConfig["Name"]["comparator"],
          Value: allFilterConfig["Name"]["value"]
        })
      }
      if("START_DATE" in allFilterConfig && allFilterConfig["START_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["START_DATE"]["comparator"],
          Value: allFilterConfig["START_DATE"]["value"]
        })
      }
      if("END_DATE" in allFilterConfig && allFilterConfig["END_DATE"]["value"] !== ""){
        objectFilterList.push({
          Key: "TimeStampDBInsertion",
          Comparator: allFilterConfig["END_DATE"]["comparator"],
          Value: allFilterConfig["END_DATE"]["value"]
        })
      }
      params["ObjectFilterList"] = JSON.stringify(objectFilterList)
    }

    //if (dateConfig && Object.keys(dateConfig).length > 0) {
    //  params["ObjectFilterList"] = dateConfig;
    //} else {
    //  if (filterConfig.length > 0) {
    //    params["ObjectFilterList"] = filterConfig;
    //  }
    //}

    try {
      const result = await Api({
        url: "object",
        method: "GET",
        params: params,
      });
      //  Sets the limit of skipped data fetched in the localStorage
      localStorage.setItem("skip", JSON.stringify(skipValue || null));

      // Checks if the response has a items that returns the items
      if (
        result.data?.Objects.length > 0 &&
        result.data?.Objects[0].ObjectType === objectType
      ) {
        return result.data;
      }

      return rejectWithValue("Object does not exist");
    } catch (error: any) {
      console.log("Sorry couldn't fetch objects");
      console.log(error);
      return rejectWithValue("Sorry couldn't fetch objects");
    }
  }
);

export const getSearchedSuggestions = createAsyncThunk(
  "getSearchedSuggestions",

  async (payload: any, { rejectWithValue }) => {
    const { objectType, searchProperty, searchTerm } = payload;
    try {
      // Used in ensuring the right request is processed when the request is successful
      let timer: any = null;
      timer = Date.now();
      const triggerTime = timer;

      const result = await Api({
        url: "object/properties",
        method: "GET",
        params: {
          ObjectType: `${objectType}`,
          SearchProperty: searchProperty,
          SearchTerm: JSON.stringify(searchTerm),
          Limit: 10,
        },
      });

      // Returns the request data
      if (timer === triggerTime) {
        if (result.data?.Properties.length > 0) return result.data.Properties;

        return rejectWithValue("No Suggestions Available!");
      }
    } catch (error: any) {
      console.log(error);
      return rejectWithValue("Sorry couldn't get suggestions");
    }
  }
);

export const getSearchedObjectByName = createAsyncThunk(
  "getSearchedObject",
  async (payload: any, { rejectWithValue }) => {
    const {
      objectType,
      relatedObject,
      value,
      limit,
      key,
      comparator,
      isOverview = false,
    } = payload;
    const relatedObjects = JSON.stringify(relatedObject);
    let filterConfig: any = [];
    if (isOverview) {
      const chosenDate: DateConfigType = JSON.parse(
        (localStorage.getItem("dateFilterConfig") as any) || null
      );
      chosenDate &&
        Object.entries(chosenDate).forEach(([keyItem, val]) => {
          filterConfig.push({
            Key: key,
            Comparator: val.comparatorType,
            Value: formatDateForRequest(val.dateValue),
          });
        });
      localStorage.setItem(
        "filterDateFutureConfig",
        JSON.stringify(filterConfig)
      );
    } else {
      filterConfig = [
        {
          Key: key,
          Comparator: comparator || "co",
          Value: value,
        },
      ];
    }



    try {
      // Used in ensuring the right request is processed when the request is successful
      let timer: any = null;
      timer = Date.now();
      const triggerTime = timer;

      const params: any = {
        ObjectType: `${objectType}`,
          Limit: limit,
          //ObjectFilterList: JSON.stringify(filterConfig),
          RelatedMaps: relatedObjects
      };
  
      const allFilterConfig = JSON.parse(
        (localStorage.getItem("allFilterConfig") as any) || null
      );
      if (allFilterConfig && Object.keys(allFilterConfig).length > 0) {
        const objectFilterList: any[] = [];
        if("ExternalID" in allFilterConfig && allFilterConfig["ExternalID"]["value"] !== ""){
          objectFilterList.push({
            Key: "ExternalID",
            Comparator: allFilterConfig["ExternalID"]["comparator"],
            Value: allFilterConfig["ExternalID"]["value"]
          })
        }
        if("Name" in allFilterConfig && allFilterConfig["Name"]["value"] !== ""){
          objectFilterList.push({
            Key: "Name",
            Comparator: allFilterConfig["Name"]["comparator"],
            Value: allFilterConfig["Name"]["value"]
          })
        }
        if("START_DATE" in allFilterConfig && allFilterConfig["START_DATE"]["value"] !== ""){
          objectFilterList.push({
            Key: "TimeStampDBInsertion",
            Comparator: allFilterConfig["START_DATE"]["comparator"],
            Value: allFilterConfig["START_DATE"]["value"]
          })
        }
        if("END_DATE" in allFilterConfig && allFilterConfig["END_DATE"]["value"] !== ""){
          objectFilterList.push({
            Key: "TimeStampDBInsertion",
            Comparator: allFilterConfig["END_DATE"]["comparator"],
            Value: allFilterConfig["END_DATE"]["value"]
          })
        }
        params["ObjectFilterList"] = JSON.stringify(objectFilterList)
      }

      const result = await Api({
        url: "object",
        method: "GET",
        params: params,
      });

      // Sets the filter config to the local storage
      localStorage.setItem("filterConfig", filterConfig);

      if (timer === triggerTime) {
        if (result.data?.Objects.length > 0) return result.data;

        return rejectWithValue("Object does not exist");
      }
    } catch (error: any) {
      console.log(error);
      return rejectWithValue("Sorry couldn't fetch objects");
    }
  }
);

const activeObjectSlice = createSlice({
  name: "activeObject",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setClearInputFields: (state, { payload }: PayloadAction<boolean>) => {
      state.isClearInputFields = payload;
    },
    changeActivePage: (state, { payload }: PayloadAction<boolean>) => {
      state.changeActivePageStatus = payload;
    },
    setActiveObjectType: (state, { payload }: PayloadAction<string>) => {
      state.activeObjectType = payload;
    },
    setSelectedTableRelationship: (
      state,
      { payload }: PayloadAction<Array<string>>
    ) => {
      state.selectedRelatedObjectsColumns = payload;
    },
    setFilterSuggestions: (
      state,
      { payload }: PayloadAction<Array<string>>
    ) => {
      state.filterSuggestions = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getActiveObject.pending, (state, action: any) => {
      // Assigned the current request ID to the requestId variable
      requestId = action.meta.requestId;

      // Updates the state when the request is pending
      state.loading = true;
      state.activeObject = [];
      state.activeNoTotal = 0;
      state.error = "";
    });
    builder.addCase(getActiveObject.fulfilled, (state, action) => {
      state.loading = false;
      // Sets the state with right data when a race condition is encountered.
      if (action.meta.requestId === requestId) {
        state.activeObject = action.payload?.Objects;
      }
      state.activeNoTotal = action.payload?.QuantityObjectsTotal;
    });
    builder.addCase(getActiveObject.rejected, (state) => {
      state.loading = false;
      state.activeObject = [];
      state.error = "Sorry couldn't fetch objects ";
    });
    builder.addCase(getSearchedObjectByName.pending, (state) => {
      state.error = "";
      state.loading = true;
      state.activeObject = [];
    });
    builder.addCase(getSearchedObjectByName.fulfilled, (state, action) => {
      // UPDATES THE STATE FOR getSearchedObjectByName action
      state.activeObject = action.payload?.Objects;
      //UPDATES THE TOTAL OBJECT COUNT USED FOR THE PAGINATION OF THE TABLE
      state.activeNoTotal = action.payload?.QuantityObjectsTotal;
      state.loading = false;
    });
    builder.addCase(getSearchedObjectByName.rejected, (state, { payload }) => {
      state.loading = false;
      state.activeObject = [];
      state.error = payload as string;
    });
    builder.addCase(getSearchedSuggestions.fulfilled, (state, action) => {
      state.loadingSuggestions = false;
      if (action.payload?.length > 0) {
        state.filterSuggestions = action.payload;
      }
    });
    builder.addCase(getSearchedSuggestions.pending, (state) => {
      state.error = "";
      state.loadingSuggestions = true;
      state.filterSuggestions = [];
    });
    builder.addCase(getSearchedSuggestions.rejected, (state, { payload }) => {
      state.loadingSuggestions = false;
      state.filterSuggestions = [];
    });
  },
});

export const {
  setErrors,
  changeActivePage,
  setActiveObjectType,
  setSelectedTableRelationship,
  setFilterSuggestions,
  setClearInputFields,
} = activeObjectSlice.actions;

export default activeObjectSlice.reducer;

export const activeObjectSelector = (state: {
  activeObject: ActiveObjectState;
}) => state.activeObject;
