import { apiUrl } from "../../../../../public/config";
import Api from "../../../../../services/api";
import { errorHandler } from "../objects/objectsApiCalls";

export const allUsersUrl = apiUrl + "/role";
export const inviteUserUrl = apiUrl + "/user";
export const userRoleUrl = apiUrl + "/user/role";

// fetch objects base on configs
export const getAllRoles = async () => {
  try {
    let time = performance.now();
    const resdata = await Api({
      url: allUsersUrl,
      method: "get",
    });
    return resdata.data.Roles;
  } catch (err: any) {
    return errorHandler(err);
  }
};

export const inviteUser = async (user: {
  email: string;
  roleName: string;
  registrationUrl: string;
}) => {
  try {
    let time = performance.now();
    const response = await Api({
      url: inviteUserUrl,
      method: "POST",
      data: {
        Email: user.email,
        RoleName: user.roleName,
        RegistrationURL: user.registrationUrl,
      },
    });

    return response.data;
  } catch (err: any) {
    return errorHandler(err);
  }
};

export const editUser = async (user: {
    originalUsername: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
}) => {
  try {
    let time = performance.now();
    const response = await Api({
      url: inviteUserUrl,
      method: "PATCH",
      data: {
        UserName: user.originalUsername,
        UpdateMap: {
          FirstName: user.firstName,
          LastName: user.lastName,
          UserName: user.username,
          Email: user.email
        }
      }
    });
    return response.data;
  } catch (err: any) {
    return errorHandler(err);
  }
};

export const editUserRole = async (user: {
  username: string;
  oldRole: string;
  role: string;
}) => {
try {
  if (user.oldRole && user.oldRole !== ""){
    let time = performance.now();
    await Api({
      url: userRoleUrl,
      method: "DELETE",
      data: {
        UserName: user.username,
        RoleName: user.oldRole
      }
    });
  }
  let time = performance.now();
  const response = await Api({
    url: userRoleUrl,
    method: "POST",
    data: {
      UserName: user.username,
      RoleName: user.role
    }
  });
  return response.data;
} catch (err: any) {
  return errorHandler(err);
}
};

export const doDeleteUser = async (user: {
  username: string;
}) => {
try {
  let time = performance.now();
  const response = await Api({
    url: inviteUserUrl,
    method: "DELETE",
    data: {
      UserName: user.username
    }
  });
  return response.data;
} catch (err: any) {
  return errorHandler(err);
}
};
