import React, { FC, useEffect, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { initialValues, validationSchema } from "./validationSchema";
import FormikControl from "../../graph/graphDetails/relationships/formik/FormikControl";
import { capitalizeFirstLetter } from "../../generics/utils/utils";
import { createNewObjectType } from "../../generics/utils/api/objects/configUpdateApiCalls";
import { getObjects, objectSelector } from "../../../redux/objects/objectSlice";
import ErrorMessageComp from "../ErrorMessageComp";

interface Props {
  closeModal: () => void;
  setSuccessMessage: (value: string) => void;
  activeObject: string;
}

const AddObjectType: FC<Props> = ({ closeModal, activeObject, setSuccessMessage }) => {
  const { objects } = useAppSelector(objectSelector);
  const newObjectsString = JSON.stringify(objects);
  const dispatch = useAppDispatch();
  // local state user
  const [errorMessage, setErrorMessage] = useState("");
  const [existingObjects, setExistingObjects] = useState<string[]>([]);

  const onSubmit = (values: any, onSubmitProps: any) => {
    const newName = capitalizeFirstLetter(values.name);
    if (existingObjects?.includes(newName.toLowerCase())) {
      setErrorMessage(
        "ObjectType Cannot be the same as the existing ObjectType"
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      // setIsSubmitting to false
      onSubmitProps.setSubmitting(false);
      return;
    }

    const objectDetails = {
      ObjectType: newName,
      Values: {
        TimeStampField: values.timeStampField,
        TimeSeriesObject: values.timeSeriesObject,
        TimeSeriesTimeStampField: values.timeSeriesTimeStampField,
      },
    };
    createNewObjectType(objectDetails)
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage(`${newName} was added successfully!`);
          closeModal();
        }
        dispatch(getObjects());
        setTimeout(() => {
          setSuccessMessage("");
        }, 10000);
      })
      .catch((error) => setErrorMessage(error));
    // setIsSubmitting to false
    onSubmitProps.setSubmitting(false);
  };

  useEffect(() => {
    let newObjects = JSON.parse(newObjectsString);
    if (Object.keys(newObjects?.Config).length > 0) {
      let existingObjs = [];
      for (const key in newObjects?.Config) {
        existingObjs.push(key.toLowerCase());
      }
      setExistingObjects(existingObjs);
    }
  }, [newObjectsString]);

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <h6 className="text-lg text-accent-grayDark font-bold">
          Add new Object
        </h6>
        <VscClose
          onClick={closeModal}
          style={{ cursor: "pointer", color: "#667085" }}
          size={24}
        />
      </div>
      {/* ERROR MESSAGE */}
      {errorMessage && <ErrorMessageComp errorMessage={errorMessage} />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
      >
        {(formik: any) => {
          return (
            <Form autoComplete="off" className="w-full px-2">
              <div className="w-full flex flex-col space-y-2 justify-center item-middle my-5">
                <FormikControl
                  control="input"
                  type="text"
                  name="name"
                  width="small"
                  label="Name"
                  formikObject={formik}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="timeStampField"
                  width="small"
                  label="Time Stamp Field"
                  formikObject={formik}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="timeSeriesObject"
                  width="small"
                  label="Time Series Object"
                  formikObject={formik}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="timeSeriesTimeStampField"
                  width="small"
                  label="Time Series Time Stamp Field"
                  formikObject={formik}
                />
              </div>

              <div className="space-x-3 w-full flex justify-between align-middle">
                <button
                  onClick={closeModal}
                  className="text-[#000] flex-1 border rounded-[8px] py-2 px-5"
                >
                  Cancel
                </button>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type="submit"
                  className="disabled:bg-primary-borderColor disabled:cursor-not-allowed bg-primary-purpleDark flex-1 justify-center items-center space-x-2 text-[#fff] rounded-[8px] py-2 px-5 font-normal"
                >
                  Create
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddObjectType;
