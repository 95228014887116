// backup of the previous config used
export const completeForwardTrackingPathMap_backup = [
    {
        RelatedObjectTypes: [
            "Article"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Article"
            }
        ]
    },
    {
        RelatedObjectTypes: [
            "Machine"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Machine"
            }
        ]
    },
    {
        RelatedObjectTypes: [
            "Product"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            }
        ]
    },
    {
        RelatedObjectTypes: [
            "ProductionOrder"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "ProductionOrderSource"
            }
        ]
    },
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ],
        BeginRelatedObjectMap: {
            ObjectType: "Product",
            RelatedObjectName: "BuiltInProduct"
        }
    },
    {
        RelatedObjectTypes: [
            "Package"
        ],
        RelatedObjectMaps: [
            {
                ObjectType: "Product",
                RelatedObjectName: "BuiltInProduct"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "Product"
            },
            {
                ObjectType: "Package",
                RelatedObjectName: "ContainingProductIsBuiltInProduct"
            },
            {
                ObjectType: "Product",
                RelatedObjectName: "Package"
            }
        ],
        BeginRelatedObjectMap: {
            ObjectType: "Product",
            RelatedObjectName: "Package"
        }
    }
]

// used config
export const completeForwardTrackingPathMap = [
    {
      RelatedObjectTypes: ["Article"],
      RelatedObjectMaps: [
        {
          ObjectType: "Product",
          RelatedObjectName: "BuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Package",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "ContainingProductIsBuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Article",
        },
      ],
    },
    {
      RelatedObjectTypes: ["Machine"],
      RelatedObjectMaps: [
        {
          ObjectType: "Product",
          RelatedObjectName: "BuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Package",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "ContainingProductIsBuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Machine",
        },
      ],
    },
    {
      RelatedObjectTypes: ["Product"],
      RelatedObjectMaps: [
        {
          ObjectType: "Product",
          RelatedObjectName: "BuiltInProduct",
        },
      ],
    },
    {
      RelatedObjectTypes: ["ProductionOrder"],
      RelatedObjectMaps: [
        {
          ObjectType: "Product",
          RelatedObjectName: "BuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Package",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "ContainingProductIsBuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "ProductionOrderSource",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "ProductionOrderSource",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "ProductionOrderTarget",
        },
      ],
    },
    {
      RelatedObjectTypes: ["Package"],
      RelatedObjectMaps: [
        {
          ObjectType: "Product",
          RelatedObjectName: "BuiltInProduct",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "Product",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "ContainingProductIsBuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Package",
        },
      ],
      BeginRelatedObjectMap: {
        ObjectType: "Product",
        RelatedObjectName: "BuiltInProduct",
      },
    },
    {
      RelatedObjectTypes: ["Package"],
      RelatedObjectMaps: [
        {
          ObjectType: "Product",
          RelatedObjectName: "BuiltInProduct",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "Product",
        },
        {
          ObjectType: "Package",
          RelatedObjectName: "ContainingProductIsBuiltInProduct",
        },
        {
          ObjectType: "Product",
          RelatedObjectName: "Package",
        },
      ],
      BeginRelatedObjectMap: {
        ObjectType: "Product",
        RelatedObjectName: "Package",
      },
    },
  ];