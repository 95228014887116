import React, { FC, useEffect, memo  } from "react";
import { ClipLoader } from "react-spinners";
import useLocalStorage from "../../../../customHooks/useLocalStorage";
import {
  fetchEitherForwardOrBackwardTracks,
  fetchObjectTracks,
  fetchSelectedRelationsOfGraph,
} from "../../../../redux/graph/actions";
import {
  setSelectedNode,
  setSelectedRelatedMapsInState,
  setStoreGraph,
} from "../../../../redux/graph/graphSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { objectSelector } from "../../../../redux/objects/objectSlice";
import {
  checkAllCheckedInputs,
  getValuesOfAllCheckedInputs,
  removeDeselectedObjectRelationshipFromCanvas,
  uncheckAllCheckedInputs,
} from "../../../generics/utils/utils";
import InitialRelationships from "./relationships/InitialRelationships";

type Props = {};

const Relationships: FC<Props> = () => {
  const dispatch = useAppDispatch();
  // STORE STATE
  const {
    parentNode,
    previousGraph,
    storeGraph,
    parentNodeId,
    isShowAllCheckboxRelations,
  } = useAppSelector((state) => state.graphReducer);
  const { objects: objectConfig } = useAppSelector(objectSelector);

  // localstorage
  const { storedValue: isFetchingSelectedNode } = useLocalStorage("isFetchingSelectedNode");
  const { storedValue: fetchedRelatedObjects } = useLocalStorage("allrelatedObjects");
  const { storedValue: selectedNode } = useLocalStorage("selectedNode");

  const { storedValue: selectedRelatedMapsInState } =
    useLocalStorage("selectedRelatedMapsInState") ?? [];

  // if a request is made check all the checkboxes
  useEffect(() => {
    if (
      parentNodeId === selectedNode?.ExternalID &&
      isShowAllCheckboxRelations
    ) {
      const allCheckbox: HTMLInputElement | null =
        document.querySelector(".allCheckbox");
      const form: any = document.querySelectorAll(".relations");
      let arrayInputs: any = [...form];
      if (allCheckbox) {
        allCheckbox.checked = true;
        checkAllCheckedInputs(arrayInputs);
        dispatch(
          setSelectedRelatedMapsInState(getValuesOfAllCheckedInputs(form))
        );
        localStorage.setItem(
          "selectedRelatedMapsInState",
          JSON.stringify(getValuesOfAllCheckedInputs(form))
        );
      }
    } else {
      const allCheckbox: HTMLInputElement | null =
        document.querySelector(".allCheckbox");
      const form: any = document.querySelectorAll(".relations");
      let arrayInputs: any = [...form];
      if (allCheckbox) {
        allCheckbox.checked = false;
        uncheckAllCheckedInputs(arrayInputs);
      }
    }
  }, [
    dispatch,
    isShowAllCheckboxRelations,
    parentNodeId,
    selectedNode?.ExternalID,
  ]);

  // HANDLING CHECKBOXES
  // reset graph
  const resetRelationship = () => {
    const allCheck: HTMLInputElement = document.querySelector(".allCheckbox")!;
    const form: HTMLElement | null = document.querySelector(".relations");
    const inputs: any = form?.getElementsByTagName("input");
    let arrayInputs: any = [...inputs];

    const savedDropRelatedNodes =
      JSON.parse(localStorage.getItem("dropRelatedNodes") as any) || null;

    if (savedDropRelatedNodes) {
      let resultValue: any = [];

      resultValue = removeDeselectedObjectRelationshipFromCanvas(
        storeGraph.nodes,
        savedDropRelatedNodes
      );

      const finalResultValue = {
        ...storeGraph,
        nodes: resultValue,
      };

      dispatch(setStoreGraph(finalResultValue));
    }
    dispatch(setSelectedNode(previousGraph.nodes[0]));
    dispatch(setSelectedRelatedMapsInState([]));
    uncheckAllCheckedInputs(arrayInputs);
    allCheck.checked = false;
  };
  // check if all checkboxes are unchecked and uncheck the select all checkbox
  const resetGraphIfAllCheckboxesAreUnchecked = (inputValues: any) => {
    const allCheckbox: HTMLInputElement | null =
      document.querySelector(".allCheckbox");
    let arrayInputs = [...inputValues];
    let shouldNotToggleAllCheckboxes = arrayInputs.every(
      (input) => input.checked === false
    );
    if (shouldNotToggleAllCheckboxes === true) {
      allCheckbox!.checked = false;
      resetRelationship();
    }
  };

  // if all the checkboxes are checked, then check the select all checkbox
  const allCheckboxesAreChecked = () => {
    const form: any = document.querySelectorAll(".relations");
    let inputValues = [...form];
    const allCheckbox: HTMLInputElement | null =
      document.querySelector(".allCheckbox");
    let shouldToggleAllCheckboxes = inputValues.every(
      (input: any) => input.checked === true
    );
    if (shouldToggleAllCheckboxes === true) {
      allCheckbox!.checked = true;
    }
  };

  //Remove GrandChildren nodes from the right
  const removeGrandChildrenNodes = () => {
    const grandChildrenNodesRemovalResult = storeGraph.nodes?.filter(
      (values: any) => {
        const depthValue = selectedNode.diveDepth + 1;
        return values.diveDepth <= depthValue;
      }
    );
    // Constructs the current the new Graph nodes
    const finalResultValue = {
      ...storeGraph,
      nodes: grandChildrenNodesRemovalResult,
    };
    // Dispatches an action to update the storeGraphState
    dispatch(setStoreGraph(finalResultValue));
  };

  // All checkboxes change handler
  const handleAllCheckboxChange = (e: any, behavior: string) => {
    const form: any = document.querySelectorAll(".relations");
    let inputValues = [...form];
    const { checked } = e.target;
    if (checked === true) {
      // alert("Here")
      checkAllCheckedInputs(inputValues);

      // Removes the grandchildren nodes from the canvas
      removeGrandChildrenNodes();

      localStorage.setItem(
        "selectedRelatedMapsInState",
        JSON.stringify(getValuesOfAllCheckedInputs(inputValues))
      );

      // Constructs the parameters to make a request.
      const parameter = {
        type: selectedNode?.group,
        ExternalID: selectedNode?.ExternalID,
        behavior: behavior,
        relatedObjects: getValuesOfAllCheckedInputs(inputValues),
      };
      const graphType = localStorage.getItem("graphType");
      const trackingDive = localStorage.getItem("trackingDive");

      switch (graphType) {
        case "single":
          dispatch(fetchSelectedRelationsOfGraph(parameter));
          break;
        case "completeTracking":
          dispatch(fetchObjectTracks(parameter));
          break;
        case "forwardAndBackwardTracking":
          dispatch(
            fetchEitherForwardOrBackwardTracks({
              tracking: trackingDive,
              type: selectedNode?.group,
              ExternalID: selectedNode?.ExternalID,
              behavior: behavior,
              relatedObjects: getValuesOfAllCheckedInputs(inputValues),
            })
          );
          break;

        default:
          dispatch(fetchSelectedRelationsOfGraph(parameter));
          break;
      }
    } else {
      uncheckAllCheckedInputs(inputValues);
      resetGraphIfAllCheckboxesAreUnchecked(inputValues);
    }
  };

  // next request config

  // Single checkbox change handler
  const handleSingleCheckboxChange = (e: any, behavior: string) => {
    const allCheckbox: HTMLInputElement | null =
      document.querySelector(".allCheckbox");
    const form: any = document.querySelectorAll(".relations");
    let arrayInputs: any = [...form];
    const { checked, value } = e.target;
    if (checked === true) {
      localStorage.setItem(
        "selectedRelatedMapsInState",
        JSON.stringify([...selectedRelatedMapsInState, value])
      );

      // Removes the grandchildren nodes from the canvas
      removeGrandChildrenNodes();

      const parameter = {
        type: selectedNode?.group,
        ExternalID: selectedNode?.ExternalID,
        behavior: behavior,
        relatedObjects: [...selectedRelatedMapsInState, value],
      };

      dispatch(fetchSelectedRelationsOfGraph(parameter));
    } else {
      const activeRelatedObjects = selectedRelatedMapsInState.filter(
        (item: string) => item !== value
      );
      localStorage.setItem(
        "selectedRelatedMapsInState",
        JSON.stringify(activeRelatedObjects)
      );

      const dropRelatedObjectsData =
        JSON.parse(localStorage.getItem("dropRelatedNodes") as any) || null;

      const unselectRelationship = dropRelatedObjectsData?.filter(
        (item: any) => {
          return (
            item.objectName === value ||
            item.group === value ||
            item.relatedObjectName === value
          );
        }
      );
      const result = removeDeselectedObjectRelationshipFromCanvas(
        storeGraph.nodes,
        unselectRelationship
      );

      const finalResultValue = {
        ...storeGraph,
        nodes: result,
      };

      dispatch(setStoreGraph(finalResultValue));

      allCheckbox!.checked = false;
    }
    // check the select all checkbox if all checkboxes are checked
    allCheckboxesAreChecked();

    // reset the graph if all checkboxes are unchecked
    resetGraphIfAllCheckboxesAreUnchecked(arrayInputs);
  };
  // HANDLING CHECKBOXES

  return (
    <div className="px-9 py-3 flex w-full">
      <>
        {isFetchingSelectedNode && (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-primary-purpleLight opacity-20 z-40 flex flex-col justify-center items-center">
            <span className="opacity-100">
              <ClipLoader size={70} loading={isFetchingSelectedNode} color="#3A6A78" />
            </span>
          </div>
        )}
        {fetchedRelatedObjects && fetchedRelatedObjects.length > 0 ? (
          <InitialRelationships
            allrelatedObjects={fetchedRelatedObjects}
            parentNode={parentNode}
            objectConfig={objectConfig}
            handleAllCheckboxChange={handleAllCheckboxChange}
            handleSingleCheckboxChange={handleSingleCheckboxChange}
          />
        ) : (
          <div className="w-full capitalize my-4 font-bold text-center">
            Select a node to show it's relationships!
          </div>
        )}
      </>
    </div>
  );
};

export default memo(Relationships);
