import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { getValue } from "../../../../../generics/utils/utils";

type Props = {
  label?: string;
  name: string;
  formikObject: any;
  groupTouched?: string;
  selfTouched?: string;
  objectType?: string;
  searchProperty?: string;
  width?: any;
};

const Input: FC<Props> = ({
  label,
  name,
  groupTouched,
  formikObject,
  selfTouched,
  objectType,
  searchProperty,
  width,
  ...rest
}) => {
  return (
    <div className="!w-full relative">
      <Field id={name} name={name} {...rest}>
        {({ field, form }: any) => {
          const error =  getValue(form.errors, name)
          return (
            <input
              type="text"
              id={name}
              autoComplete="off"
              placeholder={label}
              className={`!w-full border-1 ${error ? "border-[red]" : "border-primary-borderColor"} text-primary-textNormal placeholder-primary-textMuted text-xs rounded-md`}
              {...field} // this must come before value attribute
              onChange={(e) => {
                form.handleChange(e);
                if (e?.target?.value.trim() === "") {
                  form.setFieldValue(selfTouched, false);
                } else {
                  form.setFieldValue(selfTouched, true);
                }
              }}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} />
    </div>
  );
};

export default Input;
