import Loader from "../loader/Loader"
import { cloneDeep } from "lodash"
import { hierarchicalOptions, freestyleOptions } from "../config/options"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  setIsOpenRightSidebar,
} from "../../../redux/graph/graphSlice"
import { useState } from "react"
import CustomSwitch from "../canvas/graphMenu/CustomSwitch"
import GraphLegend from "../canvas/graphMenu/GraphLegend"
import Canvas from "../canvas/Canvas"
import { useLocation } from "react-router-dom"
import useWindowDimensions from "../../../customHooks/useWindowDimensions"

const TreeGraph = () => {
  const dispatch = useAppDispatch()
  let locationState = useLocation();
  const showTrackingTabs = locationState.pathname.includes("tracking");
  // STORE STATE
  const { loading, isOpenRightSidebar } = useAppSelector(
    (state) => state.graphReducer
  )
  const storeGraph = useAppSelector((state) =>
    cloneDeep(state.graphReducer.storeGraph)
  )

  // LOCAL STATE
  const [graphLayout, setGraphLayout] = useState(false)

  // GRAPH EVENTS
  const events = {
    selectNode: async function (event) {
      let { nodes } = event
      if (nodes[0] !== undefined) {
        const targetNode = storeGraph?.nodes.find(
          (node) => node?.id === nodes[0]
        )
        localStorage.setItem("selectedNode", JSON.stringify(targetNode))
      }
    },
  }

  // options for layout switching
  const options = graphLayout ? hierarchicalOptions : freestyleOptions
  // get the screen height
const {height} = useWindowDimensions()

  return (
    <div
      className={`${
        isOpenRightSidebar ? "w-[85%]" : "w-[100%]"
      } "graph graph-bg-Color ${height > 850 ? "h-[83.8vh]" : "h-[79vh]"} relative"`}
    >
      <div>
        <div className="">
          <div
            className={`${
              isOpenRightSidebar ? "right-96" : "right-20"
            } flex flex-row absolute top-[10px] justify-end align-middle`}
          >
            <div
              className={`legend-container  flex justify-start items-center mr-10  w-[60px] `}
            >
              <GraphLegend isOpenRightSidebar={isOpenRightSidebar} />
            </div>
            <CustomSwitch
              value={graphLayout}
              handleChange={(e) => setGraphLayout(e.target.checked)}
            />
          </div>
         {!showTrackingTabs && <div className="flex absolute top-[10px] right-0 justify-end">
            <img
              src="/assets/leftSidebar/rightMenu.svg"
              className={`border rounded-md rounded-r-none p-3 cursor-pointer mr-0`}
              onClick={() =>
                dispatch(setIsOpenRightSidebar(!isOpenRightSidebar))
              }
              alt=""
            />
          </div>}
        </div>
      </div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <Canvas storeGraph={storeGraph} options={options} events={events} />
        </>
      )}
    </div>
  )
}

export default TreeGraph
