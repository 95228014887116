import React, { FC, useState } from 'react'
import { VscClose } from 'react-icons/vsc'
import { useFormik } from "formik"
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { Roles } from '../../../../types/rolesTypes'
import { fetchGetAllUsers } from '../../../../redux/users/actions'
import { editRole } from '../../../../redux/roles/actions'

interface Props {
  closeModal: () => void
  currentUsername: string,
  currentRole: string
}

const EditUser: FC<Props> = ({ closeModal, currentUsername, currentRole }) => {
  // user roles
  const { roles } = useAppSelector((state: any) => state.roles);
  const { page } = useAppSelector((state: any) => state.users);

  // local state user
  const [errorMessage, setErrorMessage] = useState("")

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      name: currentUsername,
      role: roles?.[0]?.["RoleName"],
    },
    onSubmit: (values) => {
      const editDetails = {
        username: currentUsername,
        oldRole: currentRole,
        role: values["role"]
      };

      //
      dispatch(
        editRole(editDetails)
      );
      setErrorMessage("");
      setTimeout(() => {
        closeModal();
        dispatch(fetchGetAllUsers(page));
      }, 1000);
    },
    validate: (values) => {
      let errors: any = {}
      /*
      if (!values.name) {
        setErrorMessage("Please provide a name!")
        errors.name = "Please provide a name!"
        return
      } else {
        setErrorMessage("");
      }
      if (!values.team) {
        setErrorMessage("Please select a team!!")
        errors.name = "Please select a team!"
        return
      } else {
        setErrorMessage("");
      }
      */

      return errors
    },
  })


  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <h6 className="text-lg text-accent-grayDark font-bold">Edit {currentUsername}</h6>
        <VscClose
          onClick={closeModal}
          style={{ cursor: "pointer", color: "#667085" }}
          size={24}
        />
      </div>
      {errorMessage && (
        <div className="error-container border-[1px] border-[#FDA29B] flex p-[16px] mt-[32px] rounded-[8px] bg-[#FFFBFA] ">
          <div>
            <img src="/assets/error_icon.svg" alt="" />
          </div>
          <p className="ml-[13px] text-[14px] text-[#B42318]">{errorMessage}</p>
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex flex-col space-y-2 justify-center item-middle my-5">
          {/*<input
            className="w-full text-[16px] text-accent-grayMuted300  h-[44px] rounded-[8px] border border-accent-grayMuted300 placeholder-text-accent-grayMuted  focus:!outline-none"
            type="text"
            placeholder="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
      />*/}
          <select
            className="w-full my-1 text-accent-grayMuted [&>:not(first-of-type)]:text-accent-grayLight border-accent-grayMuted300 rounded-lg outline-none"
            name="role"
            id="role"
            onChange={formik.handleChange}
            value={formik.values.role}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled selected hidden>
              Select Role
            </option>
            {roles &&
              roles?.map((role: Roles) => {
                return (
                  <option value={role.RoleName} key={role.RoleName}>
                    {role.RoleName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="space-x-3 w-full flex justify-between align-middle">
          <button
            onClick={closeModal}
            className="text-[#000] flex-1 border rounded-[8px] py-2 px-5"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-primary-purpleDark flex-1 justify-center items-center space-x-2 text-[#fff] rounded-[8px] py-2 px-5 font-normal"
          >
            Edit User
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditUser