import * as d3 from "d3";
import { DateTime, Interval } from "luxon";

export const formatPriceUSD = (price: number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    price
  );

export const formatPercent = (percent: any = 0) => {
  let result: any = "0.00%";
  const isNumber = typeof percent === "number";
  const isString = typeof percent === "string";
  const isNotNaN = !Number.isNaN(parseFloat(percent));

  if ((isNumber || isString) && isNotNaN) {
    result = `  ${parseFloat(percent as string)}`;
  }
  return result;
};

export const getPeriod = (date: any) => {
  if (!date) return 6;
  const now = DateTime.now();
  const before = DateTime.fromJSDate(date);
  const i = Interval.fromDateTimes(before, now);
  return i.length("months");
};

export const getXTicks = (months: any) => {
  if (months <= 2) {
    if (d3?.timeDay !== null) {
      return d3.timeDay.every(5)!.filter((d: any) => d.getDate() !== 31);
    }
  }

  if (months <= 6) return d3.timeMonth.every(1);
  if (months <= 13) return d3.timeMonth.every(2);
  return d3.timeYear.every(1);
};

export const getXTickFormat = (months: any) => {
  if (months <= 2) return d3.timeFormat("%d %b");
  if (months <= 6) return d3.timeFormat("%b");
  if (months <= 13) return d3.timeFormat("%b %Y");
  return d3.timeFormat("%Y");
};

export const linGraphConfiguration = (value: string, state: any) => {
  switch (value) {
    case "Package":
      return {
        ObjectType: state.ObjectType,
        ObjectKeyValueMap: {
          ExternalID: state.ExternalID,
        },
        RelatedMaps: JSON.stringify([
          {
            RelatedObjectName: "Package",
            Limit: 1,
            RelatedMaps: [
              {
                RelatedObjectName: "Product",
                OrderBy: "TimeStampDBInsertion",
                OrderDirection: "ASC",
              },
            ],
          },
        ]),
      };

    case "ProductionOrder":
      return {
        ObjectType: state.ObjectType,
        ObjectKeyValueMap: {
          ExternalID: state.ExternalID,
        },
        RelatedMaps: JSON.stringify([
          {
            RelatedObjectName: "Machine",
            RelatedMaps: [
              {
                RelatedObjectName: "CurrentlyExecutedProductionOrder",
                RelationshipFilterList: [
                  {
                    Key: "ValidFrom",
                    Comparator: "le",
                    Value: "root.TimeStampDBInsertion",
                  },
                  {
                    Key: "ValidTo",
                    Comparator: "ge",
                    Value: "root.TimeStampDBInsertion",
                  },
                ],
              },
              {
                RelatedObjectName: "CurrentlyExecutedProductionOrder",
                RelationshipFilterList: [
                  {
                    Key: "ValidFrom",
                    Comparator: "le",
                    Value: "root.TimeStampDBInsertion",
                  },
                ],
                RelationshipKeyValueMap: {
                  ValidTo: null,
                },
              },
            ],
          },
        ]),
      };
  }
};
