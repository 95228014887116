import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import SortIconDown from "./SortIconDown";
import SortIconNone from "./SortIconNone";
import SortIconUp from "./SortIconUp";

const OrderIcon = ({
  filterSettings,
  index,
}: {
  filterSettings: any;
  index: number;
}) => {
  let result : ReactJSXElement = <SortIconNone />;
  if(filterSettings?.isActive && filterSettings?.sortType === "ASC"){
    result =   <SortIconDown />;
  }else if (filterSettings?.isActive && filterSettings?.sortType === "DESC"){
    result = <SortIconUp />;
  }
  return result;

};

export default OrderIcon;
