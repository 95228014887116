import { useLayoutEffect, useState } from "react";
import Canvas from "../canvas/Canvas";
import { rowGraphOptions, hierarchicalOptions } from "../config/options";

const RowGraph = ({ linearGraph, height, layout }) => {
  const [optionConfig, setOptionConfig] = useState(rowGraphOptions);

  useLayoutEffect(() => {
    const updateOptionsConfig = () => {
      setOptionConfig((prev) => {
        return {
          ...prev,
          height: height,
        };
      });
    };

    updateOptionsConfig();
  }, [height]);

  useLayoutEffect(() => {
    if (layout) {
      setOptionConfig(hierarchicalOptions);
    } else {
      setOptionConfig((prev) => {
        return {
          ...rowGraphOptions,
          height: height,
        };
      });
    }
  }, [height, layout]);

  // GRAPH EVENTS
  const events = {
    selectNode: async function (event) {
      let { nodes } = event;
      if (nodes[0] !== undefined) {
        const targetNode = linearGraph?.nodes.find(
          (node) => node?.id === nodes[0]
        );
        localStorage.setItem("selectedNode", JSON.stringify(targetNode));
      }
    },
  };

  return (
    <>
      <Canvas storeGraph={linearGraph} options={optionConfig} events={events} />
    </>
  );
};

export default RowGraph;
