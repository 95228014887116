import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RolesState } from "../../types/rolesTypes";
import { editUserInfos, fetchGetAllRoles, sendInvitation } from "./actions";
import { initialState } from "./initialState";

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    resetStore: (state) => {
      state.loading = false;
      state.error = null;
    },
    hideAlert: (state, { payload }: PayloadAction<boolean>) => {
      state.showAlert = payload;
    },
  },
  //  FOR ASYNC STATE MUTATION
  extraReducers: (builder) => {
    // fetchGetAllRoles  is an initial render action
    builder.addCase(fetchGetAllRoles.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchGetAllRoles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.roles = payload;
    });
    builder.addCase(fetchGetAllRoles.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(sendInvitation.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.showAlert = false;
    });
    builder.addCase(sendInvitation.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.error = "User have been invited to the system successfully.";
    });
    builder.addCase(sendInvitation.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.showAlert = false;
    });
    builder.addCase(editUserInfos.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.showAlert = false;
    });
    builder.addCase(editUserInfos.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.error = "User has been updated successfully.";
    });
    builder.addCase(editUserInfos.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.showAlert = false;
    });
  },
});

export const { setLoading, setError, setRoles, hideAlert } = rolesSlice.actions;

export default rolesSlice.reducer;
export const rolesSelector = (state: { roles: RolesState }) => state.roles;
