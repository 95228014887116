/** MultilineChart.controller.js */
import { useMemo } from "react";
import * as d3 from "d3";
import { useAppSelector } from "../../../redux/hooks";
import { lineGraphSelector } from "../../../redux/lineGraph/lineGraphSlice";
import { objectDetailsSelector } from "../../../redux/objects/objectDetailsSlice";

const useController = ({
  data,
  width,
  height,
}: {
  data: any;
  width: number;
  height: number;
}) => {
  const { globalConfiguration, currentExecutedAtMachineRelationships } =
    useAppSelector(lineGraphSelector);
  const { objectInfo }: any = useAppSelector(objectDetailsSelector);

  const xMin = useMemo(
    () => d3.min(data, ({ items }) => d3.min(items, ({ date }) => date)),
    [data]
  );

  const xMax = useMemo(
    () => d3.max(data, ({ items }) => d3.max(items, ({ date }) => date)),
    [data]
  );

  const checkObjectTypeAndReturnDate = (index: number, d: any) => {
    if (objectInfo[0]?.ObjectType === "Package") {
      if (index === 0) {
        return new Date(globalConfiguration.dateTimeFrom);
      }
      if (index === 1) {
        return new Date(globalConfiguration.dateTimeTo);
      }
    } else if (objectInfo[0]?.ObjectType === "ProductionOrder") {
      if (index === 0) {
        return new Date(currentExecutedAtMachineRelationships[0]?.ValidFrom);
      }
      if (index === 1) {
        // Checks if the last element has validTo and sets that as the value for the scale size
        // Otherwise scales the size based on the currentDate
        if (
          currentExecutedAtMachineRelationships[
            currentExecutedAtMachineRelationships.length - 1
          ]?.ValidTo
        ) {
          return new Date(
            currentExecutedAtMachineRelationships[
              currentExecutedAtMachineRelationships.length - 1
            ].ValidTo
          );
        }
        return d.date;
      }
    }
  };

  // Scales the chart by the value of the globalConfiguration if the active object type is Package
  // The global state at this time should hols the validFrom and validTo of the the contained products of package
  const domainXScaleOnTimeseries = () => {
    return d3.extent(data[0]?.items, (d: any, index) => {
      return checkObjectTypeAndReturnDate(index, d);
    });
  };

  const checkIfAnalysis = () => {
    //if (
    //  pathHasAnalysis &&
    //  (objectInfo[0]?.ObjectType === "Package" ||
    //    objectInfo[0]?.ObjectType === "ProductionOrder")
    //) {
    //  return true;
    //}

    return false;
  };

  // Scales the canvas based on the sorted first and last index of the data parsed
  const dataXScale = () => {
    return d3.extent(data[0]?.items, (d: any) => d.date);
  };
  const xScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain(
          checkIfAnalysis() ? domainXScaleOnTimeseries() : (dataXScale() as any)
        )
        .range([0, width]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xMin, xMax, width]
  );

  const yMin = useMemo(
    () => d3.min(data, ({ items }) => d3.min(items, ({ value }) => value)),
    [data]
  );

  const yMax = useMemo(
    () =>
      d3.max(data, ({ items }) =>
        d3.max(items, ({ value }) => {
          return value;
        })
      ),
    [data]
  );

  const yScale = useMemo(() => {
    const indention = (yMax - yMin) * 0.6;
    return d3
      .scaleLinear()
      .domain([yMin - indention, yMax + indention])
      .range([height, 0]);
  }, [height, yMin, yMax]);

  const yScaleForAxis = useMemo(
    () => d3.scaleBand().domain([yMin, yMax]).range([height, 0]),
    [height, yMin, yMax]
  );

  // Returns the values for the YTick lines
  const yTickFormat = (d: any) =>
    `${parseFloat(d as string) > 0 ? "" : " "}${d3
      .format(".2%")(d / 100)
      .replace("%", "")}`;

  // Returns the values for the XTick lines
  const xTickFormat = (d: any) => {
    if (d3.timeFormat("%Y")(d) === "Jan") {
      return `${d3.timeFormat("%b")(d)}, ${d.toLocaleTimeString()}`;
    }  
    return ` ${d.toLocaleTimeString()}`;
  };
  return {
    yTickFormat,
    xScale,
    yScale,
    yScaleForAxis,
    xTickFormat,
  };
};

export default useController;
