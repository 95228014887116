import React from "react";

const CustomTable = (props: { children: JSX.Element | JSX.Element[], elementKey?: string }) => {
  return (
    <table className="w-full" >
      {props.children}
    </table>
  );
};

export default CustomTable;
