import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import useFetchTracking from "../../../customHooks/useFetchTracking";
import { objectSelector } from "../../../redux/objects/objectSlice";
import { fetchEitherForwardOrBackwardTracks } from "../../../redux/graph/actions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  forwardsTrackingObjectSelector,
  getForwardsTrackingObjects,
} from "../../../redux/tracking/forwardsTrackingSlice";
import { trackingPathSelector } from "../../../redux/tracking/trackingPathSlice";
import AFTPalette from "../../generics/aftpalette";
import CustomButton from "../../generics/CustomButton";
import { getObjectType } from "../../generics/utilities/utils";
import { formatLinearRelations } from "../../generics/utils/graphFormaters";
import CustomSwitch from "../../graph/canvas/graphMenu/CustomSwitch";
import { ErrorPage } from "../../graph/error/ErrorPage";
import RowGraph from "../../graph/treeView/RowGraph";
import TrackingContainer from "../TrackingContainer";
import TrackingTable from "../TrackingTable";

const ForwardsTracking = ({
  setValue,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { objects } = useAppSelector(objectSelector);
  const dispatch: any = useAppDispatch();
  const { forwardsTrackingObjects, loading, error } = useAppSelector(
    forwardsTrackingObjectSelector
  );
  const { trackingPath } = useAppSelector(trackingPathSelector);
  const trackingPathContainerRef = useRef<HTMLDivElement>(null);
  const [graphContainerHeight, setGraphContainerHeight] = useState("");
  const [graphLayout, setGraphLayout] = useState(true);

  const externalId: string = state?.ExternalID;
  const objectType: string = state?.ObjectType;
  useFetchTracking({
    info: {
      externalId,
      objectType,
    },
    trackingAction: getForwardsTrackingObjects,
  });

  useLayoutEffect(() => {
    if (trackingPathContainerRef && trackingPathContainerRef?.current) {
      const containerHeight =
        trackingPathContainerRef?.current.clientHeight - 100;
      setGraphContainerHeight(containerHeight.toString());
    }
  }, [trackingPath]);

  const showForwardsCompleteGraph = useCallback(() => {
    const parameters = {
      tracking: "forward",
      type: objectType,
      ExternalID: externalId,
      buttonType: "All Related Objects",
    };
    dispatch(fetchEitherForwardOrBackwardTracks(parameters));
    const routData = JSON.stringify({
      parameters,
      graphDispatcher: "fetchEitherForwardOrBackwardTracks",
    });
    // routData is navigated to /graph to initiat another request when the page is refereshed
    navigate("/graph", { state: routData });
  }, [dispatch, externalId, navigate, objectType]);

  const getTrackingObjectType = (trackingObjectType: string) => {
    const object = getObjectType(objects, trackingObjectType);

    return object.split("")[0].toLowerCase() + object.slice(1, object.length);
  };
  const showForwardsTrackingObjectsTable = () => {
    if (error) {
      return (
        <>
          <div className=" flex h-[90vh] items-center justify-center">
            <ErrorPage error={error} />
          </div>
        </>
      );
    } else {
      return (
        <div
          className={`${
            trackingPath?.path?.Path && trackingPath?.path?.Path.length > 0
              ? "grid grid-cols-6"
              : "block"
          }  w-full `}
        >
          <div
            className={`table-section-container ${
              trackingPath?.path?.Path && trackingPath?.path?.Path.length > 0
                ? "col-start-1 col-end-5"
                : ""
            }  w-[100%] pr-4`}
          >
            <div className="flex items-end justify-end">
              <CustomButton
                text="Show Complete Graph"
                width={"200px"}
                action={showForwardsCompleteGraph}
              ></CustomButton>
            </div>
            <div className="section-holder border-t-2 border-[#f8f3f3] mt-4">
              <div className=" pr-4  h-[80vh] overflow-y-scroll py-2  scroll-m-2">
                {forwardsTrackingObjects &&
                  Object.keys(forwardsTrackingObjects).map(
                    (trackingObject: string) => {
                      // Validates that the tracking object has a value, otherwise returns null
                      if (
                        forwardsTrackingObjects[
                          `${trackingObject}` as keyof typeof forwardsTrackingObjects
                        ].length === 0
                      ) {
                        return null;
                      }

                      const pathLength =
                        forwardsTrackingObjects[
                          `${trackingObject}` as keyof typeof forwardsTrackingObjects
                        ][0].Paths.length;

                      return (
                        <TrackingTable
                          tracking="forward"
                          setValue={setValue}
                          key={trackingObject}
                          parentData={{ externalId, objectType }}
                          tableDetails={{
                            label:
                              forwardsTrackingObjects[
                                `${trackingObject}` as keyof typeof forwardsTrackingObjects
                              ][0]?.Paths[0]?.RelatedObject?.ObjectType,
                            data: forwardsTrackingObjects[
                              `${trackingObject}` as keyof typeof forwardsTrackingObjects
                            ][0]?.Paths,
                            pathLength,
                          }}
                        />
                      );
                    }
                  )}
              </div>
            </div>
          </div>
          {trackingPath?.path?.Path && trackingPath?.path?.Path.length > 0 && (
            <div
              className="selected-path-views relatedObjects-table-bgColor rounded-t-[8px] rounded-b-[8px] col-start-5 col-end-7  w-full shadow-sm"
              ref={trackingPathContainerRef}
            >
              <div className="w-full  mt-2 ">
                <div className="flex items-center justify-between">
                  <h4 className="pl-4 text-[16px] text-[#3A6A78] font-[600]">
                    Path
                  </h4>
                  <div>
                    <CustomSwitch
                      value={graphLayout}
                      handleChange={(e) => {
                        setGraphLayout(e.target.checked);
                      }}
                    />
                  </div>
                </div>
                <div className="path-container max-h-[80vh] overflow-y-scroll border-t-2 border-[#f8f3f3]">
                  <RowGraph
                    layout={graphLayout}
                    height={graphContainerHeight + "px"}
                    linearGraph={formatLinearRelations(
                      forwardsTrackingObjects[
                        `${getTrackingObjectType(
                          trackingPath?.activeObjectType?.toLowerCase()
                        )}s` as keyof typeof forwardsTrackingObjects
                      ]?.[0],
                      trackingPath?.path
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };
  return (
    <div className="">
      {" "}
      <TrackingContainer>
        {loading ? (
          <div className="flex items-center justify-center mt-[40vh]">
            <ClipLoader
              size={40}
              loading={loading}
              color={AFTPalette.primary.purpleLight}
            />
          </div>
        ) : (
          <>{showForwardsTrackingObjectsTable()}</>
        )}
      </TrackingContainer>
    </div>
  );
};

export default ForwardsTracking;
