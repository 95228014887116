import _, { uniqBy } from "lodash";
import { truncate } from "./utils";

export const formatDataObject = (data: any) => {
  const nodes: any[] = [];
  const edges: any[] = [];

  // if no data, return empty arrays
  if (!data) return { nodes, edges };

  nodes.push({
    id: data.ID,
    label: truncate(data.ObjectType, 7) + " \n " + truncate(data.Name, 8),
    group: data.ObjectType,
    relatedObjectName: data.ObjectType,
    object_externalID: data.ExternalID,
    uniqByExternalIDAndType: data.ExternalID + data.ObjectType,
    ...data,
  });

  edges.push({
    from: data.ID,
    to: 2,
    label: data.ObjectType,
    font: { align: "middle" },
  });

  // remove duplicates
  return { nodes: uniqBy(nodes, "id"), edges };
};

// format objects with relations and add to the current graph
export const formatRelatedObjectsAndAddToCurrentGraph = (data: any) => {
  const nodes: any[] = [];
  const edges: any[] = [];
  // if no data, return empty arrays
  if (!data) return { nodes, edges };

  nodes.push({
    id: data.ID,
    label: truncate(data.ObjectType, 7) + " \n " + truncate(data.Name, 8),
    group: data.ObjectType,
    relatedObjectName: data.ObjectType,
    object_externalID: data.ExternalID,
    uniqByExternalIDAndType: data.ExternalID + data.ObjectType,
    ...data,
  });

  edges.push({
    id: data.ID,
    from: data.ID,
    to: 2,
    label: data.ObjectType,
    font: { align: "middle" },
  });

  for (let key in data?.RelatedObjects) {
    let value = data.RelatedObjects[key];
    for (const elementkey in value.Elements) {
      let element = value.Elements[elementkey];
      for (const relationkey in element.Relationships) {
        let relation = element.Relationships[relationkey];
        nodes.push({
          id: element?.Object?.ID,
          label:
            truncate(element.Object.ObjectType, 7) +
            "\n" +
            truncate(element.Object.Name, 8),
          group: element.Object.ObjectType,
          relatedObjectName: key,
          object_externalID: element.Object.ExternalID,
          uniqByExternalIDAndType:
            element.Object.ExternalID + element.Object.ObjectType,
          ...value,
          ...element,
          ...relation,
        });
        edges.push({
          id: relation.ID,
          from: relation?.SourceObjectID,
          to: relation?.TargetObjectID,
          label: relation.Label,
          font: { align: "middle" },
          ...relation,
        });
      }
    }
  }
  // remove duplicates and returns the nodes and the edges
  return { nodes: uniqBy(nodes, "id"), edges };
};

export const formatFilteredRelatedObjects = (data: any) => {
  const nodes: any[] = [];
  const edges: any[] = [];
  // if no data, return empty arrays
  if (!data) return { nodes, edges };

  nodes.push({
    id: data.ID,
    label: truncate(data.ObjectType, 7) + " \n " + truncate(data.Name, 8),
    group: data.ObjectType,
    relatedObjectName: data.ObjectType,
    object_externalID: data.ExternalID,
    uniqByExternalIDAndType: data.ExternalID + data.ObjectType,
    ...data,
  });

  edges.push({
    id: data.ID,
    from: data.ID,
    to: 2,
    label: data.ObjectType,
    font: { align: "middle" },
  });

  for (let key in data.RelatedObjects) {
    let value = data.RelatedObjects[key];
    for (const element of value.Elements) {
      nodes.push({
        id: element.ExternalID,
        label:
          truncate(element.Object.ObjectType, 7) +
          "\n" +
          truncate(element.Object.Name, 8),
        group: element.Object.ObjectType,
        relatedObjectName: key,
        object_externalID: element.Object.ExternalID,
        uniqByExternalIDAndType:
          element.Object.ExternalID + element.Object.ObjectType,
        date: element.Object.TimeStampDBInsertion,
        ...element,
        ...element.Object,
      });
      edges.push({
        id: element.ExternalID,
        from: data.ID,
        to: element.ExternalID,
        label: element.Relationships[0].Label,
        font: { align: "middle" },
      });
    }
  }
  return { nodes: uniqBy(nodes, "id"), edges };
};

export const formatLinearRelations = (mainObj: any, pathObject: any) => {
  const nodes: any[] = [];
  const edges: any[] = [];
  // if no data, return empty arrays
  if (!pathObject || !mainObj) return { nodes, edges };

  // format the initial object
  nodes.push({
    id: mainObj?.Object?.ID,
    label:
      truncate(mainObj?.Object?.ObjectType, 7) +
      " \n " +
      truncate(mainObj?.Object?.Name, 8),
    group: mainObj?.Object?.ObjectType,
    object_externalID: mainObj?.Object?.ExternalID,
    uniqByExternalIDAndType:
      mainObj?.Object?.ExternalID + mainObj?.Object?.ObjectType,
    ...mainObj?.Object,
  });
  edges.push({
    id: mainObj?.Object?.ID,
    from: mainObj?.Object?.ID,
    to: 2,
    label: mainObj?.Object?.ObjectType,
    font: { align: "middle" },
  });

  if (pathObject?.Path?.length > 1) {
    for (let i = 0; i < pathObject.Path.length; i += 2) {
      const splitPair = pathObject.Path.slice(i, i + 2);
      if (splitPair.length > 1) {
        nodes.push({
          id: splitPair[1].ID,
          label:
            truncate(splitPair[1].ObjectType, 7) +
            " \n " +
            truncate(splitPair[1].Name, 8),
          group: splitPair[1].ObjectType,
          object_externalID: splitPair[1].ExternalID,
          uniqByExternalIDAndType:
            splitPair[1].ExternalID + splitPair[1].ObjectType,
          ...splitPair[1],
        });
        edges.push({
          id: splitPair[0]?.ID,
          from: splitPair[0]?.SourceObjectID,
          to: splitPair[0]?.TargetObjectID,
          label: splitPair[0]?.Label,
          font: { align: "middle" },
        });
      }
    }
    // last path
    const lastIndex = pathObject.Path.length - 1;
    nodes.push({
      id: pathObject?.RelatedObject.ID,
      label:
        truncate(pathObject?.RelatedObject.ObjectType, 7) +
        " \n " +
        truncate(pathObject?.RelatedObject.Name, 8),
      group: pathObject?.RelatedObject.ObjectType,
      object_externalID: pathObject?.RelatedObject.ExternalID,
      uniqByExternalIDAndType:
        pathObject?.RelatedObject.ExternalID +
        pathObject?.RelatedObject.ObjectType,
      ...pathObject?.RelatedObject,
    });
    edges.push({
      id: pathObject?.Path[lastIndex]?.ID,
      from: pathObject?.Path[lastIndex]?.SourceObjectID,
      to: pathObject?.Path[lastIndex]?.TargetObjectID,
      label: pathObject?.Path[lastIndex]?.Label,
      font: { align: "middle" },
    });
  } else {
    // if there is only one item in the path list
    nodes.push({
      id: pathObject?.RelatedObject.ID,
      label:
        truncate(pathObject?.RelatedObject.ObjectType, 7) +
        " \n " +
        truncate(pathObject?.RelatedObject.Name, 8),
      group: pathObject?.RelatedObject.ObjectType,
      object_externalID: pathObject?.RelatedObject.ExternalID,
      uniqByExternalIDAndType:
        pathObject?.RelatedObject.ExternalID +
        pathObject?.RelatedObject.ObjectType,
      ...pathObject?.RelatedObject,
    });
    edges.push({
      id: pathObject?.Path[0]?.ID,
      from: pathObject?.Path[0]?.SourceObjectID,
      to: pathObject?.Path[0]?.TargetObjectID,
      label: pathObject?.Path[0]?.Label,
      font: { align: "middle" },
    });
  }

  // final return
  return {
    nodes: _.uniqBy(nodes, "id"),
    edges: _.uniqBy(edges, "id"),
  };
};
