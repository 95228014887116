import { FC } from "react";

type Props = {
  children: React.ReactElement;
  
};
const TrackingContainer: FC<Props> = ({ children }) => {
  return <div className={" mt-[32px] lg:mr-[32px] pb-10"}>{children}</div>;
};

export default TrackingContainer;
