import React, { FC, useState } from "react";
import { Field } from "formik";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FiCalendar } from "react-icons/fi";
import { getValue } from "../../../../../generics/utils/utils";

type Props = {
  label?: string;
  name: string;
  selfTouched: string;
  formikObject: any;
  width?: number;
};


const Datepicker: FC<Props> = ({
  label,
  name,
  selfTouched,
  width,
  ...rest
}) => {

  // update error state from formik error object
  const [errorMessage, seterrorMessage] = useState("")

  return (
    <div className="flex flex-col justify-start">
    <div className={`${errorMessage ? "border border-[red] rounded-sm" : "border-primary-borderColor"}`}>
      <Field name={name} {...rest}>
        {({ field, form }: any) => {
          const { setFieldValue } = form;
          const { value } = field;
          // get the error value for the corresponding date field
          const error =  getValue(form.errors, name)
          seterrorMessage(error)
          
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                components={{ OpenPickerIcon: FiCalendar }}
                renderInput={(params) => {
                  return (
                    <TextField
                      className=""
                      sx={{
                        svg: { color: "#9CA3AF", fontSize: "14px", marginLeft: "-15px", },
                        input: { color: "#3A6A78", padding: "8px", width: "100%", fontSize: "12px", backgroundColor: "white" },
                        label: { color: "#9CA3AF", fontSize: "12px" },
                      }}
                      InputLabelProps={{ shrink: false }}
                      size="small"
                      hiddenLabel
                      {...params}
                    />
                  );
                }}
                ampm={true}
                value={value}
                {...field}
                {...rest}
                inputFormat={"yy-MM-dd HH:mm:ss a "}
                views={["year", "day", "hours", "minutes", "seconds"]}
                label={value === null && label}
                onChange={(newDate: any) => {
                  setFieldValue(name, newDate);
                  seterrorMessage("")
                  if (!newDate) {
                    form.setFieldValue(selfTouched, false);
                  } else {
                    form.setFieldValue(selfTouched, true);
                  }
                }}
              ></DateTimePicker>
            </LocalizationProvider>
          );
        }}
      </Field>
    </div>
      {errorMessage && <div className="text-[12] text-[red]">{errorMessage}</div>}
    </div>
  );
};

export default Datepicker;
