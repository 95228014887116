import React, { FC } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

interface Props {
  label: any;
}

const Labels: FC<Props> = ({ label }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left">
        <thead className="text-xs font-[400] text-accent-grayMuted capitalize bg-accent-tableBg">
          <tr>
            <th scope="col" className="px-6 py-3">
              Attributes
            </th>
            <th scope="col" className="px-6 py-3">
              English
            </th>
            <th scope="col" className="px-6 py-3">
              German
            </th>
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(label).map(
            ([subKey, subValue]: any, index: number) => {
              return (
                <tr
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-accent-tableBg"
                  } hover:bg-accent-tableBg`}
                >
                  <td className="px-6 py-4">{subKey}</td>
                  <td className="px-6 py-4">{subValue.English}</td>
                  <td className="px-6 py-4">
                    {subValue?.German ? subValue.German : "null"}
                  </td>
                  <td
                    className="px-6 py-4"
                    key={index + new Date().getMilliseconds()}
                  >
                    <span className="flex justify-end items-center space-x-5">
                      <RiDeleteBinLine
                        data-type={"delete"}
                        color="#667085"
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                      <FiEdit2
                        data-type={"editUser"}
                        color="#667085"
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Labels;
