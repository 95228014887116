import React, {
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";

import {
  activeObjectSelector,
  getActiveObject,
  changeActivePage,
} from "../../../redux/activeObject/activeObject";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { objectSelector } from "../../../redux/objects/objectSlice";
import { headerTypes } from "../../../types/products";
import AFTPalette from "../../generics/aftpalette";
import { useNavigate } from "react-router-dom";
import {
  dispatchGetActiveObject,
  displayTitleValueLabel,
  formRelatedObjects,
  getObjectType,
  getRequestRelationship,
  machineColumnHeaders,
} from "../../generics/utilities/utils";
import { formatDateTime } from "../../generics/utils/utils";
import { ErrorPage } from "../../graph/error/ErrorPage";
import TableHeader from "../TableHeader";
import NextObjectLink from "./NextObjectLink";

type Props = {
  activeProduct: string;
};
const ObjectOverview: React.FC<Props> = ({ activeProduct }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { objects } = useAppSelector(objectSelector);
  const {
    activeObject,
    loading,
    error,
    activeNoTotal,
    changeActivePageStatus,
    activeObjectType,
    selectedRelatedObjectsColumns,
  } = useAppSelector(activeObjectSelector);

  // Holds the available objects for a particular object type
  const [objectsAvailable, setObjectsAvailable]: Array<any> =
    useState(activeObject);

  // Pagination configuration information
  const objectsPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  let valueToSkip = 10;
  let limit = 10;
  let previousSkippedObjects = useRef(0);
  let pageCount = Math.ceil(activeNoTotal / objectsPerPage);
  const generatedID = useId();
  const [activatePaginate, setActivatePaginate] = useState(false);

  // Gets the object type
  let currentObject = getObjectType(objects, activeProduct);

  // Updates the pagination table and sets the active page to be the current page
  const changePageToCurrent = useCallback(
    ({ selected }: { selected: number }) => {
      setPageNumber(() => {
        return selected;
      });
    },
    []
  );

  // Updates pages visited
  useEffect(() => {
    if (!firstRender && activatePaginate) {
      fetchNextData(pageNumber);
    }
    setFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  // Resets the pageNumber when the user visits a new object Type
  useEffect(() => {
    setPageNumber(0);
  }, [activeObjectType]);

  useEffect(() => {
    setPageNumber(0);
    setTimeout(() => {
      dispatch(changeActivePage(false));
    }, 400);
  }, [changeActivePageStatus, dispatch]);

  // Creates the relatedObjects of each parent object and dispatches an action to get the parent Objects
  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      dispatchGetActiveObject(activeProduct);
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentObject, activeProduct, dispatch, objects]);

  // Adds an ID to the list of Objects
  useLayoutEffect(() => {
    const addIdToAvailableObjects = () => {
      previousSkippedObjects.current = JSON.parse(
        localStorage.getItem("skip") as string
      );

      // Generates an ID for objects received from the api when the next button is clicked
      const count: any =
        previousSkippedObjects.current !== null &&
        Array.from(
          Array(previousSkippedObjects.current),
          (_, index) => previousSkippedObjects.current + index + 1
        );

      let objectDuplicate = activeObject?.map((item: any, index: number) => {
        let itemValue;
        if (
          typeof item.id === "undefined" &&
          previousSkippedObjects.current === null
        ) {
          itemValue = {
            ...item,
            id: index + 1,
          };
        } else {
          itemValue = {
            ...item,
            id: count[index],
          };
        }
        return itemValue;
      });

      setObjectsAvailable(() => objectDuplicate);
    };
    addIdToAvailableObjects();
  }, [activeObject, activeNoTotal]);

  useEffect(() => {
    if (selectedRelatedObjectsColumns?.length > 0) {
      fetchSelectedRelatedObjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct, selectedRelatedObjectsColumns]);

  const fetchSelectedRelatedObjects = () => {
    const currentObjectRelatedObjects = formRelatedObjects(
      objects,
      currentObject
    );
    localStorage.setItem(
      "relatedObjects",
      JSON.stringify(currentObjectRelatedObjects)
    );

    // RETURNS ONLY THE NEEDED RELATED OBJECTS FOR THE CONFIG
    const relatedObjectsConfig = getRequestRelationship(
      currentObjectRelatedObjects,
      selectedRelatedObjectsColumns
    );

    dispatch(
      getActiveObject({
        objectType: currentObject,
        relatedObject: relatedObjectsConfig,
        limit: 10,
      })
    );
  };

  const fetchNextData = (activePageNumber: number) => {
    const relatedObjects = formRelatedObjects(objects, currentObject);
    // RETURNS ONLY THE NEEDED RELATED OBJECTS FOR THE CONFIG
    const singleTrueRelationships = getRequestRelationship(
      relatedObjects,
      selectedRelatedObjectsColumns
    );
    // Fetches the data with a skip based on the specified skip value
    dispatch(
      getActiveObject({
        objectType: currentObject,
        relatedObject: singleTrueRelationships,
        limit: limit,
        skipValue: activePageNumber * valueToSkip,
      })
    );
  };

  const openObjectRelationships = (e: any, objectID: string, product: any) => {
    if (e.currentTarget.innerText !== "-") {
      const relatedObjectValue =
        product?.RelatedObjects?.[objectID]?.Elements[0]?.Object;
      const objectName =
        product?.RelatedObjects?.[objectID]?.Elements[0]?.Object?.Name;
      navigate(`${objectName}`, {
        state: relatedObjectValue,
      });
    }
  };

  const styleRelationships = (product: any, relatedObjectName: string) => {
    const linkClass = "link-class";
    return showRelationship(product, `${relatedObjectName}`) !== "-"
      ? linkClass
      : "";
  };

  if (loading) {
    return (
      <>
        <div
          key={"loader"}
          className="w-[100%] overflow-hidden h-[40vh] flex items-center justify-center"
          style={{ textAlign: "center", marginTop: "10vh" }}
        >
          <ClipLoader
            size={40}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      </>
    );
  }

  // Returns the error message when error occurs
  if (!loading && error) {
    return (
      <div className=" flex h-[70vh] items-center justify-center">
        <ErrorPage error={error} />
      </div>
    );
  }

  // check whether to add s to objecttype
  const ss = activeNoTotal > 1 ? "s" : "";
  return (
    <div key={"ObjectOverviewTable" + generatedID}>
      {objectsAvailable?.length > 0 &&
      currentObject?.toLowerCase() ===
        objectsAvailable[0]?.["ObjectType"]?.toLowerCase() ? (
        <div className="mt-[32px] bg-[#fff] border-l-[1px] pr-[8px] md:pr-[0px] pb-[16px] md:pb-0 w-[100%] mb-[20px]  md:border-b-[1px] md:border-r-[1px] rounded-[8px] ">
          <div className="table-container w-full overflow-x-auto border-t-[1px] border-b-[1px] border-r-[1px] rounded-[8px] ">
            <table className="w-[100%]" key={"object-overview" + generatedID}>
              <TableHeader
                showSortIcon={true}
                center={false}
                pageType={headerTypes.objectOverview}
                tableHeader={machineColumnHeaders?.concat(
                  selectedRelatedObjectsColumns
                )}
              />
              <tbody className="objectOverviewTbody w-full z-[5]">
                {/* <!-- Table Rows --> */}
                {objectsAvailable?.length > 0 &&
                  objectsAvailable?.map((product: any, index: any) => (
                    <tr
                      key={product?.id?.toString() + product?.Name?.toString()}
                      className="border-t-[0.1px] text-[#3A6A78] space-x-10"
                    >
                      <td className="!text-[#3A6A78] pl-5"> {product?.id}.</td>
                      <td className="link-class relative table-cell-Style">
                        <div className="inline-flex  text-justify ">
                          <NextObjectLink
                            to={product?.Name}
                            name={
                              selectedRelatedObjectsColumns?.length > 4 &&
                              product?.Name?.length > 15
                                ? product?.Name.slice(0, 10) + ".."
                                : product?.Name
                            }
                            state={product}
                          />
                        </div>
                      </td>
                      <td className="relative table-cell-Style">
                        <div className="">
                          {selectedRelatedObjectsColumns?.length > 4 &&
                          product?.["ExternalID"]?.length > 15
                            ? product?.["ExternalID"].slice(0, 10) + ".."
                            : product?.["ExternalID"]}
                        </div>
                      </td>
                      <td className="relative table-cell-Style">
                        {typeof product?.["TimeStampCreation"] === "undefined"
                          ? formatDateTime(product["TimeStampDBInsertion"])
                          : formatDateTime(product["TimeStampCreation"])}
                      </td>

                      {/* Related object relationship status */}

                      {selectedRelatedObjectsColumns?.length > 0 &&
                        selectedRelatedObjectsColumns.map(
                          (relatedObjectName: string, id: number) => {
                            return (
                              <td
                                key={relatedObjectName}
                                onClick={(e) => {
                                  openObjectRelationships(
                                    e,
                                    relatedObjectName,
                                    product
                                  );
                                }}
                                className={`${styleRelationships(
                                  product,
                                  relatedObjectName
                                )} table-cell-Style `}
                              >
                                {showRelationship(
                                  product,
                                  `${relatedObjectName}`
                                )}
                              </td>
                            );
                          }
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="relative w-full h-fit overflow-x-scroll overflow-y-hidden pt-[12px] md:static  md:overflow-hidden">
            <div className="pagination-container relative w-[full] h-[40px] left-[15px] top-[12px] pr-[10px] md:pr-[0px] pb-[40px] md:pb-[0px] md:static md:bottom-[16px] md:mr-[24px] md:h-[46px] md:top-[26px]  ">
              <div className="flex justify-between align-middle mt-4">
                <div className="flex text-primary-textNormal space-x-[5px] ml-5">
                  <div className="text-primary-textNormal text-sm">
                    {activeNoTotal}
                  </div>
                  <div className="text-primary-textNormal text-sm">
                    {displayTitleValueLabel(currentObject, objects) + ss}
                  </div>
                </div>
                <ReactPaginate
                  previousLabel={
                    <span>
                      <img
                        className="inline"
                        src="/assets/PreviousIcon.svg"
                        alt="next icon label"
                      />
                    </span>
                  }
                  nextLabel={
                    <span>
                      <img
                        className="inline"
                        src="/assets/NextIcon.svg"
                        alt="next icon label"
                      />
                    </span>
                  }
                  onClick={() => setActivatePaginate(true)}
                  pageCount={pageCount}
                  onPageChange={changePageToCurrent}
                  containerClassName={`pagination`}
                  previousLinkClassName={`previousButton`}
                  nextLinkClassName={`nextButton`}
                  disabledClassName={`pagination-disabled`}
                  activeClassName={`activePagination`}
                  activeLinkClassName={`activePage`}
                  initialPage={pageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          key={"loader"}
          className="w-[100%] overflow-hidden h-[40vh] flex items-center justify-center"
          style={{ textAlign: "center", marginTop: "10vh" }}
        >
          <ClipLoader
            size={40}
            loading={loading}
            color={AFTPalette.primary.purpleLight}
          />
        </div>
      )}
    </div>
  );
};

//   Returns the relationship of the objects
export const showRelationship = (productValue: any, objectName: string) => {
  let value: string;
  if (
    productValue?.RelatedObjects &&
    objectName in productValue?.RelatedObjects
  ) {
    if (productValue?.RelatedObjects?.[objectName]?.Elements.length > 1) {
      value = "Many";
    } else {
      value =
        productValue?.RelatedObjects?.[objectName]?.Elements[0]?.Object?.Name;
    }
  } else {
    value = "-";
  }
  return value;
};

// Shows the "More" keyword when an object has more objects available
export function hasAdditionalObjects(
  product: Array<string> | any
): React.ReactNode {
  if (product.length > 0 && Object?.keys(product?.RelatedObjects)?.length > 4) {
    return "More";
  }
  return "-";
}

export const styleManyRelationships = (val: string) => {
  return val === "Many" || "More" ? "link-class" : "";
};

export default ObjectOverview;
