import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PreviousObjectState {
  previousState: any;
}

const initialState: PreviousObjectState = {
  previousState: [],
};

const breadCrumbSlice = createSlice({
  name: "breadCrumbSlice",
  initialState,
  reducers: {
    setPreviousState: (state, { payload }: PayloadAction<any>) => {
      state.previousState = Array.from(state.previousState).concat(payload);
    },
    removePreviousState: (state) => {
      const currentValue = Array.from(state.previousState);
      currentValue.pop();
      state.previousState = currentValue;
    },
    // Removes the skipped states states
    removeExcessPreviousState: (state, { payload }: PayloadAction<any>) => {
      const previousStateValue = Array.from(state.previousState);
      const currentObject = payload;

      //  Find object Index
      const objectIndex = previousStateValue?.findIndex(
        (item: any) =>
          item?.["ExternalID"] === currentObject?.["ExternalID"] &&
          item?.["ObjectType"] === currentObject?.["ObjectType"]
      );
      state.previousState = previousStateValue.slice(0, objectIndex);
    },
    resetPreviousState: (state) => {
      state.previousState = [];
    },
  },
});

export const {
  setPreviousState,
  removePreviousState,
  removeExcessPreviousState,
  resetPreviousState,
} = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;

export const breadCrumbSelector = (state: {
  previousState: PreviousObjectState;
}) => state.previousState;
