import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AlertComponent from "../components/generics/AlertComponent";
import FormHeaderComponent from "../components/generics/FormHeaderComponent";
import LoadingSpinner from "../components/generics/LoadingSpinner";
import { showIcon } from "../components/generics/utilities/utils";
import { apiUrl } from "../public/config";
import Api from "../services/api";
import { clearAuthToken, decodeToken, setAuthToken } from "../services/auth";
import { validateEmail } from "../services/validation";

const SignUp = () => {
  const location = useLocation()?.search;
  const token: any =
    location?.length > 0 &&
    (new URLSearchParams(location)?.get("token") as string);
  const userEmail: any =
    location?.length > 0 &&
    (new URLSearchParams(location)?.get("email") as string);

  const tokenValue: any =
    location?.length > 0 &&
    decodeToken(new URLSearchParams(location).get("token") as any);

  const userInfo: {
    IsActive: boolean;
    IsRegistered: boolean;
    UserName: string;
  } = tokenValue?.sub;

  const [isEyeIconOpen, setIsEyeIconOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loginError, setLoginError] = useState({
    email: false,
    newName: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newUsername] = useState(userInfo?.UserName);

  const email = useRef<HTMLInputElement>(null);
  const newName = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  clearAuthToken();

  const setLoginErrorState = (key: string) => {
    setLoginError((prev: any) => {
      return {
        ...prev,
        [key]: true,
      };
    });
  };

  const validateForm = () => {
    let key;
    // Validates the email field
    if (email.current !== null) {
      if (email.current.value === "") {
        setHasError(true);
        key = "email";
        setLoginErrorState(key);
        setErrorMessage("Sorry, the email field cannot be empty.");
        return false;
      }
      const result = validateEmail(email.current.value);
      if (!result) {
        setHasError(true);
        key = "email";
        setLoginErrorState(key);
        setErrorMessage("Please provide a valid email.");
        return false;
      }
      setHasError(false);
    }
    // Validates the username field
    if (newName.current !== null) {
      if (newName.current.value === "") {
        setHasError(true);
        key = "email";
        setLoginErrorState(key);
        setErrorMessage("Sorry, the new username field cannot be empty.");

        return false;
      }
      setHasError(false);
    }
    // Validates the password field
    if (password.current !== null) {
      if (password.current.value.length <= 7) {
        setHasError(true);
        key = "password";
        setLoginErrorState(key);
        setErrorMessage("Password must be 8 characters or more");
        return false;
      }
      setHasError(false);
    }
    return true;
  };

  const SignUpUser = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      return null;
    }
    const user = {
      UserName: userInfo?.UserName ? userInfo?.UserName : "",
      NewUserName: newName.current !== null && newName.current.value,
      Password: password.current !== null && password.current.value,
    };

    setLoading(true);
    // Submits the data
    try {
      const response = await Api({
        url: `${apiUrl}/user/register`,
        method: "PATCH",
        data: user,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);

      if (response.status === 200) {
        setAuthToken(token);
        setLoginSuccess(true);
        setTimeout(() => {
          setLoginSuccess(false);
          navigate("/login");
        }, 3500);
      }
    } catch (error: any) {
      setLoading(false);
      setHasError(true);
      if (error?.response?.data?.Error.includes("User is already registered")) {
        setErrorMessage("Sorry, user with specified name already exist");
      } else {
        setErrorMessage("Sorry, we are unable to create your account.");
      }
    }
  };

  const styleForm = (style1: string, style2: string) => {
    return hasError ? style1 : style2;
  };
  const closeAlert = () => {
    setLoginSuccess(false);
  };

  return (
    <div className="bg-[#fff] relative">
      {loginSuccess && (
        <div className="w-[359px] mt-10 md:absolute mx-auto md:top-10 md:right-20 ">
          <AlertComponent
            severity={"success"}
            message={"Account creation Successful!"}
            closeDialog={closeAlert}
          />
        </div>
      )}
      <div className="login w-full h-[100vh] flex-wrap bg-[#fff] flex items-center justify-center">
        <div
          className={`login-container w-[85%] h-[550px] md:w-[60%] md:h-[600px] lg:w-[30%]  ${styleForm(
            "lg:h-[700px]",
            "lg:h-[600px]"
          )}  `}
        >
          <FormHeaderComponent title={"Create an account"} />
          {hasError && (
            <div className="error-container border-[1px] border-[#FDA29B] flex items-center  p-[16px] mt-[32px] h-[72px] rounded-[8px] bg-[#FFFBFA] ">
              <div>
                <img src="/assets/error_icon.svg" alt="" />
              </div>
              <p className="ml-[13px] text-[14px] text-[#B42318]">
                {errorMessage}
              </p>
            </div>
          )}
          <form
            onSubmit={(e) => SignUpUser(e)}
            className="login-form mt-[32px] !text-[#667085]"
          >
            <div className="name-container">
              <label className="block  text-[14px] font-normal mb-[4px]">
                Email*
              </label>
              <div
                className={`name-container flex items-center border-[1px] ${
                  loginError?.email ? "border-[#FDA29B] " : "border-[#D0D5DD] "
                } shadow-sm rounded-[8px] ${newUsername && "bg-[#f1f1f1]"} `}
                onClick={() => {
                  setHasError(false);
                  setLoginError((prev: any) => {
                    return {
                      ...prev,
                      email: false,
                    };
                  });
                }}
              >
                <input
                  className={`name-inputField text-[16px]  w-full ml-[0.2px] h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none`}
                  placeholder="Enter your email"
                  type="text"
                  value={userEmail ? userEmail : ""}
                  readOnly={userEmail}
                  disabled={userEmail}
                  ref={email}
                />
                {loginError?.email && (
                  <img
                    className="p-4 cursor-pointer"
                    src="/assets/error_icon.svg"
                    alt="error Icon"
                  />
                )}
              </div>
            </div>
            <div className="name-container  mt-[20px]">
              <label className="block  text-[14px] font-normal mb-[4px]">
                Username*
              </label>
              <div
                className={`name-container flex items-center border-[1px] ${
                  loginError?.newName
                    ? "border-[#FDA29B] "
                    : "border-[#D0D5DD] "
                } shadow-sm rounded-[8px] `}
                onClick={() => {
                  setHasError(false);
                  setLoginError((prev: any) => {
                    return {
                      ...prev,
                      name: false,
                    };
                  });
                }}
              >
                <input
                  className={`name-inputField text-[16px]  w-full ml-[0.2px] h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none`}
                  placeholder="Enter your name"
                  type="text"
                  defaultValue={newUsername}
                  ref={newName}
                />
                {loginError?.newName && (
                  <img
                    className="p-4 cursor-pointer"
                    src="/assets/error_icon.svg"
                    alt="error Icon"
                  />
                )}
              </div>
            </div>
            <div className="password-container mt-[20px]">
              <label className="block text-[#344054] text-[14px] font-normal mb-[4px]">
                Password*
              </label>
              <div className="password-inputFieldContainer">
                <div
                  className={`password flex items-center  border-[1px] ${
                    loginError?.password
                      ? "border-[#FDA29B] "
                      : "border-[#D0D5DD] "
                  } shadow-sm rounded-[8px]`}
                  onClick={() => {
                    setHasError(false);
                    setLoginError((prev: any) => {
                      return {
                        ...prev,
                        password: false,
                      };
                    });
                  }}
                >
                  <input
                    className="password-inputField w-full text-[16px] !text-[#667085] h-[44px] rounded-[8px] border-none bg-[rgba(255,255,255,0.5)] placeholder  focus:!outline-none"
                    placeholder="Enter your Password"
                    type={`${isEyeIconOpen ? "text" : "password"}`}
                    ref={password}
                    autoComplete="off"
                  />
                  {loginError?.password ? (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => {
                        setHasError(false);
                        setIsEyeIconOpen(!isEyeIconOpen);
                      }}
                      src="/assets/error_icon.svg"
                      alt="Password Icon"
                    />
                  ) : (
                    <img
                      className="p-4 cursor-pointer"
                      onClick={() => setIsEyeIconOpen(!isEyeIconOpen)}
                      src={`/assets/${showIcon(isEyeIconOpen)}`}
                      alt="Password Icon"
                    />
                  )}
                </div>
              </div>
              <label className="text-[14px] mt-[6px]">
                Must be at least 8 characters
              </label>
            </div>
            <div className="w-full mt-[24px]">
              <button className=" bg-[#3A6A78] text-[16px] w-[100%] h-[44px] rounded-[8px] text-[#fff]">
                {loading ? <LoadingSpinner loading={loading} /> : "Get started"}
              </button>
            </div>
            <div className="register-now text-[14px] mt-[32px] flex items-center justify-center">
              <label className="text-[#667085] text-[normal] ">
                {" "}
                Already have an account?{" "}
                <Link to="/login">
                  <span className="text-[#3A6A78]"> Log in</span>
                </Link>{" "}
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
