import React, { useState, useEffect, useRef, useId } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import OrderIcon from "./tableIcons/OrderIcon";
import {
  changeActivePage,
  getActiveObject,
} from "../../redux/activeObject/activeObject";
import { useAppDispatch } from "../../redux/hooks";
import { headerTypes } from "../../types/products";
import { useLocation } from "react-router-dom";
import {
  resetDetailsPageNumber,
  sortRelatedObjects,
} from "../../redux/objects/objectDetailsSlice";

type Props = {
  tableHeader: Array<string>;
  relationshipName?: string;
  onlyActiveSettings?: {
    onlyActiveFlag: boolean;
    currentObjectType: string;
  };
  center?: boolean;
  pageType: string;
  showSortIcon: boolean;
};

const TableHeader: React.FC<Props> = ({
  tableHeader,
  center,
  pageType,
  relationshipName,
  onlyActiveSettings,
  showSortIcon,
}) => {
  const location: any = useLocation()?.state;
  const sortRelationshipName = relationshipName;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const id = useId();
  const format = useRef<boolean>();
  format.current = false;
  const [filterHeaders, setFilterHeaders] = useState(
    JSON.parse(localStorage.getItem("filterHeaders") as any) || [
      {
        name: "name",
        isActive: false,
        sortType: "none",
      },
      {
        name: "externalid",
        isActive: false,
        sortType: "none",
      },
      {
        name: "creation date",
        isActive: false,
        sortType: "none",
      },
    ]
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender]);

  const changeOrderFormat = (
    settings: {
      name: string;
      isActive: boolean;
      sortType: string;
    },
    titleValue: string
  ) => {
    // Gets the current object
    const currentObjectType = JSON.parse(
      localStorage.getItem("activeProduct") as string
    );
    // Used in handling sorting by creationDate
    const creationDate = "Creation Date";

    // Gets the current related objects
    const relatedObjects =
      JSON.parse(localStorage.getItem("relatedObjects") as any) || [];

    const order = settings.sortType;
    const orderByValue =
      titleValue === creationDate ? "TimeStampDBInsertion" : titleValue;

    if (pageType === headerTypes.objectOverview) {
      localStorage.setItem(
        "filterInfo",
        JSON.stringify({
          orderBy: orderByValue,
          order,
        })
      );

      dispatch(
        getActiveObject({
          objectType: currentObjectType,
          relatedObject: relatedObjects,
          limit: 10,
        })
      );
    } else if (pageType === headerTypes.objectDetailsOverview) {
      localStorage.setItem(
        "filterDetailsInfo",
        JSON.stringify({
          orderBy: orderByValue,
          order,
        })
      );

      dispatch(
        sortRelatedObjects({
          objectType: location.ObjectType,
          externalId: location.ExternalID,
          onlyActive:
            onlyActiveSettings !== undefined
              ? onlyActiveSettings.onlyActiveFlag
              : true,
          sortRelationshipName,
          limit: 10,
        })
      );
    }

    // Updates the pagination active page and  resets the pagination active to start from the first element.
    dispatch(resetDetailsPageNumber(true));
    // Updates the pagination active page and the this resets the pagination active to start from the first element.
    dispatch(changeActivePage(true));
  };

  const checkAndReturnSortType = (type: string) => {
    if (type === "none") {
      return "ASC";
    } else if (type === "ASC") {
      return "DESC";
    } else if (type === "DESC") {
      return "ASC";
    }
  };
  const showToolTipMessage = (title: string) => {
    let currentSortTypeValues: any = {};

    filterHeaders.map(
      (item: { name: string; isActive: boolean; sortType: string }) => {
        if (item.name === title) {
          currentSortTypeValues = item;
        }
        return "";
      }
    );

    // Use the elements sort type to determine which order type to show in the tooltip
    if (currentSortTypeValues.sortType === "DESC") {
      return "Sort Column A-Z";
    } else if (currentSortTypeValues.sortType === "ASC") {
      return "Sort Column Z-A ";
    }
    return "Sort Column A-Z";
  };

  const showIcon = (title: string, index: number) => {
    // Toggles the Icon button
    function toggleIcon() {
      let newValue = filterHeaders.map((headerItem: any, loopIndex: number) => {
        if (index === loopIndex) {
          headerItem.isActive = true;
          headerItem.sortType = checkAndReturnSortType(headerItem.sortType);
          changeOrderFormat(headerItem, title);
          return {
            ...headerItem,
          };
        }
        headerItem.isActive = false;
        headerItem.sortType = "none";
        return {
          ...headerItem,
        };
      });
      setFilterHeaders(newValue);
      localStorage.setItem("filterHeaders", JSON.stringify(filterHeaders));
    }

    if (showSortIcon) {
      return (
        <span className="">
          <Tooltip
            title={showToolTipMessage(title.toLowerCase())}
            placement="top"
          >
            <IconButton onClick={toggleIcon}>
              {/* Renders the sort Icon based on the filterSettings object
                    that holds the properties needed to determine which column is active  */}
              <OrderIcon filterSettings={filterHeaders[index]} index={index} />
            </IconButton>
          </Tooltip>
        </span>
      );
    }

    return null;
  };

  return (
    <thead
      key={`header${id}`}
      className={`text-[12px]  h-[44px]  ${
        pageType === headerTypes.trackingDetailsOverview ? "" : "sticky top-0"
      }`}
    >
      <tr className="">
        <th className="w-[32px] bg-white "></th>
        {tableHeader?.map((title, index: number) => {
          return (
            <th key={title} className="px-4 text-left bg-[#fff]">
              <div className="mx-auto !text-[14px] w-[120px] md:w-[130px] lg:w-[100%]">
                <span className="font-bold text-[#3A6A78]">{title} </span>
                {title.toLowerCase() === filterHeaders[0]?.name ||
                title.toLowerCase() === filterHeaders[1]?.name ||
                title.toLowerCase() === filterHeaders[2].name
                  ? showIcon(title, index)
                  : null}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
